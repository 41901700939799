import BsForm from 'ember-bootstrap/components/bs-form';

export default class ValidatedBsForm extends BsForm {
  get hasValidator() {
    return !!this.model?.validate;
  }

  validate(model) {
    model.validate();
    if (!model.validations.isValid) {
      throw new Error();
    }
  }
}
