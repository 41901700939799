let hasLoadedCytoscape = false;

const getCytoscape = async () => {
  const { default: cytoscape } = await import('cytoscape');

  if (!hasLoadedCytoscape) {
    hasLoadedCytoscape = true;
    const [
      { default: klay },
      { default: cytoscapePopper },
      { default: panzoom },
      { createPopper },
    ] = await Promise.all([
      import('cytoscape-klay'),
      import('cytoscape-popper'),
      import('cytoscape-panzoom'),
      import('@popperjs/core'),
    ]);

    cytoscape.use(klay);
    cytoscape.use(cytoscapePopper(createPopper));
    panzoom(cytoscape);
  }

  return cytoscape;
};

export default getCytoscape;
