import { template as template_2a98204d33c14eae8e1920fa481b4fd4 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import BlobImage from 'eflex/components/blob-image';
import { and } from 'ember-truth-helpers';
import { task, timeout } from 'ember-concurrency';
import { CameraTriggerValues } from 'eflex/constants/tasks/trigger-types';
import { waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';
import { eventBus } from 'eflex/modifiers';
import { on } from '@ember/modifier';
import { triggerEventBus, eventBus as eventBusHelper, getHardware } from 'eflex/helpers';
import Modifier, { modifier } from 'ember-modifier';
import { isTesting, macroCondition } from '@embroider/macros';
const CAPTURE_TRIGGER_TYPES = new Set([
    CameraTriggerValues.captureAdvance,
    CameraTriggerValues.taskActive
]);
const AUTO_TRIGGER_TYPES = new Set([
    'captureAdvance',
    'taskActive'
]);
const getTestBarcodeReader = ()=>{
    return {
        isCancelled: false,
        stopContinuousDecode () {},
        reset () {},
        decodeFromVideoDevice () {
            return Promise.resolve();
        }
    };
};
const getCanvasBlob = async (videoElement, imageWidth, imageHeight)=>{
    if (macroCondition(isTesting())) {
        return new Blob([
            ''
        ], {
            type: 'image/jpeg'
        });
    }
    const canvas = document.createElement('canvas');
    canvas.width = imageWidth;
    canvas.height = imageHeight;
    const context = canvas.getContext('2d');
    context.drawImage(videoElement, 0, 0, imageWidth, imageHeight);
    const { canvasToBlob } = await import('blob-util');
    const blob = await canvasToBlob(canvas, 'image/jpeg');
    canvas.remove();
    return blob;
};
const cleanupBarcodeScanner = (barcodeScanner)=>{
    if (!barcodeScanner) {
        return;
    }
    barcodeScanner.isCancelled = true;
    barcodeScanner.stopContinuousDecode();
    barcodeScanner.videoCanPlayListener = null;
    barcodeScanner.videoEndedListener = null;
    barcodeScanner.videoPlayingEventListener = null;
    barcodeScanner.reset();
};
const getReader = async (barcodeSymbology)=>{
    if (macroCondition(isTesting())) {
        return getTestBarcodeReader();
    }
    const { BrowserMultiFormatReader, DecodeHintType } = await import('@zxing/library');
    if (barcodeSymbology != null) {
        const hints = new Map([
            [
                DecodeHintType.POSSIBLE_FORMATS,
                [
                    barcodeSymbology
                ]
            ]
        ]);
        return new BrowserMultiFormatReader(hints);
    } else {
        return new BrowserMultiFormatReader();
    }
};
class WebCamScanner extends Modifier {
    #previousCameraId;
    #previousBarcodeSymbology;
    #barcodeScanner;
    #didSetup = false;
    constructor(){
        super(...arguments);
        registerDestructor(this, ()=>{
            cleanupBarcodeScanner(this.#barcodeScanner);
        });
    }
    modify(element, _positional, { cameraId, barcodeSymbology, onScan }) {
        if (this.#didSetup) {
            const cameraIdChanged = this.#previousCameraId !== cameraId;
            const barcodeSymbologyChanged = this.#previousBarcodeSymbology !== barcodeSymbology;
            this.#previousCameraId = cameraId;
            this.#previousBarcodeSymbology = barcodeSymbology;
            if (!cameraIdChanged && !barcodeSymbologyChanged) {
                return;
            }
        }
        this.#didSetup = true;
        this.start.perform(element, cameraId, barcodeSymbology, onScan);
    }
    start = task({
        keepLatest: true
    }, waitFor(async (element, cameraId, barcodeSymbology, onScan)=>{
        cleanupBarcodeScanner(this.#barcodeScanner);
        const barcodeScanner = this.#barcodeScanner = await getReader(barcodeSymbology);
        const seen = new Set();
        await barcodeScanner.decodeFromVideoDevice(cameraId, element, (value, err)=>{
            if (barcodeScanner.isCancelled) {
                cleanupBarcodeScanner(barcodeScanner);
                return;
            }
            if (err != null) {
                return;
            }
            const { text } = value;
            if (seen.has(text)) {
                return;
            }
            seen.add(text);
            onScan(text);
        });
    }));
}
export default class JemWebCam extends Component {
    @service
    eventBus;
    @service
    webCam;
    @service
    notifier;
    @tracked
    capturedWebCamImage;
    #previousTask = this.args.task;
    get cameraId() {
        let cameraId;
        if (this.args.isWebCamLoad) {
            cameraId = getHardware(this.args.station, this.args.task)?.cameraId;
        } else {
            cameraId = this.args.station?.webCam?.cameraId;
        }
        if (!this.webCam.devices.some((item)=>item.deviceId === cameraId)) {
            return undefined;
        }
        return cameraId;
    }
    onPlaying = task({
        restartable: true
    }, waitFor(async ({ target })=>{
        this.webCam.cameraStreamPlaying = true;
        const triggerType = this.args.task?.triggerType;
        if (CAPTURE_TRIGGER_TYPES.has(triggerType)) {
            await this.onCaptureImage.perform(target);
        }
    }));
    onCaptureImage = task({
        restartable: true
    }, waitFor(async (element)=>{
        const webCam = this.webCam;
        if (!webCam.cameraStreamPlaying) {
            this.notifier.sendWarning('cameraNotLoaded');
            return;
        }
        const triggerType = this.args.task.triggerType;
        const webcamDelay = this.args.task.webcamDelay;
        const isAuto = this.args.task.isAuto;
        const imageWidth = element.videoWidth;
        const imageHeight = element.videoHeight;
        let image;
        if (triggerType !== CameraTriggerValues.captureButton && globalThis.ImageCapture && webCam.cameraStream) {
            const imageCapture = new ImageCapture(webCam.cameraStream.getVideoTracks()[0]);
            if (isAuto) {
                await timeout(webcamDelay * 1000);
            }
            image = await imageCapture.takePhoto({
                imageWidth,
                imageHeight
            });
        } else {
            image = await getCanvasBlob(element, imageWidth, imageHeight);
        }
        this.capturedWebCamImage = image;
        this.eventBus.trigger('imageCaptured', {
            treeTask: this.args.task,
            image
        });
    }));
    willDestroy() {
        super.willDestroy(...arguments);
        this.eventBus.trigger('clearCapturedImage');
    }
    onDidUpdate = task(waitFor(async (element, [treeTask])=>{
        const previousTask = this.#previousTask;
        this.#previousTask = treeTask;
        if (!treeTask || !treeTask.usesWebCam || previousTask === treeTask) {
            return;
        }
        const isStarted = this.args.childStatus?.isStarted;
        if (isStarted && AUTO_TRIGGER_TYPES.has(treeTask.triggerType)) {
            await this.onCaptureImage.perform(element);
        } else {
            this.eventBus.trigger('clearCapturedImage');
        }
    }));
    clearCapturedImage = ()=>{
        if (this.capturedWebCamImage) {
            this.capturedWebCamImage = null;
        }
    };
    startWebCam = modifier((element, [cameraId])=>{
        this.webCam.initStream.perform(cameraId);
        return ()=>{
            this.webCam.destroyStreamTracks();
        };
    });
    static{
        template_2a98204d33c14eae8e1920fa481b4fd4(`
    {{eventBusHelper clearCapturedImage=this.clearCapturedImage}}

    {{#if @isWebCamLoad}}
      <video
        class="h-100 w-100 position-absolute test-web-cam-scanner"
        {{WebCamScanner
          cameraId=this.cameraId
          barcodeSymbology=@station.barcodeSymbology
          onScan=@onScan
        }}
        ...attributes
      />
    {{else if @childStatus.visionUrl}}
      <img
        class="h-100 w-100 position-absolute object-fit-contain vision-image"
        src={{@childStatus.fullVisionUrl}}
        {{didUpdate this.onDidUpdate.perform @task}}
      />
    {{else if this.capturedWebCamImage}}
      <BlobImage
        class="h-100 w-100 position-absolute object-fit-contain captured-webcam-image"
        @blob={{this.capturedWebCamImage}}
        {{didUpdate this.onDidUpdate.perform @task}}
      />
    {{else if (and @task.usesWebCam @childStatus.isStarted)}}
      {{#if @task.isBarcode}}
        <video
          class="h-100 w-100 position-absolute test-web-cam-scanner"
          {{WebCamScanner
            cameraId=this.cameraId
            barcodeSymbology=@task.barcodeSymbology
            onScan=(triggerEventBus 'barcodeScanned')
          }}
          ...attributes
        ></video>
      {{else}}
        <video
          class="h-100 w-100 position-absolute"
          srcObject={{this.webCam.cameraStream}}
          autoplay
          {{on "playing" this.onPlaying.perform}}
          {{this.startWebCam this.cameraId}}
          {{didUpdate this.onDidUpdate.perform @task}}
          {{eventBus captureWebcamImage=this.onCaptureImage.perform}}
          ...attributes
        ></video>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
