import { template as template_569e9acb0dac4240aa861a227f3d1aa9 } from "@ember/template-compiler";
import { isBlank } from '@ember/utils';
import { inputNum } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
const getLabelClass = (opacity)=>{
    opacity = Number.parseInt(opacity);
    if (opacity < 10) {
        return 'ones';
    } else if (opacity < 100) {
        return 'tens';
    } else {
        return 'hundreds';
    }
};
const updateOpacity = (onUpdate, opacity)=>{
    if (isBlank(opacity) || opacity < 0 || isNaN(opacity)) {
        opacity = 0;
    } else if (opacity > 100) {
        opacity = 100;
    }
    onUpdate(opacity);
};
export default template_569e9acb0dac4240aa861a227f3d1aa9(`
<div class="component-inputs-opacity opacity-input-wrapper" ...attributes>
  <input
    class="opacity-input form-control"
    type="number"
    value={{@opacity}}
    min={{0}}
    max={{100}}
    disabled={{@disabled}}
    {{on "input" (inputNum (fn updateOpacity @update))}}
  />
  <label class="{{getLabelClass @opacity}} {{if @disabled "disabled"}}">
    %
  </label>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
