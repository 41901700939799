import getHardware from 'eflex/helpers/get-hardware';

export default function getTaskHardwareOutput(station, task) {
  if (!station || !task) {
    return null;
  }

  if (task.hardwareOutputAssignedType) {
    const outputHardware = getHardware(station, {
      hardwareIndex: task.hardwareOutputIndex,
      hardwareAssignedType: task.hardwareOutputAssignedType,
    });

    return outputHardware?.hardwareIos[task.ioOutputIndex - 1];
  }

  return task.hardwareOutputs[0];
}
