import { service } from '@ember/service';
import pProps from 'p-props';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { action } from '@ember/object';

export default class WorkInstructionsWorkInstructionRoute extends AuthenticatedRoute {
  @service store;
  @service workInstructionRepo;
  @service router;

  model(params) {
    return this.store.findRecord('workInstruction', params.id);
  }

  afterModel(workInstruction) {
    return pProps({
      icons: this.store.findAll('workInstructionIcon'),
      configs: this.store.query('taskConfig', { workInstructions: [workInstruction.id] }),
    });
  }

  setupController(controller, workInstruction) {
    controller.workInstruction = workInstruction;
    if (this.workInstructionRepo.userCanEdit(workInstruction)) {
      controller.initEditor.perform();
    }
  }

  resetController(controller) {
    controller.cleanup();
  }

  redirect(workInstruction) {
    if (workInstruction == null) {
      this.router.transitionTo('workInstructions');
    }
  }

  @action
  error(result) {
    if (result?.errors?.[0]?.status === '404') {
      return this.router.transitionTo('workInstructions');
    } else {
      return true;
    }
  }
}
