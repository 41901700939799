import { template as template_b4c07abe79c34830bb73ab09ccc32fb7 } from "@ember/template-compiler";
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { hash } from '@ember/helper';
import { or, not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { set, inputVal } from 'eflex/helpers';
import { conditionalAutofocus } from 'eflex/modifiers';
import onKey from 'ember-keyboard/modifiers/on-key';
import { on } from '@ember/modifier';
import KeyboardWedgeEvent from 'eflex/components/keyboard-wedge-event';
class JemTaskSerialNumberTransferDetails extends Component {
    get displayedSerial() {
        return this.args.transferSerialNumber ?? this.args.serialNumber;
    }
    get showSerial() {
        return !isBlank(this.displayedSerial) && this.args.childStatus?.isFinishedPassed;
    }
    get showInput() {
        return this.args.childStatus?.isStarted && !this.args.task?.hideBarcodeInput;
    }
    get expectedString() {
        return this.args.taskConfig?.expectedString;
    }
    onSubmit = (barcode)=>{
        if (this.args.disabled) {
            return;
        }
        this.args.onSubmit(barcode, TaskStatuses.GOOD);
    };
    static{
        template_b4c07abe79c34830bb73ab09ccc32fb7(`
    <div ...attributes>
      {{#if (or this.showInput this.showSerial)}}
        <div class="mt-2">
          {{#if this.expectedString}}
            <div class="expected-string">
              <div class="text-gray-800 fw-semibold mb-0">
                {{t "expecting"}}
              </div>
              <div class="detail-text-label fs-4 fw-semibold text-gray-800 mb-1">
                {{this.expectedString}}
              </div>
            </div>
          {{/if}}

          {{#if this.showInput}}
            <input
              class="form-control serial-number-transfer-input"
              disabled={{@disabled}}
              value={{@transferSerialNumber}}
              placeholder={{t "serialNumberTransfer.newSerialNumber"}}
              {{on "input" (inputVal @onInput)}}
              {{onKey 'Enter' (inputVal this.onSubmit)}}
              {{conditionalAutofocus (not @disabled)}}
            />
            <KeyboardWedgeEvent @onScan={{this.onSubmit}} />
          {{else if this.showSerial}}
            <div class="scan-result detail-text-value fw-bold text-gray-900">
              {{this.displayedSerial}}
            </div>
          {{/if}}
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default class JemTaskSerialNumberTransfer extends Component {
    @tracked
    transferSerialNumber;
    @service
    jemRepo;
    onTaskComplete = task({
        drop: true
    }, waitFor(async (taskStatus)=>{
        if (taskStatus === TaskStatuses.RESTART || TaskStatuses.isRejected(taskStatus)) {
            this.transferSerialNumber = null;
            return this.args.onTaskComplete(taskStatus);
        }
        if (isBlank(this.transferSerialNumber)) {
            return;
        }
        if (this.args.childStatus.task.confirmRepair) {
            this.args.onRepair(taskStatus, this.args.childStatus, this.transferSerialNumber);
        } else {
            await this.jemRepo.submitSerialNumberTransfer.perform(this.args.childStatus, this.args.taskConfig, this.transferSerialNumber);
            this.transferSerialNumber = null;
        }
    }));
    onSubmit = task(waitFor(async (transferSerialNumber, taskStatus)=>{
        this.transferSerialNumber = transferSerialNumber;
        await this.onTaskComplete.perform(taskStatus, this.args.childStatus.status);
    }));
    static{
        template_b4c07abe79c34830bb73ab09ccc32fb7(`
    {{yield
      (hash
        taskDetails=(component
          JemTaskSerialNumberTransferDetails
          transferSerialNumber=this.transferSerialNumber
          serialNumber=@childStatus.parent.serialNumber
          disabled=@disabled
          task=@task
          childStatus=@childStatus
          taskConfig=@taskConfig
          onInput=(set this 'transferSerialNumber')
          onSubmit=this.onSubmit.perform
        )
        onTaskComplete=this.onTaskComplete.perform
      )
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
