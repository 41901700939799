import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';

export default class KineticAssemblyOperation extends Model {
  @service intl;

  @attr('number', { defaultValue: 0 }) sequence;
  @attr('number', { defaultValue: 0 }) runQuantity;
  @attr('number', { defaultValue: 0 }) completedQuantity;
  @attr('number', { defaultValue: 0 }) reportedQuantity;

  @belongsTo('kineticOperation', { async: false, inverse: null }) operation;
  @belongsTo('kineticJobAssembly', { async: false, inverse: 'assemblyOperations' }) assembly;
  @hasMany('station', { async: false, inverse: null }) stations;

  get allowedStations() {
    return this.stations;
  }

  get allowedStation() {
    return this.stations[0];
  }

  get quantityToReport() {
    return this.completedQuantity - this.reportedQuantity;
  }

  get job() {
    return this.assembly.job;
  }

  get isReleased() {
    return this.job.isReleased;
  }

  get name() {
    return `${this.sequence}: ${this.operation.name}`;
  }

  get part() {
    return this.assembly.part;
  }

  get partRev() {
    return this.assembly.partRev;
  }

  get hasTasks() {
    return this.operation.hasTasks;
  }

  get quantityCount() {
    return `${this.completedQuantity}/${this.runQuantity}`;
  }

  get nextAssemblyOp() {
    return this.assembly.getAssemblyOpAfter(this);
  }

  get missingConfiguration() {
    return !this.hasTasks || isEmpty(this.allowedStation);
  }

  get materials() {
    return this.assembly.materials.filter(material => {
      return material.relatedOperation === this.sequence;
    });
  }

  stationIsAllowed = (station) => {
    return this?.stations.includes(station);
  };

  stationNotAllowed = (station) => {
    return !this.stationIsAllowed(station);
  };

  warnings = (station) => {
    const warnings = [];

    if (isEmpty(this.allowedStation)) {
      warnings.push(this.intl.t('kinetic.noAssignedStation'));
    } else if (!isEmpty(station) && this.stationNotAllowed(station)) {
      warnings.push(this.intl.t('kinetic.allowedStation', { station: this.allowedStation.name }));
    }

    if (!this.hasTasks) {
      warnings.push(this.intl.t('kinetic.noTasksConfigured'));
    }

    if (this.operation.kineticDeleted) {
      warnings.push(this.intl.t('kinetic.operation.operationDeleted'));
    }

    return warnings;
  };

  asHistory = () => {
    return {
      id: this.id,
      sequence: this.sequence,
    };
  };
}
