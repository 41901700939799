import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isBelongsToRefDirty, rollbackBelongsTo } from 'eflex/util/relationship-helpers';
import { copyable } from 'eflex/decorators';

@copyable
@buildValidations({
  input: [
    validator('presence', {
      presence: true,
      get disabled() {
        return this.model.task.usesOperations;
      },
    }),
  ],
  ioIndex: [
    validator('presence', {
      presence: true,
      get disabled() {
        return !this.model.task.usesOperations;
      },
    }),
  ],

})
class HardwareInputDef extends Model {
  @attr('string') hardwareAssignedType;
  @attr('number', { defaultValue: 1 }) hardwareIndex;
  @attr('number', { defaultValue: 1 }) ioIndex;

  @belongsTo('hardwareIo', { async: false, inverse: 'hardwareInputDefs' }) input;
  @belongsTo('task', { async: false, inverse: 'hardwareInputDefs' }) task;

  @hasMany('hardwareInputDefConfig', {
    async: false,
    inverse: 'hardwareInputDef',
    cascadeDelete: true,
  }) hardwareInputDefConfigs;

  get isDirty() {
    return super.isDirty || isBelongsToRefDirty(this, 'input');
  }

  get isConnected() {
    return this.input?.isConnected;
  }

  get errorIdentifier() {
    return this.task?.name;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['task', 'hardwareInputDefConfigs']),
      copyByReference: new Set(['input']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'input');
    super.rollbackAttributes();
  }
}

export default HardwareInputDef;
