import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';
import getHardware from 'eflex/helpers/get-hardware';
import getHardwareInputDefIo from 'eflex/helpers/get-hardware-input-def-io';

export default class HardwareMessage extends Model {
  @service triggerRepo;
  @service hardwareRepo;
  @service store;

  @attr('string') type;
  @attr('string') serialNumber;
  @attr('number') responseTimeMs;
  @attr('date') createdAt;
  @attr message;

  // to prevent errors if the original station was deleted
  @attr('string') station;
  @attr('string') task;
  @attr('string') oeeButton;
  @attr('string') hardware;

  @service intl;

  get command() {
    return this.message?.command ?? this.message?.event;
  }

  get taskRecord() {
    if (this.task != null) {
      return this.store.peekRecord('task', this.task);
    } else {
      return null;
    }
  }

  get stationRecord() {
    if (this.station != null) {
      return this.store.peekRecord('station', this.station);
    } else {
      return this.taskRecord?.parent;
    }
  }

  get hardwareRecord() {
    const message = this.message;
    const task = this.taskRecord;
    const station = this.stationRecord;

    if (message?.hardwareId != null) {
      return this.hardwareRepo.allHardware.find(item => item.id === message?.hardwareId);
    }

    if (message?.triggerId != null) {
      const trigger = this.triggerRepo.allTriggers.find(item => item.id === message.triggerId);
      if (trigger) {
        return getHardware(station, trigger);
      } else {
        return null;
      }
    }

    if (task?.hardwareInputDefs?.[0] != null) {
      return getHardwareInputDefIo(station, task.hardwareInputDefs[0])?.hardware;
    }

    if (message?.header?.taskType === 'timer') {
      return { name: this.intl.t('plant.task.taskTypes.timer') };
    }

    return getHardware(station, task);
  }
}
