import Service from '@ember/service';
import config from 'eflex/config/environment';

export default class RaygunService extends Service {
  #rg4js;

  async loadRaygun() {
    const { default: rg4js } = await import('raygun4js');
    this.#rg4js = rg4js;

    rg4js('apiKey', config.raygun.apiKey);
    rg4js('enableCrashReporting', true);
    rg4js('setVersion', config.APP.version);
    rg4js('logContentsOfXhrCalls', true);

    rg4js('withCustomData', {
      environment: config.environment,
      appName: config.APP.name,
    });
  }

  sendError(error) {
    this.#rg4js?.('send', error);
  }
}
