import { template as template_fecc6d1257aa4e4ab30910ddf6525dbf } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import StandardModal from 'eflex/components/standard-modal';
import EmberGrid from 'eflex/components/ember-grid';
import { t } from 'ember-intl';
import { concat } from '@ember/helper';
import { formatDate } from 'eflex/helpers';
export default class NotificationLogAcknowledgeModal extends Component {
    @service
    systemConfig;
    get showChangeNotificationModal() {
        return this.systemConfig.jem?.changeNotifications && !this.args.stationIsRunning && isPresent(this.args.logs);
    }
    getDisplayUserName = (userFragment)=>{
        if (!userFragment) {
            return null;
        }
        const fullName = `${userFragment.firstName ?? ''} ${userFragment.lastName ?? ''}`;
        if (fullName.trim().length > 0) {
            return fullName;
        } else {
            return userFragment.userName;
        }
    };
    static{
        template_fecc6d1257aa4e4ab30910ddf6525dbf(`
    {{#if this.showChangeNotificationModal}}
      <StandardModal
        class="notification-log-acknowledge-modal"
        @size="lg"
        @keyboard="false"
        @onConfirm={{@onConfirm}}
        ...attributes
      as |modal|>
        <modal.header>
          {{t "jem.acknowledgeChangeNotifications"}}
        </modal.header>
        <modal.body class="overflow-hidden">
          <div class="row mb-1">
            {{#if @totalLogs}}
              <div class="col text-end total-logs">
                {{t "logs.totalToAcknowledge" total=@totalLogs}}
              </div>
            {{/if}}
          </div>
          <div class="row logs-grid-wrapper h-100">
            <div class="col-md-12 h-100">
              <EmberGrid
                @data={{@logs}}
                @fetch={{@fetch}}
              >
                <thead>
                  <tr>
                    <th>
                      {{t "date"}}
                    </th>
                    <th>
                      {{t "type"}}
                    </th>
                    <th>
                      {{t "user"}}
                    </th>
                    <th>
                      {{t "logs.message"}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {{#each @logs as |logRecord|}}
                    <tr class="notification-log-row">
                      <td class="log-timestamp">
                        {{formatDate logRecord.timestamp}}
                      </td>
                      <td class="log-type">
                        {{t (concat "logs.logType." logRecord.logType)}}
                      </td>
                      <td class="log-createdby">
                        {{this.getDisplayUserName logRecord.createdBy}}
                      </td>
                      <td class="log-message">
                        {{logRecord.message}}
                      </td>
                    </tr>
                  {{/each}}
                </tbody>
              </EmberGrid>
            </div>
          </div>

        </modal.body>
        <modal.footer as |footer|>
          <footer.confirm>
            {{t "acknowledge"}}
          </footer.confirm>
        </modal.footer>
      </StandardModal>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
