import { template as template_1cdbcd7222644251b6f008730e15922e } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { and, eq } from 'ember-truth-helpers';
const onShown = (tabBecameActive, { target })=>{
    tabBecameActive(target.hash.replace('#tab-', ''));
};
const Tab = template_1cdbcd7222644251b6f008730e15922e(`
<li
  class="eflex-tab nav-item"
  {{on 'shown.bs.tab' (fn onShown @tabBecameActive)}}
  ...attributes
>
  <a
    href="#tab-{{@href}}"
    data-bs-toggle={{unless @disabled "tab"}}
    class="nav-link {{if (and @href (eq @href @activeTab)) "active"}} {{if @disabled "disabled"}}"
  >
    {{yield}}
  </a>
</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Navigation = template_1cdbcd7222644251b6f008730e15922e(`
<ul class="nav eflex-tabs px-3" ...attributes>
  {{yield
    (hash
      tab=(component
        Tab
        tabBecameActive=@tabBecameActive
        activeTab=@activeTab
      )
    )
  }}
</ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Pane = template_1cdbcd7222644251b6f008730e15922e(`
{{#let (eq @activeTab @id) as |isActive|}}
  <div
    id="pane-{{@id}}"
    class="{{if isActive "active"}} tab-pane"
    ...attributes
  >
    {{#if isActive}}
      {{yield}}
    {{/if}}
  </div>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Panes = template_1cdbcd7222644251b6f008730e15922e(`
<div class="tab-content flex-fill overflow-hidden" ...attributes>
  {{yield (hash pane=(component Pane activeTab=@activeTab))}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template_1cdbcd7222644251b6f008730e15922e(`
{{yield
  (hash
    navigation=(component
      Navigation
      tabBecameActive=@tabBecameActive
      activeTab=@activeTab
    )
    panes=(component Panes activeTab=@activeTab)
  )
}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
