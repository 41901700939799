import { template as template_1a3cbcd73b3d44d8bfbc7ba98e884c5d } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { on } from '@ember/modifier';
export default class BlobImage extends Component {
    @tracked
    dataUrl;
    #previousBlob = this.args.blob;
    constructor(){
        super(...arguments);
        if (this.args.blob) {
            this.dataUrl = URL.createObjectURL(this.args.blob);
        }
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.revokeDataUrl();
    }
    updateDataUrl = (element, [blob])=>{
        if (this.#previousBlob === blob) {
            return;
        }
        this.#previousBlob = blob;
        this.revokeDataUrl();
        if (blob) {
            this.dataUrl = URL.createObjectURL(blob);
        }
    };
    revokeDataUrl = ()=>{
        if (!this.dataUrl) {
            return;
        }
        URL.revokeObjectURL(this.dataUrl);
    };
    static{
        template_1a3cbcd73b3d44d8bfbc7ba98e884c5d(`
    <img
      src={{this.dataUrl}}
      {{on 'load' this.revokeDataUrl}}
      {{didUpdate this.updateDataUrl @blob}}
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
