import { template as template_bff693e0326b49848a5ccc681d91ee5c } from "@ember/template-compiler";
import { t } from 'ember-intl';
import HardwareSelect from 'eflex/components/hardware-select';
import { fn, array } from '@ember/helper';
import { eq } from 'ember-truth-helpers';
import { getControlType, VARIABLE_TEXT, VARIABLE_NUMBER } from 'eflex/constants/variable-types';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { selectVal } from 'eflex/helpers';
const TYPE_OPTIONS = [
    VARIABLE_NUMBER,
    VARIABLE_TEXT
];
const onChangeOpcUaType = (task, opcUaType)=>{
    if (opcUaType === 'variable') {
        Object.assign(task, {
            opcUaObjectNodeId: null,
            opcUaMethodNodeId: null,
            opcUaType
        });
    } else if (opcUaType === 'method') {
        Object.assign(task, {
            opcUaResultVariableNodeId: null,
            opcUaResultVariableType: null,
            opcUaResultVariablePassedValue: null,
            opcUaResultVariableFailedValue: null,
            opcUaStartVariableNodeId: null,
            opcUaType
        });
        task.variableDefs.forEach((variableDef)=>{
            variableDef.opcUaNodeId = null;
        });
    }
};
const onChangeVariableType = (task, type)=>{
    Object.assign(task, {
        opcUaResultVariablePassedValue: null,
        opcUaResultVariableFailedValue: null,
        opcUaResultVariableType: type
    });
};
export default template_bff693e0326b49848a5ccc681d91ee5c(`
<div class="row form-row">
  <div class="col-6">
    <HardwareSelect
      @types={{array 'opc-ua'}}
      @model={{@task}}
      @form={{@form}}
    />
  </div>

  <div class="col-6">
    <@form.element
      class='test-opc-ua-type'
      @controlType="power-select"
      @options={{array 'method' 'variable'}}
      @label={{t 'opcUaType'}}
      @value={{@task.opcUaType}}
      @onChange={{fn onChangeOpcUaType @task}}
    as |el|>
      <el.control @triggerClass='form-control' as |opt|>
        {{t opt}}
      </el.control>
    </@form.element>
  </div>
</div>

{{#if (eq @task.opcUaType 'method')}}
  <div class="row form-row">
    <div class="col">
      <@form.element
        @controlType='text'
        class='test-opc-ua-object-node-id is-required'
        @label={{t 'objectNodeId'}}
        @property='opcUaObjectNodeId'
      />
    </div>
    <div class="col">
      <@form.element
        class='test-opc-ua-method-node-id is-required'
        @controlType='text'
        @label={{t 'methodNodeId'}}
        @property='opcUaMethodNodeId'
      />
    </div>
  </div>
{{else}}
  <div class="row form-row">
    <div class="col">
      <@form.element
        @controlType='text'
        class='test-opc-ua-start-variable-node-id is-required'
        @label={{t 'startVariableNodeId'}}
        @property='opcUaStartVariableNodeId'
      />
    </div>
  </div>

  <div class="row form-row">
    <div class="col">
      <@form.element
        @controlType='text'
        class='test-opc-ua-result-variable-node-id is-required'
        @label={{t 'resultVariableNodeId'}}
        @property='opcUaResultVariableNodeId'
      />
    </div>
    <div class="col">
      <@form.element
        class='test-opc-ua-result-variable-type'
        @controlType="power-select"
        @options={{TYPE_OPTIONS}}
        @value={{findBy "value" @task.opcUaResultVariableType TYPE_OPTIONS}}
        @label={{t 'resultVariableType'}}
        @onChange={{selectVal (fn onChangeVariableType @task)}}
      as |el|>
        <el.control
          @triggerClass="form-control"
          @title={{@task.opcUaResultVariableType}}
        as |type|>
          {{t type.label}}
        </el.control>
      </@form.element>
    </div>
  </div>

  <div class="row form-row">
    <div class="col">
      <@form.element
        class='test-opc-ua-passed-value is-required'
        @controlType={{getControlType @task.opcUaResultVariableType}}
        @label={{t 'resultVariablePassedValue'}}
        @property='opcUaResultVariablePassedValue'
      />
    </div>
    <div class="col">
      <@form.element
        class='test-opc-ua-failed-value is-required'
        @controlType={{getControlType @task.opcUaResultVariableType}}
        @label={{t 'resultVariableFailedValue'}}
        @property='opcUaResultVariableFailedValue'
      />
    </div>
  </div>
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
