/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const MAX_PREVIEW_HEIGHT = 74;
const MIN_WIDTH = 100;
const MIN_HEIGHT = 100;

export default class WorkInstructionEditorSizeCard extends Component {
  MIN_WIDTH = MIN_WIDTH;
  MIN_HEIGHT = MIN_HEIGHT;

  @tracked customWidth;
  @tracked customHeight;

  get isCustom() {
    return this.args.sizeCard.type === WieSettings.getStartedTypes.CUSTOM;
  }

  get sizeReady() {
    if (!this.isCustom) {
      return true;
    }

    return this.customWidth >= MIN_WIDTH && this.customHeight >= MIN_HEIGHT;
  }

  get previewWidth() {
    if (this.isCustom) {
      return '74px';
    } else {
      const scale = MAX_PREVIEW_HEIGHT / this.args.sizeCard.height;
      return `${Math.round(this.args.sizeCard.width * scale * 2) / 2}px`;
    }
  }

  get previewHeight() {
    if (this.isCustom) {
      return '74px';
    } else {
      return `${MAX_PREVIEW_HEIGHT}px`;
    }
  }

  @action
  cardClick() {
    if (!this.sizeReady) {
      return;
    }

    this.args.cardClick?.({
      width: this.customWidth ?? this.args.sizeCard.width,
      height: this.customHeight ?? this.args.sizeCard.height,
    });
  }

  @action
  setWidth(value) {
    if (value < MIN_WIDTH) {
      this.customWidth = MIN_WIDTH;
    } else {
      this.customWidth = value;
    }
  }

  @action
  setHeight(value) {
    if (value < MIN_HEIGHT) {
      this.customHeight = MIN_HEIGHT;
    } else {
      this.customHeight = value;
    }
  }
}
