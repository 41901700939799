import { template as template_f44632bd68e14cf68f052e614887736c } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { modelDisplayName, caption } from 'eflex/helpers';
const JemTaskPushToScheduleDetails = template_f44632bd68e14cf68f052e614887736c(`
<div ...attributes>
  <div class="mt-2 {{@childStatus.taskStatusClass}}">
    {{#each @childStatus.scheduleProcessData as |scheduleDatum|}}
      <div class="schedule-datum">
        <div class="fs-4 fw-semibold text-gray-800">
          {{caption scheduleDatum.station.captions}}
        </div>
        <div class="detail-text-value fw-bold text-gray-900">
          {{scheduleDatum.scheduleTarget}}x {{modelDisplayName scheduleDatum.model}}
        </div>
      </div>
    {{/each}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template_f44632bd68e14cf68f052e614887736c(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskPushToScheduleDetails
        childStatus=@childStatus
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
