import { template as template_7020532f214d44e786796fd2d3f39e73 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { toTree } from 'eflex/util/tree-helpers';
import TreeSelect from 'eflex/components/tree-select';
import { t } from 'ember-intl';
import { callWithMapped } from 'eflex/helpers';
export default class BiPanelLocations extends Component {
    @service
    areaRepo;
    get treeData() {
        return this.areaRepo.areas.map((area)=>toTree(area, {
                lastModel: 'station'
            }));
    }
    static{
        template_7020532f214d44e786796fd2d3f39e73(`
    <div class="mb-2">
      <label class="form-label form-label">
        {{t "locations"}}
      </label>
      <TreeSelect
        class="bi-station-select editable-tree-select"
        @fullWidth={{true}}
        @contentClass="bi-station-tree-content"
        @sourceTree={{this.treeData}}
        @checkable={{true}}
        @checkedIds={{@stationIds}}
        @onCheckedChanged={{callWithMapped @onChange "id"}}
        @placeholder={{t "biPanel.locations.placeholder"}}
        @checkAllText={{t "biPanel.locations.placeholder"}}
        @minDepth={{2}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
