import { template as template_f059bc5701e44ae09b3dbbd856ba49be } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { cached } from '@glimmer/tracking';
import { HARDWARE_REQUIRED_TASK_TYPES, HARDWARE_NOT_REQUIRED_TASK_TYPES, HARDWARE_OPTIONAL_TASK_TYPES } from 'eflex/constants/tasks/task-types';
import { isEmpty } from '@ember/utils';
import { getTaskHardwareInput, getTaskHardwareOutput, getHardwareInputDefIo } from 'eflex/helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
const checkPickHasFault = (station, task)=>{
    const output = getTaskHardwareOutput(station, task);
    if (output == null || !output.isConnected) {
        return true;
    }
    const input = getTaskHardwareInput(station, task);
    if (input != null && !input.isConnected) {
        return true;
    }
    return false;
};
const checkErrorProofingHasFault = (station, hardwareInputDefs)=>{
    if (isEmpty(hardwareInputDefs)) {
        return false;
    }
    return hardwareInputDefs.some((inputDef)=>{
        const input = getHardwareInputDefIo(station, inputDef);
        return !input || !input.isConnected;
    });
};
export default class JemTaskTaskFault extends Component {
    @service
    intl;
    @cached
    get hasHardwareFault() {
        const task = this.args.task;
        const taskType = task.taskType;
        if (HARDWARE_NOT_REQUIRED_TASK_TYPES.has(taskType)) {
            return false;
        }
        const hardware = this.args.hardware;
        if (HARDWARE_OPTIONAL_TASK_TYPES.has(taskType) && hardware == null) {
            return false;
        }
        const station = this.args.station;
        if (task.isErrorProofing) {
            return checkErrorProofingHasFault(station, task.hardwareInputDefs);
        } else if (task.isPick) {
            return checkPickHasFault(station, task);
        }
        return hardware?.isConnected === false;
    }
    get taskErrorMessage() {
        if (HARDWARE_REQUIRED_TASK_TYPES.has(this.args.task.taskType) && this.args.hardware == null) {
            return this.intl.t('jem.hardwareMissing');
        } else if (this.hasHardwareFault) {
            return this.intl.t('jem.hardwareUnreachable');
        } else if (this.shouldFaultCalibrationExpiration) {
            return this.intl.t('jem.calibrationExpired');
        } else {
            return null;
        }
    }
    get daysUntilCalibrationExpiration() {
        return moment(this.args.hardware?.calibrationExpirationDate).startOf('day').diff(moment().startOf('day'), 'days');
    }
    get shouldWarnCalibrationExpiration() {
        const hardware = this.args.hardware;
        return (hardware && hardware.calibrationExpirationDate && hardware.calibrationDate && hardware.warningThresholdDays && moment(hardware.calibrationExpirationDate).startOf('day').subtract(hardware.warningThresholdDays, 'days') <= moment().startOf('day'));
    }
    get shouldFaultCalibrationExpiration() {
        const hardware = this.args.hardware;
        return (hardware && hardware.calibrationExpirationDate && hardware.calibrationDate && moment(hardware.calibrationExpirationDate).startOf('day') <= moment().startOf('day'));
    }
    static{
        template_f059bc5701e44ae09b3dbbd856ba49be(`
    {{#if this.taskErrorMessage}}
      <div class="badge fault-message bg-danger text-wrap text-start ps-1 ms-1 mt-2">
        <FaIcon @icon="triangle-exclamation" @size="sm" class="mx-1" />{{this.taskErrorMessage}}
      </div>
    {{else if this.shouldWarnCalibrationExpiration}}
      <div class="badge fault-message bg-warning text-wrap text-start ps-1 ms-1 mt-2">
        <FaIcon
          @icon="circle-exclamation"
          @size="sm"
          class="mx-1" />{{t "jem.calibrationWarning" days=this.daysUntilCalibrationExpiration}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
