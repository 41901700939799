import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { typeOf } from '@ember/utils';
import { service } from '@ember/service';

export default class OperationsTasksRoute extends AuthenticatedRoute {
  @service store;
  @service router;

  async #handleTransition(transition, task) {
    await transition;
    this.controllerFor('kineticOperations.operation').openTaskPanel.perform(task);
    return transition;
  }

  afterModel(task) {
    if (typeOf(task) === 'object') {
      task = this.store.peekRecord('task', task.id);
    }

    const transition = this.router.transitionTo('kineticOperations.operation', task.parent.id);
    this.#handleTransition(transition, task);
    return transition;
  }
}
