import { template as template_b9fc0efeb7e743f09f3b27ce2da9195f } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_b9fc0efeb7e743f09f3b27ce2da9195f(`
<div class="landing-page">
  <img src="/images/epicor-logo-blue.png" />
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
}));
