import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { getOwner } from '@ember/application';
import { assert } from '@ember/debug';
import { Task } from 'ember-concurrency';

function findTaskInCurrentRouteHierarchy(router, taskName) {
  const owner = getOwner(router);
  const routeSegments = router.currentRouteName.split('.');

  for (let i = routeSegments.length; i >= 0; i--) {
    const routeName = routeSegments.slice(0, i).join('.') || 'application';
    const route = owner.lookup(`route:${routeName}`);
    const task = route[taskName];

    if (task instanceof Task) {
      return task;
    }
  }

  return null;
}

export function routeTaskFromRouterService(router, taskName) {
  const task = findTaskInCurrentRouteHierarchy(router, taskName);
  assert(`Unable to find route task ${taskName}`, task);
  return task;
}

export default class RouteTask extends Helper {
  @service router;

  compute([taskName]) {
    return routeTaskFromRouterService(this.router, taskName);
  }
}
