import { template as template_b3655793fbdc4772b25e369744d2b3b4 } from "@ember/template-compiler";
import smallestAvailable from 'eflex/util/smallest-available';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { mapBy, findBy } from '@eflexsystems/ember-composable-helpers';
import { fn } from '@ember/helper';
import { eq } from 'ember-truth-helpers';
import TrashCan from 'eflex/components/trash-can';
import AddButton from 'eflex/components/add-button';
import { isBlank } from '@ember/utils';
import { uniq, filter } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';
import PowerSelectWithCreate from 'eflex/components/power-select-with-create';
import { VARIABLE_BASE_TYPES } from 'eflex/constants/variable-types';
const getUniqueUnits = (units)=>{
    return intoArray(filter((unit)=>!isBlank(unit)), compact, uniq)(units ?? []);
};
const showCreateWhen = (value, units)=>{
    return !units.includes(value);
};
export class VariableUnits extends Component {
    onCreate = (newName)=>{
        this.args.onChange(newName.trim());
    };
    onChange = (newUnit)=>{
        if (newUnit === this.args.selected) {
            return;
        }
        this.args.onChange(newUnit);
    };
    static{
        template_b3655793fbdc4772b25e369744d2b3b4(`
    <PowerSelectWithCreate
      class="unit-select {{@variableDef.type}}"
      @triggerClass="form-control"
      @options={{getUniqueUnits @units}}
      @selected={{@selected}}
      @disabled={{@disabled}}
      @onChange={{this.onChange}}
      @onCreate={{this.onCreate}}
      @showCreateWhen={{showCreateWhen}}
      @searchEnabled={{true}}
      ...attributes
    as |unit|>
      {{unit}}
    </PowerSelectWithCreate>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default class TaskContextConfigButton extends Component {
    @service
    variableDefRepo;
    @service
    licensing;
    get nonDeletedDefs() {
        return this.args.task?.variableDefs.filter((item)=>!item.isDeleted);
    }
    get booleanDefs() {
        return this.variableDefRepo.variableDefs.filter((item)=>item.type === 'boolean');
    }
    get units() {
        return this.variableDefRepo.variableDefs.filter((item)=>item.type !== 'boolean').map((item)=>item.unit);
    }
    add = ()=>{
        this.variableDefRepo.createVariableDef({
            displayedStart: smallestAvailable(this.nonDeletedDefs?.map((item)=>item.displayedStart)),
            task: this.args.task,
            type: 'string',
            isFromDevice: true
        });
    };
    delete = (def)=>{
        if (this.args.disabled) {
            return;
        }
        this.variableDefRepo.deleteVariable(def);
    };
    selectedDataDefType = (dataDef, selected)=>{
        dataDef.type = selected.value;
    };
    static{
        template_b3655793fbdc4772b25e369744d2b3b4(`
    {{#if this.licensing.license.processDataEnabled}}
      <div class="bootstrap-table variable-config component-task-config-button">
        <div class="row form-row header-row align-items-end">
          <div class="col">
            {{t "name"}}
          </div>
          <div class="col-auto">
            <div class="width-rem-6">
              {{t "dataType"}}
            </div>
          </div>
          <div class="col-6">
            {{t "unitsAndLimits"}}
          </div>
          {{#unless @task.isAuto}}
            <div class="col-1">
              <div class="model-specific">
                {{t "required"}}
              </div>
            </div>
          {{/unless}}
          <div class="col-icon border-0"></div>
        </div>
        {{#each this.nonDeletedDefs as |def|}}
          <div class="row form-row variable-row align-items">
            <div class="col">
              <@form.element
                @controlType="text"
                @model={{def}}
                @property="name"
              as |el|>
                <el.control class="name-col" />
              </@form.element>
            </div>

            <div class="col-auto">
              <div class="width-rem-6 type-col">
                {{#if def.isNew}}
                  <@form.element
                    class="data-type-select"
                    @controlType="power-select"
                    @options={{VARIABLE_BASE_TYPES}}
                    @value={{findBy "value" def.type VARIABLE_BASE_TYPES}}
                    @onChange={{fn this.selectedDataDefType def}}
                  as |el|>
                    <el.control
                      @triggerClass="form-control"
                    as |type|>
                      {{t type.label}}
                    </el.control>
                  </@form.element>
                {{else}}
                  <@form.element>
                    <div class="data-type">
                      {{#let (findBy "value" def.type VARIABLE_BASE_TYPES) as |type|}}
                        {{t type.label}}
                      {{/let}}
                    </div>
                  </@form.element>
                {{/if}}
              </div>
            </div>
            <div class="col-6">
              {{#if (eq def.type "boolean")}}
                <div class="row align-items-end">
                  <div class="col">
                    <@form.element>
                      <VariableUnits
                        class='test-variable-unit'
                        @selected={{def.unit}}
                        @variableDef={{def}}
                        @units={{mapBy "unit" this.booleanDefs}}
                        @disabled={{@disabled}}
                        @onChange={{fn (mut def.unit)}}
                      />
                    </@form.element>
                  </div>
                  <div class="col">
                    <@form.element>
                      <VariableUnits
                        @selected={{def.falseUnit}}
                        @variableDef={{def}}
                        @units={{mapBy "falseUnit" this.booleanDefs}}
                        @disabled={{@disabled}}
                        @onChange={{fn (mut def.falseUnit)}}
                      />
                    </@form.element>
                  </div>
                </div>
              {{else if (eq def.type "number")}}
                <div class="row align-items-end">
                  <div class="col">
                    <@form.element>
                      <VariableUnits
                        @selected={{def.unit}}
                        @variableDef={{def}}
                        @units={{this.units}}
                        @disabled={{@disabled}}
                        @onChange={{fn (mut def.unit)}}
                      />
                    </@form.element>
                  </div>
                  {{#let (findBy "variableDef" def @taskConfig.variableDefConfigs) as |config|}}
                    <div class="col limits">
                      <@form.element
                        @controlType="number"
                        class="model-specific"
                        @model={{config}}
                        @property="lowerLimit"
                        @label={{t "lowerLimit"}}
                      />
                    </div>
                    <div class="col limits">
                      <@form.element
                        @controlType="number"
                        class="model-specific"
                        @model={{config}}
                        @property="upperLimit"
                        @label={{t "upperLimit"}}
                      />
                    </div>
                    <div class="col-auto limits">
                      <@form.element
                        class="model-specific width-rem-3"
                        @controlType="number"
                        @model={{config}}
                        @label={{t "decimals"}}
                        @property="decimalLength"
                      as |el|>
                        <el.control min={{0}} max={{10}} />
                      </@form.element>
                    </div>
                  {{/let}}
                </div>
              {{else}}
                <@form.element>
                  <VariableUnits
                    @selected={{def.unit}}
                    @variableDef={{def}}
                    @units={{this.units}}
                    @disabled={{@disabled}}
                    @onChange={{fn (mut def.unit)}}
                  />
                </@form.element>
              {{/if}}
            </div>

            {{#unless @task.isAuto}}
              <div class="col-1 d-flex justify-content-center">
                <@form.element
                  class="model-specific"
                  @controlType="checkbox"
                  @property="required"
                  @model={{findBy "variableDef" def @taskConfig.variableDefConfigs}}
                />
              </div>
            {{/unless}}
            <div class="col-icon align-self-center">
              <TrashCan
                class='delete-variable'
                disabled={{@disabled}}
                @onDelete={{fn this.delete def}}
              />
            </div>
          </div>
        {{/each}}

        <div class="row form-row no-hover-row">
          <div class="col-auto">
            <AddButton
              class="add test-add-variable"
              @disabled={{@disabled}}
              @onClick={{this.add}}
            >
              {{t "addProcessDataValue"}}
            </AddButton>
          </div>
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
