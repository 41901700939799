import { template as template_858b5131b59a4470a38a24765ed8af71 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import StandardModal from 'eflex/components/standard-modal';
import { fn } from '@ember/helper';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { without, sortBy, findBy } from '@eflexsystems/ember-composable-helpers';
import { not, or } from 'ember-truth-helpers';
class KineticCopyOperationModal extends Component {
    @tracked
    selectedPart;
    @tracked
    selectedPartRev;
    @tracked
    selectedOperation;
    @tracked
    showBomOnly = false;
    @tracked
    _copyFromOperation = true;
    @service
    kineticRepo;
    @service
    intl;
    copyDestinationOptions = [
        {
            label: 'kinetic.operations.copyFromOperation',
            value: true
        },
        {
            label: 'kinetic.operations.copyToOperation',
            value: false
        }
    ];
    get parts() {
        if (this.showBomOnly) {
            return this.kineticRepo.bomOnlyParts;
        } else {
            return this.kineticRepo.parts;
        }
    }
    get partRevs() {
        if (this.showBomOnly) {
            return this.selectedPart?.bomOnlyPartRevs;
        } else {
            return this.selectedPart?.children;
        }
    }
    get operations() {
        if (this.showBomOnly) {
            return this.selectedPartRev?.bomOnlyOperations;
        } else {
            return this.selectedPartRev?.children;
        }
    }
    get copyDestinationDisabled() {
        return !this.args.currentOperation.hasTasks;
    }
    get copyFromOperation() {
        if (this.copyDestinationDisabled) {
            return false;
        }
        return this._copyFromOperation;
    }
    set copyFromOperation({ value }) {
        this._copyFromOperation = value;
    }
    selectPart = (part)=>{
        Object.assign(this, {
            selectedPart: part,
            selectedPartRev: null,
            selectedOperation: null
        });
    };
    selectPartRev = (partRev)=>{
        Object.assign(this, {
            selectedPartRev: partRev,
            selectedOperation: null
        });
    };
    static{
        template_858b5131b59a4470a38a24765ed8af71(`
    <StandardModal
      class="copy-operation-modal"
      @size="md"
      @onConfirm={{fn @onConfirm this.selectedOperation this.copyFromOperation}}
      @onCancel={{@onCancel}}
    as |modal|>
      <modal.header @text={{t "kinetic.copyOperationTasks"}} />
      <modal.body>
        <BsForm
          @model={{this}}
          @disabled={{@disabled}}
        as |form|>
          <div class="row">
            <form.element
              class="operation-copy-destination col"
              @controlType="radio-group"
              @options={{this.copyDestinationOptions}}
              @optionLabelPath="label"
              @value={{findBy "value" this.copyFromOperation this.copyDestinationOptions}}
              @onChange={{fn (mut this.copyFromOperation)}}
              as |el|>
              <el.control
                @inline={{true}}
                @translate={{true}}
                @optionValuePath="value"
                @disabled={{this.copyDestinationDisabled}}
                as |opt|>
                {{t opt}}
              </el.control>
            </form.element>

            <form.element
              class="show-bom-only col"
              @controlType="checkbox"
              @value={{this.showBomOnly}}
              @onChange={{fn (mut this.showBomOnly)}}
              @label={{t "kinetic.operations.showBomOnly"}}
            />
          </div>
          <h6>
            {{#if this.copyFromOperation}}
              {{t 'kinetic.operations.to'}}
            {{else}}
              {{t 'kinetic.operations.from'}}
            {{/if}}
          </h6>
          <form.element
            class="kinetic-part-select"
            @controlType="power-select"
            @label={{t "kinetic.part"}}
            @options={{this.parts}}
            @optionLabelPath="name"
            @value={{this.selectedPart}}
            @onChange={{this.selectPart}}
          as |el|>
            <el.control
              @noMatchesMessage={{t "noMatch"}}
              @searchEnabled={{true}}
              @searchField="name"
              @allowClear={{true}}
              @placeholder={{t "kinetic.selectPart"}}
              @renderInPlace={{true}}
            />
          </form.element>
          <form.element
            class="kinetic-part-rev-select"
            @controlType="power-select"
            @label={{t "kinetic.revision"}}
            @options={{this.partRevs}}
            @optionLabelPath="name"
            @value={{this.selectedPartRev}}
            @onChange={{this.selectPartRev}}
          as |el|>
            <el.control
              @disabled={{or @disabled (not this.selectedPart)}}
              @noMatchesMessage={{t "noMatch"}}
              @searchEnabled={{true}}
              @searchField="name"
              @allowClear={{true}}
              @placeholder={{t "kinetic.selectRevision"}}
              @renderInPlace={{true}}
            />
          </form.element>

          <form.element
            @controlType="power-select"
            @label={{t "kinetic.operation"}}
            @options={{sortBy "sequence" (without @currentOperation this.operations)}}
            @optionLabelPath="name"
            @property="selectedOperation"
            class="kinetic-operation-select"
          as |el|>
            <el.control
              @disabled={{or @disabled (not this.selectedPartRev)}}
              @noMatchesMessage={{t "noMatch"}}
              @searchEnabled={{true}}
              @searchField="name"
              @allowClear={{true}}
              @placeholder={{t "kinetic.selectOperation"}}
              @renderInPlace={{true}}
            as |operation|>
              {{operation.sequence}} - {{operation.name}}
            </el.control>
          </form.element>
        </BsForm>
        <div class="tasks-configured">
          {{#if this.selectedOperation}}
            {{t 'kinetic.numTasksConfigured' numTasks=this.selectedOperation.children.length}}
          {{else}}
            <span class="placeholder"/>
          {{/if}}
        </div>
      </modal.body>
      <modal.footer as |modal-footer|>
        <modal-footer.confirm @disabled={{or @disabled (not this.selectedOperation)}}>
          {{t "copy"}}
        </modal-footer.confirm>
        <modal-footer.close />
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default KineticCopyOperationModal;
