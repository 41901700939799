import ApplicationSerializer from 'eflex/serializers/application';

export default class KineticJobSerializer extends ApplicationSerializer {
  attrs = {
    assemblies: {
      deserialize: false,
      serialize: false,
    },
  };
}
