import { anyInvalid, anyTreeItemDirty } from 'eflex/util/getter-helpers';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { arrayAttr, caption } from 'eflex/decorators';

class KineticPart extends Model {
  @attr('string', { defaultValue: 'kineticPart' }) type;
  @attr('string') path;
  @attr('string') parent;
  @attr('string') sourceJobNumber;

  @arrayAttr captions;
  @caption name;

  @belongsTo('kineticPart', { inverse: null, async: false }) sourcePart;
  @hasMany('kineticPartRev', { inverse: 'parent', async: false, cascadeDelete: true }) partRevs;

  get isFromBom() {
    return isEmpty(this.sourceJobNumber);
  }

  get childType() {
    return 'kineticPartRev';
  }

  get treeIconClass() {
    return 'icon-area';
  }

  get operations() {
    return this.children.flatMap(partRev => partRev.children);
  }

  get tasks() {
    return this.operations.flatMap(operation => operation.children);
  }

  @cached
  get children() {
    return this.partRevs.filter(item => !item.isDeleted);
  }

  get allChildren() {
    return this.partRevs;
  }

  get kineticDeleted() {
    return this.children.every(item => item.kineticDeleted);
  }

  get bomOnlyPartRevs() {
    return this.children.filter(item => item.isFromBom);
  }

  @cached
  get isSelfOrChildInvalid() {
    return this.isInvalid ||
      this.isChildInvalid;
  }

  @cached
  get isChildInvalid() {
    return anyInvalid(this.children);
  }

  @cached
  get isSelfOrChildDirty() {
    return this.isDirty || anyTreeItemDirty(this.partRevs);
  }

  get validatedRelationships() {
    return ['partRevs'];
  }

  rollbackAttributes() {
    this.partRevs.forEach(partRev => { partRev.rollbackAttributes(); });
    super.rollbackAttributes();
  }
}

export default KineticPart;
