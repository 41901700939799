import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';

export default class ContainPartsRoute extends AuthenticatedRoute {
  @service store;

  queryParams = {
    serialNumber: {
      refreshModel: false,
    },
    campaign: {
      refreshModel: true,
    },
  };

  async model(params) {
    params.scrap = false;
    await Promise.all([
      this.store.query('jemContainment', params),
      this.store.findAll('jemCampaign'),
    ]);
  }
}
