import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isPresent } from '@ember/utils';
import { validIpAddress } from 'eflex/constants/regex';
import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';
import { service } from '@ember/service';

@buildValidations({
  name: [validator('presence', true)],

  ipAddress: [
    validator('format', { regex: validIpAddress }),

    validator('inline', {
      validate(ipAddress, options, cognexCamera, attribute) {
        const checked = getOwner(cognexCamera)
          .lookup('service:hardwareRepo')
          .cognexCameras
          .filter(item => item.isDeleted === false);

        return checkUniqueField(cognexCamera, checked, attribute).result;
      },
    }),
  ],

})
class CognexCamera extends Hardware {
  @service intl;

  @attr('string') ipAddress;
  @attr('number') port;
  @attr('string') username;
  @attr('string') password;

  get isConfigured() {
    return isPresent(this.ipAddress);
  }

  get hardwareGroup() {
    return this.intl.t('cognexCamera');
  }
}

export default CognexCamera;
