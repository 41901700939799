import { isTesting, macroCondition } from '@embroider/macros';

export default function (intl, key, status) {
  if (macroCondition(isTesting())) {
    return intl.t(key);
  }

  if (intl.exists(key)) {
    return intl.t(key);
  }

  if (status != null) {
    return `${intl.t('unknown')}(${status})`;
  }

  return intl.t('unknown');
}
