import { template as template_ea11a0453afb4bbd9e4ce8140598532d } from "@ember/template-compiler";
import { service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import { AnimationTypes } from 'eflex/constants/jem/instruction-animation-types';
import { TextTypes, EflexObjTypes } from 'eflex/constants/work-instructions/tool-props';
import { fabric } from 'fabric';
import { clone } from 'ramda';
import { dequal } from 'dequal';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { getCanvasObjects, getCanvasObjectByPoint, getCanvasObjectById, getCanvasCoordsForClick, updateObjectProperty, getArrowsByLine, scaleCanvas as origScaleCanvas, cleanupCanvas, initializeCanvas } from 'eflex/util/fabric-helpers';
import { waitFor } from '@ember/test-waiters';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { onResize } from 'eflex/modifiers';
import Spinner from 'eflex/components/spinner';
import { on } from '@ember/modifier';
const ANIMATION_DURATION = 200;
const renderAllSafe = (canvas)=>{
    try {
        canvas.renderAll();
    } catch (e) {
        // Don't care if animation is interrupted midway.
        if (!(e instanceof TypeError)) {
            throw e;
        }
    }
};
const setLineColor = (lineGroup, strokeColor)=>{
    if (strokeColor == null) {
        return;
    }
    lineGroup.getObjects()?.forEach(function(obj) {
        switch(obj.eflex?.type){
            case EflexObjTypes.STRAIGHT_LINE:
                {
                    obj.set('stroke', strokeColor);
                    break;
                }
            case EflexObjTypes.ARROWHEAD:
                {
                    obj.set({
                        fill: strokeColor,
                        stroke: strokeColor
                    });
                    break;
                }
        }
    });
};
const setFill = (obj, fillColor)=>{
    if (obj.fill) {
        obj.set('fill', fillColor);
    }
    if (obj.stroke) {
        obj.set('stroke', fillColor);
    }
};
const setIconColor = (icon, fillColor)=>{
    if (fillColor == null) {
        return;
    }
    if (icon.type === 'group') {
        icon.getObjects().forEach((obj)=>{
            setFill(obj, fillColor);
        });
    } else {
        setFill(icon, fillColor);
    }
};
const setTextboxColor = (obj, fillColor, strokeColor)=>{
    if (fillColor != null) {
        obj.setSelectionStyles({
            fill: fillColor
        }, 0, obj.text.length);
    }
    if (strokeColor != null) {
        obj.set('stroke', strokeColor);
        obj.dirty = true;
    }
};
const setColor = (obj, fillColor, strokeColor, allowNull = false)=>{
    const type = obj.eflex?.type ?? obj.type;
    switch(type){
        case EflexObjTypes.LINE_GROUP:
            {
                setLineColor(obj, strokeColor);
                break;
            }
        case EflexObjTypes.ICON:
            {
                setIconColor(obj, fillColor);
                break;
            }
        case TextTypes.TEXTBOX:
            {
                setTextboxColor(obj, fillColor, strokeColor);
                break;
            }
        default:
            {
                if (fillColor != null || allowNull) {
                    obj.set('fill', fillColor);
                }
                if (strokeColor != null || allowNull) {
                    obj.set('stroke', strokeColor);
                }
            }
    }
};
const notifyModified = (canvas, target)=>{
    if (!target) {
        return;
    }
    canvas.fire('object:modified', {
        target
    });
    canvas.renderAll();
};
const prepareLineObjects = (canvas, previewMode)=>{
    const lines = getCanvasObjects(canvas, EflexObjTypes.STRAIGHT_LINE);
    lines.forEach((line)=>{
        const arrowheads = getArrowsByLine(canvas, line);
        const layer = getCanvasObjects(canvas).indexOf(line);
        const id = line.id;
        line.id = undefined;
        const objects = [
            line
        ];
        objects.push(...arrowheads);
        objects.forEach((obj)=>{
            obj.opacity = 1;
        });
        const active = new fabric.ActiveSelection(objects, {
            canvas
        });
        const group = active.toGroup();
        group.set({
            id,
            selectable: false,
            editable: false,
            eflex: {
                type: EflexObjTypes.LINE_GROUP,
                startHidden: line.eflex.startHidden,
                originalStrokeColor: line.eflex.originalStrokeColor
            }
        });
        group.moveTo(layer);
        if (line.eflex.startHidden && !previewMode) {
            group.opacity = 0;
        }
        canvas.discardActiveObject();
    });
};
const scaleCanvas = (element, canvas, workInstruction)=>{
    const display = element.querySelector('.work-instruction-display');
    if (display == null) {
        return;
    }
    const { width, height } = display.getBoundingClientRect();
    origScaleCanvas(canvas, width, height, workInstruction?.width, workInstruction?.height);
};
const setStartHiddenObjectOpacity = (canvas, opacity)=>{
    getCanvasObjects(canvas)?.forEach((obj)=>{
        if (obj.eflex?.startHidden) {
            obj.opacity = opacity;
        }
    });
    renderAllSafe(canvas);
};
export default class JemDynamicInstruction extends Component {
    @service
    store;
    previousAnimations = this.args.animations;
    previousWorkInstruction = this.args.workInstruction;
    canvas;
    onResize = task({
        drop: true
    }, waitFor(async (element)=>{
        await timeout(getDelayTime(100));
        scaleCanvas(element, this.canvas, this.args.workInstruction);
    }));
    loadCanvas = task({
        enqueue: true
    }, waitFor(async (element)=>{
        cleanupCanvas(this.canvas);
        await this.store.findRecord('workInstruction', this.args.workInstruction.id, {
            reload: true
        });
        this.canvas = initializeCanvas(element.querySelector('.work-instruction-canvas'), {
            width: this.args.workInstruction.width,
            height: this.args.workInstruction.height,
            hoverCursor: 'default'
        });
        const canvasJson = this.args.workInstruction.deployedCanvas;
        canvasJson.selection = false;
        canvasJson.objects.forEach((obj)=>{
            obj.objectCaching = false;
            obj.selectable = false;
            obj.editable = false;
            if (obj.eflex != null) {
                obj.eflex.originalFillColor = obj.fill;
                obj.eflex.originalStrokeColor = obj.stroke;
                if (obj.type === TextTypes.TEXTBOX) {
                    obj.eflex.originalStyles = clone(obj.styles);
                }
                if (obj.eflex.link?.length > 0) {
                    obj.hoverCursor = 'pointer';
                }
                if (obj.eflex.startHidden && !this.args.previewMode) {
                    obj.opacity = 0;
                }
            }
        });
        await new Promise((resolve)=>{
            this.canvas.loadFromJSON(canvasJson, resolve);
        });
        scaleCanvas(element, this.canvas, this.args.workInstruction);
        prepareLineObjects(this.canvas, this.args.previewMode);
        this.animate.perform(); // not yielded since it can use a while true for flash
    }));
    compareAttrs = task({
        onState: null
    }, waitFor(async (element, [workInstruction, animations, previewMode])=>{
        const workInstructionChanged = this.previousWorkInstruction !== workInstruction;
        const animationsChanged = !dequal(this.previousAnimations, animations);
        this.previousWorkInstruction = workInstruction;
        this.previousAnimations = animations;
        if (!workInstruction || workInstructionChanged || (previewMode && animations != null)) {
            await this.animate.cancelAll({
                resetState: true
            });
        }
        if (workInstructionChanged) {
            await this.loadCanvas.perform(element);
        } else if (animationsChanged || (previewMode && animations != null)) {
            await this.animate.cancelAll({
                resetState: true
            });
            this.animate.perform();
            if (previewMode) {
                await this.resetCanvas.perform();
            }
        }
    }));
    resetCanvas = task({
        restartable: true
    }, waitFor(async ()=>{
        setStartHiddenObjectOpacity(this.canvas, 0);
        await timeout(this.#getDelayTime(this.args.resetTimeout));
        const tasks = [
            this.animate.cancelAll({
                resetState: true
            })
        ];
        tasks.push(getCanvasObjects(this.canvas)?.map((obj)=>this.resetObject.perform(obj)));
        await all(tasks);
        setStartHiddenObjectOpacity(this.canvas, 1);
    }));
    resetObject = task(waitFor(async (obj)=>{
        let fillColor, strokeColor;
        if (obj.eflex != null) {
            await this.resetObjectOpacity.perform(obj);
            fillColor = obj.eflex.originalFillColor;
            strokeColor = obj.eflex.originalStrokeColor;
        }
        if (obj.type === TextTypes.TEXTBOX) {
            setTextboxColor(obj, null, strokeColor);
            if (obj.eflex != null) {
                obj.styles = clone(obj.eflex.originalStyles);
            }
        } else {
            setColor(obj, fillColor, strokeColor, true);
        }
        renderAllSafe(this.canvas);
    }));
    resetObjectOpacity = task(waitFor(async (obj)=>{
        if (obj.eflex == null || !obj.eflex.isFlashing) {
            return;
        }
        const opacity = obj.eflex.startHidden ? 0 : 1;
        updateObjectProperty(obj.eflex, 'isFlashing', false);
        notifyModified(this.canvas, obj);
        await this._animateOpacity.perform(obj, opacity, {
            duration: 10
        });
    }));
    showObject = task(waitFor(async (obj)=>{
        await this.resetObjectOpacity.perform(obj);
        await this._animateOpacity.perform(obj, 1, {
            duration: ANIMATION_DURATION,
            easing: fabric.util.ease.easeInQuad
        });
    }));
    hideObject = task(waitFor(async (obj)=>{
        await this.resetObjectOpacity.perform(obj);
        await this._animateOpacity.perform(obj, 0, {
            duration: ANIMATION_DURATION,
            easing: fabric.util.ease.easeOutQuad
        });
    }));
    flashObject = task(waitFor(async (obj)=>{
        obj.eflex ??= {};
        updateObjectProperty(obj.eflex, 'isFlashing', true);
        notifyModified(this.canvas, obj);
        while(true){
            await all([
                this._animateEaseOutAndIn.perform(obj),
                timeout(this.#getDelayTime(ANIMATION_DURATION * 4))
            ]);
        }
    }));
    _animateEaseOutAndIn = task(waitFor(async (obj)=>{
        await this._animateOpacity.perform(obj, 0, {
            duration: ANIMATION_DURATION,
            easing: fabric.util.ease.easeOutQuad
        });
        await this._animateOpacity.perform(obj, 1, {
            duration: ANIMATION_DURATION,
            easing: fabric.util.ease.easeInQuad
        });
    }));
    animate = task({
        enqueue: true
    }, waitFor(async ()=>{
        if (isEmpty(this.args.animations) || !this.canvas) {
            return;
        }
        const animateTasks = this.args.animations.map((animation)=>{
            const obj = getCanvasObjectById(this.canvas, animation.object);
            if (obj == null) {
                return null;
            }
            switch(animation.animation){
                case AnimationTypes.SHOW:
                    {
                        setColor(obj, animation.fillColor, animation.strokeColor);
                        return this.showObject.perform(obj);
                    }
                case AnimationTypes.HIDE:
                    {
                        setColor(obj, animation.fillColor, animation.strokeColor);
                        return this.hideObject.perform(obj);
                    }
                case AnimationTypes.FLASH:
                    {
                        setColor(obj, animation.fillColor, animation.strokeColor);
                        return this.flashObject.perform(obj);
                    }
                case AnimationTypes.RESET:
                    {
                        return this.resetObject.perform(obj);
                    }
                default:
                    {
                        return null;
                    }
            }
        });
        await all(animateTasks);
    }));
    _animateOpacity = task(waitFor(async (obj, value, options)=>{
        await new Promise((resolve)=>{
            options.onComplete = resolve;
            options.onChange = ()=>{
                renderAllSafe(this.canvas);
            };
            obj.animate('opacity', value, options);
        });
    }));
    openLink = task({
        drop: true
    }, waitFor(async (event)=>{
        if (event.target.tagName !== 'CANVAS') {
            return;
        }
        event.preventDefault();
        const getCanvasCoordsForClickFunc = this.args.getCanvasCoordsForClick ?? getCanvasCoordsForClick;
        const { x, y } = getCanvasCoordsForClickFunc(this.canvas, event);
        const obj = getCanvasObjectByPoint(this.canvas, x, y, true);
        const link = obj?.eflex?.link;
        if (isBlank(link)) {
            return;
        }
        if (link.startsWith('red:')) {
            await fetch(link.replace('red:', ''));
        } else {
            window.open(link);
        }
    }));
    willDestroy() {
        super.willDestroy(...arguments);
        cleanupCanvas(this.canvas);
        this.canvas = null;
    }
    #getDelayTime(time) {
        if (this.args.bypassDelayTime) {
            return time;
        } else {
            return getDelayTime(time);
        }
    }
    static{
        template_ea11a0453afb4bbd9e4ce8140598532d(`
    <div
      class="component-jem-dynamic-instruction"
      {{didInsert this.loadCanvas.perform}}
      {{didUpdate this.compareAttrs.perform @workInstruction @animations @previewMode}}
      {{onResize this.onResize.perform}}
      ...attributes
    >
      {{#if this.loadCanvas.isRunning}}
        <Spinner />
      {{/if}}
      <div
        class="work-instruction-display"
        title={{@workInstruction.deployedName}}
        {{on "pointerup" this.openLink.perform}}
      >
        <canvas class="work-instruction-canvas"></canvas>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
