import { template as template_f06dc5fc96024358b417e790a6e64748 } from "@ember/template-compiler";
import Portal from 'ember-stargate/components/portal';
import PortalTarget from 'ember-stargate/components/portal-target';
import { serviceHelper } from 'eflex/helpers';
import { get } from '@ember/helper';
import { t } from 'ember-intl';
import Link from 'eflex/components/link';
import BsTooltip from 'eflex/components/bs-tooltip';
export default template_f06dc5fc96024358b417e790a6e64748(`
<Portal @target="breadcrumbs">
  <div
    class="component-breadcrumbs row p-3 pb-2 g-0 hidden-print z-title-bar w-100
      {{if (get (serviceHelper 'resizeListener') 'navCompressed') 'collapsed' 'full'}}"
    ...attributes
  >
    <div class="col row g-0">
      <div class="col d-flex flex-column">
        {{#if @prevPage}}
          <div
            class="d-flex align-items-center section-name app-section-name text-gray-900 text-capitalize fs-4"
          >
            <Link
              class="prev-screen-name link-gray-900 text-decoration-none"
              @route={{@prevPageRoute}}
              @model={{@prevPageModel}}
            >
              {{@prevPage}}
            </Link>
            <span class="text-gray-500 ms-2">/</span>
          </div>
        {{/if}}
        <div class="row align-items-center">
          {{#if @page}}
            <h5 class="screen-name col-auto text-gray-900 text-capitalize fw-bold mb-0">
              {{@page}}
            </h5>
          {{/if}}
          {{#let
            (get (serviceHelper "currentUser") "user")
          as |user|}}
            {{#if @userNotPermitted}}
              {{#if user.isNotUser}}
                <div class="view-only-badge badge rounded-pill text-bg-gray-400 w-auto text-capitalize">
                  {{t 'viewonly'}}
                </div>
              {{else}}
                <div class="no-permission-badge badge rounded-pill text-bg-orange w-auto">
                  {{t 'permissions.configurationLocked'}}
                  <BsTooltip>
                    {{t 'permissions.contactAdmin'}}
                  </BsTooltip>
                </div>
              {{/if}}
            {{/if}}
          {{/let}}
          <div class="col d-flex align-items-center h-100">
            {{yield to="navOptions"}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <PortalTarget @name="page-functions" />
    </div>
    <div class="col-auto d-flex align-items-center action-title-strip">
      {{yield}}
    </div>
  </div>
</Portal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
