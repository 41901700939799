import { template as template_7e311c052b274d3599fb6f61e22e659b } from "@ember/template-compiler";
import moment from 'moment-timezone';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import PowerSelect from 'eflex/components/power-select';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { selectVal } from 'eflex/helpers';
import { t } from 'ember-intl';
export default class DropDownsTimezones extends Component {
    @service
    intl;
    get timezones() {
        const timezones = moment.tz.names().map((timezone)=>({
                name: timezone,
                value: timezone
            }));
        timezones.unshift({
            name: this.args.defaultValue ?? this.intl.t('settings.server.timezone.browser'),
            value: null
        });
        return timezones;
    }
    static{
        template_7e311c052b274d3599fb6f61e22e659b(`
    <PowerSelect
      @searchEnabled={{true}}
      @options={{this.timezones}}
      @disabled={{@disabled}}
      @selected={{findBy "value" @selectedTimezone this.timezones}}
      @onChange={{selectVal @onChange}}
      @placeholder={{t "settings.server.timezone.browser"}}
      @searchField="name"
      class="app-timezones-dropdown"
      ...attributes
    as |timezone|>
      {{timezone.name}}
    </PowerSelect>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
