import { action } from '@ember/object';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { getOwner } from '@ember/application';
import OidcSession from 'eflex/services/oidc-session';
import JwtSession from 'eflex/services/session';
import { didCancel } from 'ember-concurrency';
import { isTesting, isDevelopingApp, macroCondition } from '@embroider/macros';

export default class ApplicationRoute extends Route {
  @service session;
  @service notifier;
  @service currentUser;
  @service treeUpdateListener;
  @service resizeListener;
  @service dataPreloader;
  @service raygun;
  @service intl;

  async beforeModel(transition) {
    if (macroCondition(isTesting())) {
      await this.session.setup();
      return;
    }

    this.#registerSession(transition);
    await this.session.setup();
    await this.currentUser.loadLocale.perform('en-us', true);
    this.intl.setLocale('en-us');
    await this.dataPreloader.loadCurrentUserAndData.perform();
    this.resizeListener.start.perform();
    this.treeUpdateListener.start();
  }

  @action
  error(error) {
    if (this.controller) {
      this.controller.isLoading = false;
    }

    if (didCancel(error)) {
      return;
    }

    if (macroCondition(isDevelopingApp() || isTesting())) {
      throw error;
    }

    console.error(error);
    this.notifier.sendError('errorcomm');
    this.raygun.sendError(error);
  }

  @action
  loading(transition) {
    if (macroCondition(isTesting())) {
      return;
    }

    if (!this.controller) {
      return;
    }

    this.controller.isLoading = true;
    // cannot use await or it messes up error handling
    // eslint-disable-next-line promise/catch-or-return
    transition.promise.finally(() => {
      this.controller.isLoading = false;
    });
  }

  #registerSession(transition) {
    if (transition.targetName === 'loginOidc') {
      this.#setSession(OidcSession);
      return;
    }

    const authStorage = localStorage['ember_simple_auth-session'];
    if (authStorage && JSON.parse(authStorage).authenticated?.authenticator === 'authenticator:oidc') {
      this.#setSession(OidcSession);
      return;
    }

    this.#setSession(JwtSession);
  }

  #setSession(newSessionClass) {
    if (this.session instanceof newSessionClass) {
      return;
    }

    const owner = getOwner(this);
    owner.unregister('service:session');
    owner.register('service:session', newSessionClass);
    this.session = owner.lookup('service:session');
  }
}
