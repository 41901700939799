import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { validIpAddress } from 'eflex/constants/regex';
import { portValidator, checkIpUnique } from 'eflex/util/validators';
import { getOwner } from '@ember/application';
import { service } from '@ember/service';

@buildValidations({
  name: [validator('presence', true)],

  port: [
    portValidator,
    validator('presence', true),
    validator('inline', {
      validate(port, options, keyence) {
        return checkIpUnique(keyence, getOwner(keyence).lookup('service:hardwareRepo').keyences);
      },
    }),
  ],

  ipAddress: [
    validator('presence', true),
    validator('format', {
      regex: validIpAddress,
      allowBlank: false,
    }),
    validator('inline', {
      validate(ipAddress, options, keyence) {
        return checkIpUnique(keyence, getOwner(keyence).lookup('service:hardwareRepo').keyences);
      },
    }),
  ],
})
class Keyence extends Hardware {
  @service intl;

  @attr('string') ipAddress;
  @attr('number', { defaultValue: 9004 }) port;

  get isConfigured() {
    return isPresent(this.ipAddress) && isPresent(this.port);
  }

  get hardwareGroup() {
    return this.intl.t('keyence');
  }
}

export default Keyence;
