import { template as template_9ae44a0acab8494b915cd64f8c7043c9 } from "@ember/template-compiler";
import { decOpacityToHex } from 'eflex/util/opacity-helper';
import { isBlank } from '@ember/utils';
import OpacityInput from 'eflex/components/inputs/opacity';
import { inputVal } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
const DEFAULT_OPACITY = '100';
const DEFAULT_COLOR = '#000000';
const updateColor = (onUpdateColor, opacity, color)=>{
    color ??= DEFAULT_COLOR;
    const opacityHex = decOpacityToHex(opacity);
    onUpdateColor(color, opacity, color.concat(opacityHex));
};
const updateOpacity = (onUpdateColor, color, opacity)=>{
    opacity ??= DEFAULT_OPACITY;
    const opacityHex = decOpacityToHex(opacity);
    onUpdateColor(color, opacity, color.concat(opacityHex));
};
export default template_9ae44a0acab8494b915cd64f8c7043c9(`
<div class="component-inputs-color-opacity d-flex align-items-center" ...attributes>
  {{#let
    (if (isBlank @selectedOpacity) DEFAULT_OPACITY @selectedOpacity)
    (if (isBlank @selectedColor) DEFAULT_COLOR @selectedColor)
  as |opacity color|}}
    {{#unless @hideOpacity}}
      <OpacityInput
        class="opacity flex-fill me-2"
        @opacity={{opacity}}
        @disabled={{@disabled}}
        @update={{fn updateOpacity @updateColor color}}
      />
    {{/unless}}

    <input
      type="color"
      value={{color}}
      disabled={{@disabled}}
      class="square-color-picker"
      {{on "input" (inputVal (fn updateColor @updateColor opacity))}}
    />
  {{/let}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
