import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class Licensing extends Service {
  @tracked license;

  @service store;
  @service webSocket;

  get noProductsLicensed() {
    return !this.license || this.license.noProductsLicensed;
  }

  get unlicensed() {
    return !this.license || this.license.unlicensed;
  }

  loadLicense = task(waitFor(async () => {
    const licenses = await this.store.findAll('license');
    this.license = licenses?.[0];
  }));

  constructor() {
    super(...arguments);
    this.webSocket.addListener('licenseUpdate', this._updateLicense);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.webSocket.removeListener('licenseUpdate');
  }

  #enableDisableFeatures(keys, enable) {
    keys = new Set(keys);
    this.license.features.forEach((feature) => {
      if (keys.has(feature.key)) {
        feature.enabled = enable;
      }
    });
  }

  enableFeatures(...keys) {
    this.#enableDisableFeatures(keys, true);
  }

  disableFeatures(...keys) {
    this.#enableDisableFeatures(keys, false);
  }

  _updateLicense = (license) => {
    this.license = this.store.push(this.store.normalize('license', license));
  };
}
