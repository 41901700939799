import Modifier from 'ember-modifier';

export default class WhenChanged extends Modifier {
  #didSetup = false;
  #cache = new Map();

  modify(element, [func, ...unboundArgs], named) {
    if (!this.#didSetup) {
      this.#didSetup = true;

      for (const key in named) {
        this.#cache.set(key, named[key]);
      }

      func(element, ...unboundArgs, named);

      return;
    }

    let isChanged = false;

    for (const key in named) {
      if (!this.#cache.has(key) || this.#cache.get(key) !== named[key]) {
        isChanged = true;
        this.#cache.set(key, named[key]);
      }
    }

    if (isChanged) {
      func(element, ...unboundArgs, named);
    }
  }
}
