import { template as template_18028240babb428389c55a82db79e048 } from "@ember/template-compiler";
import { isEmpty, isPresent } from '@ember/utils';
import { pipe, chain, map, difference, head, filter, prop } from 'ramda';
import { dequal } from 'dequal';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { t } from 'ember-intl';
import style from 'ember-style-modifier';
import { on } from '@ember/modifier';
import { inputVal } from 'eflex/helpers';
import BasicDropdown from 'ember-basic-dropdown/components/basic-dropdown';
import { isEmpty as isEmptyHelper, or, and, not } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { isPresent as isPresentHelper, withDefault, inc } from '@eflexsystems/ember-composable-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { onResize } from 'eflex/modifiers';
import CheckboxInput from 'eflex/components/checkbox-input';
class TreeSelectItem {
    @tracked
    id;
    @tracked
    name;
    @tracked
    treeIconClass;
    @tracked
    children = [];
    @tracked
    path;
    @tracked
    modelName;
    @tracked
    position;
    @tracked
    checked = false;
    @tracked
    visible = true;
    @tracked
    expanded = false;
    constructor(props){
        Object.assign(this, props);
    }
}
const getLeafIndentWidth = (indent)=>{
    return `${indent * 27}px`;
};
const getLeafIsClickable = (canExpand, checkable)=>{
    return canExpand || (!canExpand && !checkable);
};
const onClick = (isClickable, onClickFn)=>{
    if (!isClickable) {
        return;
    }
    event.stopPropagation();
    onClickFn();
};
const Leaf = template_18028240babb428389c55a82db79e048(`
<div data-location-name={{@text}} ...attributes>
  <div class="label">
    {{#let (getLeafIsClickable @canExpand @checkable) as |isClickable|}}
      <div
        class="
          icon
          expand-icon
          pointer
          {{if (withDefault @expanded true) "dropdown-close-icon" "dropdown-open-icon"}}
          {{if @canExpand "can-expand"}}
        "
        {{on 'click' (fn onClick isClickable @onClick)}}
      ></div>
      {{#if @checkable}}
        <CheckboxInput
          class="d-inline-block align-text-bottom"
          @checked={{@checked}}
          @onChange={{fn @onCheckedChanged (not @checked)}}
        />
      {{/if}}
      <div
        class="indent d-inline-block"
        {{style width=(getLeafIndentWidth @indent)}}
      ></div>
      {{#if @iconClass}}
        <div
          class="icon data-icon me-2 {{@iconClass}} {{if isClickable "pointer"}}"
          {{on 'click' (fn onClick isClickable @onClick)}}
        ></div>
      {{/if}}
      <div
        class="label-text d-inline-block {{if isClickable "pointer"}}"
        {{on 'click' (fn onClick isClickable @onClick)}}
      >
        {{@text}}
      </div>
    {{/let}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getCanExpandBranch = (branchData, depth, maxDepth)=>{
    return isPresent(branchData.children) && (maxDepth == null || depth < maxDepth);
};
const updateChecked = (data, checked)=>{
    data.forEach((child)=>{
        child.checked = checked;
        if (isPresent(child.children)) {
            updateChecked(child.children, checked);
        }
    });
};
class Branch extends Component {
    onClick = (canExpand, branchData)=>{
        if (canExpand) {
            branchData.expanded = !branchData.expanded;
        } else {
            this.args.onClick(branchData);
        }
    };
    updateChecked = (branchData)=>{
        updateChecked([
            branchData
        ], !branchData.checked);
        this.args.onCheckedChanged();
    };
    onChildCheckedChanged = (branchData)=>{
        branchData.checked = branchData.children.every((item)=>item.checked);
        this.args.onCheckedChanged();
    };
    static{
        template_18028240babb428389c55a82db79e048(`
    {{#let (getCanExpandBranch @branchData @depth @maxDepth) as |canExpand|}}
      <div
        class="
          branch
          {{if this.expanded "expanded"}}
          {{if canExpand "can-expand" "no-expand"}}
        "
        ...attributes
      >
        {{#if @branchData.visible}}
          <Leaf
            @checked={{@branchData.checked}}
            @checkable={{@checkable}}
            @indent={{@depth}}
            @expanded={{@branchData.expanded}}
            @canExpand={{canExpand}}
            @iconClass={{@branchData.treeIconClass}}
            @text={{@branchData.name}}
            @onClick={{fn this.onClick canExpand @branchData}}
            @onCheckedChanged={{fn this.updateChecked @branchData}}
          />
          {{#if (and @branchData.expanded canExpand)}}
            <div class="branches">
              {{#each @branchData.children as |child|}}
                <Branch
                  @branchData={{child}}
                  @depth={{inc @depth}}
                  @maxDepth={{@maxDepth}}
                  @checkable={{@checkable}}
                  @onCheckedChanged={{fn this.onChildCheckedChanged @branchData}}
                  @onClick={{@onClick}}
                />
              {{/each}}
            </div>
          {{/if}}
        {{/if}}
      </div>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const Trigger = template_18028240babb428389c55a82db79e048(`
<div class="tree-select-trigger" ...attributes>
  <div class="tree-select-trigger-target">
    {{#if (and (not @allChecked) (isEmptyHelper @selected))}}
      <span class="input-placeholder">
        {{@placeholder}}
      </span>
    {{else if (and @allChecked @checkable)}}
      <span class="item">
        {{@allCheckedText}}
      </span>
    {{else}}
      {{#each @selected as |item|}}
        <span class="item ember-power-select-multiple-option">
          {{item.name}}
          {{#unless @disabled}}
            <span
              class="ember-power-select-multiple-remove-btn {{if @checkable "show" "hide"}}"
              {{on "click" (fn @onRemove item)}}
            >
              ×
            </span>
          {{/unless}}
        </span>
      {{/each}}
    {{/if}}
  </div>
  {{#if (and (not @disabled) (or (and (not @checkable) (isPresentHelper @selected)) @allChecked))}}
    <div class="tree-select-trigger-clear">
      <FaIcon @icon="xmark" {{on "click" @onClear}} />
    </div>
  {{/if}}
  <span class="ember-power-select-status-icon"></span>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const findTreeItemByPathArray = (treeData, pathArray)=>{
    return pipe(intoArray(chain((item)=>{
        if (item.id !== pathArray[0]) {
            return null;
        }
        if (pathArray.length === 1) {
            return item;
        } else if (item.children.length > 0) {
            pathArray.shift();
            return findTreeItemByPathArray(item.children, pathArray);
        } else {
            return null;
        }
    }), compact), head)(treeData);
};
const setTreeItemChecked = (id, treePaths, localTree, checked)=>{
    const path = treePaths.find((p)=>p.match(new RegExp(`${id}$`)));
    const item = findTreeItemByPathArray(localTree, path.split('#'));
    item.checked = checked;
};
const checkByChildren = (item)=>{
    if (isPresent(item.children)) {
        const checked = item.children.every((_item)=>_item.checked);
        if (item.checked !== checked) {
            item.checked = checked;
        }
    }
};
const updateCheckByChildren = (treeData)=>{
    treeData.forEach((item)=>{
        if (isEmpty(item.children)) {
            return;
        }
        checkByChildren(item);
        updateCheckByChildren(item.children);
        //need to check children again if the child check status was affected by its own children
        checkByChildren(item);
    });
};
const cleanEmptyChildren = (parent, minDepth, depth = 0)=>{
    if (depth === minDepth) {
        return parent;
    }
    const children = intoArray(map((child)=>cleanEmptyChildren(child, minDepth, depth + 1)), compact)(parent.children);
    if (children.length > 0) {
        parent.children = children;
        return parent;
    }
};
export default class TreeSelect extends Component {
    @tracked
    localTree = [];
    @tracked
    treePaths = new TrackedArray();
    @tracked
    selected;
    @tracked
    searchTerm = '';
    @tracked
    allChecked = false;
    @tracked
    verticalPosition = 'auto';
    @tracked
    contentMaxHeight;
    previousCheckedIds = this.args.checkedIds;
    constructor(){
        super(...arguments);
        let treeData = this.args.sourceTree;
        // Specifying a minimum depth will make sure only those paths are shown
        // rather than having an incomplete path selection. Example: if we want to
        // select a station but the group has no stations, we dont show that group
        if (this.args.minDepth != null && this.args.minDepth > 0) {
            treeData = intoArray(map((branch)=>cleanEmptyChildren(branch, this.args.minDepth, 0)), compact)(treeData);
        }
        treeData = this._copy(treeData);
        Object.assign(this, {
            localTree: treeData,
            selected: this._getSelection(treeData)
        });
        updateCheckByChildren(this.localTree);
        this._updateAllChecked();
    }
    onDidUpdate = (element, [checkedIds])=>{
        const checkedIdsChanged = !dequal(this.previousCheckedIds, checkedIds);
        if (!checkedIdsChanged) {
            return;
        }
        const toUncheck = difference(this.previousCheckedIds, checkedIds);
        const toCheck = difference(checkedIds, this.previousCheckedIds);
        this.previousCheckedIds = checkedIds;
        toUncheck.forEach((id)=>{
            setTreeItemChecked(id, this.treePaths, this.localTree, false);
        });
        toCheck.forEach((id)=>{
            setTreeItemChecked(id, this.treePaths, this.localTree, true);
        });
        this.selected = this._getSelection(this.localTree);
        updateCheckByChildren(this.localTree);
        this._updateAllChecked();
    };
    minWidth = (element)=>{
        if (this.args.fullWidth) {
            this.contentWidth = `${element.clientWidth}px`;
        }
    };
    search = (searchTerm)=>{
        this.searchTerm = searchTerm;
        this._updateVisible(this.localTree, searchTerm);
        this._updateAllChecked();
        this._updateSelected();
    };
    checkAllChanged = (checked)=>{
        this.allChecked = checked;
        this._updateProperty('checked', checked, this.localTree);
        this._updateSelected();
    };
    dataCheckedChanged = ()=>{
        this._updateAllChecked();
        this._updateSelected();
    };
    remove = (item)=>{
        this._uncheckTree(this.localTree, item.position.slice());
        this._updateAllChecked();
        this._updateSelected();
    };
    clear = ()=>{
        this.allChecked = false;
        this._updateProperty('checked', false, this.localTree);
        this._updateSelected();
        this.args.onClick?.(null);
    };
    onClick = (branchData)=>{
        if (!this.clickable) {
            this._updateProperty('checked', false, this.localTree);
            if (branchData?.length) {
                this._updateProperty('checked', true, branchData);
            } else {
                this._updateProperty('checked', true, [
                    branchData
                ]);
            }
            this._updateSelected();
        }
        this.args.onClick?.(branchData);
    };
    dropdownClosed = ()=>{
        this._updateProperty('expanded', false, this.localTree);
    };
    dropdownOpen = (component, { target })=>{
        const trigger = target.closest('.tree-select-trigger')?.children[0] ?? target.closest('.tree-select-trigger-target');
        if (!trigger) {
            return true;
        }
        const pageHeight = document.documentElement.clientHeight;
        const offset = trigger.getBoundingClientRect();
        const triggerBottom = offset.top + trigger.clientHeight;
        let height = pageHeight - triggerBottom - 35; // Leave a bit of a margin
        let verticalPosition = 'below';
        if (height < pageHeight / 3) {
            height = offset.top - 35;
            verticalPosition = 'above';
        }
        Object.assign(this, {
            contentMaxHeight: `${height}px`,
            verticalPosition
        });
        // prevent opening when a remove button is pressed
        if (target.classList?.value?.includes('times')) {
            return false;
        }
        return true;
    };
    onBranchClick = (close, branchData)=>{
        this.onClick(branchData);
        close();
    };
    _getSelection(treeData, depth = 0) {
        return intoArray(chain((child)=>{
            if (isPresent(child.children) && (this.args.maxDepth == null || depth < this.args.maxDepth)) {
                return this._getSelection(child.children, depth + 1);
            } else {
                return child;
            }
        }), compact, filter(prop('checked')))(treeData);
    }
    _copy(sourceTree, indexPath = []) {
        if (sourceTree == null) {
            return [];
        }
        return sourceTree.map((sourceItem, index)=>{
            const position = [
                ...indexPath,
                index
            ];
            const item = {
                id: sourceItem.id,
                name: sourceItem.name,
                treeIconClass: sourceItem.treeIconClass,
                path: sourceItem.path,
                modelName: sourceItem.modelName,
                expanded: sourceItem.expanded,
                position,
                checked: this.args.checkedIds?.includes(sourceItem.id) ?? false,
                children: this._copy(sourceItem.children, position)
            };
            checkByChildren(item);
            this.treePaths.push(item.path);
            return new TreeSelectItem(item);
        });
    }
    _updateProperty(property, value, treeData) {
        treeData?.forEach((child)=>{
            child[property] = value;
            if (isPresent(child.children)) {
                this._updateProperty(property, value, child.children);
            }
        });
    }
    _updateVisible(treeData, searchTerm = '') {
        if (treeData == null) {
            treeData = [];
        }
        treeData.forEach((child)=>{
            const match = child.name?.toLowerCase().includes(searchTerm.toLowerCase().trim());
            let childMatch = false;
            if (match) {
                child.visible = true;
                this._updateProperty('visible', true, child.children);
            } else {
                if (isPresent(child.children)) {
                    childMatch = this._updateVisible(child.children, searchTerm);
                }
                child.visible = childMatch;
            }
        });
        return treeData.some((item)=>item.visible);
    }
    _updateAllChecked() {
        this.allChecked = this.localTree.every((item)=>item.checked);
    }
    _updateSelected() {
        const selection = this._getSelection(this.localTree);
        this.selected = selection;
        this.args.onCheckedChanged?.(selection);
    }
    _uncheckTree(tree, position) {
        if (position.length === 0) {
            return;
        }
        const index = position.shift();
        const item = tree[index];
        item.checked = false;
        this._uncheckTree(item.children, position);
    }
    static{
        template_18028240babb428389c55a82db79e048(`
    <div
      class="tree-select"
      {{didUpdate this.onDidUpdate @checkedIds}}
      ...attributes
    >
      <BasicDropdown
        @disabled={{@disabled}}
        @verticalPosition={{this.verticalPosition}}
        @onClose={{this.dropdownClosed}}
        @onOpen={{this.dropdownOpen}}
        class="tree-select-dropdown"
      as |dropdown|>
        <dropdown.Trigger>
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            <Trigger
              class={{@triggerClass}}
              @checkable={{@checkable}}
              @placeholder={{@placeholder}}
              @allChecked={{this.allChecked}}
              @allCheckedText={{@checkAllText}}
              @selected={{this.selected}}
              @onRemove={{this.remove}}
              @onClear={{this.clear}}
              @disabled={{@disabled}}
              {{didInsert this.minWidth}}
              {{onResize this.minWidth}}
            />
          {{/if}}
        </dropdown.Trigger>
        <dropdown.Content
          class="{{@contentClass}} tree-select-dropdown-content tree-select-position-{{this.verticalPosition}}"
          @width={{this.contentWidth}}
        >
          <div class="tree-select-content" {{style maxHeight=this.contentMaxHeight}}>
            <div class="search input-group">
              <input
                placeholder={{t "searchFor"}}
                value={{this.searchTerm}}
                class="form-control"
                {{on "input" (inputVal this.search)}}
              />
              <div class="input-group-append input-group-text">
                <div class="icon icon-search"></div>
              </div>
            </div>
            {{#if @checkAllText}}
              <div class="check-all no-expand">
                <Leaf
                  @checked={{this.allChecked}}
                  @checkable={{@checkable}}
                  @text={{@checkAllText}}
                  @onCheckedChanged={{this.checkAllChanged}}
                  @onClick={{fn this.onClick this.localTree}}
                />
                <div class="divider"></div>
              </div>
            {{/if}}
            <div class="branches">
              {{#each this.localTree as |branchData|}}
                <Branch
                  @branchData={{branchData}}
                  @maxDepth={{@maxDepth}}
                  @depth={{0}}
                  @checkable={{@checkable}}
                  @onCheckedChanged={{this.dataCheckedChanged}}
                  @onClick={{fn this.onBranchClick dropdown.actions.close}}
                />
              {{/each}}
            </div>
          </div>
        </dropdown.Content>
      </BasicDropdown>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
