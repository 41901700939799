import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task, taskGroup, waitForProperty } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { dasherize } from '@ember/string';
import { forEach, filter, pipe, without, pluck } from 'ramda';
import { isAncestorOf, recursiveSave } from 'eflex/util/tree-helpers';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { isEmpty } from '@ember/utils';
import JobStatuses from 'eflex/constants/kinetic-job-statuses';
import { copyTask, addToEndOfSequence } from 'eflex/services/task-repo';

const filterKineticDeleted = (data) => {
  const options = data.filter(d => !d.kineticDeleted);
  const deleted = data.filter(d => d.kineticDeleted);

  if (!isEmpty(deleted)) {
    options.push({
      groupName: 'Deleted',
      options: deleted,
    });
  }

  return options;
};

export default class KineticOperationsOperationController extends Controller {
  @tracked showChangeNotificationModal = false;
  @tracked showTaskPanel = false;
  @tracked activeTaskPanelTab = 'task-device';
  @tracked operation;
  @tracked selectedTask;
  @tracked _selectedPart;
  @tracked _selectedPartRev;
  @tracked showCopyOperationModal = false;
  @tracked activeAssemblyOps;

  gridTaskConfigLoadTracker = new Map();

  @service taskRepo;
  @service validationErrorNotifier;
  @service notifier;
  @service systemConfig;
  @service store;
  @service currentUser;
  @service eventBus;
  @service taskConfigRepo;
  @service kineticOperationRepo;
  @service kineticRepo;
  @service fileDownloader;
  @service router;

  @taskGroup({ drop: true }) operationActions;

  loadAssemblyOps = task(waitFor(async (part) => {
    this.activeAssemblyOps = await this.store.query('kineticAssemblyOperation', {
      operations: pluck('id', part.operations),
      jobStatus: JobStatuses.released,
    });
  }));

  get showSpinner() {
    return this.copyTask.isRunning ||
      this.copyOperation.isRunning ||
      (this.save.isRunning && !this.showChangeNotificationModal);
  }

  get selectedPart() {
    return this._selectedPart ?? this.operation?.part;
  }

  set selectedPart(val) {
    this._selectedPart = val;
  }

  get selectedPartRev() {
    return this._selectedPartRev ?? this.operation?.partRev;
  }

  set selectedPartRev(val) {
    this._selectedPartRev = val;
  }

  get filteredParts() {
    const parts = this.kineticRepo.parts.filter(part => {
      return isEmpty(part.sourceJobNumber);
    }) ?? [];

    return filterKineticDeleted(parts);
  }

  get filteredPartRevs() {
    const partRevs = this.kineticRepo.partRevs.filter(partRev => {
      return partRev.parent === this.selectedPart && isEmpty(partRev.sourceJobNumber);
    }) ?? [];

    return filterKineticDeleted(partRevs);
  }

  get filteredOperations() {
    const operations = this.kineticOperationRepo.operations.filter(operation => {
      return operation.parent === this.selectedPartRev && isEmpty(operation.sourceJobNumber);
    }) ?? [];

    return filterKineticDeleted(operations);
  }

  _loadTaskGridConfigs = task(waitFor(async (operation, modelId) => {
    const cacheKey = modelId ?? true;
    const operationCacheSet = this.gridTaskConfigLoadTracker.get(operation.id);

    if (operationCacheSet?.has(cacheKey)) {
      return;
    }

    if (operationCacheSet) {
      operationCacheSet.add(cacheKey);
    } else {
      this.gridTaskConfigLoadTracker.set(operation.id, new Set([cacheKey]));
    }

    await this.store.query('taskConfig', {
      ancestorPath: operation.path,
      model: modelId,
    });
  }));

  gridTaskConfigs = trackedTask(this, this._loadTaskGridConfigs, () => [
    this.operation,
  ]);

  get isInvalid() {
    return this.operation?.isSelfOrChildInvalid;
  }

  get disabled() {
    return this.currentUser.isNotAdmin || this.operationActions.isRunning;
  }

  get isDirty() {
    if (this.operation?.isDeleted) {
      return false;
    }

    return this.operation?.isSelfOrChildDirty ?? false;
  }

  save = task({ group: 'operationActions' }, waitFor(async () => {
    if (!this.operation) {
      return;
    }

    if (this.isInvalid) {
      this.validationErrorNotifier.sendErrors([this.operation]);
      return;
    }

    if (this.systemConfig.jem?.changeNotifications) {
      this.showChangeNotificationModal = true;
      await waitForProperty(this, 'showChangeNotificationModal', false);
    }

    await recursiveSave(this.operation);
  }));

  copyTask = task({ group: 'operationActions' }, waitFor(async treeTask => {
    if (!this.operation || this.currentUser.isNotAdmin) {
      return;
    }

    await this.taskConfigRepo.loadTaskConfigs.perform(treeTask);

    const copy = copyTask(treeTask, this.operation);

    this.closeAllPanels();
    Object.assign(this, {
      activeTaskPanelTab: 'task-device',
      showTaskPanel: true,
      selectedTask: copy,
    });
  }));

  copyOperation = task({ group: 'operationActions' }, waitFor(async (targetOperation, copyFromOperation) => {
    let sourceOp, destinationOp;
    if (copyFromOperation) {
      sourceOp = this.operation;
      destinationOp = targetOperation;
    } else {
      sourceOp = targetOperation;
      destinationOp = this.operation;
    }

    await this.kineticOperationRepo.copyOperationTasks.perform(sourceOp, destinationOp);
    this.showCopyOperationModal = false;
  }));

  exportPdf = task({ drop: true }, waitFor(async () => {
    await this.fileDownloader.getFile.perform(
      `operationWorkInstructions/${this.operation.id}`,
      'application/pdf, application/zip',
      `operation_instructions_${dasherize(this.operation.name)}`,
    );
  }));

  @action
  updateTaskCardRows(treeItem) {
    if (treeItem.type !== 'task' || this.operation == null || !isAncestorOf(this.operation, treeItem)) {
      return;
    }

    const newTaskStart = treeItem.row;
    const newTaskEnd = newTaskStart + treeItem.sizeY;

    pipe(
      intoArray(
        without([treeItem]),
        filter(taskRecord => {
          const changed = taskRecord.changedAttributes();
          return changed.row != null && newTaskStart <= changed.row[1] && changed.row[1] < newTaskEnd;
        }),
      ),
      forEach(taskRecord => {
        addToEndOfSequence(this.operation, taskRecord);
      }),
    )(this.operation.tasks);
  }

  closeAllPanels() {
    this.showTaskPanel = false;
  }

  openTaskPanel = task(waitFor(async taskRecord => {
    Object.assign(this, {
      showTaskPanel: true,
    });

    await this.taskConfigRepo.loadTaskConfigs.perform(taskRecord);

    this.selectedTask = taskRecord;
  }));

  @action
  deleteTask(taskRecord) {
    if (this.selectedTask === taskRecord) {
      Object.assign(this, {
        selectedTask: null,
        showTaskPanel: false,
      });
    }

    this.taskRepo.delete(taskRecord);
  }

  @action
  rollback() {
    if (this.selectedTask?.isNew) {
      this.closeAllPanelsAndClearPanelModel();
    }

    this.operation.rollbackAttributes();
    this.eventBus.trigger('taskCardsRolledBack');
  }

  @action
  addTask(taskType) {
    this.closeAllPanels();
    Object.assign(this, {
      showTaskPanel: true,
      activeTaskPanelTab: 'task-device',
      selectedTask: this.taskRepo.create({
        parent: this.operation,
        taskType,
        row: (this.selectedTask?.row ? this.selectedTask.rowEnd + 1 : null),
        column: 1,
      }),
    });
  }

  @action
  closeAllPanelsAndClearPanelModel() {
    this.closeAllPanels();
    this.selectedTask = null;
    this.activeTaskPanelTab = 'task-device';
  }
}
