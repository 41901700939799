/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class WorkInstructionEditorLibraryListView extends Component {
  @service workInstructionRepo;

  @action
  libraryCardDraggable(workInstruction) {
    return this.workInstructionRepo.userCanEdit(workInstruction);
  }
}
