import { template as template_d3977b4a70d8405b9f0dd09104b9b285 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { sortByProp, sortByProps } from 'ramda-adjunct';
import { t } from 'ember-intl';
import PowerSelect from 'eflex/components/power-select';
import { optional } from '@eflexsystems/ember-composable-helpers';
import { serviceHelper } from 'eflex/helpers';
import { fn } from '@ember/helper';
import { handleContextChange } from 'eflex/services/task-repo';
const getFilteredModels = (treeItem, allowAll, defaultTaskConfigContext)=>{
    const options = sortByProp('code', treeItem.area.models ?? []);
    if (allowAll) {
        options.unshift(defaultTaskConfigContext.getContext(treeItem));
    }
    return options;
};
const getSelectedModel = (selectedContext, filteredModels)=>{
    if (selectedContext.allSelected) {
        return filteredModels[0];
    } else {
        return selectedContext;
    }
};
export const ModelContextSelector = template_d3977b4a70d8405b9f0dd09104b9b285(`
<div class="row form-row model-context-selector" ...attributes>
  <div class="col">
    <label class="form-label">
      {{t "model"}}
    </label>
    {{#let (getFilteredModels @treeItem @allowAll (serviceHelper 'defaultTaskConfigContext')) as |filteredModels|}}
      <PowerSelect
        class="plant-model-select options-select"
        @options={{filteredModels}}
        @selected={{getSelectedModel @selectedContext filteredModels}}
        @searchField="displayName"
        @renderInPlace={{@renderInPlace}}
        @onChange={{optional @contextWasSelected}}
      as |model|>
        {{model.displayName}}
      </PowerSelect>
    {{/let}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getSortedAreaComponents = (components)=>{
    return sortByProps([
        'isComplex',
        'priority'
    ], components ?? []);
};
const getComponentOptions = (treeItem, allowAll, defaultTaskConfigContext)=>{
    let options = [];
    if (allowAll) {
        options = [
            defaultTaskConfigContext.getContext(treeItem)
        ];
    }
    const component = treeItem.component;
    if (component) {
        options = options.concat(sortByProps([
            'isComplex',
            'priority'
        ], component.options));
    }
    return options;
};
const getSelectedComponentOption = (selectedContext, componentOptions)=>{
    if (selectedContext.allSelected) {
        return componentOptions[0];
    } else {
        return selectedContext;
    }
};
export class ComponentContextSelector extends Component {
    @service
    taskRepo;
    @service
    defaultTaskConfigContext;
    onSelectedComponent = (treeItem, componentOptions, component)=>{
        if (component == null || component === treeItem.component) {
            return;
        }
        treeItem.taskConfigs.forEach((taskConfig)=>{
            taskConfig.deleteRecord();
        });
        this.taskRepo.createComponentOptionConfigs(treeItem, component);
        handleContextChange(treeItem, component);
        treeItem.component = component;
        this.args.contextWasSelected(componentOptions[0]);
    };
    static{
        template_d3977b4a70d8405b9f0dd09104b9b285(`
    <div class="component-context-selector row" ...attributes>
      {{#let (getComponentOptions @treeItem @allowAll this.defaultTaskConfigContext) as |componentOptions|}}
        <div class="col-6">
          <div class="component-select-wrapper">
            <label class="form-label">
              {{t "components"}}
            </label>
            <PowerSelect
              class="app-component-select"
              @options={{getSortedAreaComponents @treeItem.area.bomSource.components}}
              @selected={{@treeItem.component}}
              @searchEnabled={{true}}
              @searchField="displayName"
              @renderInPlace={{@renderInPlace}}
              @onChange={{fn this.onSelectedComponent @treeItem componentOptions}}
              @disabled={{@disableComponentSelect}}
            as |comp|>
              {{comp.displayName}}
            </PowerSelect>
          </div>
        </div>
        {{#unless @treeItem.component.isAlwaysRun}}
          <div class="col-6">
            <label class="form-label">
              {{t "options"}}
            </label>
            <PowerSelect
              class="app-option-select options-select"
              @options={{componentOptions}}
              @selected={{getSelectedComponentOption @selectedContext componentOptions}}
              @searchEnabled={{true}}
              @searchField="name"
              @renderInPlace={{@renderInPlace}}
              @placeholder={{t "options"}}
              @onChange={{optional @contextWasSelected}}
            as |opt|>
              {{opt.displayName}}
            </PowerSelect>
          </div>
        {{/unless}}
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default template_d3977b4a70d8405b9f0dd09104b9b285(`
{{#if @treeItem.usesComponents}}
  <ComponentContextSelector
    @treeItem={{@treeItem}}
    @renderInPlace={{@renderInPlace}}
    @selectedContext={{@selectedContext}}
    @contextWasSelected={{@contextWasSelected}}
    @allowAll={{@allowAll}}
    @disableComponentSelect={{@disableComponentSelect}}
    ...attributes
  />
{{else}}
  <ModelContextSelector
    @treeItem={{@treeItem}}
    @renderInPlace={{@renderInPlace}}
    @selectedContext={{@selectedContext}}
    @contextWasSelected={{@contextWasSelected}}
    @allowAll={{@allowAll}}
    ...attributes
  />
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
