import { template as template_67027839b01b487591779b4db029c020 } from "@ember/template-compiler";
import StandardModal from 'eflex/components/standard-modal';
import { t } from 'ember-intl';
export default template_67027839b01b487591779b4db029c020(`
<StandardModal
  @size="sm"
  @onCancel={{@onCancel}}
  @onConfirm={{@onConfirm}}
  ...attributes
as |modal|>
  <modal.header @text={{t "jem.wieApprovals.enabled"}} />
  <modal.body>
    <p>
      {{t "jem.wieApprovals.modalBody"}}
    </p>
  </modal.body>
  <modal.footer as |footer|>
    <footer.confirm>
      {{t "jem.wieApprovals.modalConfirm"}}
    </footer.confirm>
    <footer.close>
      {{t "cancel"}}
    </footer.close>
  </modal.footer>
</StandardModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
