// This "type definition" import comment provides auto-complete, go-to-def, etc.
// for Ember's internals throughout your application, so don't remove it!
/**
  @typedef {import('ember-source/types')} Types
*/
import 'core-js/es/array/to-reversed';
import 'core-js/es/array/to-sorted';
import '@glint/environment-ember-loose';
import '@glint/environment-ember-template-imports';

import { importSync, isDevelopingApp, macroCondition } from '@embroider/macros';

if (macroCondition(isDevelopingApp())) {
  importSync('eflex/deprecation-workflow');

  // ember-inspector compatibility with embroider
  const glimmerRuntime = importSync('@glimmer/runtime');
  const glimmerReference = importSync('@glimmer/reference');
  globalThis.define('@glimmer/runtime', () => glimmerRuntime);
  globalThis.define('@glimmer/reference', () => glimmerReference);
}

import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'eflex/config/environment';
import 'eflex/extensions/ember-data';
import 'eflex/extensions/fabric';
import 'eflex/extensions/ember';
import 'eflex/extensions/pnotify';
import 'eflex/extensions/moment';
import 'bootstrap';
import Application from '@ember/application';
import 'webrtc-adapter';
import 'eflex/assets/styles.scss';

export default class App extends Application {
  name = 'eflex';
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
