export const addObjectIfNotPresent = (array, obj) => {
  if (!array.includes(obj)) {
    array.push(obj);
  }

  return array;
};

export const addObjectsIfNotPresent = (array, objs) => {
  objs.forEach(obj => { addObjectIfNotPresent(array, obj); });
  return array;
};

export const removeObject = (array, obj) => {
  const index = array.indexOf(obj);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
};

export const removeObjects = (array, objs) => {
  objs.forEach(obj => { removeObject(array, obj); });
  return array;
};
