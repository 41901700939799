import BsContextualHelp from 'eflex/components/bs-contextual-help';
import { default as OrigBsPopover } from 'ember-bootstrap/components/bs-popover';
import { getComponentTemplate, setComponentTemplate } from '@ember/component';

class BsPopover extends BsContextualHelp {
  // make sure events inside work
  get delayHide() {
    return 150;
  }

  get placement() {
    return this.args.placement ?? 'right';
  }

  get triggerEvents() {
    return this.args.triggerEvents ?? 'click';
  }

  get arrowElement() {
    return this.overlayElement.querySelector('.arrow');
  }
}

setComponentTemplate(getComponentTemplate(OrigBsPopover), BsPopover);

export default BsPopover;
