import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';

export default class PartsGenealogyRoute extends AuthenticatedRoute {
  @service eflexAjax;

  queryParams = {
    searchMode: {
      refreshModel: true,
    },
  };

  model(queryParams) {
    if (isBlank(queryParams.searchTerm)) {
      return null;
    }

    return this.eflexAjax.get.perform('marriages', queryParams);
  }
}
