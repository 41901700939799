import Model, { attr, hasMany } from '@ember-data/model';
import { flatten, pipe, pluck, uniq } from 'ramda';
import { compact, sortByProps } from 'ramda-adjunct';
import { dp } from '@eflexsystems/ramda-helpers';
import JobStatuses from 'eflex/constants/kinetic-job-statuses';

export default class KineticJob extends Model {
  @attr('string') jobNumber;
  @attr('string') jobStatus;
  @attr('date') updatedAt;
  @attr('date') createdAt;

  @hasMany('kineticJobAssembly', { inverse: 'job', async: false, cascadeDelete: true }) assemblies;

  get allOperations() {
    return pipe(
      pluck('assemblyOperations'),
      flatten,
      compact,
      sortByProps([dp('assembly.assemblySequence'), 'sequence']),
    )(this.assemblies ?? []);
  }

  get allowedStations() {
    return pipe(
      pluck('stations'),
      flatten,
      compact,
      uniq,
    )(this.allOperations ?? []);
  }

  get isClosed() {
    return this.jobStatus === JobStatuses.closed;
  }

  get isComplete() {
    return this.jobStatus === JobStatuses.complete;
  }

  get isReleased() {
    return this.jobStatus === JobStatuses.released;
  }

  get isUnreleased() {
    return this.jobStatus === JobStatuses.unreleased;
  }
}
