import RESTSerializer from '@ember-data/serializer/rest';
import { warn } from '@ember/debug';
import { camelize } from '@ember/string';

const normalizeEmbeddedRelationship = (hash, store, relationshipMeta, relationshipHash) => {
  let modelName = relationshipMeta.type;
  if (relationshipMeta.options.polymorphic) {
    modelName = relationshipHash.type;
  }
  const modelClass = store.modelFor(modelName);
  const serializer = store.serializerFor(modelName);

  const { data, included } = serializer.normalize(modelClass, relationshipHash, null);

  hash.included = hash.included ?? [];
  hash.included.push(data);
  if (included) {
    hash.included = hash.included.concat(included);
  }

  return data;
};

const removeEmbeddedForeignKey = (store, snapshot, embeddedSnapshot, relationship, json) => {
  if (relationship.kind !== 'belongsTo') {
    return;
  }

  const schema = store.modelFor(snapshot.modelName);
  const parentRecord = schema.inverseFor(relationship.key, store);
  if (!parentRecord) {
    return;
  }

  const name = parentRecord.name;
  const embeddedSerializer = store.serializerFor(embeddedSnapshot.modelName);
  const parentKey = embeddedSerializer.keyForRelationship(name, parentRecord.kind, 'deserialize');

  if (!parentKey) {
    return;
  }

  json[parentKey] = undefined;
};

const generateSerializedHasMany = (store, snapshot, relationship) => {
  const hasMany = snapshot.hasMany(relationship.key) ?? [];

  return hasMany.map(embeddedSnapshot => {
    const embeddedJson = embeddedSnapshot.serialize({ includeId: true });
    if (relationship.options.polymorphic) {
      embeddedJson.type = camelize(embeddedSnapshot.modelName);
    }
    removeEmbeddedForeignKey(store, snapshot, embeddedSnapshot, relationship, embeddedJson);
    return embeddedJson;
  });
};

export default class ApplicationSerializer extends RESTSerializer {
  serialize(snapshot, options = {}) {
    options.includeId = true;

    const json = super.serialize(snapshot, options);

    if (snapshot.record.polymorphic) {
      json.type = camelize(snapshot.modelName);
    }

    return json;
  }

  normalize(typeClass, hash, prop) {
    if (hash._id != null) {
      hash.id ??= hash._id;
      hash._id = undefined;
    }

    if (hash.__v != null) {
      hash.__v = undefined;
    }

    const normalizedHash = super.normalize(typeClass, hash, prop);
    this.#extractEmbeddedRecords(typeClass, normalizedHash);
    return normalizedHash;
  }

  serializeBelongsTo(snapshot, json, relationship) {
    const attr = relationship.key;
    const relationshipsDefinition = this.store
      .getSchemaDefinitionService()
      .relationshipsDefinitionFor({ type: snapshot.modelName });

    if (this.#noSerializeOptionSpecified(relationshipsDefinition, attr)) {
      super.serializeBelongsTo(snapshot, json, relationship);
      return;
    }
    const includeIds = this.#hasSerializeIdsOption(attr);
    const includeRecords = this.#defIsEmbedded(relationshipsDefinition, attr);
    const embeddedSnapshot = snapshot.belongsTo(attr);
    if (includeIds) {
      const serializedKey = this.#getSerializedKey(snapshot.modelName, relationship);

      if (!embeddedSnapshot) {
        json[serializedKey] = null;
      } else {
        json[serializedKey] = embeddedSnapshot.id;

        if (relationship.options.polymorphic) {
          this.serializePolymorphicType(snapshot, json, relationship);
        }
      }
    } else if (includeRecords) {
      this.#serializeEmbeddedBelongsTo(snapshot, json, relationship);
    }
  }

  serializeHasMany(snapshot, json, relationship) {
    const attr = relationship.key;

    const relationshipsDefinition = this.store
      .getSchemaDefinitionService()
      .relationshipsDefinitionFor({ type: snapshot.modelName });

    const hasSerializeIdsOption = this.#hasSerializeIdsOption(attr);
    const hasSerializeIdsAndTypesOption = this.#hasSerializeIdsAndTypesOption(attr);
    const defIsEmbedded = this.#defIsEmbedded(relationshipsDefinition, attr);

    if (
      !hasSerializeIdsOption &&
      !hasSerializeIdsAndTypesOption &&
      !defIsEmbedded
    ) {
      super.serializeHasMany(snapshot, json, relationship);
      return;
    }

    let serializedHasMany;
    const serializedKey = this.#getSerializedKey(snapshot.modelName, relationship);

    if (hasSerializeIdsAndTypesOption) {
      const hasMany = snapshot.hasMany(relationship.key) ?? [];

      serializedHasMany = hasMany.map(recordSnapshot => ({
        id: recordSnapshot.id,
        type: camelize(recordSnapshot.modelName),
      }));
    } else if (hasSerializeIdsOption) {
      serializedHasMany = snapshot.hasMany(attr, { ids: true });
    } else if (defIsEmbedded) {
      warn(
        // eslint-disable-next-line max-len
        `The embedded relationship '${serializedKey}' is undefined for '${snapshot.modelName}' with id '${snapshot.id}'. Please include it in your original payload.`,
        // eslint-disable-next-line unicorn/no-typeof-undefined
        typeof snapshot.hasMany(relationship.key) !== 'undefined',
        { id: 'ds.serializer.embedded-relationship-undefined' },
      );

      serializedHasMany = generateSerializedHasMany(this.store, snapshot, relationship);
    }

    json[serializedKey] = serializedHasMany;
  }

  #serializeEmbeddedBelongsTo(snapshot, json, relationship) {
    const embeddedSnapshot = snapshot.belongsTo(relationship.key);
    const serializedKey = this.#getSerializedKey(snapshot.modelName, relationship);

    if (!embeddedSnapshot) {
      json[serializedKey] = null;
      return;
    }

    json[serializedKey] = embeddedSnapshot.serialize({ includeId: true });
    removeEmbeddedForeignKey(this.store, snapshot, embeddedSnapshot, relationship, json[serializedKey]);

    if (relationship.options.polymorphic) {
      this.serializePolymorphicType(snapshot, json, relationship);
    }
  }

  #getSerializedKey(modelName, relationship) {
    const schema = this.store.modelFor(modelName);
    let serializedKey = this._getMappedKey(relationship.key, schema);
    if (serializedKey === relationship.key && this.keyForRelationship) {
      serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
    }
    return serializedKey;
  }

  #getAttrsOption(attr) {
    return this.attrs && (this.attrs[camelize(attr)] ?? this.attrs[attr]);
  }

  #hasSerializeIdsOption(attr) {
    const option = this.#getAttrsOption(attr);
    return option && (option.serialize === 'ids' || option.serialize === 'id');
  }

  #hasSerializeIdsAndTypesOption(attr) {
    const option = this.#getAttrsOption(attr);
    return option && option.serialize === 'ids-and-types';
  }

  #noSerializeOptionSpecified(relationshipsDefinition, attr) {
    return !this.#hasSerializeIdsOption(attr) &&
      !this.#hasSerializeIdsAndTypesOption(attr) &&
      !this.#defIsEmbedded(relationshipsDefinition, attr);
  }

  #extractEmbeddedRecords(typeClass, partial) {
    const relationshipsDefinition = this.store
      .getSchemaDefinitionService()
      .relationshipsDefinitionFor({ type: typeClass.modelName });

    typeClass.eachRelationship((key, relationship) => {
      const hasSerializeIdsAndTypesOption = this.#hasSerializeIdsAndTypesOption(key);

      if (
        !hasSerializeIdsAndTypesOption &&
        !this.#defIsEmbedded(relationshipsDefinition, key)
      ) {
        return;
      }

      const relationshipHash = partial.data?.relationships?.[key]?.data;

      if (!relationshipHash) {
        return;
      }

      let normalizedRelationshipData;

      if (hasSerializeIdsAndTypesOption) {
        normalizedRelationshipData = relationshipHash.map(item => ({
          id: item._id ?? item.id,
          type: item.type,
        }));
      } else if (relationship.kind === 'hasMany') {
        normalizedRelationshipData = relationshipHash.map(item =>
          normalizeEmbeddedRelationship(partial, this.store, relationship, item),
        );
      } else {
        normalizedRelationshipData = normalizeEmbeddedRelationship(partial, this.store, relationship, relationshipHash);
      }

      partial.data.relationships[key] = { data: normalizedRelationshipData };
    });
  }

  #defIsEmbedded(relationshipsDefinition, attr) {
    return (relationshipsDefinition[camelize(attr)] ?? relationshipsDefinition[attr])?.options.embedded ?? false;
  }
}
