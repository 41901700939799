import { template as template_8432f71de9a94a3e9aa0b7226225e004 } from "@ember/template-compiler";
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
import { hash } from '@ember/helper';
import { serviceHelper as service } from 'eflex/helpers';
const IoIndex = template_8432f71de9a94a3e9aa0b7226225e004(`
<@form.element
  class='test-io-index'
  @controlType='number'
  @model={{@model}}
  @label={{t 'ioIndex'}}
  @property={{withDefault @property 'ioIndex'}}
  @invisibleLabel={{@invisibleLabel}}
  ...attributes
as |elem|>
  <elem.control min='1' />
</@form.element>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const HardwareIndex = template_8432f71de9a94a3e9aa0b7226225e004(`
{{#if @usesOperations}}
  <@form.element
    class='test-hardware-index'
    @model={{@model}}
    @controlType='number'
    @property={{withDefault @property 'hardwareIndex'}}
    @label={{t 'number'}}
    @invisibleLabel={{@invisibleLabel}}
    @size={{@size}}
    ...attributes
  as |elem|>
    <elem.control min='1' />
  </@form.element>
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const HardwarePowerSelect = template_8432f71de9a94a3e9aa0b7226225e004(`
<@form.element
  @controlType={{withDefault @controlType 'power-select'}}
  @model={{@model}}
  @property={{withDefault @property (if @usesOperations 'hardwareAssignedType' 'hardware')}}
  @label={{t 'hardware'}}
  @invisibleLabel={{@invisibleLabel}}
  @options={{@options}}
  ...attributes
as |el|>
  <el.control
    class='test-hardware-select'
    @placeholder={{@placeholder}}
    @searchEnabled={{true}}
    @searchField={{unless @usesOperations 'name'}}
    @onChange={{withDefault @onChange el.setValue}}
  as |hardware|>
    {{#if hardware}}
      {{#if @usesOperations}}
        {{t hardware}}
      {{else}}
        {{hardware.name}}
      {{/if}}
    {{else}}
      {{@placeholder}}
    {{/if}}
  </el.control>
</@form.element>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getOptions = (types, required, usesOperations, hardwareRepo)=>{
    let options;
    if (usesOperations) {
        options = hardwareRepo.getAllowedCamelizedHardwareTypes(types);
    } else {
        options = hardwareRepo.getGroupedAllowedHardware(types);
    }
    if (!required) {
        options = [
            null,
            ...options
        ];
    }
    return options;
};
export default template_8432f71de9a94a3e9aa0b7226225e004(`
{{#let
  (withDefault @default '---')
  (withDefault @usesOperations @model.usesOperations)
  (service 'hardwareRepo')
as |placeholder usesOperations hardwareRepo|}}
  {{#let (getOptions @types @required usesOperations hardwareRepo) as |options|}}
    {{#if (has-block)}}
      {{yield
        (hash
          select=(component HardwarePowerSelect
            controlType=@controlType
            model=@model
            usesOperations=usesOperations
            options=options
            placeholder=placeholder
            invisibleLabel=@invisibleLabel
            form=@form
          )
          index=(component HardwareIndex
            form=@form
            model=@model
            usesOperations=usesOperations
            invisibleLabel=@invisibleLabel
          )
          ioIndex=(component IoIndex
            form=@form
            model=@model
            usesOperations=usesOperations
            invisibleLabel=@invisibleLabel
          )
        )
      }}
    {{else}}
      <div class='row' ...attributes>
        <div class='col'>
          <HardwarePowerSelect
            @controlType={{@controlType}}
            @model={{@model}}
            @usesOperations={{usesOperations}}
            @options={{options}}
            @placeholder={{placeholder}}
            @invisibleLabel={{@invisibleLabel}}
            @form={{@form}}
          />
        </div>

        {{#if usesOperations}}
          <div class='col-2'>
            <HardwareIndex
              @form={{@form}}
              @model={{@model}}
              @usesOperations={{true}}
              @invisibleLabel={{@invisibleLabel}}
            />
          </div>
        {{/if}}
      </div>
    {{/if}}
  {{/let}}
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
