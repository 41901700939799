import { template as template_6ab35fe598f54d2788a494388040c0b5 } from "@ember/template-compiler";
import { isBlank, isEmpty } from '@ember/utils';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
const onSubmit = (bomSourceLookupValue, tempSerialNumber, onEnter)=>{
    if (isEmpty(bomSourceLookupValue) || isBlank(tempSerialNumber)) {
        return;
    }
    onEnter();
};
export default template_6ab35fe598f54d2788a494388040c0b5(`
<div class="col-3">
  <div class="jem-lookup-input">
    <BsForm
      @model={{this}}
      @submitOnEnter={{true}}
      @onSubmit={{fn onSubmit @bomSourceLookupValue @tempSerialNumber @onEnter}}
      @readonly={{@stationIsRunning}}
    as |form|>
      <form.element
        class="mb-0"
        @controlType="text"
        @size="lg"
        @value={{if @stationIsRunning @buildStatusBomSourceLookupValue @bomSourceLookupValue}}
        @label={{t "jem.bomSourceLookupValue"}}
        @onChange={{@onInput}}
      as |el|>
        <el.control
          class="jem-part-input-value"
          placeholder={{@buildStatusBomSourceLookupValue}}
        />
      </form.element>
    </BsForm>
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
