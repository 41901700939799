import numbro from 'numbro';

export default function formatBytes(bytes) {
  if (bytes != null) {
    return numbro(bytes).format({
      output: 'byte',
      base: 'decimal',
      spaceSeparated: true,
      mantissa: 2,
    });
  }
}
