import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { service } from '@ember/service';

@buildValidations({
  name: [validator('presence', true)],
  cupsName: [validator('presence', true)],
})
class ZebraPrinter extends Hardware {
  @service intl;

  @attr('string') cupsName;

  get isConfigured() {
    return isPresent(this.cupsName);
  }

  get isConnected() {
    return true;
  }

  get hardwareGroup() {
    return this.intl.t('printer');
  }
}

export default ZebraPrinter;
