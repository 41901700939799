import { template as template_7b2b183356d74392bf972c09297e766b } from "@ember/template-compiler";
import { isEmpty } from '@ember/utils';
import RouteTemplate from 'ember-route-template';
import Breadcrumbs from 'eflex/components/breadcrumbs';
import BsForm from 'eflex/components/bs-form';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import { hash, fn } from '@ember/helper';
import SpinnerOverlay from 'eflex/components/spinner-overlay';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import { timeout, all, task } from 'ember-concurrency';
import { flow, filter, propEq, values } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import JobStatuses from 'eflex/constants/kinetic-job-statuses';
import getDelayTime from 'eflex/util/get-delay-time';
import { OUTBOX_EVENT_MAPPINGS, OUTBOX_EVENTS, INBOX_EVENT_MAPPINGS, INBOX_EVENTS } from 'eflex/constants/integration-sync-events';
import Component from '@glimmer/component';
import { isTesting, isDevelopingApp, macroCondition } from '@embroider/macros';
const EVENT_NAMES = [
    {
        groupName: 'Inbox',
        options: INBOX_EVENTS
    },
    {
        groupName: 'Outbox',
        options: OUTBOX_EVENTS
    }
];
const getFilteredPartRevs = (kineticPartRevs, selectedPart)=>{
    return flow(kineticPartRevs ?? [], [
        filter(propEq('parent', selectedPart)),
        sortByProp('name')
    ]);
};
const getFilteredOperations = (kineticOperations, selectedPartRev)=>{
    return flow(kineticOperations ?? [], [
        filter(propEq('parent', selectedPartRev)),
        sortByProp('sequence')
    ]);
};
const getEventData = (eventName, kineticRepo)=>{
    const employeeId = '1';
    const shift = 1;
    const resourceGroupId = 'group1';
    const resourceId = 'station1';
    let data;
    switch(eventName){
        case INBOX_EVENT_MAPPINGS.Site:
            {
                data = {
                    name: 'Main Plant',
                    timeZone: 'America/Detroit'
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.ReasonCode:
            {
                data = {
                    reasonCode: 'SCMBD',
                    description: 'Machine Breakdown',
                    reasonType: 'Scrap',
                    deleted: false
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.Station:
            {
                data = {
                    departmentId: 'area1',
                    departmentDescription: 'Area 1',
                    resourceGroupId,
                    resourceGroupDescription: 'Group 1',
                    resourceId,
                    resourceDescription: 'Station 1',
                    deleted: false
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.Employee:
            {
                data = {
                    employeeId,
                    supervisorEmployeeId: employeeId,
                    name: 'Charles Johnson',
                    email: 'charles@somewhere.com',
                    mobile: '0773585415',
                    status: 'Active',
                    defaultShift: shift,
                    defaultResourceGroupId: resourceGroupId,
                    deleted: false
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.Shift:
            {
                data = {
                    shift,
                    name: 'Shift A',
                    startDate: new Date(),
                    endDate: null,
                    deleted: false
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.BOM:
            {
                data = {
                    materials: [],
                    partNumber: 'ECPC101',
                    partDescription: 'ECPC Test 1',
                    revisionNumber: 'B',
                    revisionDescription: 'Big',
                    deleted: false,
                    operations: [
                        {
                            sequence: 0,
                            operationCode: 'AssyUnit',
                            operationCodeDescription: 'Assembly',
                            resources: [
                                {
                                    resourceGroupId,
                                    resourceId
                                },
                                {
                                    resourceGroupId,
                                    resourceId
                                }
                            ]
                        },
                        {
                            sequence: 1,
                            operationCode: 'Hammer',
                            operationCodeDescription: 'Hammer Time',
                            resources: [
                                {
                                    resourceGroupId,
                                    resourceId
                                },
                                {
                                    resourceGroupId,
                                    resourceId
                                }
                            ]
                        }
                    ]
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.IssueMaterial:
            {
                data = {
                    jobNumber: 'CPCTEST1',
                    partNumber: 'FLR',
                    assemblySequence: 0,
                    materialSequence: 10,
                    relatedOperation: 10,
                    requiredQuantity: 10,
                    issuedQuantity: 8,
                    issuedComplete: false,
                    quantityPer: 1,
                    issuedQuantities: [
                        {
                            partNumber: 'FLR',
                            fromWarehouseCode: 'CHI',
                            toWarehouseCode: 'PRD',
                            fromBinNum: '00-00-00',
                            toBinNum: 'ASM',
                            transactionQuantity: 8
                        }
                    ],
                    company: kineticRepo.kinetic.companyId,
                    siteId: kineticRepo.kinetic.siteId
                };
                break;
            }
        case OUTBOX_EVENT_MAPPINGS.ClockInOut:
            {
                data = {
                    employeeId,
                    shift,
                    clockInDateTime: new Date(),
                    clockOutDateTime: null
                };
                break;
            }
        case OUTBOX_EVENT_MAPPINGS.JobActivity:
            {
                data = {
                    jobNumber: 'JOB-1',
                    assemblySequence: 0,
                    operationSequence: 0,
                    resourceId,
                    employeeId,
                    activityType: 'Production'
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.Job:
            {
                data = {
                    jobNumber: 'CPCTEST1',
                    assemblySequence: 0,
                    parentAssemblySequence: 0,
                    assemblyPartNumber: 'COOKIE',
                    assemblyPartDescription: 'Tasty Cookie',
                    assemblyRevisionNumber: 'B',
                    serialTracked: false,
                    lotTracked: false,
                    requiredQuantity: 12,
                    jobStatus: JobStatuses.released,
                    deleted: false,
                    materials: [
                        {
                            sequence: 10,
                            partNumber: 'FLR',
                            partDescription: 'Flour',
                            relatedOperation: 10,
                            quantityPer: 0,
                            fixedQuantity: false,
                            serialTracked: false,
                            lotTracked: false
                        }
                    ],
                    operations: [
                        {
                            sequence: 10,
                            operationCode: 'Bake',
                            operationCodeDescription: 'Bake',
                            productionStandard: 0,
                            productionFormat: 'PH',
                            productionResourceGroupID: 'Ovens',
                            setupResourceGroupID: 'Ovens',
                            runQuantity: 12,
                            completedQuantity: 0,
                            resources: [
                                {
                                    resourceGroupId: 'Ovens',
                                    resourceId: 'ConvOven'
                                }
                            ]
                        }
                    ],
                    company: kineticRepo.kinetic.companyId,
                    siteId: kineticRepo.kinetic.siteId
                };
                break;
            }
        default:
            {
                data = {
                    company: kineticRepo.kinetic.companyId,
                    siteId: kineticRepo.kinetic.siteId
                };
                break;
            }
    }
    return JSON.stringify(data, null, 2);
};
let IntegrationTesterTemplate;
if (macroCondition(isDevelopingApp() && !isTesting())) {
    IntegrationTesterTemplate = class extends Component {
        @service
        eflexAjax;
        @service
        notifier;
        @service
        store;
        @service
        kineticRepo;
        @service
        stationRepo;
        @tracked
        eventName;
        @tracked
        jobCount = 1;
        @tracked
        jobStatus = JobStatuses.released;
        @tracked
        assembliesPerJob = 1;
        @tracked
        requiredQuantity = 10;
        @tracked
        _selectedPart;
        @tracked
        _selectedPartRev;
        @tracked
        selectedOperations;
        @tracked
        data;
        kineticParts = this.store.peekAll('kineticPart');
        kineticPartRevs = this.store.peekAll('kineticPartRev');
        kineticOperations = this.store.peekAll('kineticOperation');
        get selectedPart() {
            return this._selectedPart;
        }
        set selectedPart(val) {
            this._selectedPart = val;
        }
        get selectedPartRev() {
            return this._selectedPartRev;
        }
        set selectedPartRev(val) {
            this._selectedPartRev = val;
        }
        _waitForLength = task(waitFor(async (checked, expected)=>{
            while(checked.length !== expected){
                await timeout(getDelayTime(50));
            }
        }));
        sendIntegrationJson = task({
            drop: true
        }, waitFor(async (type, data, eventName)=>{
            let json;
            try {
                json = JSON.parse(data);
            } catch  {
                this.notifier.sendError('Invalid JSON');
                return;
            }
            await this.eflexAjax.post.perform(type, {
                eventName,
                data: json
            });
        }));
        quickCreate = task({
            drop: true
        }, waitFor(async (kineticParts, kineticPartRevs, kineticOperations, stations)=>{
            const events = [
                INBOX_EVENT_MAPPINGS.Site,
                INBOX_EVENT_MAPPINGS.Shift,
                INBOX_EVENT_MAPPINGS.Employee,
                INBOX_EVENT_MAPPINGS.Station,
                INBOX_EVENT_MAPPINGS.BOM,
                INBOX_EVENT_MAPPINGS.JOB
            ];
            const previousPartCount = kineticParts.length;
            const previousPartRevCount = kineticPartRevs.length;
            const previousOperationsCount = kineticOperations.length;
            for (const eventName of events){
                const data = getEventData(eventName, this.kineticRepo);
                await this.sendIntegrationJson.perform('integrationInboxes', data, eventName);
            }
            await all([
                this._waitForLength.perform(kineticParts, previousPartCount + 1),
                this._waitForLength.perform(kineticPartRevs, previousPartRevCount + 1),
                this._waitForLength.perform(kineticOperations, previousOperationsCount + 2)
            ]);
            const part = kineticParts.find((kineticPart)=>kineticPart.name === 'ECPC101');
            const partRev = part.partRevs.find((rev)=>rev.name === 'B');
            const operation = partRev.operations.find((op)=>op.name === 'AssyUnit');
            await this.createJobs.perform({
                jobCount: 1,
                jobStatus: JobStatuses.released,
                requiredQuantity: 10,
                assembliesPerJob: 1,
                partRev,
                operations: [
                    operation
                ],
                stations
            });
        }));
        createJobs = task({
            drop: true
        }, waitFor(async ({ jobCount, jobStatus, requiredQuantity, assembliesPerJob, partRev, operations, stations })=>{
            const store = this.store;
            const jobs = [];
            const assemblies = [];
            for(let i = 0; i < jobCount; i++){
                const job = store.createRecord('kineticJob', {
                    jobNumber: `JOB-${i}`,
                    jobStatus
                });
                for(let j = 0; j < assembliesPerJob; j++){
                    const assembly = store.createRecord('kineticJobAssembly', {
                        assemblySequence: j,
                        requiredQuantity,
                        job,
                        partRev
                    });
                    operations.forEach((operation, index)=>{
                        store.createRecord('kineticAssemblyOperation', {
                            sequence: index,
                            runQuantity: requiredQuantity,
                            operation,
                            assembly,
                            stations
                        });
                    });
                    assemblies.push(assembly);
                }
                jobs.push(job);
            }
            await all(jobs.map((job)=>job.save()));
            await all(assemblies.map((assembly)=>assembly.save()));
        }));
        loadPart = (part)=>{
            this.selectedPart = part;
            this.selectedPartRev = null;
            this.selectedOperations = null;
        };
        loadPartRev = (partRev)=>{
            this.selectedPartRev = partRev;
            this.selectedOperations = null;
        };
        selectOperations = (operations)=>{
            this.selectedOperations = operations;
        };
        setEventName = (eventName)=>{
            this.eventName = eventName;
            this.data = getEventData(eventName, this.kineticRepo);
        };
        static{
            template_7b2b183356d74392bf972c09297e766b(`
      <Breadcrumbs @page="Integration Tester">
        <BsButton @onClick={{
          fn
          this.quickCreate.perform
          this.kineticParts
          this.kineticPartRevs
          this.kineticOperations
          this.stationRepo.stations
        }}>
          Quick Create
        </BsButton>
      </Breadcrumbs>

      <div class="row">
        <div class="col-sm-6">
          <BsForm @model={{this}} as |form|>
            <form.element
              @controlType="power-select"
              @label="Event Name"
              @options={{EVENT_NAMES}}
              @value={{this.eventName}}
              @onChange={{this.setEventName}}
            />
            <form.element
              @controlType="text"
              @label="Manual Event Name"
              @value={{this.eventName}}
              @onChange={{this.setEventName}}
            />
            <form.element
              @controlType="textarea"
              @label="Data"
              @property="data"
            as |elem|>
              <elem.control rows='18' />
            </form.element>
            <BsButton
              @onClick={{
                fn
                this.sendIntegrationJson.perform
                'integrationOutboxes'
                this.data
                this.eventName
              }}
            >
              Add To Outbox
            </BsButton>
            <BsButton
              @onClick={{
                fn
                this.sendIntegrationJson.perform
                'integrationInboxes'
                this.data
                this.eventName
              }}
            >
              Add To Inbox
            </BsButton>
          </BsForm>
        </div>
        <div class="col-sm-6">
          <BsForm
            @model={{this}}
            @onSubmit={{
              fn
              this.createJobs.perform
              (hash
                jobCount=this.jobCount
                jobStatus=this.jobStatus
                requiredQuantity=this.requiredQuantity
                assembliesPerJob=this.assembliesPerJob
                partRev=this.selectedPartRev
                operations=this.selectedOperations
                stations=this.stationRepo.stations
              )
            }}
          as |form|>
            <form.element
              @controlType="power-select"
              @label={{t "kinetic.part"}}
              class="kinetic-part-select"
              @options={{sortByProp 'name' this.kineticParts}}
              @optionLabelPath="name"
              @value={{this.selectedPart}}
              @onChange={{this.loadPart}}
            as |el|>
              <el.control
                @noMatchesMessage={{t "noMatch"}}
                @searchEnabled={{true}}
                @searchField="name"
                @allowClear={{true}}
                @placeholder={{t "kinetic.selectPart"}}
              />
            </form.element>
            <form.element
              @controlType="power-select"
              @label={{t "kinetic.revision"}}
              class="kinetic-part-rev-select"
              @options={{getFilteredPartRevs this.kineticPartRevs this.selectedPart}}
              @optionLabelPath="name"
              @value={{this.selectedPartRev}}
              @onChange={{this.loadPartRev}}
            as |el|>
              <el.control
                @disabled={{isEmpty this.selectedPart}}
                @noMatchesMessage={{t "noMatch"}}
                @searchEnabled={{true}}
                @searchField="name"
                @allowClear={{true}}
                @placeholder={{t "kinetic.selectRevision"}}
              />
            </form.element>
            <form.element
              @controlType="power-select-multiple"
              @label={{t "kinetic.operation"}}
              class="kinetic-operation-select"
              @options={{getFilteredOperations this.kineticOperations this.selectedPartRev}}
              @value={{this.selectedOperations}}
              @onChange={{this.selectOperations}}
            as |el|>
              <el.control
                @disabled={{isEmpty this.selectedPartRev}}
                @noMatchesMessage={{t "noMatch"}}
                @searchEnabled={{true}}
                @searchField="name"
                @allowClear={{true}}
                @placeholder={{t "kinetic.selectOperation"}}
              as |operation|>
                {{operation.sequence}} - {{operation.name}}
              </el.control>
            </form.element>
            <form.element
              @controlType="number"
              @label="Job Count"
              @property="jobCount"
            />
            <form.element
              @controlType="power-select"
              @label="Job Status"
              @options={{values JobStatuses}}
              @value={{this.jobStatus}}
              @onChange={{fn (mut this.jobStatus)}}
            />
            <form.element
              @controlType="number"
              @label="Assemblies Per Job"
              @property="assembliesPerJob"
            />
            <form.element
              @controlType="number"
              @label="Required Quantity"
              @property="requiredQuantity"
            />
            <form.submitButton @disabled={{isEmpty this.selectedOperations}}>
              Create Jobs
            </form.submitButton>
          </BsForm>
        </div>
      </div>

      {{#if this.generate.isRunning}}
        <SpinnerOverlay @message={{t "loading.wait"}} />
      {{/if}}
    `, {
                component: this,
                eval () {
                    return eval(arguments[0]);
                }
            });
        }
    };
} else {
    IntegrationTesterTemplate = template_7b2b183356d74392bf972c09297e766b(``, {
        eval () {
            return eval(arguments[0]);
        }
    });
}
export default RouteTemplate(IntegrationTesterTemplate);
