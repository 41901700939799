import { template as template_dd5c437b69174592b101c1f585dae90a } from "@ember/template-compiler";
import { intoArray } from '@eflexsystems/ramda-helpers';
import { prop, pluck, filter } from 'ramda';
import { mapIndexed } from 'ramda-adjunct';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { fn } from '@ember/helper';
const compressGaps = (tasks, sequenceType)=>{
    const usedGaps = [
        true
    ];
    tasks.forEach((task)=>{
        const size = sequenceType === 'row' ? task.sizeY : 1;
        for(let i = task[sequenceType]; i < task[sequenceType] + size; i++){
            usedGaps[i] = true;
        }
    });
    const availableGaps = intoArray(mapIndexed((p, index)=>({
            available: p == null,
            position: index
        })), filter(prop('available')), pluck('position'))(usedGaps);
    tasks.forEach((task)=>{
        const availableBefore = filter((a)=>task[sequenceType] > a, availableGaps);
        task[sequenceType] = task[sequenceType] - availableBefore.length;
    });
};
const onClick = (tasks)=>{
    compressGaps(tasks, 'row');
    compressGaps(tasks, 'column');
};
export default template_dd5c437b69174592b101c1f585dae90a(`
<BsButton
  class="clean-sequence"
  @onClick={{fn onClick @tasks}}
  @disabled={{@disabled}}
  ...attributes
>
  <FaIcon @icon="broom" @prefix="fas" class="me-2" />
  {{t "plant.station.cleanSequence"}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
