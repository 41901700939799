import ApplicationSerializer from 'eflex/serializers/application';

export default class VariableDefConfigSerializer extends ApplicationSerializer {
  attrs = {
    taskConfig: {
      serialize: false,
      deserialize: false,
    },
    variableDefForValue: {
      serialize: 'ids',
      deserialize: 'ids',
    },
  };

  serialize(snapshot) {
    const serialized = super.serialize(...arguments);

    if (snapshot.record.taskConfig?.parent?.isAuto) {
      serialized.required = true;
    }

    return serialized;
  }
}
