import { isBlank } from '@ember/utils';

const getTreeItemsNeedingSave = (treeItem, accumulator = []) => {
  if (!treeItem.isSelfOrChildDirty) {
    return accumulator;
  }

  if (treeItem.isDeleted) {
    accumulator.push(treeItem);
    return accumulator;
  }

  if (treeItem.isDirty) {
    accumulator.push(treeItem);
  }

  const children = treeItem.allChildren ?? [];
  children.forEach(child => { getTreeItemsNeedingSave(child, accumulator); });
  return accumulator;
};

const walkTree = (children, lastType = null, accumulator = []) => {
  if (isBlank(children)) {
    return accumulator;
  }

  children.forEach(child => {
    accumulator.push(child);

    if (child.type !== lastType) {
      walkTree(child.allChildren, lastType, accumulator);
    }
  });

  return accumulator;
};

export const toTree = (treeItem, options = {}) => {
  options.isExpanded ??= false;

  const { modelName } = treeItem.constructor;
  let children = [];

  if (modelName !== options.lastModel) {
    children = treeItem.children;

    if (options.filter != null) {
      children = children?.filter(options.filter);
    }

    children = children?.map((child) => toTree(child, options));
  }

  return {
    id: treeItem.id,
    name: treeItem.name,
    treeIconClass: treeItem.treeIconClass,
    modelName,
    children,
    path: treeItem.path,
    checked: false,
    visible: false,
    expanded: options.isExpanded,
  };
};

export const isAncestorOf = (treeItem, otherLocation) => {
  if (!treeItem) {
    return false;
  }

  return otherLocation?.path?.startsWith(treeItem.path) ?? false;
};

export const recursiveSave = async (treeItem) => {
  const toSave = getTreeItemsNeedingSave(treeItem);
  await Promise.all(toSave.map(child => child.save()));
};

export const getDescendants = (treeItem, lastType) => {
  if (treeItem.type === lastType) {
    return [];
  }

  return walkTree(treeItem.allChildren, lastType);
};

export const updateLocationPaths = (treeItem) => {
  let newPath = treeItem.id;

  if (treeItem.parent) {
    newPath = `${treeItem.parent.path}#${newPath}`;
  }

  treeItem.path = newPath;
  getDescendants(treeItem).forEach((child) => updateLocationPaths(child));
};
