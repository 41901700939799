import { later } from '@ember/runloop';

export const schedulePush = (record, cb) => {
  if (record == null) {
    cb();
    return;
  }

  if (
    record.isDestroyed ||
    !record.isLoaded ||
    (!record.isDirty && record.isDeleted) ||
    (record.isSaving && record.isDeleted)
  ) {
    return;
  }

  if (!record.isSaving) {
    cb();
    return;
  }

  // eslint-disable-next-line ember/no-runloop
  later(null, () => {
    schedulePush(record, cb);
  }, 10);
};

export const updateFromWebSocket = (type, store, updatedRecord) => {
  const id = updatedRecord.id ?? updatedRecord._id;
  let record = store.peekRecord(type, id);

  schedulePush(record, () => {
    record = store.push(store.normalize(type, updatedRecord));
  });

  return record;
};

export const deleteFromWebSocket = (type, store, id, preUnload) => {
  const deleted = store.peekRecord(type, id);

  if (deleted == null) {
    return;
  }

  if (typeof preUnload === 'function') {
    preUnload(deleted);
  }

  schedulePush(deleted, () => {
    deleted.unloadRecord();
  });

  return deleted;
};
