import { template as template_04f29c6e8cfb47019c32c1b156da96b9 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { set, eventBus } from 'eflex/helpers';
import { hash } from '@ember/helper';
import { notEq, and, not, or } from 'ember-truth-helpers';
import Portal from 'ember-stargate/components/portal';
import Spinner from 'eflex/components/spinner';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
const JemTaskImageCaptureDetails = template_04f29c6e8cfb47019c32c1b156da96b9(`
<div ...attributes>
  {{#if (notEq @task.triggerType "captureAdvance")}}
    <BsButton
      @type="primary"
      @disabled={{@disabled}}
      @onClick={{@onStartWebcamCapture}}
      class="text-capitalize capture-webcam-image px-3 py-2 mt-2"
    >
      <FaIcon @icon="camera" @prefix="fas" class="me-2" />
      {{#if @capturedWebcamImage}}
        {{t "retakeImage"}}
      {{else}}
        {{t "captureImage"}}
      {{/if}}
    </BsButton>
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class JemTaskImageCapture extends Component {
    @tracked
    capturedWebcamImage;
    @service
    jemRepo;
    @service
    notifier;
    @service
    eventBus;
    onImageCaptured = task(waitFor(async ({ treeTask, image })=>{
        if (!this.args.childStatus?.isStarted || treeTask !== this.args.task) {
            return;
        }
        this.capturedWebcamImage = image;
        if (this.args.task?.triggerType === 'captureAdvance' && image != null) {
            await this.onTaskComplete.perform(TaskStatuses.GOOD);
        }
    }));
    onTaskComplete = task({
        drop: true
    }, waitFor(async (status)=>{
        let webcamImageUrl;
        if (status > TaskStatuses.STOPPED && this.capturedWebcamImage != null) {
            try {
                ({ webcamImageUrl } = await this.jemRepo.uploadWebcamImage.perform(this.args.childStatus.parent.serialNumber, this.capturedWebcamImage, this.args.childStatus.task, status, this.args.station.facsId));
            } catch (e) {
                this.notifier.sendError(e);
                if (status !== TaskStatuses.REJECTED) {
                    return;
                }
            }
        }
        if (webcamImageUrl != null) {
            this.args.childStatus.visionUrl = webcamImageUrl;
        }
        this.eventBus.trigger('clearCapturedImage');
        return this.args.onTaskComplete(status);
    }));
    onStartWebcamCapture = ()=>{
        if (this.capturedWebcamImage != null) {
            this.eventBus.trigger('clearCapturedImage');
        } else {
            this.eventBus.trigger('captureWebcamImage');
        }
    };
    static{
        template_04f29c6e8cfb47019c32c1b156da96b9(`
    {{eventBus
      imageCaptured=this.onImageCaptured.perform
      clearCapturedImage=(set this 'capturedWebcamImage' null)
    }}

    {{yield
      (hash
        taskDetails=(component
          JemTaskImageCaptureDetails
          disabled=@disabled
          task=@task
          capturedWebcamImage=this.capturedWebcamImage
          onStartWebcamCapture=this.onStartWebcamCapture
        )
        okDisabled=(or
          this.onTaskComplete.isRunning
          (and (not this.capturedWebcamImage) @childStatus.isStarted)
        )
        onTaskComplete=this.onTaskComplete.perform
      )
    }}
    {{#if this.onTaskComplete.isRunning}}
      <Portal @target="jem-task-spinner-portal">
        <Spinner class="position-absolute" />
      </Portal>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
