import GridRoute from 'eflex/util/grid-route';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugLiveBuildStatusLogsRoute extends GridRoute {
  @service store;

  getData = task(waitFor(async (params = {}) => {
    const results = await this.store.query('liveBuildStatusLog', params);
    const data = new TrackedArray(results);
    data.meta = { count: results.meta?.count };

    return data;
  }));
}
