import BsContextualHelp from 'eflex/components/bs-contextual-help';
import { default as OrigBsTooltip } from 'ember-bootstrap/components/bs-tooltip';
import { getComponentTemplate, setComponentTemplate } from '@ember/component';

class BsTooltip extends BsContextualHelp {
  get arrowElement() {
    return this.overlayElement.querySelector('.tooltip-arrow');
  }
}

setComponentTemplate(getComponentTemplate(OrigBsTooltip), BsTooltip);

export default BsTooltip;
