import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { validIpAddress } from 'eflex/constants/regex';
import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';

@buildValidations({
  name: [validator('presence', true)],

  ipAddress: [
    validator('format', { regex: validIpAddress }),

    validator('inline', {
      validate(ipAddress, options, turckIo, attribute) {
        const checked = getOwner(turckIo).lookup('service:hardwareRepo').turckIos.filter(item => !item.isDeleted);
        return checkUniqueField(turckIo, checked, attribute).result;
      },
    }),
  ],
})
class TurckIo extends Hardware {
  @attr('string') ipAddress;

  @service intl;

  get isConfigured() {
    return isPresent(this.ipAddress);
  }

  get hardwareGroup() {
    return this.intl.t('turckIo');
  }
}

export default TurckIo;
