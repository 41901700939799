import { template as template_5288aea302e9463f8f78de5bcca53d95 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task, waitForProperty } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
export default class InputsLockObject extends Component {
    @service
    imageEditor;
    onDidInsert = task(waitFor(async ()=>{
        await waitForProperty(this.imageEditor, 'canvas');
        this.#checkAndSetLocked();
        this.imageEditor.on('selection:created', this.#checkAndSetLocked).on('selection:updated', this.#checkAndSetLocked);
    }));
    toggleLock = (locked)=>{
        const objects = this.imageEditor.canvas.getActiveObjects();
        if (objects == null) {
            return;
        }
        for (const obj of objects){
            obj.lockMovementX = locked;
            obj.lockMovementY = locked;
            obj.lockRotation = locked;
            obj.lockScalingX = locked;
            obj.lockScalingY = locked;
            obj.lockSkewingX = locked;
            obj.lockSkewingY = locked;
        }
        this.imageEditor.updateHistory();
        this.args.onChange(locked);
    };
    #checkAndSetLocked = ()=>{
        const obj = this.imageEditor.canvas.getActiveObject();
        if (obj == null) {
            return;
        }
        const locked = obj.lockMovementX && obj.lockMovementY && obj.lockRotation && obj.lockScalingX && obj.lockScalingY && obj.lockSkewingX && obj.lockSkewingY;
        this.args.onChange(locked);
    };
    willDestroy() {
        super.willDestroy(...arguments);
        this.imageEditor.off('selection:created', this.#checkAndSetLocked).off('selection:updated', this.#checkAndSetLocked);
    }
    static{
        template_5288aea302e9463f8f78de5bcca53d95(`
    <div
      {{didInsert this.onDidInsert.perform}}
      ...attributes
    >
      <div class="property-button-group">
        <button
          class="btn app-unlock {{unless @locked "btn-primary selected"}}"
          type="button"
          {{on "click" (fn this.toggleLock false)}}
        >
          <div class="icon icon-unlock"></div>
        </button>
        <button
          class="btn app-lock {{if @locked "btn-primary selected"}}"
          type="button"
          {{on "click" (fn this.toggleLock true)}}
        >
          <div class="icon icon-lock"></div>
        </button>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
