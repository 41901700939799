import { template as template_fe5882f0ac324ed7b892a060fcab8ae2 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';
import getUrlType from 'eflex/util/get-url-type';
import { eq } from 'ember-truth-helpers';
import PowerSelectWithCreate from 'eflex/components/power-select-with-create';
import { t } from 'ember-intl';
import { optional } from '@eflexsystems/ember-composable-helpers';
import { inputNum } from 'eflex/helpers';
import { on } from '@ember/modifier';
export default class HostedFilePicker extends Component {
    @service
    store;
    @service
    hostedFileRepo;
    fileType = trackedFunction(this, async ()=>{
        const computedImageUrl = this.computedImageUrl;
        if (!computedImageUrl) {
            return null;
        }
        if (computedImageUrl.mime != null) {
            return computedImageUrl.mime;
        }
        if (computedImageUrl.name == null) {
            return null;
        }
        return await getUrlType(computedImageUrl.name);
    });
    get allowPageSelection() {
        return this.args.allowPageSelection ?? true;
    }
    get computedImageUrl() {
        if (this.args.externalImageUrl == null) {
            return null;
        }
        const recordId = this.args.externalImageUrl.split('/').at(-1).match(/^([\da-z]*)/)?.[1];
        let record;
        if (!isBlank(recordId)) {
            record = this.store.peekRecord('hostedFile', recordId);
        }
        if (record != null) {
            return {
                id: record.id,
                name: record.name,
                mime: record.mime,
                pdfPage: this.args.pdfPage
            };
        } else {
            return {
                id: null,
                name: this.args.externalImageUrl,
                pdfPage: this.args.pdfPage
            };
        }
    }
    get fileOptions() {
        let options = [];
        options = this.hostedFileRepo.hostedFiles.map(({ id, name, mime })=>({
                id,
                name,
                mime
            }));
        if (this.computedImageUrl != null && this.computedImageUrl.id == null) {
            options.push(this.computedImageUrl);
        }
        if (this.args.mimeWhitelist?.length > 0) {
            options = options.filter((option)=>{
                return this.args.mimeWhitelist.includes(option.mime);
            });
        }
        return options;
    }
    setExternalImageUrl = (url)=>{
        this.args.onChange?.(url, null, url);
    };
    updateLink = (link)=>{
        if (link != null) {
            const record = this.store.peekRecord('hostedFile', link.id);
            this.args.onChange?.(record.url, record.id, record.name);
        } else {
            this.args.onChange?.(null);
        }
    };
    static{
        template_fe5882f0ac324ed7b892a060fcab8ae2(`
    <div
      class="file-picker component-hosted-file-picker col"
      ...attributes
    >
      <div class="row">
        <div class="col">
          <PowerSelectWithCreate
            class="w-100"
            @triggerClass="form-control"
            @placeholder={{@placeholder}}
            @searchEnabled={{true}}
            @searchField="name"
            @disabled={{@disabled}}
            @options={{this.fileOptions}}
            @selected={{this.computedImageUrl}}
            @allowClear={{true}}
            @renderInPlace={{true}}
            @onChange={{this.updateLink}}
            @onCreate={{this.setExternalImageUrl}}
          as |hostedFile|>
            {{hostedFile.name}}
          </PowerSelectWithCreate>
        </div>
        {{#if this.allowPageSelection}}
          {{#if (eq "application/pdf" this.fileType.value)}}
            <div class="d-flex align-items-center col-2">
              <label class="me-1">
                {{t "page"}}:
              </label>
              <input
                type="Number"
                value={{this.computedImageUrl.pdfPage}}
                class="form-control pdf-page flex-fill"
                {{on "input" (inputNum (optional @onPdfPageInput))}}
              />
            </div>
          {{/if}}
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
