import { service } from '@ember/service';
import LocationRepoBase from 'eflex/services/location-repo-base';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';
import { tap, filter, without, prop } from 'ramda';
import { intoArray, maxOf } from '@eflexsystems/ramda-helpers';
import { sortByPaths } from 'ramda-adjunct';
import { updateLocationPaths } from 'eflex/util/tree-helpers';
import { changeType as changeTaskConfigType, copyTaskConfig } from 'eflex/services/task-config-repo';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { getOwner } from '@ember/application';

const shiftSequenceDown = (station, taskRecord) => {
  intoArray(
    filter((task) => task.row >= taskRecord.row),
    without([taskRecord]),
    tap(task => { task.row += 1; }),
  )(station.children);
};

const applyDefaults = (taskRecord) => {
  const store = taskRecord.store;
  const intl = getOwner(taskRecord).lookup('service:intl');

  store.createRecord('decisionDef', {
    task: taskRecord,
    name: intl.t('pass'),
  });

  store.createRecord('decisionDef', {
    task: taskRecord,
    name: intl.t('fail'),
  });

  switch (taskRecord.taskType) {
    case taskTypes.imageCapture:
    case taskTypes.vision: {
      taskRecord.isVision = true;
      taskRecord.cameraConfiguration.evisionEnabled = true;
      break;
    }
    case taskTypes.barcode: {
      taskRecord.strings.push(store.createRecord('barcodeString', {}));
      break;
    }
    case taskTypes.multispindle: {
      taskRecord.spindles.push(store.createRecord('spindle', { task: taskRecord }));
      break;
    }
  }
};

export const handleContextChange = (taskRecord, context) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (taskRecord.taskType) {
    case taskTypes.barcode: {
      if (taskRecord.usesComponents && context.isAlwaysRun) {
        taskRecord.passThrough = false;
      }
      break;
    }
  }
};

export const getConfig = (taskRecord, context) => {
  if (context == null) {
    return null;
  }

  assert('context may not be a component. only model or component option.',
    context.constructor.modelName !== 'component');

  if (taskRecord.usesModels) {
    return taskRecord.taskConfigs.find(taskConfig => taskConfig.configModel === context);
  } else {
    return taskRecord.taskConfigs.find(taskConfig => taskConfig.configOption === context);
  }
};

export const getWorkInstructionTriggerConfigs = (taskRecord, context) => {
  return getConfig(taskRecord, context)?.triggerConfigs?.filter(item =>
    item.constructor.modelName === 'work-instruction-hardware-trigger-config',
  );
};

export const addToEndOfSequence = (station, taskRecord) => {
  const siblings = without([taskRecord], station.children);
  let row = 1;

  if (isPresent(siblings)) {
    row = maxOf(prop('row'), siblings) + 1;
  }

  Object.assign(taskRecord, {
    row,
    column: 1,
  });
};

export const copyTask = (treeTask, parent, keepName = false) => {
  const taskCopy = treeTask.copy(true);
  taskCopy.parent = parent;
  if (!keepName) {
    taskCopy.name = `Copy of ${treeTask.name}`;
  }

  updateLocationPaths(taskCopy);
  addToEndOfSequence(parent, taskCopy);

  treeTask.edhrMappings.forEach(edhrMapping => {
    const edhrMappingCopy = edhrMapping.copy(true);
    edhrMappingCopy.task = taskCopy;
  });

  treeTask.triggers.forEach(trigger => {
    const triggerCopy = trigger.copy(true);
    triggerCopy.task = taskCopy;

    trigger.edhrMappings?.forEach(edhrMapping => {
      triggerCopy.edhrMappings.push(edhrMapping.copy(true));
    });

    trigger.variableDefs?.forEach(variableDefs => {
      triggerCopy.variableDefs.push(variableDefs.copy(true));
    });
  });

  treeTask.taskConfigs.forEach(taskConfig => {
    copyTaskConfig(taskConfig, taskCopy);
  });

  return taskCopy;
};

export const removeEdhrMappingsBelowMaxBoltCount = (treeTask) => {
  treeTask.triggers
    .flatMap(trigger => trigger.edhrMappings)
    .filter(mapping => mapping?.boltIndex > treeTask.maxBoltCount)
    .forEach(trigger => { trigger.deleteRecord(); });
};

export const removeTaskBasedEdhrMappings = (treeTask) => {
  treeTask.edhrMappings
    .filter(item => item.dataTask)
    .forEach(edhrMapping => { edhrMapping.deleteRecord(); });
};

export const changeType = (taskRecord, type) => {
  if (taskRecord.taskType === type) {
    return;
  }

  Object.assign(taskRecord, {
    taskType: type,
    hardware: null,
    hardwareInput: null,
    showOkButton: true,
    decisionRequired: false,
  });

  taskRecord.hardwareOutputs.length = 0;
  taskRecord.hardwareInputDefs.forEach(hardwareInputDef => { hardwareInputDef.deleteRecord(); });
  taskRecord.decisionDefs.forEach(decisionDef => { decisionDef.deleteRecord(); });
  taskRecord.variableDefs.forEach(variableDef => { variableDef.deleteRecord(); });
  taskRecord.spindles.forEach(spindle => { spindle.deleteRecord(); });
  taskRecord.strings.forEach(string => { string.deleteRecord(); });

  applyDefaults(taskRecord);

  taskRecord.taskConfigs.forEach(taskConfig => {
    changeTaskConfigType(taskConfig);
  });
};

export default class TaskRepoService extends LocationRepoBase {
  @service taskConfigRepo;
  @service buildDataRepo;
  @service systemConfig;
  @service store;

  tasks = this.store.peekAll('task');

  create(properties = {}) {
    assert('task must have a task type', properties.taskType);
    const station = properties.parent;
    const defaultCamera = this.systemConfig.evision?.fileRetention?.defaultCameraConfiguration;

    if (defaultCamera) {
      properties.cameraConfiguration = defaultCamera.copy();
    } else {
      properties.cameraConfiguration = this.store.createRecord('cameraConfiguration', {
        daysToStorePassedCompressed: 365,
        daysToStoreFailedCompressed: 365,
        daysToStorePassedRaw: 365,
        daysToStoreFailedRaw: 365,
        daysToStoreThumbnails: 365,
        evisionEnabled: true,
      });
    }

    const taskRecord = super.create('task', properties);

    if (isPresent(properties.row)) {
      shiftSequenceDown(station, taskRecord);
    } else {
      addToEndOfSequence(station, taskRecord);
    }

    const { area } = taskRecord;

    applyDefaults(taskRecord);

    if (taskRecord.usesModels) {
      area.models.forEach(model => { this.createConfig(taskRecord, model); });
    } else if (area?.usesComponents && area?.bomSource != null) {
      const alwaysRun = area.alwaysRunComponent;
      assert('need an always run component', alwaysRun);
      taskRecord.component = alwaysRun;
      this.createConfig(taskRecord, alwaysRun.options?.[0]);
    } else if (taskRecord.parent.type === 'kineticOperation') {
      this.createConfig(taskRecord);
    }

    return taskRecord;
  }

  delete(deleted) {
    deleted.taskConfigs.forEach(taskConfig => { taskConfig.deleteRecord(); });
    deleted.deleteRecord();
  }

  getConfigForModelOrBuildDatum = (taskRecord, model, buildDatum) => {
    if (taskRecord == null) {
      return;
    }

    if (taskRecord.usesOperations) {
      return taskRecord.children[0];
    }

    if (model != null) {
      return getConfig(taskRecord, model);
    }

    if (taskRecord.usesComponents && buildDatum?.components != null) {
      return this.buildDataRepo.getTaskConfigForBuildDatum(taskRecord, buildDatum);
    }

    return null;
  };

  createConfig(taskRecord, context) {
    const options = { parent: taskRecord };
    if (taskRecord.usesModels) {
      options.configModel = context;
    } else if (taskRecord.usesComponents) {
      options.configOption = context;
    }

    return this.taskConfigRepo.create(options);
  }

  createComponentOptionConfigs(taskRecord, component) {
    component.options.forEach(option => {
      this.createConfig(taskRecord, option);
    });
  }

  getAllPreviousTasks(treeTask, includeSelf = false) {
    const allTasks = sortByPaths(
      [
        ['area', 'order'],
        ['group', 'order'],
        ['station', 'order'],
        ['row'],
        ['column'],
      ],
      this.tasks,
    );

    let index = allTasks.indexOf(treeTask);

    if (index === -1) {
      return [];
    }

    if (includeSelf) {
      index = index + 1;
    }

    return allTasks.slice(0, index);
  }
}
