import Model, { attr, belongsTo } from '@ember-data/model';
import { copyable } from 'eflex/decorators';

@copyable
class StationHardware extends Model {
  @belongsTo('hardware', { async: false, polymorphic: true, inverse: 'stationHardwares' }) hardware;
  @belongsTo('station', { async: false, inverse: 'stationHardwares' }) station;

  @attr('number', { defaultValue: 1 }) index;

  get group() {
    return this.hardware.hardwareGroup;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['station']),
      copyByReference: new Set(['hardware']),
    };
  }
}

export default StationHardware;
