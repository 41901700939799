import Controller from '@ember/controller';
import { service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { waitFor } from '@ember/test-waiters';

export default class SettingsBackupController extends Controller {
  @service notifier;
  @service eflexAjax;
  @service store;
  @service currentUser;

  @tracked showBackupConfigPanel = false;
  @tracked history = [];
  @tracked full;
  @tracked config;

  get isDirty() {
    return this.full.isDirty || this.config.isDirty;
  }

  get isInvalid() {
    return this.full.isInvalid || this.config.isInvalid;
  }

  get disabled() {
    return this.isDirty || this.runBackup.isRunning;
  }

  runBackup = task(waitFor(async (type, config) => {
    await this.eflexAjax.post.perform('backups/files', {
      backupFile: {
        type,
        retain: config.retain,
        remoteUrl: config.remoteUrl,
        remoteUsername: config.remoteUsername,
        remotePassword: config.remotePassword,
      },
    });

    this.history = [...((await this.store.query('backupHistory', {
      skip: 0,
      take: 30,
    })))];
  }));

  save = task(waitFor(async () => {
    if (this.isInvalid) {
      this.notifier.sendError('errors.saving');
      return;
    }

    await all([this.full.save(), this.config.save()]);
  }));

  sendTest = task(waitFor(async () => {
    try {
      await this.eflexAjax.post.perform('networkPaths', {
        address: this.full.remoteUrl,
        remoteUsername: this.full.remoteUsername,
        remotePassword: this.full.remotePassword,
      });

      this.notifier.sendSuccess('logs.connectedToSharedFolder');
    } catch {
      this.notifier.sendError('logs.couldNotConnectToSharedFolder');
    }
  }));

  @action
  closePanel() {
    this.showBackupConfigPanel = false;
  }

  @action
  rollback() {
    this.full.rollbackAttributes();
    this.config.rollbackAttributes();
  }

  @action
  setBackupTime(options, [date]) {
    options.time = date;
  }

  @action
  onRemoteUrlInput(remoteUrl) {
    this.full.remoteUrl = remoteUrl;
    this.config.remoteUrl = remoteUrl;
  }

  @action
  onRemoteUsernameInput(remoteUsername) {
    this.full.remoteUsername = remoteUsername;
    this.config.remoteUsername = remoteUsername;
  }

  @action
  onRemotePasswordInput(remotePassword) {
    this.full.remotePassword = remotePassword;
    this.config.remotePassword = remotePassword;
  }

  formatDuration = (start, end) => {
    if (end == null) {
      end = moment();
    }

    if (start == null) {
      return 'Invalid Date';
    }

    const startDate = moment(new Date(start));
    const endDate = moment(new Date(end));

    if (!startDate.isValid() || !endDate.isValid()) {
      return 'Invalid Date';
    }

    const dur = endDate.diff(startDate);
    return moment.duration(dur, 'milliseconds').format('DD:HH:mm:ss');
  };
}
