import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugPacketsController extends Controller {
  queryParams = ['searchTerm', 'beginDate', 'endDate', 'datablockId', 'stationIds', 'page'];

  @tracked showJsonModal = false;
  @tracked model = new TrackedArray();
  @tracked displayedPacket;
  @tracked searchTerm;
  @tracked beginDate;
  @tracked endDate;
  @tracked datablockId;
  @tracked stationIds = [];
  @tracked page = 1;

  @action
  onShowJsonModal(obj) {
    if (obj == null) {
      return;
    }

    Object.assign(this, {
      showJsonModal: true,
      displayedPacket: obj,
    });
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
