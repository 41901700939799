import { template as template_fadc3cdee2534141819276b2e52b36b0 } from "@ember/template-compiler";
import { t } from 'ember-intl';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { service } from '@ember/service';
import { all, task, timeout, waitForProperty, waitForQueue } from 'ember-concurrency';
import { EflexObjTypes } from 'eflex/constants/work-instructions/tool-props';
import getDelayTime from 'eflex/util/get-delay-time';
import { fabric } from 'fabric';
import { getCanvasObjects } from 'eflex/util/fabric-helpers';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import BsButton from 'eflex/components/bs-button';
import { isEmpty } from 'ember-truth-helpers';
import { round } from 'ramda-adjunct';
export default class WorkInstructionEditorToolPropertiesCrop extends Component {
    @service
    imageEditor;
    @tracked
    backingImage;
    @tracked
    backingScreen;
    @tracked
    currentCropbox;
    @tracked
    cropWidth = 0;
    @tracked
    cropHeight = 0;
    @tracked
    cropApplied = true;
    cropEventsRemoved = false;
    onDidInsert = task(waitFor(async ()=>{
        await all([
            waitForProperty(this.imageEditor, 'canvas'),
            waitForQueue('afterRender')
        ]);
        await this._createCrop.perform();
        this.imageEditor.on('selection:created', this._createCrop.perform).on('selection:updated', this._updateCrop.perform).on('selection:cleared', this._clearCropListener.perform);
    }));
    _clearCropListener = task(waitFor(async ()=>{
        // not an async method but concurrency is required to prevent multiple firings in listener
        await this._clearCrop();
    }));
    applyCrop = task(waitFor(async ()=>{
        this.cropApplied = true;
        this.currentCropbox.eflex.cropApplied = true;
        const image = this._getImageByCropbox(this.currentCropbox);
        this.removeCropEvents();
        this.imageEditor.canvas.discardActiveObject();
        this._clearCrop(false);
        const newImage = await this._applyClipPath.perform(image);
        this.imageEditor.enableHistory();
        this.imageEditor.canvas.fire('object:modified', {
            target: newImage
        });
        this.imageEditor.trigger('wie:crop:applied', newImage);
    }));
    _getIntersect = task(waitFor(async (line1, line2)=>{
        if (typeof line1 === 'object' && typeof line2 === 'object') {
            const intersect = {};
            intersect[line1.key] = line1.value;
            intersect[line2.key] = line2.value;
            return intersect;
        } else {
            const { default: nerdamer } = await import('nerdamer/all');
            const eq = nerdamer(`${line1} = ${line2}`);
            const xIntersect = eq.solveFor('x')[0].valueOf();
            const yIntersect = nerdamer(line1).evaluate({
                x: xIntersect
            }).valueOf();
            return {
                x: xIntersect,
                y: yIntersect
            };
        }
    }));
    _onScaling = task({
        restartable: true
    }, waitFor(async (image, controls, clipPath, delay = 0)=>{
        const { tl: itl, tr: itr, bl: ibl, br: ibr } = image.aCoords;
        const imageWidth = image.getScaledWidth();
        const imageHeight = image.getScaledHeight();
        // image top slope and controls top slope should always be equal.
        // same for image left slope and controls left slope.
        const topSlope = this._getSlope(itr, itl);
        const leftSlope = this._getSlope(ibl, itl);
        const iTopEquation = this._getPointSlopeLine(topSlope, itl);
        const iLeftEquation = this._getPointSlopeLine(leftSlope, itl);
        const iBottomEquation = this._getPointSlopeLine(topSlope, ibr);
        const iRightEquation = this._getPointSlopeLine(leftSlope, ibr);
        if (delay) {
            await timeout(getDelayTime(delay));
        }
        controls.setCoords();
        let { tl: ctl, bl: cbl } = controls.aCoords;
        // since the image is always rectangular we can interchange top/bottom or left/right slopes.
        let cTopEquation = this._getPointSlopeLine(topSlope, ctl);
        let cLeftEquation = this._getPointSlopeLine(leftSlope, cbl);
        const topAboveOrBelow = this._aboveOrBelowLine(itl, itr, ctl);
        const validTop = topAboveOrBelow <= 0;
        if (!validTop) {
            const topIntersect = await this._getIntersect.perform(iTopEquation, cLeftEquation);
            controls.set({
                top: topIntersect.y,
                left: topIntersect.x
            });
            controls.setCoords();
            ctl = controls.aCoords.tl;
            cTopEquation = this._getPointSlopeLine(topSlope, ctl);
        }
        const leftAboveOrBelow = this._aboveOrBelowLine(itl, ibl, ctl);
        const validLeft = leftAboveOrBelow >= 0;
        if (!validLeft) {
            const leftIntersect = await this._getIntersect.perform(iLeftEquation, cTopEquation);
            controls.set({
                top: leftIntersect.y,
                left: leftIntersect.x
            });
            controls.setCoords();
            cbl = controls.aCoords.bl;
            cLeftEquation = this._getPointSlopeLine(leftSlope, cbl);
        }
        const rightIntersect = await this._getIntersect.perform(iRightEquation, cTopEquation);
        const maxH = this._getLineLength(rightIntersect, ibr);
        const maxScaleY = maxH / imageHeight;
        const bottomIntersect = await this._getIntersect.perform(iBottomEquation, cLeftEquation);
        const maxW = this._getLineLength(bottomIntersect, ibr);
        const maxScaleX = maxW / imageWidth;
        if (controls.scaleX > maxScaleX) {
            controls.scaleX = maxScaleX;
        }
        if (controls.scaleY > maxScaleY) {
            controls.scaleY = maxScaleY;
        }
        clipPath.top = controls.top;
        clipPath.left = controls.left;
        clipPath.scaleX = controls.scaleX;
        clipPath.scaleY = controls.scaleY;
        image.dirty = true;
        Object.assign(this, {
            cropWidth: controls.getScaledWidth(),
            cropHeight: controls.getScaledHeight()
        });
    }));
    _createCrop = task(waitFor(async ()=>{
        const image = this._activeImage();
        if (image == null) {
            return;
        }
        this.imageEditor.disableHistory();
        image.set({
            lockMovementX: true,
            lockMovementY: true
        });
        const clipPath = new fabric.Rect({
            width: image.getScaledWidth(),
            height: image.getScaledHeight(),
            top: image.top,
            left: image.left,
            angle: image.angle,
            absolutePositioned: true
        });
        const controls = new fabric.Rect({
            width: image.getScaledWidth(),
            height: image.getScaledHeight(),
            top: image.top,
            left: image.left,
            angle: image.angle,
            fill: 'rgba(0, 0, 0, 0.1)',
            cornerColor: 'rgba(0, 0, 0, 0.9)',
            lockMovementX: true,
            lockMovementY: true,
            lockScalingFlip: true,
            eflex: {
                type: EflexObjTypes.CROPBOX,
                itemKey: image.eflex.itemKey,
                childObject: true,
                cropApplied: false
            }
        });
        controls.setControlsVisibility({
            mtr: false
        });
        this._initControlListeners(image, controls, clipPath);
        await this._addBackingImage.perform(image);
        this.imageEditor.canvas.add(controls);
        image.clipPath = clipPath;
        image.dirty = true;
        this.imageEditor.canvas.setActiveObject(controls);
        this.imageEditor.canvas.renderAll();
        Object.assign(this, {
            currentCropbox: controls,
            cropWidth: controls.getScaledWidth(),
            cropHeight: controls.getScaledHeight()
        });
    }));
    _updateCrop = task(waitFor(async ()=>{
        if (this.currentCropbox == null) {
            return;
        }
        this._clearCrop();
        await this._createCrop.perform();
    }));
    _applyClipPath = task(waitFor(async (image)=>{
        const clone = await new Promise((resolve)=>{
            image.clone(resolve);
        });
        clone.setCoords();
        clone.clipPath.setCoords();
        const { tl: itl, tr: itr, bl: ibl } = clone.aCoords;
        const topSlope = this._getSlope(itr, itl);
        const leftSlope = this._getSlope(ibl, itl);
        const iTopEquation = this._getPointSlopeLine(topSlope, itl);
        const iLeftEquation = this._getPointSlopeLine(leftSlope, itl);
        const { tl: ctl, bl: cbl } = clone.clipPath.aCoords;
        const cTopEquation = this._getPointSlopeLine(topSlope, ctl);
        const cLeftEquation = this._getPointSlopeLine(leftSlope, cbl);
        const topIntersect = await this._getIntersect.perform(iTopEquation, cLeftEquation);
        const top = this._getLineLength(topIntersect, ctl);
        const leftIntersect = await this._getIntersect.perform(iLeftEquation, cTopEquation);
        const left = this._getLineLength(leftIntersect, ctl);
        const width = clone.clipPath.getScaledWidth();
        const height = clone.clipPath.getScaledHeight();
        clone.clipPath = null;
        clone.angle = 0;
        const dataUrl = clone.toDataURL({
            multiplier: 1,
            top,
            left,
            width,
            height
        });
        const newImage = await new Promise((resolve)=>{
            fabric.Image.fromURL(dataUrl, resolve);
        });
        newImage.set({
            top: image.clipPath.top,
            left: image.clipPath.left,
            angle: image.angle,
            eflex: image.eflex
        });
        newImage.eflex.id = null;
        this.imageEditor.canvas.remove(image);
        this.imageEditor.canvas.add(newImage);
        return newImage;
    }));
    _addBackingImage = task(waitFor(async (image)=>{
        const backingImage = await new Promise((resolve)=>{
            image.clone(resolve);
        });
        let layer = this.imageEditor.getObjectIndex(image);
        if (layer < 0) {
            layer = 0;
        }
        const backingScreen = new fabric.Rect({
            width: backingImage.getScaledWidth(),
            height: backingImage.getScaledHeight(),
            top: backingImage.top,
            left: backingImage.left,
            angle: backingImage.angle,
            fill: 'rgba(255, 255, 255, 0.45)',
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            eflex: {
                childObject: true
            }
        });
        this.imageEditor.canvas.insertAt(backingScreen, layer);
        backingImage.set({
            opacity: 0.75,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            clipPath: null,
            eflex: {
                childObject: true
            }
        });
        this.imageEditor.canvas.insertAt(backingImage, layer);
        Object.assign(this, {
            backingScreen,
            backingImage
        });
    }));
    willDestroy() {
        super.willDestroy(...arguments);
        if (!this.cropApplied) {
            this.removeCropEvents();
        }
    }
    _clearCrop = (history = true)=>{
        if (!this.currentCropbox.eflex.cropApplied) {
            const image = this._getImageByCropbox(this.currentCropbox);
            this._revertCrop(image);
        }
        this.imageEditor.canvas.remove(this.currentCropbox);
        this.imageEditor.canvas.remove(this.backingImage);
        this.imageEditor.canvas.remove(this.backingScreen);
        Object.assign(this, {
            cropApplied: true,
            currentCropbox: null,
            backingImage: null,
            backingScreen: null,
            cropWidth: 0,
            cropHeight: 0
        });
        if (history) {
            this.imageEditor.enableHistory();
        }
    };
    _initControlListeners(image, controls, clipPath) {
        // constrain controls to inside of existing image
        controls.on('scaling', ()=>{
            this._onScaling.perform(image, controls, clipPath, 100);
        });
        controls.on('modified', ()=>{
            this.cropApplied = false;
            controls.eflex.cropApplied = false;
            if (this.currentCropbox) {
                this._onScaling.perform(image, controls, clipPath);
            }
        });
    }
    _revertCrop(image) {
        image.set({
            clipPath: null,
            dirty: true,
            lockMovementX: false,
            lockMovementY: false
        });
        this.imageEditor.canvas.renderAll();
    }
    _isImage(obj) {
        return obj != null && obj.type === 'image';
    }
    _activeImage() {
        const obj = this.imageEditor.canvas?.getActiveObject();
        if (this._isImage(obj)) {
            return this.imageEditor.applyItemKey(obj);
        }
    }
    _getImageByCropbox(cropbox) {
        const images = getCanvasObjects(this.imageEditor.canvas, 'image').filter((item)=>item.eflex?.itemKey === cropbox.eflex.itemKey);
        if (images.length > 1) {
            throw new Error('More images than expected for cropbox.');
        }
        return images[0];
    }
    _getSlope(p1, p2) {
        return (p2.y - p1.y) / (p2.x - p1.x);
    }
    _getPointSlopeLine(slope, point) {
        if (slope.valueOf() === 0) {
            // horizontal line
            return {
                key: 'y',
                value: point.y
            };
        } else if (!Number.isFinite(slope.valueOf())) {
            // vertical line
            return {
                key: 'x',
                value: point.x
            };
        } else {
            // point slope line eq: y = m(x - Px) + Py
            return `${slope.toFixed(2)} * (x - ${point.x.toFixed(2)}) + ${point.y.toFixed(2)}`;
        }
    }
    _getLineLength(p1, p2) {
        return Math.hypot(((p2.x - p1.x)), ((p2.y - p1.y)));
    }
    _aboveOrBelowLine(p1, p2, checkPoint) {
        // above or below eq: d=(x−x1)(y2−y1)−(y−y1)(x2−x1)
        return (checkPoint.x - p1.x) * (p2.y - p1.y) - (checkPoint.y - p1.y) * (p2.x - p1.x);
    }
    removeCropEvents() {
        if (this.cropEventsRemoved) {
            return;
        }
        this.cropEventsRemoved = true;
        this.imageEditor.off('selection:created', this._createCrop.perform).off('selection:updated', this._updateCrop.perform).off('selection:cleared', this._clearCropListener.perform);
    }
    cancelCrop = ()=>{
        const image = this._getImageByCropbox(this.currentCropbox);
        this.removeCropEvents();
        this.imageEditor.canvas.discardActiveObject();
        this._clearCrop();
        this.imageEditor.trigger('wie:crop:applied', image);
    };
    static{
        template_fadc3cdee2534141819276b2e52b36b0(`
    <div
      class="component-work-instruction-editor-tool-properties-crop wie-crop-panel"
      {{didInsert this.onDidInsert.perform}}
      ...attributes
    >
      <div class="eflex-scroll h-100">
        <h6 class="title">
          {{t "imageEditor.crop"}}
        </h6>

        <div class="property-flex-row size-group">
          <div class="size-label-container">
            <label class="form-label width-label">
              {{t "imageEditor.width"}}
            </label>
            <span class="times">
              &times;
            </span>
            <label class="form-label height-label">
              {{t "imageEditor.height"}}
            </label>
          </div>
          <div class="size-input-container">
            <input
              type="number"
              value={{round this.cropWidth}}
              min={{0}}
              step={{1}}
              disabled
              class="form-control width-input"
            />
            <div class="keep-aspect-ratio">
              <div class="icon icon-link disabled"></div>
            </div>
            <input
              type="number"
              value={{round this.cropHeight}}
              min={{0}}
              step={{1}}
              disabled
              class="form-control height-input"
            />
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="crop-buttons">
          <div class="property-flex-row mb-2">
            <BsButton
              class="text-uppercase crop-apply"
              @type="primary"
              @disabled={{this.cropApplied}}
              @onClick={{this.applyCrop.perform}}
            >
              {{t "apply"}}
            </BsButton>
          </div>
          <div class="property-flex-row">
            <BsButton
              class="text-uppercase crop-cancel"
              @type="secondary"
              @disabled={{isEmpty this.currentCropbox}}
              @onClick={{this.cancelCrop}}
            >
              {{t "cancel"}}
            </BsButton>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
    ;
}
