import Model, { attr, hasMany } from '@ember-data/model';
import { isBlank, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { camelize } from '@ember/string';
import { arrayAttr, caption } from 'eflex/decorators';

class Hardware extends Model {
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('boolean', { defaultValue: false }) isConnected;
  @attr('string') connectionError;
  @arrayAttr captions;
  @caption name;

  @hasMany('task', { async: false, inverse: 'hardware', as: 'hardware' }) tasks;
  @hasMany('hardwareIo', { async: false, inverse: 'hardware', as: 'hardware' }) hardwareIos;

  @hasMany('stationHardware', {
    async: false,
    inverse: 'hardware',
    as: 'hardware',
    cascadeDelete: true,
  }) stationHardwares;

  @hasMany('trigger', {
    async: false,
    polymorphic: true,
    as: 'hardware',
    inverse: 'hardware',
    cascadeDelete: true,
  }) triggers;

  @service intl;
  @service taskRepo;
  @service stationRepo;
  @service hardwareInputDefRepo;

  get polymorphic() {
    return true;
  }

  // must be implemented by base class
  get isConfigured() {
    return false;
  }

  get hasError() {
    return isPresent(this.connectionError);
  }

  get triggerDisplayName() {
    return this.name;
  }

  get displayName() {
    let displayName = this.intl.t(camelize(this.constructor.modelName));

    if (!isBlank(this.name)) {
      displayName = `${displayName} - ${this.name}`;
    }

    return displayName;
  }

  get displayType() {
    return this.intl.t(camelize(this.constructor.modelName));
  }
}

export default Hardware;
