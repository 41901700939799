import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import config from 'eflex/config/environment';

export default class AuthenticatedUrl extends Helper {
  @service currentUser;

  compute([url]) {
    url = `${config.servicePrefix}/${url}`;
    return this.currentUser.getUserTokenUrl(url);
  }
}
