import config from 'eflex/config/environment';
import Ember from 'ember';
import { didCancel } from 'ember-concurrency';

const VAGRANT_ADDRESS = '192.168.56.10';

export default {
  name: 'ember-cli-raygun',
  async initialize(applicationInstance) {
    const raygunConfig = config.raygun;

    if (!raygunConfig.enabled || globalThis.location.hostname === VAGRANT_ADDRESS) {
      return;
    }

    const raygunService = applicationInstance.lookup('service:raygun');
    await raygunService.loadRaygun();

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    Ember.onerror = function(error) {
      if (didCancel(error)) {
        return;
      }
      raygunService.sendError(error);
    };
  },
};
