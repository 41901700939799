import { template as template_6a6e36210a024ac4ba21b12a520cac22 } from "@ember/template-compiler";
import { fn, get, uniqueId } from '@ember/helper';
import { eq, or } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
export default template_6a6e36210a024ac4ba21b12a520cac22(`
{{#let (uniqueId) as |name|}}
  {{#each @options as |option|}}
    <div
      class="
        {{option.class}}
        form-check
        eflex-radio-button
        {{if @inline "form-check-inline"}}
      "
      ...attributes
    >
      {{#let
        (uniqueId)
        (if @optionLabelPath (get option @optionLabelPath) option)
        (if @optionValuePath (get option @optionValuePath) option)
      as |uid optionLabel optionValue|}}
        <input
          class="form-check-input"
          type="radio"
          id={{uid}}
          name={{name}}
          value={{optionValue}}
          disabled={{or @disabled option.disabled}}
          checked={{eq option @value}}
          {{on "click" (fn @onChange option)}}
        />
        <label for={{uid}} class="form-check-label">
          {{#if (has-block)}}
            {{yield optionLabel}}
          {{else}}
            {{optionLabel}}
          {{/if}}
        </label>
      {{/let}}
    </div>
  {{/each}}
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
