import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class EvisionImageRetrievalController extends Controller {
  queryParams = ['searchTerm', 'beginDate', 'endDate', 'imageType'];

  @service eflexAjax;
  @service currentUser;
  @service zipImages;

  @tracked selectAll = false;
  @tracked model = new TrackedArray();
  @tracked currentImage;
  @tracked showImageModal = false;
  @tracked showDeleteModal = false;
  @tracked searchTerm;
  @tracked beginDate;
  @tracked endDate;
  @tracked passed;
  @tracked imageType;
  @tracked taskIds = [];

  get selectedImages() {
    return this.model.filter(item => item.isSelected);
  }

  get selectedImageIds() {
    return this.selectedImages.map(item => item.id);
  }

  get someSelected() {
    return this.model
      ?.filter(item => item.isDeleted === false)
      .some(item => item.isSelected);
  }

  deleteImages = task(waitFor(async params => {
    if (!this.someSelected) {
      this.showDeleteModal = false;
      return;
    }

    await this.eflexAjax.post.perform('evision/images/delete', {
      ...params,
      selectedImages: this.selectedImageIds,
    });

    this.showDeleteModal = false;
    this.selectedImages.filter(item => !item.isPdf).forEach(item => { item.hasImage = false; });
    this.selectedImages.filter(item => item.isPdf).forEach(selectedImage => {
      selectedImage.unloadRecord();
    });

    this.model.forEach(image => { image.isSelected = false; });
  }));

  @action
  zipImagesAction(params) {
    this.zipImages.zip({
      selectAll: this.selectAll,
      selectedImageIds: this.selectedImageIds,
      totalImageCount: this.model.meta?.count ?? 0,
      params,
    });
  }

  @action
  onSelectAll(selectAll) {
    this.selectAll = selectAll;

    this.model
      ?.filter(item => item.isDeleted === false)
      .forEach(item => { item.isSelected = selectAll; });
  }

  @action
  setSelected(image, isSelected) {
    image.isSelected = isSelected;

    if (!isSelected) {
      this.selectAll = false;
    }
  }

  @action
  showImage(image) {
    this.currentImage = image;
    this.showImageModal = true;
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
