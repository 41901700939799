import Model, { attr, belongsTo } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';

export default class KineticAssemblyMaterial extends Model {
  @service intl;

  @attr('number') sequence;
  @attr('string') partNumber;
  @attr('string') partDescription;
  @attr('number') relatedOperation;
  @attr('number') quantityPer;
  @attr('boolean', { defaultValue: false }) fixedQuantity;
  @attr('boolean', { defaultValue: false }) serialTracked;
  @attr('boolean', { defaultValue: false }) lotTracked;

  @belongsTo('kineticJobAssembly', { inverse: 'materials', async: false }) assembly;

  get issuedMaterial() {
    return this.assembly.issuedMaterials.find(issue => {
      return issue.materialSequence === this.sequence &&
        issue.partNumber === this.partNumber &&
        issue.relatedOperation === this.relatedOperation;
    });
  }

  get quantityIssued() {
    if (isEmpty(this.issuedMaterial)) {
      return 0;
    }

    return `${this.issuedMaterial.issuedQuantity}/${this.issuedMaterial.requiredQuantity}`;
  }

  get issuedComplete() {
    return this.issuedMaterial?.issuedComplete ? this.intl.t('True') : this.intl.t('value.false');
  }
}
