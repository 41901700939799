import { template as template_faedebaa6c6043d1ba911362e913b305 } from "@ember/template-compiler";
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import SearchTag from 'eflex/components/criteria-visor/search-tag';
import FilterWrapper from 'eflex/components/criteria-visor/filter-wrapper';
export default class CriteriaVisorTextField extends Component {
    @service
    intl;
    @tracked
    isDisplayed = Boolean(this.value);
    get value() {
        return this.args[this.args.key];
    }
    get breadcrumb() {
        if (isBlank(this.value)) {
            return null;
        }
        return this.intl.t(`breadcrumbs.${this.args.key}`, {
            [this.args.key]: this.value
        });
    }
    constructor(){
        super(...arguments);
        this.args.initParam(this.args.key, this.value);
    }
    onDisplayedChanged = (isDisplayed)=>{
        this.isDisplayed = isDisplayed;
        this.args.updateParam(this.args.key, null);
    };
    onChange = (value)=>{
        this.args.updateParam(this.args.key, value);
    };
    static{
        template_faedebaa6c6043d1ba911362e913b305(`
    <SearchTag class={{@key}} @isDisplayed={{this.isDisplayed}} @text={{this.breadcrumb}} />

    <FilterWrapper
      @form={{@form}}
      @label="controls.criteria.{{@key}}"
      @isDisplayed={{this.isDisplayed}}
      @onChecked={{this.onDisplayedChanged}}
      ...attributes
    >
      <@form.element
        @model={{this}}
        @property="value"
        @onChange={{this.onChange}}
      />
    </FilterWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
