import { isTesting, isDevelopingApp, macroCondition } from '@embroider/macros';

const NavBarMap = [
  {
    key: 'plant',
    label: 'plant.editor',
    faIcon: 'align-center',
    children: [
      { key: 'plant', label: 'plant.editor.config' },
      { key: 'kineticOperations', label: 'operations', lic: 'operations' },
      { key: 'kineticJobs', label: 'jobs', lic: 'operations' },
      { key: 'codes', label: 'plant.editor.codes' },
      { key: 'workInstructions', label: 'plant.editor.workInstructions' },
      { key: 'hardware', label: 'plant.editor.hardware' },
      { key: 'bomSources', label: 'plant.editor.bomSources', lic: 'componentBased' },
      {
        key: 'bomManager',
        label: 'plant.editor.bomManager.index',
        lic: 'bomManager',
      },
      { key: 'schedules', label: 'plant.editor.schedules' },
      { key: 'productionScheduler', label: 'plant.editor.productionScheduler' },
    ],
  },

  {
    key: 'reporting',
    label: 'reporting',
    children: [
      { key: 'parts.buildHistory', label: 'reporting.parts.partHistory' },
      { key: 'parts.genealogy', label: 'reporting.parts.partGenealogy' },
    ],
  },

  {
    key: 'bi',
    label: 'businessIntelligence',
    lic: 'bi',
    children: [
      { key: 'bi.andon', label: 'bi.andon' },
      { key: 'bi.quality', label: 'bi.quality' },
      { key: 'bi.production', label: 'bi.production' },
      { key: 'bi.cycleTime', label: 'bi.cycleTime' },
      { key: 'bi.processData', label: 'bi.processData' },
      { key: 'bi.torqueData', label: 'bi.torqueData' },
      { key: 'bi.oee', label: 'bi.oee' },
      { key: 'bi.serialNumberReport', label: 'bi.serialNumberReport' },
    ],
  },

  { key: 'containParts', label: 'containParts' },

  { key: 'repair', label: 'repair' },

  { key: 'jem', label: 'jem' },

  { key: 'oee', label: 'oee', lic: 'oee' },

  { key: 'status', label: 'status.plcs' },

  { key: 'settings', label: 'settings' },

  { key: 'logs', label: 'logs' },

  {
    key: 'evision',
    label: 'evision',
    lic: 'visionOrImageCapture',
    children: [
      { key: 'evision.imageRetrieval', label: 'evision.imageRetrieval' },
      { key: 'evision.camera', label: 'evision.cameras' },
      { key: 'evision.nonConforming', label: 'evision.nonconf' },
    ],
  },

  {
    key: 'debug',
    label: 'debug',
    children: [
      { key: 'debug.packets', label: 'packets' },
      { key: 'debug.liveBuildStatusLogs', label: 'liveBuildStatusLogs' },
      { key: 'debug.audits', label: 'audits', faIcon: 'clipboard-list' },
      { key: 'debug.hardwareMessages', label: 'hardwareMessages' },
      { key: 'debug.systemLogs', label: 'systemLogs' },
      { key: 'debug.kineticIntegrationLogs', label: 'kineticIntegrationLogs' },
    ],
  },

  {
    key: 'links',
    label: 'links',
    children: [
      { href: '/admin', label: 'adminPanel' },
      { href: '/consul', label: 'consul' },
      { href: '/cups', label: 'cups', lic: 'print' },
      { href: '/metabase', label: 'metabase', lic: 'bi' },
      { href: '/netdata', label: 'netdata' },
      { href: '/nodeRed', label: 'nodeRed', lic: 'nodeRed' },
    ],
  },
];

if (macroCondition(isDevelopingApp() && !isTesting())) {
  NavBarMap.push({
    key: 'development',
    label: 'development',
    faIcon: 'user-ninja',
    children: [
      { key: 'styleguide', label: 'styleguide' },
      { key: 'schema', label: 'schema' },
      { key: 'dataGenerator', label: 'treeGenerator' },
      { key: 'integrationTester', label: 'integrationTester' },
    ],
  });
}

export default NavBarMap;
