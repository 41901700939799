import { registerDeprecationHandler } from '@ember/debug';

const SILENCED_DEPRECATIONS = new Set([]);

registerDeprecationHandler((message, options) => {
  if (SILENCED_DEPRECATIONS.has(options.id)) {
    return;
  }

  throw new Error(message);
});
