import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class KineticIssuedMaterial extends Model {
  @attr('string') partNumber;
  @attr('number') assemblySequence;
  @attr('number') materialSequence;
  @attr('number') relatedOperation;
  @attr('number') requiredQuantity;
  @attr('number') issuedQuantity;
  @attr('boolean', { defaultValue: false }) issuedComplete;
  @attr('number') quantityPer;

  @belongsTo('kineticJobAssembly', { async: false, inverse: 'issuedMaterials' }) assembly;
  @hasMany('kineticMaterialQuantity', { async: false, inverse: null, embedded: true }) issuedQuantities;
}
