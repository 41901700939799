import { template as template_22f6ae938cb34e57b8d1cdbb72a6c87d } from "@ember/template-compiler";
import KeyboardWedgeEvent from 'eflex/components/keyboard-wedge-event';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { inputVal } from 'eflex/helpers';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import onKey from 'ember-keyboard/modifiers/on-key';
import BsButton from 'eflex/components/bs-button';
import BsTooltip from 'eflex/components/bs-tooltip';
import Link from 'eflex/components/link';
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import getProductVersion from 'eflex/util/get-product-version';
import config from 'eflex/config/environment';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { isTesting, macroCondition } from '@embroider/macros';
import { getOwner } from '@ember/application';
import JwtSession from 'eflex/services/session';
const year = moment().get('year');
class LoginTemplate extends Component {
    @service
    session;
    @service
    intl;
    @service
    systemConfig;
    @tracked
    password;
    @tracked
    errorMessage;
    @tracked
    username;
    _sendLoginRequest = async (credentials)=>{
        Object.assign(credentials, {
            timezone: moment.tz.guess(),
            clientTime: moment().toDate()
        });
        const { session, intl } = this;
        try {
            await session.authenticate('authenticator:jwt', credentials);
        } catch (e) {
            if (macroCondition(!isTesting())) {
                console.error(e);
            }
            let errorMessage = e.message;
            if (intl.exists(errorMessage)) {
                errorMessage = intl.t(errorMessage);
            }
            Object.assign(this, {
                password: null,
                errorMessage
            });
        }
    };
    badgeScan = task(waitFor(async (badgeId)=>{
        await this._sendLoginRequest({
            badgeId
        });
    }));
    login = task(waitFor(async ()=>{
        if (this.session.isOidc) {
            const owner = getOwner(this);
            owner.unregister('service:session');
            owner.register('service:session', JwtSession);
            this.session = owner.lookup('service:session');
            await this.session.setup();
        }
        await this._sendLoginRequest({
            username: this.username,
            password: this.password
        });
    }));
    static{
        template_22f6ae938cb34e57b8d1cdbb72a6c87d(`
    <div class="container-fluid vh-100 bg-white">
      <KeyboardWedgeEvent @onScan={{this.badgeScan.perform}} />
      <div class="login-page row align-items-center h-100">
        <div class="col-6 row flex-column align-items-center justify-content-center gx-0 bg-brand-texture h-100">
          <div class="col d-flex flex-column align-items-center justify-content-center">
            <img width="300" src="/images/epicor-logo-white.png" class="mb-3" />
            <h6 class="text-white fw-normal">
              {{t "cpc"}}
            </h6>
          </div>
          <div class="col-auto">
            <div class="version-wrapper d-flex text-gray-200 fs-4 p-2">
              <div class="me-3">
                {{t "footer"}} © {{year}}.
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 row gx-0 justify-content-center align-items-center login-container">
          <form class="col-8 col-xl-5 form-signin">
            <div class="mb-5">
              <h4 class="text-gray-900 fw-normal">
                {{t "controls.login.redirect.welcome"}}
              </h4>
              <div class="text-gray-900">
                {{t "controls.login.redirect.enterCredentials"}}
              </div>
            </div>
            <div class="d-flex align-items-center mb-4">
              <FaIcon @icon="user" @prefix="fas" @size="xs" class="position-absolute text-gray-600" />
              <input
                type="text"
                placeholder="{{t "username"}}..."
                value={{this.username}}
                class="form-control login-field app-login-username border-0 border-bottom shadow-none rounded-0 ps-4"
                {{on "input" (inputVal (fn (mut this.username)))}}
              />
            </div>
            <div class="d-flex align-items-center mb-5">
              <FaIcon @icon="lock" @prefix="fas" @size="xs" class="position-absolute text-gray-600" />
              <input
                type="password"
                placeholder="{{t "password"}}..."
                class="form-control login-field app-login-password border-0 border-bottom shadow-none rounded-0 ps-4"
                value={{this.password}}
                {{onKey 'Enter' this.login.perform}}
                {{on "input" (inputVal (fn (mut this.password)))}}
              />
            </div>
            <div class="d-flex justify-content-center mb-2">
              <BsButton
                class="w-100 app-login-button rounded-pill fs-3 py-2"
                @type="primary"
                @size="lg"
                @onClick={{this.login.perform}}
              >
                {{t "controls.login.login"}}
              </BsButton>
            </div>
            <span class="text-gray-600 version-text">
              <BsTooltip @triggerEvents="hover">
                {{config.APP.version}}
              </BsTooltip>
              {{t "version"}} {{getProductVersion}}
            </span>
            {{#if this.systemConfig.oidcConfig.value.enabled}}
              <Link
                class='w-100 btn btn-secondary btn-lg mt-2'
                @route="loginOidc"
              >
                {{t "singleSignOn"}}
              </Link>
            {{/if}}
            {{#if this.errorMessage}}
              <div class="text-center invalid-login text-danger mt-2">
                {{this.errorMessage}}
              </div>
            {{/if}}
          </form>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(LoginTemplate);
