import { template as template_d900f56d7d88454d889ef6ee357fca80 } from "@ember/template-compiler";
import { service } from '@ember/service';
import moment from 'moment-timezone';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import { t } from 'ember-intl';
import StandardModal from 'eflex/components/standard-modal';
import { fn } from '@ember/helper';
import { inputVal } from 'eflex/helpers';
import { on } from '@ember/modifier';
import PowerSelectMultiple from 'eflex/components/power-select-multiple';
const NO_CODE_WARNINGS = {
    scrap: 'errors.noScrapCodesForStation',
    reject: 'errors.noRejectCodesForStation',
    fault: 'errors.noFaultCodesForStation',
    repair: 'errors.noRepairCodesForStation'
};
export default class CodeConfirmationModal extends Component {
    @service
    currentUser;
    @service
    codeRepo;
    @service
    notifier;
    @service
    intl;
    @tracked
    selected = [];
    @tracked
    note;
    codes = trackedFunction(this, async ()=>{
        const availableCodes = await this.codeRepo.retrieveCodes(this.args.codeType, this.args.station);
        if (this.isDestroying || this.isDestroyed) {
            return;
        }
        if (isEmpty(availableCodes)) {
            this.notifier.sendWarning(NO_CODE_WARNINGS[this.args.codeType]);
        }
        return availableCodes;
    });
    get invalid() {
        return isEmpty(this.selected);
    }
    get timestamp() {
        return moment().format('M-DD-YY h:mma').slice(0, -1);
    }
    get noCodeWarning() {
        return NO_CODE_WARNINGS[this.args.codeType];
    }
    codeMatcher(code, term) {
        return `${code.code} - ${code.description}`.indexOf(term);
    }
    onConfirm = ()=>{
        return this.args.onConfirm({
            note: this.note,
            codes: this.selected.map((code)=>({
                    code: code.code,
                    description: code.description
                }))
        });
    };
    confirmWithoutCode = ()=>{
        return this.args.onConfirm({
            codes: [
                {
                    code: 'NOCODE',
                    description: this.intl.t('reasonCodes.noCodesConfigured')
                }
            ]
        });
    };
    static{
        template_d900f56d7d88454d889ef6ee357fca80(`
    {{#let this.codes.value as |codes|}}
      {{#if codes}}
        <StandardModal
          class="code-confirmation-modal"
          @onCancel={{@onCancel}}
          @onConfirm={{this.onConfirm}}
          ...attributes
        as |modal|>
          <modal.header @text={{@modalHeader}} />
          <modal.body>
            <div class="user-datetime fw-light fst-italic mb-2">
              {{this.currentUser.user.displayUsername}}, {{this.timestamp}}
            </div>
            <div class="code-confirmation-wrapper mb-2">
              <label class="form-label">
                <span>
                  {{@selectLabel}} &nbsp;
                </span>
                <span class="fst-italic">
                  ({{t "selectOneOrMore"}})
                </span>
              </label>
              <PowerSelectMultiple
                class="code-confirmation-selection"
                @searchEnabled={{true}}
                @matcher={{this.codeMatcher}}
                @options={{codes}}
                @selected={{this.selected}}
                @renderInPlace={{true}}
                @onChange={{fn (mut this.selected)}}
              as |code|>
                {{code.code}} - {{code.description}}
              </PowerSelectMultiple>
            </div>
            <div class="code-confirmation-note d-flex flex-column">
              <label class="form-label">
                {{t "notes"}}
              </label>
              <textarea
                class="form-control flex-fill"
                value={{this.note}}
                {{on "input" (inputVal (fn (mut this.note)))}}
              />
            </div>
          </modal.body>
          <modal.footer as |footer|>
            <footer.confirm @disabled={{this.invalid}}>
              {{t "save"}}
            </footer.confirm>
            <footer.close>
              {{t "cancel"}}
            </footer.close>
          </modal.footer>
        </StandardModal>
      {{else}}
        <StandardModal
          class="no-code-confirm-modal"
          @size="sm"
          @onCancel={{@onCancel}}
          @onConfirm={{this.confirmWithoutCode}}
          ...attributes
        as |modal|>
          <modal.header @text={{t "confirmItem" item=(t @codeType)}} />
          <modal.body>
            <div>{{t this.noCodeWarning}}</div>
          </modal.body>
          <modal.footer />
        </StandardModal>
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
