import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
import { isDevelopingApp, macroCondition } from '@embroider/macros';

momentDurationFormatSetup(moment);

// native toLocaleString is crazy slow
moment.duration.fn.format.defaults.useToLocaleString = false;

if (macroCondition(isDevelopingApp())) {
  moment.deprecationHandler = function (ignored, msg) {
    throw new Error(msg);
  };
}
