import { template as template_8476eeb079de439089f42e4b2c0f1f83 } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import BsButton from 'eflex/components/bs-button';
import BsTooltip from 'eflex/components/bs-tooltip';
import { t } from 'ember-intl';
import { or } from 'ember-truth-helpers';
import TaskStatuses from 'eflex/constants/task-statuses';
export default template_8476eeb079de439089f42e4b2c0f1f83(`
<BsButton
  @type="danger"
  @size={{or @size "md"}}
  @onClick={{fn @onTaskComplete TaskStatuses.REJECTED}}
  @disabled={{@disabled}}
  class="jem-status reject-jem-task reject-button text-uppercase text-white px-3 py-2"
  ...attributes
>
  {{#if @notAuthorized}}
    <BsTooltip>
      {{t "notAuthorized"}}
    </BsTooltip>
  {{/if}}
  {{t "reject"}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
