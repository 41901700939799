import { service } from '@ember/service';
import LocationRepoBase from 'eflex/services/location-repo-base';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { removeObjects } from 'eflex/util/array-helpers';
import { without, clone } from 'ramda';
import { copyToAllContexts as copyWorkInstructionToAllContexts } from 'eflex/services/work-instruction-repo';
import { updateLocationPaths } from 'eflex/util/tree-helpers';
import { createTriggerConfig } from 'eflex/services/trigger-repo';

const setupBarcode = (config) => {
  config.enabled = false;
  config.strings = config.task?.strings.map(string => string.copy()) ?? [];
};

const setupVision = (config) => {
  config.strings = config.task?.strings.map(string => string.copy()) ?? [];
};

const setupPick = (config) => {
  if (!config.task?.hardwareInput) {
    config.pickSensorEnabled = false;
  }
};

const applyDefaults = (taskConfig) => {
  const store = taskConfig.store;
  const taskType = taskConfig.task?.taskType;

  if (taskType == null) {
    return;
  }

  taskConfig.task.nonDeletedVariableDefs.forEach(def => {
    store.createRecord('variableDefConfig', {
      taskConfig,
      variableDef: def,
    });
  });

  taskConfig.task.nonDeletedHardwareInputDefs.forEach(def => {
    store.createRecord('hardwareInputDefConfig', {
      taskConfig,
      hardwareInputDef: def,
    });
  });

  taskConfig.task.nonDeletedDecisionDefs.forEach(def => {
    store.createRecord('decisionDefConfig', {
      taskConfig,
      decisionDef: def,
    });
  });

  taskConfig.task.nonDeletedSpindles.forEach((spindle, i) => {
    store.createRecord('spindleConfig', {
      taskConfig,
      spindle,
      name: `Spindle ${i + 1}`,
    });
  });

  switch (taskType) {
    case taskTypes.barcode: {
      setupBarcode(taskConfig);
      break;
    }
    case taskTypes.vision: {
      setupVision(taskConfig);
      break;
    }
    case taskTypes.pick: {
      setupPick(taskConfig);
      break;
    }
  }
};

const copyToDefConfig = (sourceConfig, defConfig, defName, configsName = null) => {
  if (defConfig.isDeleted) {
    return;
  }

  configsName ??= `${defName}Configs`;

  const toCopy = sourceConfig[configsName].find(item => item[defName] === defConfig[defName]);

  defConfig.eachAttribute(attr => {
    defConfig[attr] = clone(toCopy[attr]);
  });

  defConfig.eachRelationship(relationship => {
    if (relationship === 'taskConfig') {
      return;
    }

    defConfig[relationship] = toCopy[relationship];
  });
};

export const changeType = (taskConfig) => {
  taskConfig.hardwareInputDefConfigs.forEach(hardwareInputDefConfig => { hardwareInputDefConfig.deleteRecord(); });
  taskConfig.decisionDefConfigs.forEach(decisionDefConfig => { decisionDefConfig.deleteRecord(); });
  taskConfig.variableDefConfigs.forEach(variableDefConfig => { variableDefConfig.deleteRecord(); });
  taskConfig.spindleConfigs.forEach(spindleConfig => { spindleConfig.deleteRecord(); });
  taskConfig.pushToScheduleConfigs.forEach(pushToScheduleConfig => { pushToScheduleConfig.deleteRecord(); });

  applyDefaults(taskConfig);
};

export const copyDecisionToAllContexts = (sourceConfig) => {
  if (sourceConfig == null) {
    return;
  }

  const destinationConfigs = without([sourceConfig], sourceConfig.parent.children);

  destinationConfigs.forEach(config => {
    config.decisionDefConfigs.forEach(decisionDefConfig => {
      copyToDefConfig(sourceConfig, decisionDefConfig, 'decisionDef');
    });
  });
};

export const copyToAllContexts = (sourceConfig) => {
  if (sourceConfig == null) {
    return;
  }

  const destinationConfigs = without([sourceConfig], sourceConfig.parent.children);

  destinationConfigs.forEach((config) => {
    if (config.isDeleted) {
      return;
    }

    config.eachAttribute(attr => {
      if (attr === 'path') {
        return;
      }

      config[attr] = clone(sourceConfig[attr]);
    });

    config.hardwareInputDefConfigs.forEach((defConfig) => {
      copyToDefConfig(sourceConfig, defConfig, 'hardwareInputDef');
    });

    config.variableDefConfigs.forEach((defConfig) => {
      copyToDefConfig(sourceConfig, defConfig, 'variableDef');
    });

    if (sourceConfig.parent.taskType === taskTypes.decision) {
      config.decisionDefConfigs.forEach((defConfig) => {
        copyToDefConfig(sourceConfig, defConfig, 'decisionDef');
      });
    }

    config.spindleConfigs.forEach((defConfig) => {
      copyToDefConfig(sourceConfig, defConfig, 'spindle');
    });

    let barcodeStringIndex = 0;
    config.strings.forEach(barcodeString => {
      if (barcodeString.isDeleted) {
        barcodeStringIndex += 1;
        return;
      }

      const toCopy = sourceConfig.strings[barcodeStringIndex];

      barcodeString.eachAttribute(attr => {
        if (toCopy?.[attr] != null) {
          barcodeString[attr] = clone(toCopy[attr]);
        }
      });

      barcodeStringIndex += 1;
    });

    config.pushToScheduleConfigs = sourceConfig.pushToScheduleConfigs
      .filter(item => !item.isDeleted)
      .map(pushToScheduleConfig => pushToScheduleConfig.copy());
  });
};

export const copyEventToAllContexts = (sourceConfig) => {
  if (sourceConfig == null) {
    return;
  }

  const destinationConfigs = without([sourceConfig], sourceConfig.parent.children);

  destinationConfigs.forEach(config => {
    config.triggerConfigs
      .filter(item => item.constructor.modelName !== 'work-instruction-hardware-trigger-config')
      .forEach(triggerConfig => {
        copyToDefConfig(sourceConfig, triggerConfig, 'parentTrigger', 'triggerConfigs');
      });
  });
};

export const copyAllToAllContexts = (sourceConfig) => {
  copyToAllContexts(sourceConfig);
  copyEventToAllContexts(sourceConfig);

  sourceConfig.triggerConfigs
    .filter(item => item.constructor.modelName === 'work-instruction-hardware-trigger-config')
    .forEach(triggerConfig => {
      copyWorkInstructionToAllContexts(
        sourceConfig.parent,
        triggerConfig,
        triggerConfig.event,
      );
    });
};

export const copyTaskConfig = (taskConfig, parent) => {
  const taskConfigCopy = taskConfig.copy(true);
  taskConfigCopy.parent = parent;
  updateLocationPaths(taskConfigCopy);

  taskConfig.variableDefConfigs.forEach((variableDefConfig, index) => {
    const variableDefConfigCopy = variableDefConfig.copy(true);
    variableDefConfigCopy.variableDef = parent.variableDefs[index];
    variableDefConfigCopy.taskConfig = taskConfigCopy;
  });

  taskConfig.hardwareInputDefConfigs.forEach((hardwareInputDefConfig, index) => {
    const hardwareInputDefConfigCopy = hardwareInputDefConfig.copy(true);
    hardwareInputDefConfigCopy.hardwareInputDef = parent.hardwareInputDefs[index];
    hardwareInputDefConfigCopy.taskConfig = taskConfigCopy;
  });

  taskConfig.triggerConfigs.forEach((triggerConfig, index) => {
    const triggerConfigCopy = triggerConfig.copy(true);
    triggerConfigCopy.parentTrigger = parent.triggers[index];
    triggerConfigCopy.taskConfig = taskConfigCopy;
  });

  taskConfig.decisionDefConfigs.forEach((decisionDefConfig, index) => {
    const decisionDefConfigCopy = decisionDefConfig.copy(true);
    decisionDefConfigCopy.decisionDef = parent.decisionDefs[index];
    decisionDefConfigCopy.taskConfig = taskConfigCopy;
  });

  taskConfig.spindleConfigs.forEach((spindleConfig, index) => {
    const spindleConfigCopy = spindleConfig.copy(true);
    spindleConfigCopy.spindle = parent.spindles[index];
    spindleConfigCopy.taskConfig = taskConfigCopy;
  });

  taskConfig.strings.forEach(barcodeString => {
    const barcodeStringCopy = barcodeString.copy(true);
    barcodeStringCopy.taskConfig = taskConfigCopy;
  });

  return taskConfigCopy;
};

export const removeInvalidBoltAnimations = (taskConfig) => {
  const boltCount = Number.parseInt(taskConfig.boltCount);

  taskConfig.triggerConfigs
    .filter(item => item.constructor.modelName === 'work-instruction-hardware-trigger-config')
    .forEach(function (triggerConfig) {
      const invalidAnimations = triggerConfig.animations
        .filter(item => item.bolt !== 'all')
        .filter((animation) => Number.parseInt(animation.bolt) > boltCount);

      removeObjects(triggerConfig.animations, invalidAnimations);
    });
};

export default class TaskConfigRepoService extends LocationRepoBase {
  @service store;
  @service triggerRepo;

  #loadedTasks = new Set();
  _loadedTaskParents = new Set();
  #loadedTaskConfigContexts = new Map();

  loadTaskConfigs = task(waitFor(async parent => {
    const parentType = parent.constructor.modelName;
    const params = { ancestorPath: parent.path };

    if (parentType === 'station' || parentType === 'kineticOperation') {
      if (this._loadedTaskParents.has(parent.id)) {
        return;
      }
      await this.store.query('taskConfig', params);
      this._loadedTaskParents.add(parent.id);
      parent.tasks?.forEach(treeTask => { this.#loadedTasks.add(treeTask.id); });
      return;
    }

    if (this.#loadedTasks.has(parent.id) || this._loadedTaskParents.has(parent.parent.id)) {
      return;
    }

    await this.store.query('taskConfig', params);
    this.#loadedTasks.add(parent.id);
  }));

  loadContextTaskConfigs = task(waitFor(async (station, context) => {
    let taskParent = station;

    if (station.usesOperations) {
      taskParent = context;
    }

    if (!taskParent) {
      return;
    }

    const parentCacheId = taskParent.id;

    if (this._loadedTaskParents.has(parentCacheId)) {
      return;
    }

    let cacheKey = true;
    const params = { ancestorPath: taskParent.path };

    if (context && (station.usesModels || station.usesComponents)) {
      cacheKey = context.id;
      params.model = context.id;
    }

    const parentCacheSet = this.#loadedTaskConfigContexts.get(parentCacheId);

    if (parentCacheSet?.has(cacheKey)) {
      return;
    }

    await this.store.query('taskConfig', params);

    if (parentCacheSet) {
      parentCacheSet.add(cacheKey);
    } else {
      this.#loadedTaskConfigContexts.set(parentCacheId, new Set([cacheKey]));
    }
  }));

  create(attrs = {}) {
    const taskConfig = super.create('taskConfig', attrs);

    taskConfig.task.triggers.forEach(trigger => {
      createTriggerConfig(trigger, taskConfig);
    });

    applyDefaults(taskConfig);

    return taskConfig;
  }

  delete(deleted) {
    deleted.deleteRecord();
  }
}
