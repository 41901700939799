import { template as template_e7006311b56b4666bd4a31793389ef90 } from "@ember/template-compiler";
import { isEmpty } from '@ember/utils';
import { t } from 'ember-intl';
import PowerSelect from 'eflex/components/power-select';
import { sortBy, withDefault } from '@eflexsystems/ember-composable-helpers';
import { or } from 'ember-truth-helpers';
import KeyboardWedgeEvent from 'eflex/components/keyboard-wedge-event';
import { fn } from '@ember/helper';
import { serviceHelper as service } from 'eflex/helpers';
const filterOptions = (data, station, intl)=>{
    const sortedOptions = {
        allowed: [],
        stationNotAllowed: [],
        missingConfiguration: []
    };
    data.forEach((datum)=>{
        if (!datum.isReleased) {
            return;
        }
        if (datum.missingConfiguration) {
            sortedOptions.missingConfiguration.push(datum);
            return;
        }
        if (!datum.allowedStations.includes(station)) {
            sortedOptions.stationNotAllowed.push(datum);
            return;
        }
        sortedOptions.allowed.push(datum);
    });
    const options = sortedOptions.allowed;
    if (!isEmpty(sortedOptions.missingConfiguration)) {
        options.push({
            groupName: intl.t('kinetic.operations.missingConfiguration'),
            disabled: true,
            options: sortedOptions.missingConfiguration
        });
    }
    if (!isEmpty(sortedOptions.stationNotAllowed)) {
        options.push({
            groupName: intl.t('jem.stationNotAllowed'),
            disabled: true,
            options: sortedOptions.stationNotAllowed
        });
    }
    return options;
};
const getFilteredAssemblies = (kineticJobAssemblies, selectedJob, station, intl)=>{
    const assemblies = kineticJobAssemblies.filter((assembly)=>{
        return assembly.job === selectedJob;
    }) ?? [];
    return filterOptions(assemblies, station, intl);
};
const getFilteredOperations = (kineticAssemblyOperations, selectedAssembly, station, intl)=>{
    const operations = kineticAssemblyOperations?.filter((assemblyOp)=>{
        return assemblyOp.assembly === selectedAssembly;
    }) ?? [];
    return filterOptions(operations, station, intl);
};
const onOperationScanned = (kineticAssemblyOperations, onOperationSelected, operationName)=>{
    //todo: this would be some sort of JOB-assembly-op scan? need to parse and load from that
    onOperationSelected(kineticAssemblyOperations.find((operation)=>{
        return operation.name === operationName;
    }));
};
export default template_e7006311b56b4666bd4a31793389ef90(`
{{#let
  (service 'kineticRepo')
  (service 'intl')
as |kineticRepo intl|}}
  <div class="col-auto align-self-end d-flex flex-column mb-0">
    <label class="form-label">
      {{t "quantity"}}
    </label>
    <div
      class="
        jem-operation-quantity
        form-control
        form-control-lg
        border-0
        d-flex
        align-items-center
        fw-semibold
        px-3
        bg-gray-800
      "
    >
      {{withDefault @assemblyOperation.quantityCount "-"}}
    </div>
  </div>

  <div class="col">
    <label class="form-label">
      {{t "job"}}
    </label>
    <PowerSelect
      class="jem-job-select no-chrome-power-select"
      @triggerClass="jem-job-select-trigger form-control form-control-lg bg-gray-800 border-0"
      @searchEnabled={{true}}
      @searchField="jobNumber"
      @options={{sortBy "jobNumber" (filterOptions kineticRepo.kineticJobs @station intl)}}
      @selected={{@selectedJob}}
      @allowClear={{true}}
      @placeholder={{t "kinetic.selectJob"}}
      @disabled={{@disabled}}
      @onChange={{@onJobSelected}}
    as |job|>
      {{job.jobNumber}}
    </PowerSelect>
  </div>

  <div class="col">
    <label class="form-label">
      {{t "kinetic.assembly"}}
    </label>
    <PowerSelect
      class="jem-assembly-select no-chrome-power-select"
      @triggerClass="jem-assembly-select-trigger form-control form-control-lg bg-gray-800 border-0"
      @options={{
        sortBy
        "assemblySequence"
        (getFilteredAssemblies kineticRepo.kineticJobAssemblies @selectedJob @station intl)
      }}
      @selected={{@selectedAssembly}}
      @allowClear={{true}}
      @placeholder={{t "kinetic.selectAssembly"}}
      @disabled={{or @disabled (isEmpty @selectedJob)}}
      @onChange={{@onAssemblySelected}}
      @searchField="assemblySequence"
    as |assembly|>
      {{assembly.assemblySequence}}
    </PowerSelect>
  </div>

  <div class="col">
    <label class="form-label">
      {{t "operation"}}
    </label>
    <PowerSelect
      class="jem-operation-select no-chrome-power-select"
      @triggerClass="jem-operation-select-trigger form-control form-control-lg bg-gray-800 border-0"
      @searchEnabled={{true}}
      @searchField="name"
      @options={{
        sortBy
        "name"
        (getFilteredOperations kineticRepo.kineticAssemblyOperations @selectedAssembly @station intl)
      }}
      @selected={{@assemblyOperation.operation}}
      @disabled={{or @disabled (isEmpty @selectedAssembly)}}
      @allowClear={{true}}
      @placeholder={{t "kinetic.selectOperation"}}
      @onChange={{@onOperationSelected}}
    as |operation|>
      {{operation.name}}
    </PowerSelect>
  </div>

  {{#if (isEmpty @assemblyOperation.operation)}}
    <KeyboardWedgeEvent
      @onScan={{fn onOperationScanned kineticRepo.kineticAssemblyOperations @onOperationSelected}}
    />
  {{/if}}
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
