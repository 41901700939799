import getHardware from 'eflex/helpers/get-hardware';

export default function getHardwareInputDefIo(station, inputDef) {
  if (!station || !inputDef) {
    return null;
  }

  if (inputDef.hardwareAssignedType) {
    const hardware = getHardware(station, inputDef);
    return hardware?.hardwareIos[inputDef.ioIndex - 1];
  }

  return inputDef.input;
}
