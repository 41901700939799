import Controller from '@ember/controller';
import QueryParams from 'eflex/constants/bi-query-params';
import STATION_FILTERS from 'eflex/constants/bi-andon-station-filters';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { andonReports } from 'eflex/constants/bi-report-options';
export default class BiProductionController extends Controller {
  @tracked queryParams = [...Object.keys(QueryParams), 'stationFilter'];

  stationFilterOptions = Object.values(STATION_FILTERS);

  @tracked stationFilter = STATION_FILTERS.all;
  @tracked drawerCollapsed = false;
  @tracked stationIds = [];
  @tracked refresher = false;
  selectedReport = andonReports.stationSummary;

  get reportOptions() {
    return Object.values(andonReports);
  }

  @action
  resetCriteria() {
    Object.assign(this, {
      stationIds: [],
      stationFilter: STATION_FILTERS.all,
    });
  }

  @action
  refresh() {
    this.refresher = true;
  }
}
