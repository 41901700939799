import Model, { attr, belongsTo } from '@ember-data/model';

export default class KineticEmployee extends Model {
  @attr('string') employeeId;
  @attr('string') supervisorEmployeeId;
  @attr('string') name;
  @attr('date') clockInTime;

  @belongsTo('kineticShift', { async: false, inverse: null }) defaultShift;
  @belongsTo('kineticShift', { async: false, inverse: null }) currentShift;
}
