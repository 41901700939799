import Service, { service } from '@ember/service';
import moment from 'moment-timezone';

export default class ScheduleRepoService extends Service {
  @service store;

  schedules = this.store.peekAll('schedule');

  createSchedule() {
    const schedule = this.store.createRecord('schedule', {});

    [...Array.from({ length: 7 }).keys()].forEach((day) => {
      this.store.createRecord('scheduleDay', {
        schedule,
        day,
        startHours: null,
        startMinutes: null,
        endHours: null,
        endMinutes: null,
      });
    });

    return schedule;
  }

  deleteSchedule(schedule) {
    schedule.deleteRecord();
  }

  archiveSchedule(schedule) {
    schedule.endDate = new Date();
  }

  updateDate(schedule, date, isEnd = false) {
    if (isEnd) {
      schedule.endDate = moment(date).endOf('day').toDate();
    } else {
      schedule.startDate = moment(date).startOf('day').toDate();
    }
  }

}
