import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';

export default class StatusRoute extends AuthenticatedRoute {
  @service store;

  model() {
    return this.store.findAll('serverStatus');
  }

  setupController(controller, serverStatuses) {
    controller.serverStatuses = serverStatuses;
  }
}
