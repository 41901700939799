import Service, { service } from '@ember/service';
import { hardwareTypes } from 'eflex/constants/hardware-types';
import { bomSourceModelNames } from 'eflex/constants/bom-source-types';
import { capitalize } from '@ember/string';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { updateFromWebSocket, deleteFromWebSocket } from 'eflex/util/websocket-helpers';

const UPDATE_TREE_ITEM_EVENT_TYPES = new Set([
  'station',
  'task',
  'kineticPart',
  'kineticPartRev',
  'kineticOperation',
]);
const DELETE_TREE_ITEM_EVENT_TYPES = new Set([
  'area',
  'group',
  'station',
  'task',
  'kineticPart',
  'kineticPartRev',
  'kineticOperation',
]);

const LISTENED_TYPES = [
  'area',
  'group',
  'station',
  'task',
  'taskConfig',
  'kineticPart',
  'kineticPartRev',
  'kineticOperation',
  'kineticShift',
  'model',
  'hardwareIo',
  'productionSchedule',
  'systemConfiguration',
  'workInstruction', // only deletes are published
  ...Object.keys(hardwareTypes),
  ...bomSourceModelNames,
  'configurationHistory',
];

export default class TreeUpdateListenerService extends Service {
  @service store;
  @service webSocket;
  @service eventBus;

  _updateStationFromWebSocket = task(waitFor(async (station) => {
    if (station.currentInstructionTriggerConfig) {
      const workInstructionHardwareTriggerConfig = this.store.push(
        this.store.normalize(
          'workInstructionHardwareTriggerConfig',
          station.currentInstructionTriggerConfig,
        ),
      );
      station.currentInstructionTriggerConfig = station.currentInstructionTriggerConfig.id;

      await workInstructionHardwareTriggerConfig.belongsTo('workInstruction').load();
    }

    this._updateFromWebSocket('station', station);
  }));

  start() {
    for (const type of LISTENED_TYPES) {
      if (type === 'station') {
        this.webSocket.addListener('updatedStation', json => {
          this._updateStationFromWebSocket.perform(json);
        });

      } else {
        this.webSocket.addListener(`updated${capitalize(type)}`, json => {
          this._updateFromWebSocket(type, json);
        });
      }

      this.webSocket.addListener(`deleted${capitalize(type)}`, json => {
        this._deleteFromWebSocket(type, json);
      });
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    LISTENED_TYPES.forEach((type) => {
      const capitalizedType = capitalize(type);
      this.webSocket.removeListener(`updated${capitalizedType}`);
      this.webSocket.removeListener(`deleted${capitalizedType}`);
    });
  }

  _updateFromWebSocket(type, treeItem) {
    const record = updateFromWebSocket(type, this.store, treeItem);

    if (UPDATE_TREE_ITEM_EVENT_TYPES.has(type)) {
      this.eventBus.trigger('updatedTreeItem', record);
    }
  }

  _deleteFromWebSocket(type, id) {
    deleteFromWebSocket(type, this.store, id, (deleted) => {
      if (DELETE_TREE_ITEM_EVENT_TYPES.has(type)) {
        this.eventBus.trigger('deletedTreeItem', deleted);
      }
    });
  }
}
