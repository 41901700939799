import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class EvisionNonConformingController extends Controller {
  queryParams = ['searchTerm', 'beginDate', 'endDate'];

  @service eflexAjax;
  @service currentUser;

  @tracked selectAll = false;
  @tracked model = new TrackedArray();
  @tracked currentImage;
  @tracked showImageModal = false;
  @tracked showDeleteModal = false;
  @tracked searchTerm;
  @tracked beginDate;
  @tracked endDate;

  get selectedImages() {
    return this.model.filter(item => item.isSelected);
  }

  get selectedImageIds() {
    return this.selectedImages.map(item => item.id);
  }

  get someSelected() {
    return this.model
      ?.filter(item => item.isDeleted === false)
      .some(item => item.isSelected);
  }

  deleteImages = task(waitFor(async (params = {}) => {
    if (!this.someSelected) {
      this.showDeleteModal = false;
      return;
    }

    await this.eflexAjax.post.perform('evision/images/delete', {
      ...params,
      nonConforming: true,
      selectedImages: this.selectedImageIds,
    });

    this.showDeleteModal = false;
    this.send('refresh', params);
  }));

  @action
  onSelectAll(selectAll) {
    this.selectAll = selectAll;

    this.model
      ?.filter(item => item.isDeleted === false)
      .forEach((image) => {
        image.isSelected = selectAll;
      });
  }

  @action
  setSelected(image, isSelected) {
    image.isSelected = isSelected;

    if (!isSelected) {
      this.selectAll = false;
    }
  }

  @action
  showImage(image) {
    this.currentImage = image;
    this.showImageModal = true;
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
