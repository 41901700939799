import { service } from '@ember/service';
import LocationRepoBase from 'eflex/services/location-repo-base';
import { all, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { without, clone } from 'ramda';
import { updateLocationPaths, recursiveSave } from 'eflex/util/tree-helpers';
import { removeObject } from 'eflex/util/array-helpers';
import { getJemConfig } from 'eflex/services/location-repo';
import { copyTaskConfig } from 'eflex/services/task-config-repo';

const COPY_PREREQ_IGNORED_RELATIONSHIPS = new Set(['station', 'configModel', 'configOption']);

const copyVariableDefs = (variableDefs, station, stationCopy) => {
  return variableDefs.map(variableDef => {
    const variableDefCopy = variableDef.copy(true);

    if (variableDef.variableDefForValue) {
      const taskIndex = station.tasks.indexOf(variableDef.variableDefForValue.task);

      if (taskIndex !== -1) {
        const variableDefIndex = station
          .tasks[taskIndex]
          .variableDefs
          .indexOf(variableDef.variableDefForValue);

        variableDefCopy.variableDefForValue = stationCopy
          .tasks[taskIndex]
          .variableDefs[variableDefIndex];
      }
    }

    return variableDefCopy;
  });
};

const copyEdhrMappings = (edhrMappings, station, stationCopy) => {
  return edhrMappings.map(edhrMapping => {
    const edhrMappingCopy = edhrMapping.copy(true);

    if (edhrMapping.dataTask) {
      edhrMappingCopy.dataTask = stationCopy.tasks[station.tasks.indexOf(edhrMapping.dataTask)];
    }

    if (edhrMapping.variableDef) {
      const taskIndex = station.tasks.indexOf(edhrMapping.variableDef.task);

      if (taskIndex !== -1) {
        const variableDefIndex = station.tasks[taskIndex].variableDefs.indexOf(edhrMapping.variableDef);

        edhrMappingCopy.variableDef = stationCopy
          .tasks[taskIndex]
          .variableDefs[variableDefIndex];
      }
    }

    return edhrMappingCopy;
  });
};

export default class StationRepoService extends LocationRepoBase {
  @service licensing;
  @service eflexAjax;
  @service locationRepo;
  @service notifier;
  @service taskConfigRepo;
  @service configurationHistoryRepo;
  @service systemConfig;

  stations = this.store.peekAll('station');

  createAndSave = task(waitFor(async (properties = {}) => {
    const station = this.create(properties);

    await station.save();
    if (this.systemConfig.jem?.configurationHistory) {
      await this.configurationHistoryRepo.createNewVersion.perform(station, 'Created Station');
    }
    await this._recreateBuildData.perform(station);
    return station;
  }));

  move = task(waitFor(async (dropped, droppedOn, source) => {
    if (dropped.isSelfOrChildInvalid) {
      this.validationErrorNotifier.sendErrors([dropped]);
      return;
    }

    const loadTaskConfigPromises = [this.taskConfigRepo.loadTaskConfigs.perform(dropped)];

    await all(loadTaskConfigPromises);

    const droppedOnParent = droppedOn.parent;
    let newParent;

    if (dropped.type === droppedOn.type) {
      newParent = droppedOnParent;
    } else if (dropped.type === droppedOn.childType) {
      newParent = droppedOn;
    }

    const droppedParent = dropped.parent;
    dropped.parent = newParent;
    const parentChildren = newParent.stations;
    removeObject(droppedParent.stations, dropped);

    if (droppedParent === droppedOnParent) {
      const index = parentChildren.indexOf(droppedOn) + 1;
      parentChildren.splice(index, 0, dropped);
    } else if (droppedParent === droppedOn) {
      parentChildren.splice(0, 0, dropped);
    } else {
      parentChildren.push(dropped);
    }

    if (droppedParent === newParent) {
      this._reorder(droppedParent);
      await recursiveSave(droppedParent);
    } else {
      updateLocationPaths(dropped);
      this._reorder(droppedParent);
      this._reorder(newParent);

      const savedStations = droppedParent
        .stations
        .filter(item => item.isDirty)
        .concat(newParent.stations.filter(item => item.isDirty));

      const savedTasks = savedStations.flatMap(station => station.tasks).filter(item => item.isDirty);

      await this.locationRepo.bulkSave.perform({
        source,
        stations: savedStations,
        tasks: savedTasks,
        taskConfigs: savedTasks.flatMap(_task => _task.taskConfigs).filter(item => item.isDirty),
      });
    }

    await this._recreateBuildData.perform(dropped);
  }));

  _recreateBuildData = task(waitFor(async station => {
    if (station.usesModels || station.usesOperations) {
      return;
    }

    await all([this.eflexAjax.post.perform('buildData/station', { stationId: station.id })]);
  }));

  create(properties = {}) {
    const { area } = properties.parent;

    Object.assign(properties, {
      usesComponents: area.usesComponents && this.licensing.license?.componentBasedEnabled,
      usesOperations: area.usesOperations ?? false,
      oee: area.oeeEnabled && this.licensing.license?.oeeEnabled,
    });

    const station = super.create('station', properties);

    this.store.createRecord('stationLoadJemConfiguration', { station });

    if (station.usesComponents) {
      const alwaysRunOption = station.area.alwaysRunComponent?.options?.[0];
      this.createJemPrerequisite(station, alwaysRunOption, true);
      this.createJemConfig(station);
    } else {
      station.area.models.forEach(model => {
        this.createJemConfig(station, model);
        this.createJemPrerequisite(station, model);
      });
    }

    return station;
  }

  createJemConfig(station, model = null) {
    return (
      getJemConfig(station.jemConfigurations, model) ??
      this.store.createRecord('stationJemConfiguration', {
        model,
        station,
      })
    );
  }

  getJemPrerequisite(station, context) {
    if (station.usesModels) {
      return station.jemPrerequisites.find(prereq => prereq.configModel === context);
    } else {
      return station.jemPrerequisites.find(prereq => prereq.configOption === context);
    }
  }

  createJemPrerequisite(station, context, enabled = false) {
    if (this.getJemPrerequisite(station, context)) {
      return;
    }

    if (station.usesModels) {
      this.store.createRecord('jemPrerequisite', {
        configModel: context,
        station,
        enabled,
      });
    } else {
      this.store.createRecord('jemPrerequisite', {
        configOption: context,
        station,
        enabled,
      });
    }
  }

  copyJemPrereqToAllContexts = (sourcePrereq) => {
    if (sourcePrereq == null) {
      return;
    }

    const destinationPrereqs = without([sourcePrereq], sourcePrereq.station.jemPrerequisites);

    destinationPrereqs.forEach((prereq) => {
      if (prereq.isDeleted) {
        return;
      }

      prereq.eachAttribute(attr => {
        prereq[attr] = clone(sourcePrereq[attr]);
      });

      prereq.eachRelationship(relationship => {
        if (COPY_PREREQ_IGNORED_RELATIONSHIPS.has(relationship)) {
          return;
        }

        prereq[relationship] = sourcePrereq[relationship];
      });
    });
  };

  copyStationJemCycleToAllContexts = (sourceConfig) => {
    if (sourceConfig == null) {
      return;
    }

    const destinationConfigs = without([sourceConfig], sourceConfig.station.stationJemConfigurations);

    destinationConfigs.forEach((config) => {
      if (config.isDeleted) {
        return;
      }

      Object.assign(config, {
        targetCycleTime: sourceConfig.targetCycleTime,
        jemWarningPercent: sourceConfig.jemWarningPercent,
      });
    });
  };

  createJemConfigTab(station) {
    return this.store.createRecord('customTabJemConfiguration', { station });
  }

  copyStation(station, options = {}) {
    const stationCopy = station.copy(true, options);
    updateLocationPaths(stationCopy);

    station.tasks.forEach(treeTask => {
      const taskCopy = treeTask.copy(true);
      taskCopy.parent = stationCopy;
      updateLocationPaths(taskCopy);

      if (treeTask.edhrMappings) {
        taskCopy.edhrMappings = copyEdhrMappings(treeTask.edhrMappings, station, stationCopy);
      }

      treeTask.triggers.forEach(trigger => {
        const triggerCopy = trigger.copy(true);
        triggerCopy.task = taskCopy;

        if (trigger.edhrMappings) {
          triggerCopy.edhrMappings = copyEdhrMappings(trigger.edhrMappings, station, stationCopy);
        }

        if (trigger.variableDefs) {
          triggerCopy.variableDefs = copyVariableDefs(trigger.variableDefs, station, stationCopy);
        }
      });

      treeTask.taskConfigs.forEach(taskConfig => {
        copyTaskConfig(taskConfig, taskCopy);
      });

    });

    station.triggers.forEach((trigger) => {
      const triggerCopy = trigger.copy(true);
      triggerCopy.station = stationCopy;

      if (trigger.variableDefs) {
        triggerCopy.variableDefs = copyVariableDefs(trigger.variableDefs, station, stationCopy);
      }

      trigger.triggerConfigs.forEach(triggerConfig => {
        const triggerConfigCopy = triggerConfig.copy(true);
        triggerConfigCopy.parentTrigger = triggerCopy;
        triggerConfigCopy.station = stationCopy;
      });
    });

    return stationCopy;
  }
}
