import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { TrackedArray } from 'tracked-built-ins';

export default class LogsController extends Controller {
  queryParams = ['searchTerm', 'beginDate', 'endDate', 'logLevel'];

  @tracked selectAll = false;
  @tracked model = new TrackedArray();
  @tracked showAcknowledgeModal = false;
  @tracked searchTerm;
  @tracked beginDate;
  @tracked endDate;
  @tracked logLevel;
  @tracked includeInactive;

  @service currentUser;
  @service notifier;

  get activeLogs() {
    return this.model.filter(item => item.isActive);
  }

  get selectedLogs() {
    return this.activeLogs.filter(item => item.isSelected);
  }

  @action
  onSelectAll(selectAll) {
    this.selectAll = selectAll;

    this.model
      ?.filter(item => item.isDeleted === false)
      .forEach(log => { log.isSelected = selectAll; });
  }

  @action
  setSelected(log, isSelected) {
    log.isSelected = isSelected;

    if (!isSelected) {
      this.selectAll = false;
    }
  }

  @action
  acknowledgeSelected() {
    if (isEmpty(this.selectedLogs)) {
      this.notifier.sendWarning('logs.noLogsSelected');
    } else if (isEmpty(this.model)) {
      this.notifier.sendWarning('logs.noLogs');
    } else {
      this.showAcknowledgeModal = true;
    }
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
