
import Service, { service } from '@ember/service';
import { EJSON, ObjectId } from 'bson';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import { includes } from 'ramda';
import { waitFor } from '@ember/test-waiters';

export default class QueryRunnerService extends Service {
  @service eflexAjax;
  @service store;

  runQuery = task(waitFor(async (collection, query, returnCount = false) => {
    return await this.eflexAjax.post.perform('queries', {
      collection,
      query: EJSON.stringify(query),
      returnCount,
    });
  }));

  queryWithParams = task(waitFor(async (params = {}, query = [], isOee = false) => {
    let matches = [];
    let startField, endField, stationField;
    const scheduleField = 'station';

    if (isOee) {
      startField = 'startDate';
      endField = 'endDate';
    } else {
      startField = 'timestamp';
      endField = 'timestamp';
    }

    if (isOee) {
      stationField = 'station._id';
    } else {
      stationField = 'location._id';
    }

    matches = matches.concat(await this.buildQueryMatches.perform(
      startField,
      endField,
      stationField,
      scheduleField,
      params,
    ));

    if (!isBlank(params.modelIds)) {
      matches.push({
        $match: {
          'model._id': {
            $in: params.modelIds.map((modelId) => new ObjectId(modelId)),
          },
        },
      });
    }

    if (!isBlank(params.taskId)) {
      matches.push({
        $match: {
          'children.location._id': new ObjectId(params.taskId),
        },
      });
    }

    if (!isBlank(params.tags)) {
      matches.push({
        $match: {
          'children.location.tags': {
            $in: params.tags,
          },
        },
      });
    }

    if (!isBlank(params.userIds)) {
      matches.push({
        $match: {
          userId: {
            $in: params.userIds.map((userId) => new ObjectId(userId)),
          },
        },
      });
    }

    if (!isBlank(params.states)) {
      const states = params.states.map((state) => {
        return state.toLowerCase();
      });
      matches.push({
        $match: {
          state: { $in: states },
        },
      });
    }

    return [...matches, ...query];
  }));

  queryScheduleHistoryWithParams = task(waitFor(async (params = {}, query = []) => {
    let matches = [];

    const startField = 'startDate';
    const endField = 'endDate';
    const stationField = 'station';
    const scheduleField = 'text';

    matches = matches.concat(await this.buildQueryMatches.perform(
      startField,
      endField,
      stationField,
      scheduleField,
      params,
    ));

    return [...matches, ...query];
  }));

  buildQueryMatches = task(waitFor(async (startField, endField, stationField, scheduleField, params = {}) => {
    const matches = [];

    if (!isBlank(params.startDate)) {
      matches.push({
        $match: {
          [startField]: { $gte: new Date(params.startDate) },
        },
      });
    }

    if (!isBlank(params.endDate)) {
      matches.push({
        $match: {
          [endField]: { $lte: new Date(params.endDate) },
        },
      });
    }

    if (!isBlank(params.shiftNames)) {
      const scheduleHistories = await this.runQuery.perform(
        'ScheduleHistories',
        this._queryScheduleHistories(params),
      );

      if (!isBlank(scheduleHistories)) {
        if (scheduleField !== 'station') {
          matches.push({
            $match: {
              $or: scheduleHistories.map((shift) => {
                return { text: shift.text };
              }),
            },
          });
        } else {
          matches.push({
            $match: {
              $or: scheduleHistories.map((shift) => {
                return {
                  $and: [
                    { [startField]: { $gte: new Date(shift.startDate) } },
                    { [endField]: { $lte: new Date(shift.endDate) } },
                    { [stationField]: new ObjectId(shift.station) },
                  ],
                };
              }),
            },
          });
        }
      } else {
        return [
          { $match: {
            _id: null,
          } },
        ];
      }
    }

    if (!isBlank(params.stationIds)) {
      matches.push({
        $match: {
          [stationField]: {
            $in: params.stationIds.map((stationId) => new ObjectId(stationId)),
          },
        },
      });
    }

    if (!isBlank(params.stationId)) {
      matches.push({
        $match: {
          [stationField]: new ObjectId(params.stationId),
        },
      });
    }

    return matches;
  }));

  // jscpd:ignore-start
  _queryScheduleHistories(params = {}) {
    const [shiftIds, shiftNames] = this._peekSchedules(params);

    return [
      {
        $match: {
          startDate: { $gte: new Date(params.startDate) },
          endDate: { $lte: new Date(params.endDate) },
          $or: [{ text: { $in: shiftNames } }, { schedule: { $in: shiftIds } }],
        },
      },
    ];
  }
  // jscpd:ignore-end

  _querySchedules(params = {}) {
    const [shiftIds, shiftNames] = this._peekSchedules(params);

    return [
      {
        $match: {
          startDate: { $gte: new Date(params.startDate) },
          endDate: { $lte: new Date(params.endDate) },
          $or: [{ text: { $in: shiftNames } }, { schedule: { $in: shiftIds } }],
        },
      },
    ];
  }

  _peekSchedules(params = {}) {
    const shiftIds = [];
    const shiftNames = [];

    this.store.peekAll('schedule').forEach((shift) => {
      if (includes(shift?.text, params?.shiftNames)) {
        shiftIds.push(new ObjectId(shift._id));
        shiftNames.push(shift.text);
      }
    });

    return [shiftIds, shiftNames];
  }
}
