import { template as template_8852c1720a3c41f4b2d8143cc34e2941 } from "@ember/template-compiler";
import { uniqueId } from '@ember/helper';
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
export default class FileInput extends Component {
    onChange = ({ target })=>{
        this.args.onChange(target.files, ()=>{
            if (target) {
                target.value = '';
            }
        });
    };
    static{
        template_8852c1720a3c41f4b2d8143cc34e2941(`
    <span
      class="
        component-file-input
        x-file-input
        {{if @disabled "x-file-input--disabled"}}
      "
    >
      {{#let (uniqueId) as |uid|}}
        <input
          type="file"
          id={{uid}}
          class="x-file--input"
          disabled={{@disabled}}
          {{on "change" this.onChange}}
          ...attributes
        >
        <label for={{uid}}>
          {{yield}}
        </label>
      {{/let}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
