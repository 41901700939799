import TaskParent from 'eflex/models/task-parent';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import validateTaskSequence from 'eflex/util/validate-task-sequence';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { clone } from 'ramda';

@buildValidations({
  name: [validator('presence', true)],
  taskSequence: [
    validator('inline', { validate: validateTaskSequence }),
  ],
})
class KineticOperation extends TaskParent {
  @attr('string', { defaultValue: 'kineticOperation' }) type;
  @attr('number', { defaultValue: 0 }) sequence;
  @attr('string') operationCode;
  @attr('string') operationCodeDescription;
  @attr('string') path;
  @attr('string') sourceJobNumber;
  @attr('boolean', { defaultValue: false }) kineticDeleted;

  @belongsTo('kineticPartRev', { inverse: 'operations', async: false }) parent;
  @hasMany('station', { inverse: null, async: false }) stations;

  get isFromBom() {
    return isEmpty(this.sourceJobNumber);
  }

  get order() {
    return this.sequence;
  }

  get part() {
    return this.partRev?.part;
  }

  get partRev() {
    return this.parent;
  }

  get hasTasks() {
    return !isEmpty(this.children);
  }

  asHistory() {
    return {
      id: this.id,
      type: 'kineticOperation',
      captions: clone(this.captions),
    };
  }
}

export default KineticOperation;
