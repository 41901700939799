import { service } from '@ember/service';
import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class PartsBuildHistoryImagesController extends Controller {
  @service zipImages;

  @tracked currentImage;
  @tracked showCarouselModal = false;

  @controller('parts/build-history') buildHistoryController;

  get imagesByStation() {
    const result = {};
    this.buildHistoryController.images?.forEach(function (image) {
      const stationId = image.station?.id;

      result[stationId] ??= {
        name: image.stationName,
        images: [],
      };

      result[stationId].images.push(image);
    });

    return result;
  }

  @action
  export() {
    this.zipImages.zip({
      selectedImageIds: this.buildHistoryController.images.filter(item => item.isSelected).map(item => item.id),
    });
  }

  @action
  onShowCarouselModal(image) {
    Object.assign(this, {
      currentImage: image,
      showCarouselModal: true,
    });
  }
}
