import pProps from 'p-props';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';

export default class SettingsBackupRoute extends AuthenticatedRoute {
  @service store;

  model() {
    return pProps({
      backupConfigs: this.store.findAll('backupConfig'),
      history: this.store.query('backupHistory', {
        skip: 0,
        take: 30,
      }),
    });
  }

  setupController(controller, { backupConfigs, history }) {
    Object.assign(controller, {
      full: backupConfigs.find(item => item.type === 'full'),
      config: backupConfigs.find(item => item.type === 'config'),
      history: [...history],
    });
  }
}
