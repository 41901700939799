import Service, { service } from '@ember/service';

export default class UserRepo extends Service {
  @service store;

  users = this.store.peekAll('user');

  get nonDeletedUsers() {
    return this.users.filter(item => !item.isDeleted);
  }

  getWieTags(type) {
    return [...new Set(this.nonDeletedUsers.flatMap(user => user[type]))].toSorted();
  }
}
