import { template as template_48ebd9937aa44f6f9c2a133d04aaddd1 } from "@ember/template-compiler";
import { AnimationTypeValues } from 'eflex/constants/jem/instruction-animation-types';
import { AnimationEventValues } from 'eflex/constants/jem/instruction-animation-events';
import { reject } from 'ramda';
import BsForm from 'eflex/components/bs-form';
import { serviceHelper as service, inputVal, selectVal } from 'eflex/helpers';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { eq, or } from 'ember-truth-helpers';
import TrashCan from 'eflex/components/trash-can';
import AddButton from 'eflex/components/add-button';
const getCurrentAnimations = (animations, boltCount)=>{
    return reject((animation)=>animation.isDeleted || (animation.bolt !== 'all' && Number.parseInt(animation.bolt) > Number.parseInt(boltCount)))(animations);
};
const addAnimation = (animations, instructionObjects, bolts, store)=>{
    const animation = store.createRecord('workInstructionAnimation', {
        object: instructionObjects[0].id,
        event: AnimationEventValues[0],
        animation: AnimationTypeValues[0],
        bolt: bolts[0].value
    });
    animations.push(animation);
};
const getInstructionObjects = (workInstruction)=>{
    return workInstruction.getDynamicObjectNames();
};
const deleteAnimation = (animation)=>{
    animation.deleteRecord();
};
const setAnimationObject = (animationConfig, object)=>{
    animationConfig.object = object?.id;
};
const toggleColors = (animationConfig, property, checked)=>{
    animationConfig[property] = checked ? '#000000' : null;
};
const getBolts = (boltCount, intl)=>{
    const bolts = [
        {
            label: intl.t('jem.animations.allBolts'),
            value: 'all'
        }
    ];
    for(let i = 1; i <= boltCount; i++){
        bolts.push({
            label: `${intl.t('bolt')} ${i}`,
            value: `${i}`
        });
    }
    return bolts;
};
export default template_48ebd9937aa44f6f9c2a133d04aaddd1(`
<div class="component-jem-instructions-animation" ...attributes>
  <div class="bootstrap-table animation-config">
    <BsForm @model={{this}} as |form|>
      <div class="row form-row header-row">
        <div class="col-auto px-1 border-0">
          <div class="play-col"></div>
        </div>
        <div title={{t "objectName"}} class="col-3">
          {{t "objectName"}}
        </div>
        <div title={{t "fastener"}} class="col-2">
          {{t "fastener"}}
        </div>
        <div title={{t "event"}} class="col-2">
          {{t "event"}}
        </div>
        <div title={{t "animation"}} class="col-2">
          {{t "animation"}}
        </div>
        <div title={{t "color"}} class="col">
          <div class="color-col">
            {{t "color"}}
            <span class="subheader">
              ({{t "imageEditor.fill"}}/{{t "imageEditor.stroke"}})
            </span>
          </div>
        </div>
      </div>
      {{#let
        (getInstructionObjects @triggerConfig.workInstruction)
        (getBolts @boltCount (service 'intl'))
      as |instructionObjects bolts|}}
        <div class="animation-row-wrapper">
          {{#each (getCurrentAnimations @triggerConfig.animations @boltCount) as |animationConfig|}}
            <div class="d-flex animation-row row form-row">
              <div class="col-auto px-1">
                <div class="play-col">
                  <div
                    class="icon icon-play"
                    {{on "click" (fn @playAnimation animationConfig)}}
                  ></div>
                </div>
              </div>
              <div class="col-3">
                <div class="object-col">
                  <form.element
                    class="animation-instruction-object model-specific"
                    @controlType="power-select"
                    @options={{instructionObjects}}
                    @optionLabelPath="name"
                    @onChange={{fn setAnimationObject animationConfig}}
                    @value={{findBy "id" animationConfig.object instructionObjects}}
                  as |el|>
                    <el.control
                      @disabled={{@disabled}}
                      @triggerClass="form-control form-control-sm"
                    />
                  </form.element>
                </div>
              </div>
              <div class="col-2">
                <div class="bolt-col">
                  <form.element
                    class="animation-bolt model-specific"
                    @controlType="power-select"
                    @options={{bolts}}
                    @optionLabelPath="label"
                    @onChange={{selectVal (fn (mut animationConfig.bolt))}}
                    @value={{findBy "value" animationConfig.bolt bolts}}
                  as |el|>
                    <el.control
                      @disabled={{@disabled}}
                      @triggerClass="form-control form-control-sm"
                    />
                  </form.element>
                </div>
              </div>
              <div class="col-2">
                <form.element
                  class="animation-event model-specific"
                  @controlType="power-select"
                  @options={{AnimationEventValues}}
                  @model={{animationConfig}}
                  @property="event"
                as |el|>
                  <el.control
                    @disabled={{@disabled}}
                    @triggerClass="form-control form-control-sm" as |boltEvent|
                  >
                    {{t boltEvent}}
                  </el.control>
                </form.element>
              </div>
              <div class="col-2">
                <div class="animation-col">
                  <form.element
                    class="animation-type model-specific"
                    @controlType="power-select"
                    @options={{AnimationTypeValues}}
                    @model={{animationConfig}}
                    @property="animation"
                  as |el|>
                    <el.control
                      @disabled={{@disabled}}
                      @triggerClass="form-control form-control-sm" as |animation|
                    >
                      {{t animation}}
                    </el.control>
                  </form.element>
                </div>
              </div>
              <div class="col">
                <div class="color-col">
                  {{#let (findBy "id" animationConfig.object instructionObjects) as |currentObject|}}
                    <div class="animation-fill-color d-flex align-items-center mb-1">
                      {{#let
                        (or
                          @disabled
                          (or
                            (eq currentObject.type "image")
                            (eq currentObject.type "straight-line")
                            (eq animationConfig.animation "reset")
                          )
                        )
                      as |fillDisabled|}}
                        <form.element
                          @controlType="checkbox"
                          @value={{animationConfig.fillColor}}
                          @label={{t "imageEditor.fill"}}
                          @onChange={{fn toggleColors animationConfig 'fillColor'}}
                        as |el|>
                          <el.control @disabled={{fillDisabled}} />
                        </form.element>

                        {{#if animationConfig.fillColor}}
                          <input
                            type="color"
                            class="square-color-picker ms-auto"
                            value={{animationConfig.fillColor}}
                            disabled={{fillDisabled}}
                            {{on "input" (inputVal (fn (mut animationConfig.fillColor)))}}
                          />
                        {{else}}
                          <div class="square-color-picker ms-auto border not-allowed"/>
                        {{/if}}
                      {{/let}}
                    </div>

                    <div class="animation-stroke-color d-flex align-items-center">
                      {{#let (or @disabled (or
                        (eq currentObject.type "image")
                        (eq currentObject.type "icon")
                        (eq animationConfig.animation "reset")
                      )) as |strokeDisabled|}}
                        <form.element
                          @controlType="checkbox"
                          @value={{animationConfig.strokeColor}}
                          @label={{t "imageEditor.stroke"}}
                          @onChange={{fn toggleColors animationConfig 'strokeColor'}}
                        as |el|>
                          <el.control @disabled={{strokeDisabled}} />
                        </form.element>

                        {{#if animationConfig.strokeColor}}
                          <input
                            type="color"
                            class="square-color-picker ms-auto"
                            value={{animationConfig.strokeColor}}
                            disabled={{strokeDisabled}}
                            {{on "input" (inputVal (fn (mut animationConfig.strokeColor)))}}
                          />
                        {{else}}
                          <div class="square-color-picker ms-auto border not-allowed"/>
                        {{/if}}
                      {{/let}}
                    </div>
                  {{/let}}
                </div>
              </div>
              <div class="col-auto px-1">
                <div class="trash-col">
                  <TrashCan
                    disabled={{@disabled}}
                    @onDelete={{fn deleteAnimation animationConfig}}
                  />
                </div>
              </div>
            </div>
          {{/each}}
        </div>
        {{#unless @disabled}}
          <div class="row form-row no-hover-row py-0">
            <div class="col-auto">
              <AddButton
                class="add-animation mb-1"
                @onClick={{
                  fn
                  addAnimation
                  @triggerConfig.animations
                  instructionObjects
                  bolts
                  (service 'store')
                }}
              >
                {{t "addAnimation"}}
              </AddButton>
            </div>
          </div>
        {{/unless}}
      {{/let}}
    </BsForm>
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
