import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { arrayAttr } from 'eflex/decorators';

@buildValidations({
  fieldSeparator: [validator('presence', true)],
})
class NamingConvention extends Model {
  @arrayAttr selectedOptions;
  @arrayAttr defaultOptions;
  @attr('string') fieldSeparator;
}

export default NamingConvention;
