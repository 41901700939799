import Model, { attr } from '@ember-data/model';

export default class IntegrationBase extends Model {
  @attr('string') _id;
  @attr('string') EventType;
  @attr Data;
  @attr('number') Priority;
  @attr('string', { defaultValue: '' }) Scope;
  @attr('number', { defaultValue: 0 }) Retries;
  @attr('date', { defaultValue: null }) ProcessedDateTime;
  @attr('number', { defaultValue: 0 }) Status;
  @attr('string', { defaultValue: null }) Consumer;
  @attr('date', { defaultValue: null }) ConsumerExpiresDateTime;
  @attr('string') CorrelationId;
  @attr('string', { defaultValue: '' }) Key;
  @attr('boolean', { defaultValue: false }) Archived;
  @attr('date', { defaultValue: null }) NextRunDateTime;
  @attr('date') CreatedDateTime;
}
