import { attr } from '@ember-data/model';
import BomSource from 'eflex/models/bom-source';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { sourceValidations } from 'eflex/util/bom-source-validations';

@buildValidations({
  ...sourceValidations,

  url: [validator('presence', { presence: true })],
})
class RestPullBomSource extends BomSource {
  @attr('string') source;
  @attr('string') url;

  get isRestPull() {
    return true;
  }

  get typeName() {
    return 'RESTPull';
  }
}

export default RestPullBomSource;
