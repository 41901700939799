import { template as template_e2f18720df4143f79abefdde7b3d3c43 } from "@ember/template-compiler";
import PowerSelect from 'eflex/components/power-select';
const LEVELS = [
    'Month',
    'Week',
    'Day',
    'Hour'
];
export default template_e2f18720df4143f79abefdde7b3d3c43(`
<div class="component-bi-dropdown">
  <PowerSelect
    @options={{LEVELS}}
    @selected={{@selectedLevel}}
    @onChange={{@onSelectedLevel}}
  as |level|>
    {{level}}
  </PowerSelect>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
