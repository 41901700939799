import { template as template_3ab553f20135409b8229923e16736212 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task, all, waitForProperty } from 'ember-concurrency';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { PERMISSION_NAMES } from 'eflex/constants/user-permissions';
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import Breadcrumbs from 'eflex/components/breadcrumbs';
import SaveButtons from 'eflex/components/save-buttons';
import ButtonPowerSelect from 'eflex/components/button-power-select';
import ConfigTable from 'eflex/components/config-table';
import ConfigButton from 'eflex/components/config-button';
import LinkPopover from 'eflex/components/link-popover';
import TrashCan from 'eflex/components/trash-can';
import StandardModal from 'eflex/components/standard-modal';
import ConfigurationHistoryNotesModal from 'eflex/components/modals/configuration-history-notes';
import HardwarePanel from 'eflex/components/panels/hardware';
import InputsLiveSearch from 'eflex/components/inputs/search-live';
import { or, eq, not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import { isBlank, isPresent } from '@ember/utils';
import { filterBy, rejectBy } from '@eflexsystems/ember-composable-helpers';
import { chain, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';
const getDisplayedHardware = (nonDeletedHardwares, searchFilter)=>{
    if (isBlank(searchFilter)) {
        return nonDeletedHardwares;
    }
    searchFilter = searchFilter.toLowerCase();
    return nonDeletedHardwares.filter((hardware)=>{
        return hardware.displayName.toLowerCase().includes(searchFilter);
    });
};
class HardwareTemplate extends Component {
    @service
    validationErrorNotifier;
    @service
    hardwareRepo;
    @service
    currentUser;
    @service
    systemConfig;
    @service
    taskRepo;
    @service
    stationRepo;
    @service
    hardwareInputDefRepo;
    @tracked
    hardwareToDelete;
    @tracked
    searchFilter;
    @tracked
    selectedHardware;
    @tracked
    locationsToUpdateVersion = [];
    @tracked
    hideTransitionModal = false;
    @tracked
    showConfigurationHistoryNotesModal = false;
    @tracked
    showHardwarePanel = false;
    getAssignedStations = (hardware)=>{
        const hardwareIos = hardware.hardwareIos;
        const { taskRepo, stationRepo, hardwareInputDefRepo } = this;
        const tasks = taskRepo.tasks;
        return new Set([
            ...hardware.tasks.map((item)=>item.parent),
            ...stationRepo.stations.filter((item)=>item.webCam === hardware),
            ...intoArray(chain((hardwareIo)=>[
                    tasks.find((treeTask)=>treeTask.hardwareOutputs.includes(hardwareIo))?.parent,
                    tasks.find((item)=>item.hardwareInput === hardwareIo)?.parent,
                    hardwareInputDefRepo.hardwareInputDefs.find((item)=>item.input === hardwareIo)?.task?.parent
                ]), compact)(hardwareIos)
        ]);
    };
    save = task({
        drop: true
    }, waitFor(async (isInvalid, nonDeletedHardwares, hardwareIos, dirtyHardwares, dirtyHardwareIos, jemConfig)=>{
        if (isInvalid) {
            this.validationErrorNotifier.sendErrors([
                ...nonDeletedHardwares,
                ...hardwareIos.filter((item)=>!item.isDeleted)
            ]);
            return;
        }
        const updateLocations = new Set();
        await all(dirtyHardwareIos.map((hardwareIo)=>{
            this.getAssignedStations(hardwareIo.hardware).forEach((station)=>{
                updateLocations.add(station);
            });
            return hardwareIo.save();
        }));
        await all(dirtyHardwares.map((hardware)=>{
            this.getAssignedStations(hardware).forEach((station)=>{
                updateLocations.add(station);
            });
            return hardware.save();
        }));
        if (!jemConfig?.configurationHistory || updateLocations.size === 0) {
            return;
        }
        Object.assign(this, {
            showConfigurationHistoryNotesModal: true,
            hideTransitionModal: true,
            locationsToUpdateVersion: Array.from(updateLocations)
        });
        await waitForProperty(this, 'showConfigurationHistoryNotesModal', false);
        Object.assign(this, {
            showConfigurationHistoryNotesModal: false,
            hideTransitionModal: false,
            locationsToUpdateVersion: []
        });
    }));
    deleteHardware = (hardware)=>{
        this.hardwareToDelete = null;
        hardware.hardwareIos.forEach((hardwareIo)=>{
            hardwareIo.deleteRecord();
        });
        hardware.deleteRecord();
    };
    toggleDeleteWarning = (hardware, attachedStations, event)=>{
        if (isPresent(attachedStations)) {
            event.preventDefault();
            event.stopPropagation();
            this.hardwareToDelete = hardware;
        } else {
            this.deleteHardware(hardware);
        }
    };
    openHardwarePanel = (hardware)=>{
        Object.assign(this, {
            showHardwarePanel: true,
            selectedHardware: hardware
        });
    };
    closeHardwarePanel = ()=>{
        Object.assign(this, {
            showHardwarePanel: false,
            selectedHardware: null
        });
    };
    rollback = (selectedHardware, hardwares, hardwareIos)=>{
        if (selectedHardware?.isDeleted || selectedHardware?.isNew) {
            this.closeHardwarePanel();
        }
        hardwares.forEach((hardware)=>{
            hardware.rollbackAttributes();
        });
        hardwareIos.forEach((hardwareIo)=>{
            hardwareIo.rollbackAttributes();
        });
    };
    addHardware = (hardwareType)=>{
        const hardware = this.hardwareRepo.createHardware(hardwareType);
        this.openHardwarePanel(hardware);
    };
    static{
        template_3ab553f20135409b8229923e16736212(`
    {{#let (this.hardwareRepo.getAllAssignableHardware) as |hardwares|}}
      {{#let (chain (prop 'hardwareIos') hardwares) as |hardwareIos|}}
        {{#let
          (rejectBy 'isDeleted' hardwares)
          (this.currentUser.hasPermission PERMISSION_NAMES.hardware)
        as |nonDeletedHardwares hasPermission|}}
          <Breadcrumbs
            @navKey="plant"
            @page={{t "hardware"}}
            @userNotPermitted={{not hasPermission}}
          />

          {{#let
            (or (anyInvalid hardwares) (anyInvalid hardwareIos))
            (filterBy 'isDirty' hardwares)
            (filterBy 'isDirty' hardwareIos)
          as |isInvalid dirtyHardwares dirtyHardwareIos|}}
            <SaveButtons
              @isDirty={{or
                (isPresent dirtyHardwares)
                (isPresent dirtyHardwareIos)
              }}
              @isInvalid={{isInvalid}}
              @save={{fn
                this.save.perform
                isInvalid
                nonDeletedHardwares
                hardwareIos
                dirtyHardwares
                dirtyHardwareIos
                this.systemConfig.jem
              }}
              @rollback={{fn this.rollback this.selectedHardware hardwares hardwareIos}}
              @allowUserSave={{hasPermission}}
              @hideTransitionModal={{this.hideTransitionModal}}
            as |showValidations|>
              {{#if this.showHardwarePanel}}
                <HardwarePanel
                  @hardware={{this.selectedHardware}}
                  @showValidations={{showValidations}}
                  @onClose={{this.closeHardwarePanel}}
                  @disabled={{not hasPermission}}
                />
              {{/if}}
            </SaveButtons>
          {{/let}}

          <div class="plant-hardware d-flex flex-column h-100">
            <div class="sticky-top d-flex flex-row align-items-center mb-4">
              <div class="hardware-filter w-25">
                <InputsLiveSearch
                  @placeholder="{{t 'search'}}..."
                  @searchValue={{this.searchFilter}}
                  @onInput={{fn (mut this.searchFilter)}}
                />
              </div>
              <div class="ms-auto">
                <ButtonPowerSelect
                  @dropdownClass="add-hardware"
                  @options={{(this.hardwareRepo.getAllAssignableHardwareTypes)}}
                  @placeholder={{t "hardware"}}
                  @searchEnabled={{true}}
                  @onChange={{this.addHardware}}
                  @disabled={{not hasPermission}}
                  class="add-hardware ms-auto"
                as |hardwareType|>
                  {{t hardwareType}}
                </ButtonPowerSelect>
              </div>
            </div>

            <ConfigTable class="hardware-list d-flex flex-column overflow-x-hidden">
              <div class="row form-row header-row sticky-top bg-white">
                <div class="col-icon" />
                <div class="col-auto col-hardware-status">{{t "status"}}</div>
                <div class="col">{{t "name"}}</div>
                <div class="col">{{t "type"}}</div>
                <div class="col col-hardware-stations">{{t "stations"}}</div>
                <div class="col-icon border-0"/>
              </div>

              {{#each (getDisplayedHardware nonDeletedHardwares this.searchFilter) as |hardware|}}
                {{#let (this.getAssignedStations hardware) as |attachedStations|}}
                  <div
                    class="
                      hardware-row
                      form-row
                      row
                      align-items-center
                      {{if (eq this.selectedHardware hardware) "selected"}}
                    "
                  >
                    <div class="col-auto">
                      <ConfigButton @onClick={{fn this.openHardwarePanel hardware}} />
                    </div>
                    <div class="col-auto col-hardware-status">
                      {{#if (not hardware.isConfigured)}}
                        <div class="connection-indicator badge rounded-pill text-bg-secondary">
                          {{t 'noStatus'}}
                        </div>
                      {{else if hardware.hasError}}
                        <div class="connection-indicator badge rounded-pill text-bg-danger text-white">
                          {{t 'disconnected'}}
                        </div>
                      {{else if hardware.isConnected}}
                        <div class="connection-indicator badge rounded-pill text-bg-success">
                          {{t 'connected'}}
                        </div>
                      {{else}}
                        <div class="connection-indicator badge rounded-pill text-bg-danger text-white">
                          {{t 'disconnected'}}
                        </div>
                      {{/if}}
                    </div>
                    <div class="col col-hardware-name">
                      {{hardware.name}}
                    </div>
                    <div class="col col-hardware-type">
                      {{hardware.displayType}}
                    </div>
                    <div class="col col-hardware-stations">
                      <LinkPopover
                        @label={{attachedStations.size}}
                        @options={{attachedStations}}
                        @route='plant.stations'
                      as |station|>
                        {{station.name}}
                      </LinkPopover>
                    </div>
                    <div class="col-icon">
                      <TrashCan
                        disabled={{not hasPermission}}
                        @onDelete={{fn this.toggleDeleteWarning hardware attachedStations}}
                      />
                    </div>
                  </div>
                {{/let}}
              {{/each}}
            </ConfigTable>
          </div>

          {{#if this.hardwareToDelete}}
            <StandardModal
              class="delete-hardware-warning"
              @header={{t "plant.stations.hardware.hardwareAssigned"}}
              @text={{t "plant.stations.hardware.areYouSure" name=this.hardwareToDelete.name}}
              @onConfirm={{fn this.deleteHardware this.hardwareToDelete}}
              @onCancel={{fn (mut this.hardwareToDelete) null}}
              @size="sm"
            />
          {{/if}}

          {{#if this.showConfigurationHistoryNotesModal}}
            <ConfigurationHistoryNotesModal
              @locations={{this.locationsToUpdateVersion}}
              @onConfirm={{fn (mut this.showConfigurationHistoryNotesModal) false}}
            />
          {{/if}}
        {{/let}}
      {{/let}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(HardwareTemplate);
