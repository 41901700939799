import { template as template_a76b2ef5ac814d9aa81d68c9490bd293 } from "@ember/template-compiler";
import moment from 'moment-timezone';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import SearchTag from 'eflex/components/criteria-visor/search-tag';
import FilterWrapper from 'eflex/components/criteria-visor/filter-wrapper';
import { t } from 'ember-intl';
export default class CriteriaVisorDateRange extends Component {
    @service
    intl;
    @tracked
    isDisplayed = this.args.beginDate || this.args.endDate;
    @tracked
    selectedRange;
    constructor(){
        super(...arguments);
        this.args.initParam('beginDate', this.beginDate?.toJSON());
        this.args.initParam('endDate', this.endDate?.toJSON());
    }
    get beginDate() {
        return this.args.beginDate ? new Date(this.args.beginDate) : null;
    }
    get endDate() {
        return this.args.endDate ? new Date(this.args.endDate) : null;
    }
    get beginDateBreadcrumb() {
        const beginDate = this.beginDate;
        if (!beginDate) {
            return null;
        }
        return this.intl.t('breadcrumbs.beginDate', {
            beginDate: moment(beginDate).format('lll')
        });
    }
    get endDateBreadcrumb() {
        const endDate = this.endDate;
        if (!endDate) {
            return null;
        }
        return this.intl.t('breadcrumbs.endDate', {
            endDate: moment(endDate).format('lll')
        });
    }
    dateRangeOptions = [
        {
            label: 'datetime.today',
            value: 'today',
            beginDate: moment().startOf('day').toDate(),
            endDate: moment().add(1, 'hours').toDate()
        },
        {
            label: 'datetime.yesterday',
            value: 'yesterday',
            beginDate: moment().subtract(1, 'days').startOf('day').toDate(),
            endDate: moment().subtract(1, 'days').endOf('day').toDate()
        },
        {
            label: 'datetime.lastweek',
            value: 'week',
            beginDate: moment().subtract(7, 'days').toDate(),
            endDate: moment().add(1, 'hours').toDate()
        },
        {
            label: 'datetime.lastmonth',
            value: 'month',
            beginDate: moment().subtract(1, 'months').toDate(),
            endDate: moment().add(1, 'hours').toDate()
        },
        {
            label: 'datetime.lastquarter',
            value: 'quarter',
            beginDate: moment().subtract(3, 'months').toDate(),
            endDate: moment().add(1, 'hours').toDate()
        }
    ];
    onBeginDateChange = (beginDate)=>{
        this.args.updateParam('beginDate', beginDate?.toJSON());
    };
    onEndDateChange = (endDate)=>{
        this.args.updateParam('endDate', endDate?.toJSON());
    };
    onDisplayedChanged = (isDisplayed)=>{
        this.selectedRange = null;
        this.onBeginDateChange(null);
        this.onEndDateChange(null);
        this.isDisplayed = isDisplayed;
    };
    onSelectedRange = (range)=>{
        this.selectedRange = range;
        this.onBeginDateChange(range?.beginDate);
        this.onEndDateChange(range?.endDate);
    };
    static{
        template_a76b2ef5ac814d9aa81d68c9490bd293(`
    <SearchTag class='beginDate' @isDisplayed={{this.isDisplayed}} @text={{this.beginDateBreadcrumb}} />
    <SearchTag class='endDate' @isDisplayed={{this.isDisplayed}} @text={{this.endDateBreadcrumb}} />

    <FilterWrapper
      class="eflex-date-range-criteria"
      @form={{@form}}
      @label="datetime"
      @isDisplayed={{this.isDisplayed}}
      @onChecked={{this.onDisplayedChanged}}
      ...attributes
    >
      <div class="input-group">
        <div class="col">
          <div class="d-flex flex-row mb-2">
            <@form.element
              id="date-range"
              class="w-100"
              @controlType="power-select"
              @options={{this.dateRangeOptions}}
              @value={{this.selectedRange}}
              @onChange={{this.onSelectedRange}}
            as |el|>
              <el.control
                class="date-range"
                @placeholder={{t "controls.daterange.select"}}
                as |range|
              >
                {{t range.label}}
              </el.control>
            </@form.element>
          </div>
          <div class="d-flex flex-row">
            <span class="d-flex flex-row w-50">
              <label for="start-date" class="form-label pe-3 text-nowrap">
                {{t "datetime.begin"}}
              </label>
              <@form.element
                id="start-date"
                class="start-date w-100"
                @controlType="date-picker"
                @value={{this.beginDate}}
                @onChange={{this.onBeginDateChange}}
              as |el|>
                <el.control
                  @altInput={{true}}
                  @enableTime={{true}}
                  @allowInput={{true}}
                  @dateFormat="Z"
                  @altFormat="M j, Y h:iK"
                />
              </@form.element>
            </span>
            <span class="d-flex flex-row w-50">
              <label for="end-date" class="form-label ps-3 pe-3 text-nowrap">
                {{t "datetime.end"}}
              </label>
              <@form.element
                id="end-date"
                class="end-date w-100"
                @controlType="date-picker"
                @value={{this.endDate}}
                @onChange={{this.onEndDateChange}}
              as |el|>
                <el.control
                  @altInput={{true}}
                  @enableTime={{true}}
                  @allowInput={{true}}
                  @dateFormat="Z"
                  @altFormat="M j, Y h:iK"
                />
              </@form.element>
            </span>
          </div>
        </div>
      </div>
    </FilterWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
