import Service, { service } from '@ember/service';
import { without, flow, reduce, filter } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import { ASSIGNABLE_HARDWARE_TYPES } from 'eflex/constants/hardware-types';
import { camelize } from '@ember/string';

const ADAM_IO_PIN_COUNT = 8;
const TURCK_IO_PIN_COUNT = 16;

const setupAdamIo = (hardware) => {
  const store = hardware.store;

  for (let i = 0; i < ADAM_IO_PIN_COUNT; i++) {
    store.createRecord('hardwareIo', {
      index: i,
      hardware,
      inputName: `Input ${i + 1}`,
      outputName: `Output ${i + 1}`,
    });
  }
};

const setupTurckIo = (hardware) => {
  const store = hardware.store;

  for (let i = 0; i < TURCK_IO_PIN_COUNT; i++) {
    store.createRecord('hardwareIo', {
      index: i,
      hardware,
      inputName: `Input ${i}`,
      outputName: `Output ${i}`,
    });
  }
};

const getAllowedHardwareTypes = (hardwareTypes, license) => {
  return without(license.disabledHardware, hardwareTypes);
};

export const groupHardware = (hardware) => {
  return flow(hardware ?? [], [
    reduce((acc, item) => {
      const existing = acc.find(_item => _item.groupName === item.hardwareGroup);

      if (existing != null) {
        existing.options.push(item);
      } else {
        acc.push({
          groupName: item.hardwareGroup,
          options: [item],
        });
      }

      return acc;
    }, []),
    sortByProp('groupName'),
  ]);
};

export default class HardwareRepoService extends Service {
  @service store;
  @service licensing;

  adamIos = this.store.peekAll('adamIo');
  audios = this.store.peekAll('audio');
  cognexCameras = this.store.peekAll('cognexCamera');
  datalogics = this.store.peekAll('datalogic');
  edhrs = this.store.peekAll('edhr');
  keyences = this.store.peekAll('keyence');
  lightGuides = this.store.peekAll('lightGuide');
  nodeReds = this.store.peekAll('nodeRed');
  opcUas = this.store.peekAll('opcUa');
  openProtocolTools = this.store.peekAll('openProtocolTool');
  plcHardwares = this.store.peekAll('plcHardware');
  turckIos = this.store.peekAll('turckIo');
  universalRobots = this.store.peekAll('universalRobot');
  webCams = this.store.peekAll('webCam');
  workInstructionHardwares = this.store.peekAll('workInstructionHardware');
  zebraPrinters = this.store.peekAll('zebraPrinter');

  get workInstructionHardware() {
    return this.workInstructionHardwares[0];
  }

  get allHardware() {
    return this.adamIos
      .concat(this.audios)
      .concat(this.cognexCameras)
      .concat(this.datalogics)
      .concat(this.edhrs)
      .concat(this.keyences)
      .concat(this.lightGuides)
      .concat(this.nodeReds)
      .concat(this.opcUas)
      .concat(this.openProtocolTools)
      .concat(this.plcHardwares)
      .concat(this.turckIos)
      .concat(this.universalRobots)
      .concat(this.webCams)
      .concat(this.workInstructionHardwares)
      .concat(this.zebraPrinters);
  }

  get allHardwareNonDeleted() {
    return this.allHardware.filter(item => !item.isDeleted);
  }

  createHardware(hardwareType) {
    const hardware = this.store.createRecord(hardwareType, {});

    switch (hardwareType) {
      case 'adamIo': {
        setupAdamIo(hardware);
        break;
      }
      case 'turckIo': {
        setupTurckIo(hardware);
        break;
      }
    }

    return hardware;
  }

  getHardwareByType(hardwareTypes) {
    hardwareTypes = new Set(getAllowedHardwareTypes(hardwareTypes, this.licensing.license));

    return flow(this.allHardware, [
      filter(hardware => {
        return hardwareTypes.has(hardware.constructor.modelName);
      }),
      sortByProp('hardwareGroup'),
    ]);
  }

  getAllowedCamelizedHardwareTypes(hardwareTypes) {
    return getAllowedHardwareTypes(hardwareTypes, this.licensing.license)
      .map((type) => camelize(type));
  }

  getAllAssignableHardware = () => {
    return this.getHardwareByType(ASSIGNABLE_HARDWARE_TYPES);
  };

  getAllAssignableHardwareTypes = () => {
    return getAllowedHardwareTypes(ASSIGNABLE_HARDWARE_TYPES, this.licensing.license)
      .map(hardwareType => camelize(hardwareType));
  };

  getGroupedAllowedHardware(hardwareTypes) {
    const hardwares = this.getHardwareByType(hardwareTypes);
    return groupHardware(hardwares);
  }
}
