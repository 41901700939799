import { template as template_14668f98ae06488a94bb6d3c73ca1e03 } from "@ember/template-compiler";
import { TriggerEvents, StationEvents, OeeEvents } from 'eflex/constants/tasks/trigger-events';
import AdamIo from 'eflex/components/trigger-configs/adam-io';
import Audio from 'eflex/components/trigger-configs/audio';
import Edhr from 'eflex/components/trigger-configs/edhr';
import LightGuide from 'eflex/components/trigger-configs/light-guide';
import NodeRed from 'eflex/components/trigger-configs/node-red';
import PlcHardware from 'eflex/components/trigger-configs/plc-hardware';
import TurckIo from 'eflex/components/trigger-configs/turck-io';
import { dasherize } from '@ember/string';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import HardwareSelect from 'eflex/components/hardware-select';
import { fn } from '@ember/helper';
import { deleteTrigger, createTrigger } from 'eflex/services/trigger-repo';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { eq, and } from 'ember-truth-helpers';
import TrashCan from 'eflex/components/trash-can';
const ALLOWED_TRIGGER_TYPES = [
    'light-guide',
    'adam-io',
    'edhr',
    'audio',
    'plc-hardware',
    'turck-io',
    'node-red'
];
const OEE_ALLOWED_TRIGGER_TYPES = [
    'light-guide',
    'adam-io',
    'audio',
    'plc-hardware',
    'turck-io',
    'node-red'
];
const HIDDEN_TRIGGER_CONFIG_TYPES = new Set([
    'work-instruction-hardware-trigger-config'
]);
const getTriggerComponent = (trigger)=>{
    let modelName;
    if (trigger.hardware) {
        modelName = trigger.hardware.constructor.modelName;
    } else {
        modelName = dasherize(trigger.hardwareAssignedType);
    }
    switch(modelName){
        case 'adam-io':
            {
                return AdamIo;
            }
        case 'audio':
            {
                return Audio;
            }
        case 'edhr':
            {
                return Edhr;
            }
        case 'light-guide':
            {
                return LightGuide;
            }
        case 'node-red':
            {
                return NodeRed;
            }
        case 'plc-hardware':
            {
                return PlcHardware;
            }
        case 'turck-io':
            {
                return TurckIo;
            }
        default:
            {
                return null;
            }
    }
};
const triggerTypeIsShown = (triggerConfig)=>{
    if (triggerConfig.isDeleted) {
        return false;
    }
    if (HIDDEN_TRIGGER_CONFIG_TYPES.has(triggerConfig.constructor.modelName)) {
        return false;
    }
    return true;
};
const getTriggerEvents = (isOeeEvent, isStationEvent)=>{
    if (isOeeEvent) {
        return OeeEvents;
    } else if (isStationEvent) {
        return StationEvents;
    } else {
        return TriggerEvents;
    }
};
export default template_14668f98ae06488a94bb6d3c73ca1e03(`
<div class="component-trigger-configs trigger-configs flex-fill" ...attributes>
  <div class="bootstrap-table">
    <BsForm
      @model={{this}}
      @disabled={{@disabled}}
      @showValidations={{@showValidations}}
    as |form|>
      {{#let
        (and (eq @location.type 'task') @location.usesOperations)
      as |usesHardwareTypes|}}
        <div class="row form-row header-row px-2">
          <div class="col-3">
            {{t "hardware"}}
          </div>
          {{#if usesHardwareTypes}}
            <div class="col-1">
              {{t "hardwareNumber"}}
            </div>
          {{/if}}
          <div class="col-1">
            {{t "event"}}
          </div>
          <div class="col">
            {{t "config"}}
          </div>
          <div class="col-icon border-0" />
        </div>
        <HardwareSelect
          @form={{form}}
          @usesOperations={{usesHardwareTypes}}
          @types={{if @isOeeEvent OEE_ALLOWED_TRIGGER_TYPES ALLOWED_TRIGGER_TYPES}}
          @invisibleLabel={{true}}
          @required={{true}}
        as |hardwareSelect|>
          {{#each @triggerConfigs as |triggerConfig|}}
            {{#if (triggerTypeIsShown triggerConfig)}}
              {{#let triggerConfig.parentTrigger as |trigger|}}
                <div class="trigger-config row form-row align-items-start bg-gray-100 rounded p-2">
                  <div class="col-3">
                    <div class="nested-header" />
                    <form.element
                      @controlType="checkbox"
                      @model={{triggerConfig}}
                      @property="enabled"
                      @label={{if usesHardwareTypes (t trigger.hardwareAssignedType) trigger.hardware.name}}
                      class="form-switch ps-3"
                    />
                  </div>
                  <hardwareSelect.index
                    class='col-1'
                    @model={{trigger}}
                    @size='sm'
                  />
                  <div class="col-1">
                    <div class="nested-header" />
                    <form.element
                      @controlType="power-select"
                      @options={{getTriggerEvents @isOeeEvent @isStationEvent}}
                      @model={{trigger}}
                      @property="event"
                      class="trigger-event"
                    as |el|>
                      <el.control
                        @triggerClass="form-control form-control-sm"
                      as |triggerEvent|>
                        {{t triggerEvent}}
                      </el.control>
                    </form.element>
                  </div>
                  <div class="col">
                    {{#let (getTriggerComponent trigger) as |TriggerComponent|}}
                      {{#if TriggerComponent}}
                        <TriggerComponent
                          @form={{form}}
                          @triggerConfig={{triggerConfig}}
                          @useModelSpecific={{withDefault @useModelSpecific true}}
                          @isStationEvent={{@isStationEvent}}
                          @isOeeEvent={{@isOeeEvent}}
                        />
                      {{/if}}
                    {{/let}}
                  </div>

                  <div class="col-icon d-flex flex-column ps-0">
                    <div class="nested-header" />
                    <TrashCan
                      class="delete-trigger p-1"
                      @onDelete={{fn deleteTrigger trigger}}
                    />
                  </div>
                </div>
              {{/let}}
            {{/if}}
          {{/each}}
          <div class="row">
            <div class="col-auto">
              <hardwareSelect.select
                class='test-add-trigger'
                @controlType='button-power-select'
                @usesOperations={{usesHardwareTypes}}
                @placeholder={{t "addEvent"}}
                @onChange={{fn createTrigger @location 'start'}}
              />
            </div>
          </div>
        </HardwareSelect>
      {{/let}}
    </BsForm>
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
