export const RETRY_STRATEGY_VALUES = {
  always: 0,
  rejects: 1,
  never: 2,
};

export const REJECT_ALLOWED_RETRY_STRATEGIES = new Set([
  RETRY_STRATEGY_VALUES.always,
  RETRY_STRATEGY_VALUES.rejects,
]);

export default RETRY_STRATEGY_VALUES;
