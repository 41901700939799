import { template as template_dd85ef5d2e09484f8b01c9c3f9f103c4 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { uniqueId } from '@ember/helper';
import Component from '@glimmer/component';
import { waitFor } from '@ember/test-waiters';
import { on } from '@ember/modifier';
export default class FileButton extends Component {
    @service
    fileUploader;
    @service
    notifier;
    get fileSelector() {
        return this.args.fileSelector ?? uniqueId();
    }
    get allowTypes() {
        return this.args.allowTypes ?? [
            '*/*'
        ];
    }
    upload = task({
        drop: true
    }, waitFor(async (files)=>{
        const file = files.target.files[0];
        if (this.args.skipUpload) {
            this.args.onSuccess(file);
            return;
        }
        try {
            const response = await this.fileUploader.post.perform(this.args.path, file);
            this.args.onSuccess(response);
        } catch (error) {
            console.error(error);
            this.notifier.sendError(error);
        }
    }));
    static{
        template_dd85ef5d2e09484f8b01c9c3f9f103c4(`
    <span class="component-file-button" ...attributes>
      <label for={{this.fileSelector}} class="file-label">
        <span class="file-plus">
          +
        </span>
        <span class="file-placeholder">
          {{@placeholder}}
        </span>
      </label>
      <input
        id={{this.fileSelector}}
        type="file"
        accept={{this.allowTypes}}
        multiple={{@multiple}}
        class="file-upload"
        {{on "change" this.upload.perform}}
      />
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
