/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import { all, task, timeout, waitForProperty, waitForQueue } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { updateObjectProperty } from 'eflex/util/fabric-helpers';

export default class WorkInstructionEditorToolPropertiesNormal extends Component {
  @service notifier;
  @service imageEditor;
  @service workInstructionRepo;
  @service workInstructionImageRepo;
  @service systemConfig;
  @service store;

  supportedFileTypes = WieSettings.supportedFileTypes;

  @tracked selectedBackgroundColor = '#000000';
  @tracked calculatedBackgroundColor = '#000000FF';
  @tracked workInstructionVersion;
  @tracked externalBackgroundUrl;
  @tracked backgroundFileName = '';
  @tracked selectedBackgroundOpacity = 100;

  get hasBackground() {
    return !isEmpty(this.backgroundFileName);
  }

  get hasBackgroundFile() {
    return this.hasBackground && isEmpty(this.externalBackgroundUrl);
  }

  get opacity() {
    return this.selectedBackgroundOpacity / 100;
  }

  get lastUpdate() {
    return moment(this.args.currentWorkInstruction?.updatedAt).format('MM-DD-YY, hh:mma');
  }

  get wieEditorTags() {
    const tags = this.args.currentWorkInstruction?.wieEditorTags ?? [];
    return tags.toSorted();
  }

  get wieApproverTags() {
    const tags = this.args.currentWorkInstruction?.wieApproverTags ?? [];
    return tags.toSorted();
  }

  get wieDeployerTags() {
    const tags = this.args.currentWorkInstruction?.wieDeployerTags ?? [];
    return tags.toSorted();
  }

  onDidInsert = task(waitFor(async () => {
    await all([
      waitForProperty(this.imageEditor, 'canvas'),
      waitForQueue('afterRender'),
    ]);

    this.updateSelectedProperties();
  }));

  _addBackgroundImage = task(waitFor(async ({ src, filename, id, isExternal }) => {
    this.backgroundFileName = filename;

    const result = await this.imageEditor.addBackgroundImage.perform(src, {
      opacity: this.opacity,
      eflex: {
        id: id ?? null,
        fileName: filename,
        isExternal: isExternal != null,
      },
    });

    if (result == null) {
      this.notifier.sendError('imageEditor.error.backgroundImage');
      this._clearBackground();
    }
  }));

  updateBackgroundImageFile = task(waitFor(async files => {
    this._clearBackground();

    if (files != null) {
      const file = files[0];
      if (!this.workInstructionImageRepo.fileAllowed(file)) {
        return;
      }

      const { blobToDataURL } = await import('blob-util');
      const dataUrl = await blobToDataURL(file);
      await this._addBackgroundImage.perform({ src: dataUrl, filename: file.name });
    }
  }));

  updateBackgroundImageUrl = task(waitFor(async (url, id, name) => {
    this.externalBackgroundUrl = url;
    this._clearBackground(false);

    if (url != null) {
      await this._addBackgroundImage.perform({ src: url, id, filename: name, isExternal: true });
    }
  }));

  onUpdateCalculatedBackgroundColor = task({ restartable: true }, waitFor(async (color, opacity, combinedColor) => {
    Object.assign(this, {
      calculatedBackgroundColor: combinedColor,
      selectedBackgroundColor: color,
    });

    await timeout(getDelayTime(200));

    this.imageEditor.updateCanvasProperty('backgroundColor', color);
  }));

  toggleRequestApproval = task(waitFor(async () => {
    await this.workInstructionRepo.toggleRequestApprovalAndSave.perform(this.args.currentWorkInstruction);
  }));

  _clearBackground(clearUrl = true) {
    this.imageEditor.removeBackgroundImage();
    this.backgroundFileName = '';

    if (clearUrl) {
      this.externalBackgroundUrl = null;
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.imageEditor.canvas?.off('after:render');
  }

  #updateBackgroundImageProperties = () => {
    const { backgroundImage } = this.imageEditor.canvas;

    const selectedBackgroundOpacity = isNaN(backgroundImage?.opacity)
      ? 100
      : Number.parseInt(Math.round(backgroundImage?.opacity * 100));

    Object.assign(this, {
      backgroundFileName: backgroundImage?.eflex?.fileName,
      selectedBackgroundOpacity,
    });

    if (this.hasBackground && backgroundImage.eflex.isExternal) {
      this.externalBackgroundUrl = backgroundImage.getSrc();
    }
  };

  @action
  updateSelectedProperties() {
    this.imageEditor.canvas.on('after:render', this.#updateBackgroundImageProperties);
    this.selectedBackgroundColor = this.imageEditor.canvas.backgroundColor;
    this.#updateBackgroundImageProperties();
  }

  @action
  updateBackgroundOpacity(val) {
    this.selectedBackgroundOpacity = val;
    updateObjectProperty(this.imageEditor.canvas.backgroundImage, 'opacity', this.opacity);
    this.imageEditor.notifyModified(this.imageEditor.canvas.backgroundImage);
  }

  @action
  onMakeDefault() {
    const normalDefault = this.store.createRecord('wieConfig/normal', {
      background: this.selectedBackgroundColor,
    });

    this.systemConfig.config.wie.editorDefaults.normal = normalDefault;
    return this.args.saveDefault();
  }
}
