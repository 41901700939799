import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class CodeRepoService extends Service {
  @service store;

  rejectCodes = this.store.peekAll('rejectCode');
  repairCodes = this.store.peekAll('repairCode');
  faultCodes = this.store.peekAll('faultCode');
  scrapCodes = this.store.peekAll('scrapCode');

  async retrieveCodes(type, station) {
    const codes = await this.store.findAll(`${type}Code`);

    const globalCodes = codes.filter(({ stations }) => isEmpty(stations));
    const stationCodes = station?.[`${type}Codes`] ?? [];

    return globalCodes.concat(stationCodes);
  }
}
