import { template as template_995e60868e434029ae18061418a35cd6 } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { inputVal } from 'eflex/helpers';
export default template_995e60868e434029ae18061418a35cd6(`
<div class="link-input-wrapper" ...attributes>
  <input
    class="form-control link-input"
    type="url"
    value={{@link}}
    disabled={{@disabled}}
    placeholder="https://"
    {{on "input" (inputVal @updateLink)}}
  />
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
