import { template as template_5d8674c0884945b3abcfcb814c72334e } from "@ember/template-compiler";
import createWorker from 'eflex/util/create-worker';
import Component from '@glimmer/component';
import numbro from 'numbro';
import { gte, or, lt, lte } from 'ember-truth-helpers';
import BsProgress from 'ember-bootstrap/components/bs-progress';
import { t } from 'ember-intl';
import { modulo, divide } from 'ramda';
import { floor } from 'ramda-adjunct';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { resourceFactory, resource } from 'ember-resources';
import { whenChanged } from 'eflex/modifiers';
import { tracked } from '@glimmer/tracking';
import { isTesting, macroCondition } from '@embroider/macros';
const Timer = (onMessage)=>{
    return resource(({ on })=>{
        const worker = createWorker(({ data: command })=>{
            if (globalThis.timerRef) {
                clearInterval(globalThis.timerRef);
                globalThis.timerRef = null;
            }
            if (command !== 'start') {
                return;
            }
            const now = performance.now();
            globalThis.timerRef = setInterval(()=>{
                self.postMessage((performance.now() - now) / 1000);
            }, 100);
        });
        worker.addEventListener('message', onMessage, {
            passive: true
        });
        on.cleanup(()=>{
            worker.removeEventListener('message', onMessage);
            worker.terminate();
        });
        return worker;
    });
};
resourceFactory(Timer);
const getTimerProgress = (targetTime, runningTimeInSeconds)=>{
    if (!targetTime) {
        return 0;
    }
    return Math.min(Math.ceil((runningTimeInSeconds / targetTime) * 100), 100);
};
const getTimerType = (targetTime, runningTimeInSeconds, alarmMargin)=>{
    if (!targetTime || runningTimeInSeconds <= (targetTime * alarmMargin)) {
        return 'success';
    } else if (runningTimeInSeconds < targetTime) {
        return 'warning';
    } else {
        return 'danger';
    }
};
const getTimerTextClass = (targetTime, runningTimeInSeconds, alarmMargin)=>{
    if (runningTimeInSeconds <= (targetTime * alarmMargin)) {
        return 'timer-success';
    } else if (runningTimeInSeconds < targetTime) {
        return 'timer-warning';
    } else {
        return 'timer-danger';
    }
};
const getTargetTimeSeconds = (targetTime)=>{
    return numbro(targetTime % 60).format({
        optionalMantissa: true,
        mantissa: 1
    });
};
const getRunningTimeDeciSecond = (runningTimeInSeconds)=>{
    const fractionalSeconds = 10 * (runningTimeInSeconds - Math.floor(runningTimeInSeconds));
    return `.${Math.floor(fractionalSeconds) % 10}`;
};
export default class JemTimer extends Component {
    @tracked
    workerSeconds = 0;
    originalRunningTimeInSeconds = this.args.currentRunningTimeInSeconds;
    get runningTimeInSeconds() {
        return this.workerSeconds + this.originalRunningTimeInSeconds;
    }
    onWorkerMessage = ({ data: seconds })=>{
        this.workerSeconds = seconds;
    };
    startOrStopTimer = (element, worker, { isRunning })=>{
        if (macroCondition(isTesting())) {
            return;
        }
        if (isRunning) {
            this.workerSeconds = 0;
            this.originalRunningTimeInSeconds = this.args.currentRunningTimeInSeconds;
            worker.postMessage('start');
        } else {
            worker?.postMessage('stop');
        }
    };
    static{
        template_5d8674c0884945b3abcfcb814c72334e(`
    {{#let
      this.runningTimeInSeconds
      (withDefault @targetCycleTime 0)
      (divide @warningPercent 100)
      (Timer this.onWorkerMessage)
    as |runningTimeInSeconds targetTime alarmMargin worker|}}
      <div
        class="
          jem-timer
          col-auto
          d-flex
          flex-column
          align-items-end
          text-end
          w-100
          mt-0
          {{if targetTime (getTimerTextClass targetTime runningTimeInSeconds alarmMargin) 'timer-white'}}
          {{if @isRunning "is-active"}}
        "
        {{whenChanged this.startOrStopTimer worker
          buildStatusId=@buildStatusId
          isRunning=@isRunning
        }}
        ...attributes
      >
        {{#let (floor (modulo runningTimeInSeconds 60)) as |runningTimeSecond|}}
          <div class="timer-text timer-color h1 d-flex justify-content-end mb-2">
            {{#if (lt runningTimeInSeconds 60)}}
              <span class="timer-color text-opacity-50">0:00:</span>
              {{#if (lte runningTimeSecond 9)}}
                <span class="timer-color text-opacity-50">0</span>
              {{/if}}
              <span>{{runningTimeSecond}}</span>
              <span class="timer-deci-seconds h4 align-self-end">
                {{~getRunningTimeDeciSecond runningTimeInSeconds~}}
              </span>
            {{else}}
              {{#let (floor (divide runningTimeInSeconds 3600)) as |runningTimeHour|}}
                {{#if runningTimeHour}}
                  {{#if (lte runningTimeHour 9)}}
                    <span class="timer-color text-opacity-50">0</span>
                  {{/if}}
                  <span>{{runningTimeHour}}:</span>
                {{else}}
                  <span class="timer-color text-opacity-50">00:</span>
                {{/if}}

                {{#let (modulo (floor (divide runningTimeInSeconds 60)) 60) as |runningTimeMinute|}}
                  {{#if (or runningTimeMinute runningTimeHour)}}
                    {{#if (lte runningTimeMinute 9)}}
                      <span class="timer-color {{if runningTimeHour '' 'text-opacity-50'}}">0</span>
                    {{/if}}
                    <span>{{runningTimeMinute}}:</span>
                  {{else}}
                    <span class="timer-color text-opacity-50">00:</span>
                  {{/if}}
                {{/let}}
              {{/let}}

              {{#if (lte runningTimeSecond 9)}}
                <span>0</span>
              {{/if}}
              <span>{{runningTimeSecond}}</span>
            {{/if}}
          </div>
        {{/let}}

        {{#if targetTime}}
          <BsProgress class="w-100 mb-3" as |pg|>
            <pg.bar
              @value={{getTimerProgress targetTime runningTimeInSeconds}}
              @type={{getTimerType targetTime runningTimeInSeconds alarmMargin}}
            />
          </BsProgress>
          <h6 class="target-time text-white fw-semibold mb-4 ms-auto">
            <div class="target-timer d-flex justify-content-end">
              <span class="target-time-label me-2">{{t "target"}}</span>

              <span class="fw-bold d-flex">
                {{#let
                  (floor (divide targetTime 3600))
                  (modulo (floor (divide targetTime 60)) 60)
                as |targetTimeHours targetTimeMinutes|}}
                  {{#if targetTimeHours}}
                    {{#if (lte targetTimeHours 9)}}
                      <span class='text-white text-opacity-25'>0</span>
                    {{/if}}
                    <span>{{targetTimeHours}}:</span>
                  {{else}}
                    <span class="text-white text-opacity-25">00:</span>
                  {{/if}}

                  {{#if (or targetTimeMinutes targetTimeHours)}}
                    <div class="min d-flex">
                      {{#if (lte targetTimeMinutes 9)}}
                        <span class={{if targetTimeHours '' 'text-white text-opacity-25'}}>0</span>
                      {{/if}}
                      <span class="time">{{targetTimeMinutes}}:</span>
                    </div>
                  {{else}}
                    <span class="text-white text-opacity-25">00:</span>
                  {{/if}}

                  {{#let (getTargetTimeSeconds targetTime) as |targetTimeSeconds|}}
                    {{#if (or targetTimeSeconds targetTimeMinutes)}}
                      <div class="sec d-flex">
                        {{#if (lt targetTimeSeconds 10)}}
                          <span
                            class={{if (gte targetTime 60) '' 'text-white text-opacity-25'}}
                          >
                            0
                          </span>
                        {{/if}}
                        <span class="time">{{targetTimeSeconds}}</span>
                      </div>
                    {{else}}
                      <span class="text-white text-opacity-25">00</span>
                    {{/if}}
                  {{/let}}
                {{/let}}
              </span>
            </div>
          </h6>
        {{/if}}
      </div>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
