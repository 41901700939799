import { template as template_1566b4ecc2624514816715e9bd482805 } from "@ember/template-compiler";
import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { removeObject } from 'eflex/util/array-helpers';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { fn } from '@ember/helper';
import { inputVal } from 'eflex/helpers';
import onKey from 'ember-keyboard/modifiers/on-key';
import { on } from '@ember/modifier';
export default class StatusServer extends Component {
    @tracked
    newLinkName;
    @tracked
    newLinkUrl;
    @tracked
    adding = false;
    @service
    store;
    @service
    currentUser;
    addLink = task(waitFor(async ()=>{
        if (isBlank(this.newLinkName) || isBlank(this.newLinkUrl) || this.args.statuses.flatMap((status)=>status.links).some((link)=>link.name === this.newLinkName)) {
            return;
        }
        this.adding = false;
        const link = this.store.createRecord('serverStatusLink', {
            url: this.newLinkUrl,
            name: this.newLinkName
        });
        this.args.server.links.push(link);
        try {
            await this.args.server.save();
        } finally{
            Object.assign(this, {
                newLinkName: null,
                newLinkUrl: null
            });
        }
    }));
    deleteLink = task(waitFor(async (link)=>{
        removeObject(this.args.server.links, link);
        await this.args.server.save();
    }));
    static{
        template_1566b4ecc2624514816715e9bd482805(`
    <form ...attributes>
      <fieldset>
        <legend>
          {{@server.hostname}}
        </legend>
        {{#if @server.networkStatuses}}
          <div class="center network-statuses">
            <h4>
              {{t "status.servers.network"}}
            </h4>
            <table class="table table-striped network-table">
              <thead>
                <tr>
                  <th>
                    {{t "ipAddress"}}
                  </th>
                  <th>
                    {{t "status.servers.subnetMask"}}
                  </th>
                </tr>
              </thead>
              <tbody>
                {{#each @server.networkStatuses as |networkStatus|}}
                  <tr>
                    <td>
                      {{networkStatus.ipAddress}}
                    </td>
                    <td>
                      {{networkStatus.subnetMask}}
                    </td>
                  </tr>
                {{/each}}
              </tbody>
            </table>
          </div>
        {{/if}}
        {{#if this.currentUser.user.isAdmin}}
          <div class="center links">
            <h4>
              {{t "status.servers.links"}}
            </h4>
            {{#each @server.links as |link|}}
              <div class="link">
                <a
                  href={{link.href}}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{link.name}}
                </a>
                <i
                  class="app-delete-button float-end"
                  {{on "click" (fn this.deleteLink.perform link)}}
                >
                  <FaIcon @icon="circle-minus" @prefix="fas" />
                </i>
              </div>
            {{/each}}
            {{#if this.adding}}
              <div class="add-link">
                <input
                  class="new-link-name {{this.invalidField}}"
                  type="text"
                  value={{this.newLinkName}}
                  placeholder={{t "name"}}
                  {{on "input" (inputVal (fn (mut this.newLinkName)))}}
                />
                <input
                  class="new-link-url {{this.invalidField}}"
                  type="text"
                  value={{this.newLinkUrl}}
                  placeholder={{t "status.servers.linkUrl"}}
                  {{on "input" (inputVal (fn (mut this.newLinkUrl)))}}
                  {{onKey 'Enter' this.addLink.perform}}
                />
              </div>
              <FaIcon
                @icon="circle-check"
                @prefix="fas"
                {{on "click" this.addLink.perform}}
              />
              <FaIcon
                @icon="circle-xmark"
                @prefix="fas"
                {{on "click" (fn (mut this.adding) false)}}
              />
            {{else}}
              <FaIcon
                @icon="circle-plus"
                @prefix="fas"
                class="app-add-button"
                {{on "click" (fn (mut this.adding) true)}}
              />
            {{/if}}
          </div>
        {{/if}}
      </fieldset>
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
