import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class PeekRecordHelper extends Helper {
  @service store;

  compute([modelName, id]) {
    if (!id) {
      return null;
    }

    return this.store.peekRecord(modelName, id);
  }
}
