const TaskStatuses = {
  REJECT_RANGE_START: 11,
  REJECT_RANGE_END: 212,
  GOOD_RANGE_START: 251,
  GOOD_RANGE_END: 254,

  NOT_RECEIVED: -1,
  UNKNOWN: 0,
  STARTED: 1,
  RESTART: 2,
  STOPPED: 4,
  NEEDS_AUTH: 5,
  AUTHORIZED: 6,
  REJECTED: 25,
  BARCODE_NO_MATCH: 26,
  CHILD_BAD: 27,
  CHILD_NOT_UNIQUE: 28,
  CHILD_CONTAINED: 29,
  NO_SERIAL_NUMBER: 30,
  SERIAL_NOT_DIFFERENT: 31,
  SCRAPPED: 32,
  BYPASSED_REJECTED: 33,
  SERIAL_SAME_AS_PARENT: 34,
  NEEDS_REWORK: 189,
  OVERALL_REJECTED: 211,
  NEEDS_REWORK_OVERALL: 212,
  DECISION_NOT_REQUIRED: 213,
  USER_NOT_REQUIRED: 214,
  GOOD: 251,
  BYPASSED_GOOD: 252,
  REPAIRED: 254,
  NOT_REQUIRED: 255,

  isRejected: (status) =>
    TaskStatuses.REJECT_RANGE_START <= status && status <= TaskStatuses.REJECT_RANGE_END,

  isGood: (status) =>
    TaskStatuses.GOOD_RANGE_START <= status && status <= TaskStatuses.GOOD_RANGE_END,

  isNotRequired: (status) => status === TaskStatuses.NOT_REQUIRED ||
    status === TaskStatuses.DECISION_NOT_REQUIRED || status === TaskStatuses.USER_NOT_REQUIRED,

  isGoodOrNotRequired: (status) => TaskStatuses.isGood(status) || TaskStatuses.isNotRequired(status),
};

export default TaskStatuses;
