import { template as template_c359b18a6ee44ecc8e92cfcb8e036ad2 } from "@ember/template-compiler";
import { get, uniqueId, hash } from '@ember/helper';
import { t } from 'ember-intl';
import { findBy, reverse } from '@eflexsystems/ember-composable-helpers';
import { not, and, eq, or } from 'ember-truth-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { scrollIntoView } from 'eflex/modifiers';
import { collectBy, prop } from 'ramda';
const getStatusClass = (status, errorCode)=>{
    if (status == null) {
        return null;
    }
    if (status === 1 && errorCode == null) {
        return 'jem-text-success';
    } else {
        return 'jem-text-danger';
    }
};
const getStatusIcon = (status, errorCode)=>{
    if (status == null) {
        return null;
    }
    if (status === 1 && errorCode == null) {
        return 'check';
    } else {
        return 'xmark';
    }
};
const getIsCollapsedResult = (spindle, childStatus, groupedSpindles, isActive)=>{
    if (childStatus.isFinished && groupedSpindles.at(-1) !== spindle) {
        return true;
    }
    if (!isActive && spindle.isPassed) {
        return true;
    }
    return false;
};
const getLatestMultispindleDatum = (childStatus)=>{
    if (childStatus.multispindleProcessData.length === 1) {
        return childStatus.multispindleProcessData[0];
    }
    return childStatus.multispindleProcessData.findLast((item)=>item.overallStatus !== -1);
};
const getGroupedSpindleData = (childStatus)=>{
    const spindles = childStatus?.multispindleProcessData.flatMap((multispindleProcessDatum)=>multispindleProcessDatum.spindles);
    return collectBy(prop('spindleNumber'), spindles ?? []);
};
const JemTaskMultispindleDetails = template_c359b18a6ee44ecc8e92cfcb8e036ad2(`
<div class="component-jem-task-multispindle-details" ...attributes>
  <div class="mt-2">
    <div class="fs-4 fw-semibold text-gray-800">
      {{t "plant.station.program"}}
    </div>
    <div class="fw-bold text-gray-900">
      {{@taskConfig.programNumber}}
    </div>
  </div>
  <div>
    {{#let
      (getGroupedSpindleData @childStatus)
      @childStatus.isStarted
      (getLatestMultispindleDatum @childStatus)
    as |groupedSpindles isStarted latestMultispindleDatum|}}
      {{#each groupedSpindles as |spindleGroup|}}
        {{#let
          (uniqueId)
          (findBy "multispindleProcessDatum" latestMultispindleDatum spindleGroup)
        as |groupId activeResult|}}
          {{#let
            activeResult.isPassed
            activeResult.isRejected
            activeResult.isNotReceived
          as |isPassed isRejected isNotReceived|}}
            <div class="jem-task-table jem-spindle-group bootstrap-table shadow-sm my-2 px-2">
              <div class="
                row
                form-row
                spindle-row
                bg-gray-100
                spindle-started
                mb-0
                {{if isPassed 'spindle-good'}}
                {{if isRejected 'spindle-rejected'}}"
              >
                <div class="col spindle-name text-body fw-semibold p-2">
                  {{get spindleGroup '0.name'}}
                </div>
                {{#unless isNotReceived}}
                  <div class="col-auto">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm d-flex align-items-center"
                      data-bs-target="#{{groupId}}"
                      data-bs-toggle="collapse"
                      disabled={{or (eq spindleGroup.length 1) (and isRejected isStarted)}}
                    >
                      {{spindleGroup.length}}
                      <FaIcon class="ms-1" @icon="bars" @size="xs" @prefix="fas"/>
                    </button>
                  </div>
                {{/unless}}
                <div class="multispindle-status col-3 p-2">
                  <div class="
                    badge
                    rounded-pill
                    text-capitalize
                    {{if isPassed 'bg-success'}}
                    {{if isRejected 'bg-danger'}}
                    {{if (or (not isPassed) (not isRejected)) 'bg-primary'}}"
                  >
                    {{#if isPassed}}
                      {{t "status.251"}}
                    {{else if isRejected}}
                      {{t "rejected"}}
                    {{else}}
                      {{t "started"}}
                    {{/if}}
                  </div>
                </div>
              </div>
              <div class="row form-row header-row bg-white pb-0 mb-0">
                <div class="col torque-col fw-semibold text-capitalize border-0 p-2">{{t "torque"}}</div>
                <div class="col fw-semibold text-capitalize border-0 p-2">{{t "angle"}}</div>
              </div>
              {{#each (reverse spindleGroup) as |spindle|}}
                {{#let (eq spindle.multispindleProcessDatum latestMultispindleDatum) as |isActive|}}
                  {{#let (getIsCollapsedResult spindle @childStatus spindleGroup isActive) as |isCollapsedResult|}}
                    <div
                      id={{if isCollapsedResult groupId}}
                      class="
                        jem-spindle
                        component-jem-spindle
                        {{if isActive "active" "is-stale"}}
                        {{if isCollapsedResult "collapse" "show"}}
                      "
                      {{scrollIntoView
                        shouldScroll=(and isStarted spindle.isRejected)
                        options=(hash behavior="smooth" block="center")
                      }}
                    >
                      {{#if (or (not spindle.isNotReceived) (not isStarted))}}
                        <div class="row form-row">
                          <div class="col torque-col">
                            <div
                              class="
                                torque
                                detail-text-value
                                d-flex
                                align-items-center
                                fw-bold
                                text-body
                                me-3
                                {{getStatusClass spindle.torqueStatus spindle.errorCode}}
                              "
                            >
                              {{spindle.formattedTorque}}
                              {{#let (getStatusIcon spindle.torqueStatus spindle.errorCode) as |statusIcon|}}
                                {{#if statusIcon}}
                                  <FaIcon @icon={{statusIcon}} @prefix="fas" class="ms-2" />
                                {{/if}}
                              {{/let}}
                            </div>
                          </div>
                          <div class="col">
                            <div
                              class="
                                angle
                                detail-text-value
                                d-flex
                                align-items-center
                                fw-bold
                                text-body
                                me-3
                                {{getStatusClass spindle.angleStatus spindle.errorCode}}
                              "
                            >
                              {{#let (getStatusIcon spindle.angleStatus spindle.errorCode) as |statusIcon|}}
                                {{#if statusIcon}}
                                  <FaIcon @icon={{statusIcon}} @prefix="fas" class="ms-2" />
                                {{/if}}
                              {{/let}}
                              {{spindle.formattedAngle}}
                            </div>
                          </div>
                        </div>
                      {{/if}}

                      {{#if spindle.multispindleProcessDatum.translatedError}}
                        <div class="row form-row pt-0 pb-2 ps-2">
                          <div class="multispindle-error detail-text-label text-danger">
                            <FaIcon @icon="triangle-exclamation" @prefix="fas" class="me-1" />
                            {{spindle.multispindleProcessDatum.translatedError}}
                          </div>
                        </div>
                      {{/if}}
                    </div>
                  {{/let}}
                {{/let}}
              {{/each}}
            </div>
          {{/let}}
        {{/let}}
      {{/each}}
    {{/let}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template_c359b18a6ee44ecc8e92cfcb8e036ad2(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskMultispindleDetails
        childStatus=@childStatus
        taskConfig=@taskConfig
    ))}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
