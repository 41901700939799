import { template as template_073bc7be83274e78ade186ba557aa9b9 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { and, not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import BsTooltip from 'eflex/components/bs-tooltip';
export default class JemTaskComponentOption extends Component {
    get displayedComponentOptionValue() {
        const taskConfig = this.args.taskConfig;
        if (!taskConfig || this.args.task.component?.isAlwaysRun) {
            return null;
        }
        if (!taskConfig.configOption.isSimple) {
            return this.args.childStatus?.getRawBuildDataValue(taskConfig);
        }
        return taskConfig.configOption.value;
    }
    static{
        template_073bc7be83274e78ade186ba557aa9b9(`
    {{#if (and @childStatus @task.usesComponents (not @task.component.isAlwaysRun))}}
      <div class="component-option mt-2" ...attributes>
        <div class="form-label text-gray-800 mb-0">
          {{#if @task.component.isAlwaysRun}}
            {{t "plant.area.components.alwaysRun"}}
          {{else}}
            {{@taskConfig.configOption.name}}
          {{/if}}
        </div>
        {{#if this.displayedComponentOptionValue}}
          <div class="detail-text-value fw-bold text-gray-900 component-option-value me-2">
            {{this.displayedComponentOptionValue}}
          </div>
        {{/if}}
        {{#if (and @taskConfig (not @taskConfig.configOption.isSimple))}}
          <span class="complex-component-icon d-inline-block mt-1">
            <BsTooltip>
              {{t "complexComponent"}}
            </BsTooltip>
          </span>
        {{/if}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
