export const VARIABLE_TEXT = {
  label: 'dataType.text',
  value: 'string',
};

export const VARIABLE_NUMBER = {
  label: 'dataType.number',
  value: 'number',
};

export const VARIABLE_BOOLEAN = {
  label: 'dataType.bit',
  value: 'boolean',
};

export const VARIABLE_VARIABLE = {
  label: 'variable',
  value: 'variable',
};

export const VARIABLE_BASE_TYPES = [
  VARIABLE_TEXT,
  VARIABLE_NUMBER,
  VARIABLE_BOOLEAN,
];

export const VARIABLE_TYPES = [
  ...VARIABLE_BASE_TYPES,
  VARIABLE_VARIABLE,
];

export const getControlType = (type) => {
  if (type === 'boolean') {
    return 'checkbox';
  } else if (type === 'number') {
    return 'number';
  } else {
    return 'text';
  }
};

