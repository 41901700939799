import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import pProps from 'p-props';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { TrackedArray } from 'tracked-built-ins';
import { PAGE_SIZE } from 'eflex/constants/pagination';

export default class BomManagerBomSourceRoute extends AuthenticatedRoute {
  @service notifier;
  @service bomSourceRepo;
  @service store;

  model({ id, ...params }) {
    return pProps({
      bomSource: this.bomSourceRepo.peekRecord(id),
      buildData: this.store.query('buildDatum', {
        skip: 0,
        take: PAGE_SIZE,
        bomSource: id,
        ...params,
      }),
    });
  }

  setupController(controller, { bomSource, buildData }) {
    Object.assign(controller, {
      selectedBomSource: bomSource,
      buildData: new TrackedArray(buildData),
      selectedSerialNumber: buildData[0],
    });

    if (isEmpty(buildData)) {
      this.notifier.sendError('logs.noData');
    }
  }

  resetController(controller, isExiting) {
    if (!isExiting) {
      return;
    }

    controller.clean();

    Object.assign(controller, {
      selectedBomSource: null,
      showBomBuilder: false,
      serialNumber: null,
    });
  }
}
