import { template as template_13495be26c654eeeb41c6c0781a2afa3 } from "@ember/template-compiler";
import Control from 'ember-bootstrap/components/bs-form/element/control';
import { service } from '@ember/service';
import EmberFlatpickr from 'ember-flatpickr/components/ember-flatpickr';
export default class DatePickerControl extends Control {
    @service
    intl;
    get locale() {
        const locale = this.intl.primaryLocale;
        if (locale === 'en-us') {
            return null;
        }
        return locale.replace(/-.*/, '');
    }
    onChange = ([date])=>{
        this.args.onChange?.(date);
    };
    static{
        template_13495be26c654eeeb41c6c0781a2afa3(`
    <EmberFlatpickr
      class='ember-bootstrap-date-picker {{this.formValidationClass}}'
      @allowInput={{@allowInput}}
      @altInput={{@altInput}}
      @enableTime={{@enableTime}}
      @disabled={{@disabled}}
      @date={{@value}}
      @noCalendar={{@noCalendar}}
      @locale={{this.locale}}
      @dateFormat={{@dateFormat}}
      @onChange={{this.onChange}}
      ...attributes
    />
    {{yield}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
