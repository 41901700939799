import TriggerConfig from 'eflex/models/trigger-config';
import { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  programName: [validator('presence', true)],

  x: [
    validator('number', {
      allowString: true,
      allowBlank: true,
    }),
  ],

  y: [
    validator('number', {
      allowString: true,
      allowBlank: true,
    }),
  ],
})
class LightGuideTriggerConfig extends TriggerConfig {
  @attr('string') programName;
  @attr('number') x;
  @attr('number') y;
}

export default LightGuideTriggerConfig;
