import { template as template_a964b6a084f94e19acebfd549caba750 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { propEq, pipe, reject, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { sortByProp } from 'ramda-adjunct';
import SidePanel from 'eflex/components/side-panel';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import BsButtonGroup from 'eflex/components/bs-button-group';
import { t } from 'ember-intl';
import { not, and, or } from 'ember-truth-helpers';
import AddButton from 'eflex/components/add-button';
import { scrollIntoView } from 'eflex/modifiers';
import { fn, hash } from '@ember/helper';
import BsForm from 'eflex/components/bs-form';
import Spinner from 'eflex/components/spinner';
import { formatDate } from 'eflex/helpers';
import sortableGroup from 'ember-sortable/modifiers/sortable-group';
import sortableItem from 'ember-sortable/modifiers/sortable-item';
import sortableHandle from 'ember-sortable/modifiers/sortable-handle';
import BsButton from 'eflex/components/bs-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { sortBy } from '@eflexsystems/ember-composable-helpers';
import TrashCan from 'eflex/components/trash-can';
const ScheduleCategories = {
    incomplete: 'incomplete',
    complete: 'complete'
};
export default class ProductionSchedulePanel extends Component {
    @service
    store;
    @tracked
    scheduleCategories = ScheduleCategories;
    @tracked
    station;
    @tracked
    completedProductionSchedules = [];
    @tracked
    scheduleCategory = ScheduleCategories.incomplete;
    @tracked
    scheduleSkip = 0;
    @tracked
    scheduleTake = 30;
    @tracked
    scrollToTop = false;
    get showIncomplete() {
        return this.scheduleCategory === ScheduleCategories.incomplete;
    }
    get sortedProductionSchedules() {
        if (this.showIncomplete) {
            return pipe(intoArray(reject(prop('isDeleted')), reject(prop('isCompleted')), reject(propEq(this.args.station.currentProductionSchedule?.id, 'id'))), sortByProp('order'))(this.args.station.productionSchedules ?? []);
        } else {
            return this.completedProductionSchedules;
        }
    }
    get isDirty() {
        return this.args.station.productionSchedules.some((item)=>item.isDirty);
    }
    get showNoSchedulesMessage() {
        const noSchedules = this.sortedProductionSchedules.length === 0;
        if (this.showIncomplete) {
            return noSchedules && this.args.station.currentProductionSchedule == null;
        } else {
            return noSchedules;
        }
    }
    get showLoadMore() {
        if (this.showIncomplete || this.completedProductionSchedules.length === 0) {
            return false;
        }
        return this.completedProductionSchedules.length >= this.scheduleSkip;
    }
    fetchCompletedSchedules = task({
        drop: true
    }, waitFor(async ()=>{
        const completedSchedules = await this.store.query('productionSchedule', {
            stationId: this.args.station.id,
            isCompleted: true,
            skip: this.scheduleSkip,
            take: this.scheduleTake
        });
        this.completedProductionSchedules = this.completedProductionSchedules.concat(completedSchedules);
        this.scheduleSkip += this.scheduleTake;
    }));
    onDidUpdate = ()=>{
        Object.assign(this, {
            scheduleCategory: ScheduleCategories.incomplete,
            scheduleSkip: 0,
            scrollToTop: true
        });
    };
    setScheduleCategory = task(waitFor(async (category)=>{
        this.scheduleCategory = category;
        this.completedProductionSchedules.forEach((completedProductionSchedule)=>{
            completedProductionSchedule.unloadRecord();
        });
        this.completedProductionSchedules = [];
        this.scheduleSkip = 0;
        if (!this.showIncomplete) {
            await this.fetchCompletedSchedules.perform();
        }
        this.scrollToTop = true;
    }));
    addSchedule = ()=>{
        this.updateOrder(this.sortedProductionSchedules);
        const schedule = this.store.createRecord('productionSchedule', {
            station: this.args.station,
            order: this.sortedProductionSchedules.length
        });
        this.args.station.productionSchedules.push(schedule);
    };
    deleteSchedule = (schedule)=>{
        if (schedule.isNew) {
            schedule.rollbackAttributes();
        } else {
            schedule.deleteRecord();
        }
        this.updateOrder(this.sortedProductionSchedules);
    };
    updateOrder = (scheduleList)=>{
        scheduleList.forEach((schedule, order)=>{
            schedule.order = order;
        });
    };
    static{
        template_a964b6a084f94e19acebfd549caba750(`
    <SidePanel
      @header="{{t "productionSchedule"}} - {{@station.displayName}}"
      @onClose={{@onClose}}
      class="production-schedule-panel"
      {{didUpdate this.onDidUpdate @station}}
      ...attributes
    >
      <div class="d-flex flex-column h-100 p-3">
        {{#if @station}}
          <div class="d-flex justify-content-start align-items-center mb-4">
            <div class="btn-group">
              <BsButtonGroup
                @type="radio"
                @value={{this.scheduleCategory}}
                @onChange={{this.setScheduleCategory.perform}}
                class="radio-btn-group" as |bg|
              >
                <bg.button
                  @value={{this.scheduleCategories.incomplete}}
                  disabled={{or this.setScheduleCategory.isRunning this.isDirty}}
                  class="text-uppercase toggle-incomplete"
                >
                  {{t "incomplete"}}
                </bg.button>
                <bg.button
                  @value={{or this.setScheduleCategory.isRunning this.scheduleCategories.complete}}
                  disabled={{this.isDirty}}
                  class="text-uppercase toggle-complete"
                >
                  {{t "complete"}}
                </bg.button>
              </BsButtonGroup>
            </div>
            {{#if this.showIncomplete}}
              <AddButton
                class="add-schedule ms-auto"
                @disabled={{@disabled}}
                @onClick={{this.addSchedule}}
              >
                {{t "addModel"}}
              </AddButton>
            {{/if}}
          </div>
          <div
            class="eflex-scroll schedule-scroll-container"
            {{scrollIntoView
              shouldScroll=this.scrollToTop
              options=(hash behavior="smooth" top=0)
            }}
          >
            <div class="bootstrap-table schedule-table bg-white mb-3">
              <BsForm
                @disabled={{@disabled}}
                @showValidations={{@showValidations}}
                @model={{@station}}
              as |form|>
                <div class="row form-row header-row">
                  <div class="col-icon border-0" />
                  <div class="col">
                    {{t "modelCode"}}
                  </div>
                  <div class="col">
                    {{t "description"}}
                  </div>
                  <div class="col">
                    {{t "started"}}
                  </div>
                  <div class="col">
                    {{t "passed"}}
                  </div>
                  <div class="col">
                    {{t "failed"}}
                  </div>
                  <div class="col">
                    {{t "target"}}
                  </div>
                  <div class="col-icon border-0" />
                </div>
                {{#if this.fetchCompletedSchedules.isRunning}}
                  <Spinner class="position-absolute" />
                {{/if}}
                {{#if (and @station.currentProductionSchedule this.showIncomplete)}}
                  <div class="current-production-schedule production-schedule row form-row">
                    <div class="col-icon"></div>
                    <div class="col model-code">
                      {{@station.currentProductionSchedule.model.code}}
                    </div>
                    <div class="col model-description">
                      {{@station.currentProductionSchedule.model.name}}
                    </div>
                    <div class="col start-time">
                      {{formatDate @station.currentProductionSchedule.startTime}}
                    </div>
                    <div class="col text-success passed-count">
                      {{@station.currentProductionSchedule.passed}}
                    </div>
                    <div class="col text-danger failed-count">
                      {{@station.currentProductionSchedule.failed}}
                    </div>
                    <div class="col text-primary target-count">
                      <form.element
                        @controlType="number"
                        @property="target"
                        @model={{@station.currentProductionSchedule}}
                      />
                    </div>
                    <div class="col-icon" />
                  </div>
                {{/if}}
                <span
                  {{sortableGroup
                    disabled=(or (not this.showIncomplete) @disabled)
                    onChange=this.updateOrder
                  }}
                >
                  {{#each this.sortedProductionSchedules as |schedule|}}
                    <div
                      class="production-schedule row form-row"
                      {{sortableItem model=schedule}}
                    >
                      {{#if this.showIncomplete}}
                        <div class="col-icon">
                          <BsButton
                            @type="peripheral"
                            class="drag-handle"
                            {{sortableHandle}}
                          >
                            <FaIcon @icon="grip-vertical" @prefix="fas" />
                          </BsButton>
                        </div>
                      {{else}}
                        <div class="col-icon" />
                      {{/if}}
                      <div class="col model-code">
                        {{#if schedule.notStarted}}
                          <form.element
                            @controlType="power-select"
                            @model={{schedule}}
                            @property="model"
                            @options={{sortBy "id" @station.models}}
                          as |el|>
                            <el.control
                              @triggerClass="form-control"
                              @searchEnabled={{true}}
                              @searchField="code"
                            as |model|>
                              {{model.code}}
                            </el.control>
                          </form.element>
                        {{else}}
                          {{schedule.model.code}}
                        {{/if}}
                      </div>
                      <div class="col model-description">
                        {{#if schedule.model}}
                          {{schedule.model.name}}
                        {{else}}
                          --
                        {{/if}}
                      </div>
                      <div class="col start-time">
                        {{#if schedule.startTime}}
                          {{formatDate schedule.startTime}}
                        {{else}}
                          --
                        {{/if}}
                      </div>
                      <div class="col passed-count">
                        {{#if schedule.startTime}}
                          <div class="text-success">
                            {{schedule.passed}}
                          </div>
                        {{else}}
                          --
                        {{/if}}
                      </div>
                      <div class="col failed-count">
                        {{#if schedule.startTime}}
                          <div class="text-danger">
                            {{schedule.failed}}
                          </div>
                        {{else}}
                          --
                        {{/if}}
                      </div>
                      <div class="col target-count">
                        {{#if this.showIncomplete}}
                          <form.element
                            @controlType="number"
                            @property="target"
                            @model={{schedule}} as |el|
                          >
                            <el.control class="text-primary" />
                          </form.element>
                        {{else}}
                          <div class="text-primary">
                            {{schedule.target}}
                          </div>
                        {{/if}}
                      </div>
                      <div class="col-icon">
                        {{#if (and this.showIncomplete schedule.notStarted)}}
                          <TrashCan
                            @onDelete={{fn this.deleteSchedule schedule}}
                            disabled={{@disabled}}
                          />
                        {{/if}}
                      </div>

                    </div>
                  {{/each}}
                </span>
                {{#if this.showNoSchedulesMessage}}
                  <div class="d-block text-muted my-2">
                    {{t "productionScheduler.noSchedules"}}
                  </div>
                {{/if}}
                {{#if this.showLoadMore}}
                  <div class="d-flex justify-content-center w-100">
                    <BsButton
                      @type="primary"
                      @onClick={{this.fetchCompletedSchedules.perform}}
                      class="load-more text-uppercase my-2"
                    >
                      {{t "grid.loadMore"}}
                    </BsButton>
                  </div>
                {{/if}}
              </BsForm>
            </div>
          </div>
        {{/if}}
      </div>
    </SidePanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
