import BomSource from 'eflex/models/bom-source';
import { attr } from '@ember-data/model';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { serialNumberValidations } from 'eflex/util/bom-source-validations';

@buildValidations({ ...serialNumberValidations })
class BarcodeBomSource extends BomSource {
  @attr('number') serialNumberStart;
  @attr('number') serialNumberLength;

  get isBarcode() {
    return true;
  }

  get usesVariableValidation() {
    return true;
  }

  get typeName() {
    return 'barcodeBomSource';
  }
}

export default BarcodeBomSource;
