import { template as template_d160e65f3cd144b9b3b51bcc831ce97a } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import BsButton from 'ember-bootstrap/components/bs-button';
import Link from 'eflex/components/link';
import UserDropdown from 'eflex/components/user-dropdown';
class MainHeader extends Component {
    @service
    resizeListener;
    @service
    session;
    @service
    currentUser;
    toggleNavSize = ()=>{
        if (this.resizeListener.navCompressed) {
            this.resizeListener.expandNav();
            this.resizeListener.triggerResize();
        } else {
            this.resizeListener.compressNav();
            this.resizeListener.triggerResize();
        }
    };
    toggleNav = ()=>{
        if (this.resizeListener.navOpen) {
            this.resizeListener.closeNav();
            this.resizeListener.triggerResize();
        } else {
            this.resizeListener.openNav();
            this.resizeListener.triggerResize();
        }
    };
    static{
        template_d160e65f3cd144b9b3b51bcc831ce97a(`
    <div class="main-header bg-brand hidden-print d-flex flex-column align-items-center">
      <div class="header-menu flex-fill d-flex flex-column">
        <div class="row flex-column align-items-center g-0 h-100">
          <div class="col-auto">
            <Link
              class="home-page btn btn-epicor-nav p-0"
              @route="landingPage"
            >
              <img
                src='/images/icons/home.svg'
              />
            </Link>
          </div>
          {{#if this.session.isActuallyAuthenticated}}
            <div class="col-auto">
              <BsButton
                @type="epicor-nav"
                @onClick={{this.toggleNav}}
                class="p-0 {{if this.resizeListener.navOpen "active"}}">
                <img
                  src='/images/icons/menu.svg'
                />
              </BsButton>
            </div>
            <div class="col-auto mt-auto">
              <Link
                class="btn btn-epicor-nav p-0"
                @href="https://epicweb.epicor.com/products/connected-process-control/documentation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src='/images/icons/help-and-support.svg'
                />
              </Link>
            </div>
            <div class="col-auto">
              <UserDropdown
                @showHeaderButtons={{true}}
                class='z-top-menu'
              />
            </div>
          {{/if}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default MainHeader;
