import { taskTypes, SHOW_OK_TASK_TYPES } from 'eflex/constants/tasks/task-types';
import { ErrorProofingTriggerValues } from 'eflex/constants/tasks/trigger-types';
import { RETRY_STRATEGY_VALUES, REJECT_ALLOWED_RETRY_STRATEGIES } from 'eflex/constants/retry-strategy-values';
import RejectStrategies from 'eflex/constants/tasks/reject-strategies';

const canShowOkButton = (task, taskConfig) => {
  if (task.hideBarcodeInput) {
    return false;
  }

  if (!task.showOkButton) {
    return false;
  }

  if (SHOW_OK_TASK_TYPES.has(task.taskType)) {
    return true;
  }

  if (taskConfig?.pickUsesTime) {
    return true;
  }

  if (
    task.isErrorProofing &&
    task.triggerType === ErrorProofingTriggerValues.jemButton
  ) {
    return true;
  }

  return false;
};

export const canRetryStopReject = (task, childStatus) => {
  if (
    childStatus.isRejected &&
    REJECT_ALLOWED_RETRY_STRATEGIES.has(task.retryStrategy) &&
    task.rejectStrategy === RejectStrategies.stop
  ) {
    return true;
  }

  return false;
};

const canRetryBeShown = (task, childStatus) => {
  if (childStatus.isGood && task.retryStrategy === RETRY_STRATEGY_VALUES.always) {
    return true;
  }

  if (childStatus.isRejected && REJECT_ALLOWED_RETRY_STRATEGIES.has(task.retryStrategy)) {
    return true;
  }

  return false;
};

export const taskConfirmButtonIsShown = ({ childStatus, task, taskConfig }) => {
  if (!childStatus) {
    return false;
  }

  if (childStatus.parent.isFinished) {
    return false;
  }

  if (!taskConfig) {
    return false;
  }

  if (childStatus.isFinished) {
    return canRetryBeShown(task, childStatus);
  }

  if (childStatus.isStarted || childStatus.isRestart) {
    return canShowOkButton(task, taskConfig);
  }

  return false;
};

export const taskConfirmIsDisabled = (args) => {
  if (!taskConfirmButtonIsShown(args)) {
    return true;
  }

  const { notAuthorized, childStatus, task } = args;

  if (childStatus.isHolding) {
    return true;
  }

  if (notAuthorized) {
    return true;
  }

  if (childStatus.isStarted || childStatus.isRestart) {
    if (childStatus.processData?.some(item => item.isInvalid)) {
      return true;
    }

    if (task.decisionRequired && !childStatus.decisionProcessData.some(item => item.isSelected)) {
      return true;
    }
  }

  return false;
};

export const taskRejectButtonIsShown = ({ childStatus, task, taskConfig }) => {
  if (!childStatus) {
    return false;
  }

  if (task.taskType === taskTypes.plc) {
    return false;
  }

  if (childStatus.parent.isFinished) {
    return false;
  }

  if (!childStatus.isStarted && !childStatus.isNotReceived) {
    return false;
  }

  if (!taskConfig) {
    return false;
  }

  return true;
};

export const taskRejectIsDisabled = (args) => {
  if (!taskRejectButtonIsShown(args)) {
    return true;
  }

  if (args.notAuthorized) {
    return true;
  }

  if (args.childStatus.isHolding) {
    return true;
  }

  return false;
};
