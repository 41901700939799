import { template as template_9aaa5db249604d3b9e4124be8d3b0b3d } from "@ember/template-compiler";
import { sortByProp } from 'ramda-adjunct';
import { t } from 'ember-intl';
import { isPresent } from '@eflexsystems/ember-composable-helpers';
import QuantitySelect from 'eflex/components/quantity-select';
import { fn } from '@ember/helper';
import { serviceHelper as service } from 'eflex/helpers';
import { VariableValue, VariableType } from 'eflex/components/task-variables-config';
import TrashCan from 'eflex/components/trash-can';
const getVariableDefs = (trigger)=>{
    return trigger.variableDefs.filter((item)=>!item.isDeleted);
};
const getPreviousVariableDefs = (trigger, isStationEvent, taskRepo)=>{
    let task;
    if (trigger.task != null) {
        task = trigger.task;
    } else {
        task = sortByProp('row', trigger.station.tasks ?? []).at(-1);
    }
    const previousTasks = taskRepo.getAllPreviousTasks(task, isStationEvent);
    return previousTasks?.flatMap((_task)=>_task.variableDefs).filter((def)=>!def.isDeleted && def.isFromDevice) ?? [];
};
const addMultipleVariableDefs = (trigger, store, quantity)=>{
    for(let i = 1, end = quantity; i <= end; i++){
        trigger.variableDefs.push(store.createRecord('variableDef', {}));
    }
};
export default template_9aaa5db249604d3b9e4124be8d3b0b3d(`
{{#let
  @triggerConfig.parentTrigger
  (getVariableDefs @triggerConfig.parentTrigger)
as |trigger variableDefs|}}
  <div class="row form-row align-nested-header pt-0 mb-2">
    <div class="col">
      <div class="nested-header">
        {{t "mqttRequestTopic"}}
      </div>
      <@form.element
        class="trigger-mqtt-topic"
        @model={{trigger}}
        @controlType="text"
        @property="mqttTopic"
        @size="sm"
      />
    </div>
  </div>
  {{#if (isPresent variableDefs)}}
    <div class="row gx-2 form-row align-nested-header py-0">
      <div class="col">
        <div class="nested-header">
          {{t "variableName"}}
        </div>
      </div>
      <div class="col">
        <div class="nested-header">
          {{t "dataType"}}
        </div>
      </div>
      <div class="col">
        <div class="nested-header">
          {{t "variableValue"}}
        </div>
      </div>
      <div class="col-icon" />
    </div>
    {{#let
      (getPreviousVariableDefs trigger @isStationEvent (service 'taskRepo'))
      (service 'variableDefRepo')
    as |previousVariableDefs variableDefRepo|}}
      {{#each variableDefs as |variableDef|}}
        <div class="variable-row position-relative pt-0 pb-1 row gx-2 form-row mb-1">
          <div class="col">
            <@form.element
              class="variable-name"
              @controlType="text"
              @model={{variableDef}}
              @property="name"
              @size="sm"
              @disabled={{@disabled}}
            as |el|>
              <el.control disabled={{@disabled}} />
            </@form.element>
          </div>
          <div class="col">
            <VariableType
              @form={{@form}}
              @size='sm'
              @variableDef={{variableDef}}
              @variableDefConfig={{variableDef}}
            />
          </div>
          <div class="col">
            <VariableValue
              @variableDef={{variableDef}}
              @variableDefConfig={{variableDef}}
              @previousVariableDefs={{previousVariableDefs}}
              @form={{@form}}
              @size='sm'
            />
          </div>
          <div class="col-icon">
            <TrashCan
              disabled={{@disabled}}
              @onDelete={{fn variableDefRepo.deleteVariable variableDef}}
            />
          </div>
        </div>
      {{/each}}
    {{/let}}
  {{/if}}
  <div class="row form-row pt-1">
    <div class="col">
      <QuantitySelect
        class="text-uppercase"
        @size="sm"
        @disabled={{@disabled}}
        @onSubmit={{fn addMultipleVariableDefs trigger (service 'store')}}
      >
        {{t "components.addVariable"}}
      </QuantitySelect>
    </div>
  </div>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
