import { getOwner } from '@ember/application';
import getHardware from 'eflex/helpers/get-hardware';
import getHardwareInputDefIo from 'eflex/helpers/get-hardware-input-def-io';
import { ALLOWED_PARALLEL_HARDWARE } from 'eflex/constants/hardware-types';


// eslint-disable-next-line sonarjs/cognitive-complexity
export default function validateTaskSequence(taskSequence, options, station) {
  const tasksByRow = new Map();

  station.children.forEach(task => {
    for (let i = task.row; i <= task.rowEnd; i++) {
      if (!tasksByRow.has(i)) {
        tasksByRow.set(i, []);
      }

      tasksByRow.get(i).push(task);
    }
  });

  for (const [_key, rowTasks] of tasksByRow) {
    if (rowTasks.length === 1) {
      continue;
    }

    const seenHardware = new Set();
    const seenInputs = new Set();
    const intl = getOwner(station).lookup('service:intl');

    for (const task of rowTasks) {
      const hardware = getHardware(station, task);
      const hardwareId = hardware?.id;

      if (hardwareId != null && !ALLOWED_PARALLEL_HARDWARE.has(hardware.constructor.modelName)) {
        if (seenHardware.has(hardwareId)) {
          return intl.t('logs.invalidTaskSequence');
        }

        seenHardware.add(hardwareId);
      }

      if (task.isErrorProofing) {
        for (const def of task.hardwareInputDefs) {
          const inputId = getHardwareInputDefIo(station, def)?.id;
          if (inputId != null) {
            if (seenInputs.has(inputId)) {
              return intl.t('logs.invalidTaskSequence');
            }

            seenInputs.add(inputId);
          }
        }
      }
    }
  }

  return true;
}
