import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';

export default class MultispindleProcessDatum extends Model {
  @attr('number', { defaultValue: -1 }) overallStatus;
  @attr('number') errorCode;
  @attr('string') torqueFormat;
  @attr('date') timestamp;

  @belongsTo('buildStatusChild', { inverse: 'multispindleProcessData', async: false }) buildStatusChild;
  @hasMany('spindleProcessDatum', { async: false, inverse: 'multispindleProcessDatum', embedded: true }) spindles;

  @service intl;
  @service systemConfig;

  get selectedTorqueFormat() {
    return this.torqueFormat ?? this.systemConfig.jem.torqueFormat;
  }

  get formattedData() {
    const result = [];

    this.spindles.forEach((spindle) => {
      result.push(
        {
          timestamp: this.timestamp,
          displayName: `${this.buildStatusChild.taskName} - ${spindle.name} - ${this.intl.t('torque')}`,
          displayValue: spindle.formattedTorque,
          displayClass: (spindle.torqueStatus === 1) ? 'good' : 'bad',
        },
        {
          timestamp: this.timestamp,
          displayName: `${this.buildStatusChild.taskName} - ${spindle.name} - ${this.intl.t('angle')}`,
          displayValue: spindle.formattedAngle,
          displayClass: (spindle.angleStatus === 1) ? 'good' : 'bad',
        },
      );
    });

    return result;
  }

  get translatedError() {
    if (this.errorCode == null) {
      return null;
    }

    return this.intl.t('multispindleError', { code: this.errorCode });
  }
}
