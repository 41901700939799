import { service } from '@ember/service';
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { isEmpty, isPresent } from '@ember/utils';
import { copyable } from 'eflex/decorators';
import moment from 'moment-timezone';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { tracked } from '@glimmer/tracking';
import { uniq, pluck, pipe, map } from 'ramda';
import { concatRight, sortByProp, compact } from 'ramda-adjunct';
import { intoArray } from '@eflexsystems/ramda-helpers';

@copyable
@buildValidations({
  name: validator('presence', {
    presence: true,
    ignoreBlank: true,
  }),
  authorUsername: validator('presence', {
    presence: true,
    ignoreBlank: true,
  }),
})
class WorkInstruction extends Model {
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('string') name;
  @attr('string') authorUsername;
  @attr('string', { defaultValue() { return moment().format('YYYYMMDDHHmmss'); } }) autoVersion;
  @attr('string') userVersion;
  @attr('string') notes;
  @attr('string') displayImage;
  @attr('string') displayImageThumb;
  @attr('string') deployedName;
  @attr('string') deployedImage;
  @attr('string') deployedImageThumb;
  @attr('number', { defaultValue: 1600 }) width;
  @attr('number', { defaultValue: 1200 }) height;
  @attr('boolean', { defaultValue: false }) isDynamic;
  @attr('boolean', { defaultValue: false }) approvalRequested;
  @attr('string') md5;
  @attr deployedCanvas;
  @attr canvas;

  @belongsTo('workInstructionFolder', { async: false, inverse: 'workInstructions' }) folder;
  @hasMany('jemConfiguration', { async: false, polymorphic: true, inverse: 'workInstruction' }) jemConfigurations;
  @hasMany('workInstructionApproveReject', { inverse: null, async: false, embedded: true }) approvalsRejects;
  @hasMany('workInstructionHardwareTriggerConfig', {
    inverse: 'workInstruction',
    async: false,
  }) workInstructionHardwareTriggerConfigs;

  @tracked isSelected = false;
  displayImageData;

  @service currentUser;
  @service intl;
  @service workInstructionImageRepo;
  @service userRepo;
  @service systemConfig;

  get isDirty() {
    return super.isDirty || this.approvalsRejects.some(item => item.isDirty);
  }

  get wieEditorTags() {
    if (this.folder) {
      return this.folder.wieEditorTags;
    } else {
      return this.systemConfig.getWieTags('wieEditorTags');
    }
  }

  get wieApproverTags() {
    if (this.folder) {
      return this.folder.wieApproverTags;
    } else {
      return this.systemConfig.getWieTags('wieApproverTags');
    }
  }

  get wieDeployerTags() {
    if (this.folder) {
      return this.folder.wieDeployerTags;
    } else {
      return this.systemConfig.getWieTags('wieDeployerTags');
    }
  }

  get _approversCount() {
    return this._approvers.length;
  }

  get hasAssignedLocations() {
    return isPresent(this.assignedLocations);
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set([
        'jemConfigurations',
        'displayImageData',
        'displayImage',
        'displayImageThumb',
        'approvalsRejects',
        'createdAt',
        'updatedAt',
      ]),
    };
  }

  get taskConfigs() {
    return intoArray(
      pluck('taskConfig'),
      compact,
    )(this.workInstructionHardwareTriggerConfigs);
  }

  get assignedTasks() {
    return intoArray(
      pluck('task'),
      compact,
    )(this.taskConfigs ?? []);
  }

  get allUniqueAssignedStations() {
    return pipe(
      pluck('station'),
      concatRight(this.assignedTasks?.map(item => item.station) ?? []),
      compact,
      uniq,
    )(this.jemConfigurations ?? []);
  }

  get assignedLocations() {
    const locations = this.assignedTasks.map((task) => {
      return {
        route: task.usesOperations ? 'kineticOperations.tasks' : 'plant.tasks',
        model: task,
        name: task.name,
        iconClass: task.iconClass,
      };
    });

    this.jemConfigurations.forEach(jemConfig => {
      switch (jemConfig.constructor.modelName) {
        case 'station-jem-configuration': {
          locations.push({
            model: jemConfig.station,
            name: jemConfig.station.name,
            route: 'plant.stations',
            iconClass: jemConfig.station.treeIconClass,
          });
          break;
        }

        case 'station-load-jem-configuration': {
          locations.push({
            model: jemConfig.station,
            name: jemConfig.station.name,
            route: 'plant.stations',
            iconClass: 'load-icon',
          });
          break;
        }

        case 'custom-tab-jem-configuration': {
          locations.push({
            model: jemConfig.station?.id,
            name: jemConfig.title,
            route: 'jem.stations',
            iconClass: 'custom-tab-icon',
          });
          break;
        }
      }
    });

    return locations;
  }

  get approvals() {
    return this.approvalsRejects?.filter(item => item.state === WieSettings.approvalStates.APPROVED) ?? [];
  }

  get isRejected() {
    return this.approvalsRejects?.some(item => item.state === WieSettings.approvalStates.REJECTED);
  }

  get currentApproveReject() {
    return this.allApprovalsRejects?.find(item => item.username === this.currentUser.user?.userName);
  }

  get hasNotes() {
    return this.approvalsRejects?.some((approval) => !isEmpty(approval.notes));
  }

  get deployed() {
    return (
      this.deployedName != null ||
      this.deployedCanvas != null ||
      this.deployedName != null ||
      this.deployedImageThumb != null
    );
  }

  get isApproved() {
    if (this.isRejected) {
      return false;
    }

    if (this._approversCount === 0) {
      return true;
    }

    return this._approvers.every((approver) => {
      return this.approvals.find(approval => approval.username === approver.userName);
    });
  }

  get _approvers() {
    return this.userRepo.users.filter((user) => {
      // don't include admins for this case
      return user.wieApproverTags.some((tag) => this.wieApproverTags?.includes(tag));
    });
  }

  get allApprovalsRejects() {
    const pending = [];

    this._approvers.forEach((approver) => {
      if (this.approvalsRejects.some(item => item.username === approver.userName)) {
        return;
      }

      pending.push({
        username: approver.userName,
        displayUsername: approver.displayUsername,
        state: WieSettings.approvalStates.PENDING,
        notes: null,
      });
    });

    return this.approvalsRejects.concat(pending);
  }

  get isPending() {
    return this.approvalRequested && !this.isApproved && !this.isRejected && this._approversCount > 0;
  }

  get approvalIconTooltip() {
    if (this.isApproved) {
      return this.intl.t('wieList.tooltip.approved');
    } else if (this.isRejected) {
      return this.intl.t('wieList.tooltip.disapproved');
    } else if (this.isPending) {
      return this.intl.t('wieList.tooltip.pending');
    } else {
      return null;
    }
  }

  get approvalIconClass() {
    if (this.isApproved && this.hasNotes) {
      return 'icon-wie-approved-note';
    } else if (this.isRejected && this.hasNotes) {
      return 'icon-wie-rejected-note';
    } else if (this.isApproved) {
      return 'icon-wie-approved';
    } else if (this.isRejected) {
      return 'icon-wie-rejected';
    } else if (this.isPending) {
      return 'icon-wie-approve-reject-pending';
    } else {
      return null;
    }
  }

  get displayImageUrl() {
    return this.workInstructionImageRepo.getWieImageUrl(this.displayImage);
  }

  get displayImageThumbUrl() {
    return this.workInstructionImageRepo.getWieImageUrl(this.displayImageThumb);
  }

  get deployedImageUrl() {
    return this.workInstructionImageRepo.getWieImageUrl(this.deployedImage);
  }

  get deployedImageThumbUrl() {
    return this.workInstructionImageRepo.getWieImageUrl(this.deployedImageThumb);
  }

  get displayVersion() {
    return this.userVersion ?? this.autoVersion;
  }

  get updatedAtFormatted() {
    return moment(this.updatedAt).format('M/DD/YY h:mm:ssa').slice(0, -1);
  }

  getDynamicObjectNames(deployed = true) {
    const canvas = deployed ? this.deployedCanvas : this.canvas;

    return pipe(
      intoArray(
        map(obj => {
          if (obj.eflex?.dynamicObjectName == null) {
            return;
          }

          return {
            id: obj.id,
            name: obj.eflex.dynamicObjectName,
            type: obj.eflex.type ?? obj.type,
          };
        }),
        compact,
      ),
      sortByProp('name'),
    )(canvas?.objects ?? []);
  }
}

export default WorkInstruction;
