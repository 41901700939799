import Service, { service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { pluck, uniqBy, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { updateLocationPaths } from 'eflex/util/tree-helpers';

export default class ModelRepoService extends Service {
  @service store;
  @service locationRepo;
  @service stationRepo;
  @service systemConfig;
  @service taskRepo;

  models = this.store.peekAll('model');

  getDeletedModelsWithIncompleteProductionSchedules = task(waitFor(async area => {
    const toDelete = area.models.filter(item => item.isDeleted);

    if (toDelete.length === 0) {
      return [];
    }

    const schedules = await this.store.query('productionSchedule', {
      modelIds: toDelete.map(item => item.id),
      isCompleted: false,
    });

    return intoArray(
      uniqBy(prop('model')),
      pluck('model'),
    )(schedules);
  }));

  saveMany = task(waitFor(async (area, source) => {
    let taskConfigs = [];

    await all(area.models.map(async model => {
      if (model.isDeleted) {
        model.jemConfigurations.forEach(jemConfiguration => { jemConfiguration.unloadRecord(); });
        model.taskConfigs.forEach(taskConfig => { taskConfig.unloadRecord(); });
        await model.destroyRecord();
      } else if (model.isDirty) {
        if (model.isNew) {
          taskConfigs = taskConfigs.concat(model.taskConfigs);
        }
        await model.save();
      }
    }));

    await this.locationRepo.bulkSave.perform({
      source,
      stations: area.stations.filter(item => item.isDirty),
      taskConfigs,
    });
  }));

  copyModel = task({ drop: true }, waitFor(async modelToCopy => {
    if (modelToCopy == null) {
      return;
    }

    const copied = modelToCopy.serialize();

    copied.id = undefined;
    copied.code = undefined;
    copied.area = undefined;
    const copiedModel = this.store.createRecord('model', copied);

    await this.store.query('taskConfig', { model: modelToCopy.id });

    copiedModel.area = modelToCopy.area;

    const newTaskConfigs = modelToCopy.taskConfigs
      .map(taskConfig =>
        taskConfig.copy(true, {
          ignoreAttributes: new Set(),
          copyByReference: new Set(['parent', 'configModel', 'configOption']),
          overwrite: {
            configModel: copiedModel,
          },
          relationships: {
            triggerConfigs: {
              ignoreAttributes: new Set(),
              copyByReference: new Set(['parentTrigger', 'workInstruction']),
            },
            variableDefConfigs: {
              ignoreAttributes: new Set(),
              copyByReference: new Set(['variableDef']),
            },
            hardwareInputDefConfigs: {
              ignoreAttributes: new Set(),
              copyByReference: new Set(['hardwareInputDef']),
            },
            decisionDefConfigs: {
              ignoreAttributes: new Set(),
              copyByReference: new Set(['decisionDef']),
            },
            spindleConfigs: {
              ignoreAttributes: new Set(),
              copyByReference: new Set(['spindle']),
            },
            strings: {
              ignoreAttributes: new Set(),
            },
          },
        }),
      );

    modelToCopy.jemConfigurations.filter(item => item.station).map(jemConfiguration => jemConfiguration.copy(true, {
      ignoreAttributes: new Set(['id']),
      copyByReference: new Set(['workInstruction', 'station']),
      overwrite: {
        model: copiedModel,
      },
    }));

    modelToCopy.jemPrerequisites.map(jemPrerequisite => jemPrerequisite.copy(true, {
      ignoreAttributes: new Set(),
      copyByReference: new Set(['station', 'prereqs1', 'prereqs2', 'configModel', 'configOption']),
      overwrite: {
        configModel: copiedModel,
      },
    }));

    newTaskConfigs.forEach(taskConfig => {
      updateLocationPaths(taskConfig);
    });

    return copiedModel;
  }));

  create = (area) => {
    const model = this.store.createRecord('model', { area });

    area.stations.forEach(station => {
      this.stationRepo.createJemPrerequisite(station, model);
      station.tasks.forEach(taskRecord => {
        this.taskRepo.createConfig(taskRecord, model);
      });
      this.stationRepo.createJemConfig(station, model);
    });

    return model;
  };

  deleteModel = (model) => {
    if (model.isNew) {
      model.taskConfigs.forEach(taskConfig => { taskConfig.unloadRecord(); });
    }

    model.deleteRecord();
  };
}
