import { template as template_06c1fa0306bf41adb750ed6bc5b0c68b } from "@ember/template-compiler";
import { not, and } from 'ember-truth-helpers';
import { link as linkHelper } from 'ember-link';
import { on } from '@ember/modifier';
export default template_06c1fa0306bf41adb750ed6bc5b0c68b(`
{{#if (and @route (not @disabled))}}
  {{#let (linkHelper route=@route model=@model query=@query) as |link|}}
    <a
      class={{if link.isActive "active"}}
      href={{link.url}}
      {{on 'click' link.open}}
      ...attributes
    >
      {{yield}}
    </a>
  {{/let}}
{{else}}
  <a
    class={{if @disabled "disabled"}}
    href={{@href}}
    ...attributes
  >
    {{yield}}
  </a>
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
