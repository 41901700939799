/* import __COLOCATED_TEMPLATE__ from './options-panel.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dedupeTracked } from 'tracked-toolbox';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { incrementPriority, decrementPriority } from 'eflex/util/component-priority-helpers';
import { reject } from 'ramda';

export default class BomSourcesOptionPanel extends Component {
  @dedupeTracked selectedOption;
  @tracked showComplexOptionModal = false;
  @tracked activeTab = this.args.component?.isComplex ? 'complex-options' : 'options';

  @service componentRepo;
  @service notifier;

  get options() {
    return reject(option => option.isDeleted)(this.args.component?.options);
  }

  @action
  deleteOption(option) {
    const errors = this.componentRepo.delete(option, option.peers);
    errors?.forEach(captionParams => {
      this.notifier.sendError('components.deletionWarning', captionParams);
    });
  }

  @action
  addSimpleOption(component) {
    this.componentRepo.addSimpleOption(component);
  }

  @action
  addComplexOption(component) {
    Object.assign(this, {
      showComplexOptionModal: true,
      selectedOption: this.componentRepo.addComplexOption(component),
    });
  }

  @action
  incrementPriority(selected) {
    incrementPriority(selected, selected.peers);
  }

  @action
  decrementPriority(selected) {
    decrementPriority(selected, selected.peers);
  }

  @action
  onEditComplexOption(option) {
    Object.assign(this, {
      selectedOption: option,
      showComplexOptionModal: true,
    });
  }

  @action
  onConfirmComplexOptionModal() {
    this.showComplexOptionModal = false;
    this.args.component.options.push(this.selectedOption);
    this.selectedOption = null;
  }

  @action
  onCancelComplexOptionModal() {
    this.showComplexOptionModal = false;
    if (!this.selectedOption.isNew) {
      return;
    }

    this.selectedOption.unloadRecord();
    this.selectedOption = null;
  }
}
