import BsButton from 'ember-bootstrap/components/bs-button';
import { action } from '@ember/object';
import { didCancel } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class EflexBsButton extends BsButton {
  get __disabled() {
    const { disabled, _disabled, preventConcurrency } = this.args;
    return (preventConcurrency !== false && this.isPending) || disabled || _disabled;
  }

  @action
  @waitFor
  async handleClick(e) {
    try {
      await super.handleClick(e);
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }
}
