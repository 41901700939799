import { template as template_251339a9977a4b2796086e75ce098358 } from "@ember/template-compiler";
import { get, hash } from '@ember/helper';
import Component from '@glimmer/component';
import TaskStatuses from 'eflex/constants/task-statuses';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
export class JemTaskVisionDetails extends Component {
    get someChildrenRejected() {
        return this.args.childStatus?.visionProcessData?.some((item)=>item.isRejected) ?? false;
    }
    get showCaptureImageButton() {
        const childStatus = this.args.childStatus;
        if (!childStatus) {
            return false;
        }
        const rejectedOrStarted = childStatus.isStarted || childStatus.isRejected || this.someChildrenRejected;
        return rejectedOrStarted && this.args.task.triggerType === 'captureButton' && childStatus.parent.isStarted;
    }
    get barcodeStrings() {
        return this.args.taskConfig?.strings?.map((item)=>item.barcodeString) ?? [];
    }
    get children() {
        const processData = this.args.childStatus?.visionProcessData ?? [];
        return processData.map((kid)=>{
            let taskStatusClass;
            if (kid.isNotRequired) {
                taskStatusClass = 'not-required';
            } else if (kid.isRejected) {
                taskStatusClass = 'danger';
            } else if (kid.isGood) {
                taskStatusClass = 'success';
            }
            return {
                name: kid.name,
                barcode: kid.barcode,
                taskStatusClass
            };
        });
    }
    captureImage = ()=>{
        return this.args.onTaskComplete?.(TaskStatuses.NOT_RECEIVED);
    };
    static{
        template_251339a9977a4b2796086e75ce098358(`
    <div ...attributes>
      <div class="mt-2">
        {{#if this.showCaptureImageButton}}
          <BsButton
            class="text-uppercase capture-image"
            @type="primary"
            @size="lg"
            @disabled={{@childStatus.isHolding}}
            @onClick={{this.captureImage}}
          >
            {{#if this.someChildrenRejected}}
              {{t "retakeImage"}}
            {{else}}
              {{t "captureImage"}}
            {{/if}}
          </BsButton>
        {{/if}}
        {{#each this.children as |child index|}}
          <div class="vision-barcode">
            <div class="fs-4 fw-semibold text-gray-800">
              {{child.name}}
            </div>
            {{#if (get this.barcodeStrings index)}}
              <div class="expected-value d-flex align-items-baseline">
                <div class="text-gray-800 fw-semibold">
                  {{t "expecting"}}
                </div>
                <div class="fs-4 fw-semibold text-gray-800 ms-2">
                  {{get this.barcodeStrings index}}
                </div>
              </div>
            {{/if}}
            <div class="displayed-value status detail-text-value fw-bold text-gray-900 {{child.taskStatusClass}}">
              {{child.barcode}}
            </div>
          </div>
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default template_251339a9977a4b2796086e75ce098358(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskVisionDetails
        task=@task
        childStatus=@childStatus
        taskConfig=@taskConfig
        captureImage=this.captureImage
        onTaskComplete=@onTaskComplete
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
