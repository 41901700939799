import { template as template_602fb4a17d994446a1798a868357b03c } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import OEE_STATES from 'eflex/constants/oee-states';
import STATION_FILTERS from 'eflex/constants/bi-andon-station-filters';
import moment from 'moment-timezone';
import { formatStateColumnData } from 'eflex/util/bi-format-oee-state-cell';
import { tracked } from '@glimmer/tracking';
import TaskStatuses from 'eflex/constants/task-statuses';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import TabulatorTable from 'eflex/components/tabulator-table';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import { isTesting, macroCondition } from '@embroider/macros';
const REFRESH_INTERVAL = 15_000;
export default class AndonStationSummary extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    store;
    @service
    stationRepo;
    @service
    licensing;
    @tracked
    timerToken;
    startAutoRefresh = task({
        drop: true
    }, waitFor(async ()=>{
        if (macroCondition(isTesting())) {
            return;
        }
        if (globalThis.isIntegrationTest) {
            return;
        }
        while(true){
            await timeout(REFRESH_INTERVAL);
            this.args.refresher = true;
        }
    }));
    get params() {
        return {
            stationIds: this.args.stationIds,
            stationFilter: this.args.stationFilter
        };
    }
    get tableConfiguration() {
        const columns = [
            {
                title: this.intl.t('station'),
                field: 'station',
                tooltip: false,
                headerSort: false,
                formatter: 'textarea'
            },
            {
                title: this.intl.t('bi.chartLabel.operator'),
                field: 'operator',
                tooltip: false,
                headerSort: false,
                formatter: 'textarea'
            },
            {
                title: this.intl.t('model'),
                field: 'model',
                tooltip: false,
                headerSort: false,
                formatter: 'textarea'
            }
        ];
        if (this.params.stationFilter === STATION_FILTERS.oee || this.params.stationFilter === STATION_FILTERS.all) {
            columns.push({
                title: this.intl.t('bi.chartLabel.oeeState'),
                field: 'state',
                tooltip: false,
                headerSort: false,
                formatter: (cell)=>formatStateColumnData(cell),
                width: 100
            }, {
                title: this.intl.t('bi.chartLabel.oeeStateDuration'),
                field: 'oeeStateDuration',
                tooltip: false,
                headerSort: false,
                formatter: (cell)=>this.formatStateDuration(cell),
                maxWidth: 185
            }, {
                title: this.intl.t('bi.chartLabel.faults'),
                field: 'faults',
                tooltip: false,
                headerSort: false,
                formatter: 'textarea'
            });
        }
        if (this.params.stationFilter === STATION_FILTERS.jem || this.params.stationFilter === STATION_FILTERS.all) {
            columns.push({
                title: this.intl.t('bi.chartLabel.currentStationState'),
                field: 'stationState',
                tooltip: false,
                headerSort: false,
                minWidth: 100
            }, {
                title: this.intl.t('bi.chartLabel.stationCycleTime'),
                field: 'stationCycleTime',
                tooltip: false,
                headerSort: false,
                formatter: (cell)=>this.formatCycleTime(cell),
                minWidth: 125
            });
        }
        return {
            columns
        };
    }
    getOeeStateData = task({
        restartable: true
    }, waitFor(async ()=>{
        const oeeStatesQuery = [
            {
                $match: {
                    endDate: null,
                    state: {
                        $in: Object.values(OEE_STATES.STATES)
                    }
                }
            },
            {
                $group: {
                    _id: '$station._id',
                    station: {
                        $first: '$station'
                    },
                    state: {
                        $first: '$state'
                    },
                    customButtonColor: {
                        $first: '$customButtonColor'
                    },
                    faultCodes: {
                        $first: '$faultCodes'
                    },
                    totalTimeInState: {
                        $sum: {
                            $dateDiff: {
                                startDate: '$startDate',
                                endDate: '$$NOW',
                                unit: 'millisecond'
                            }
                        }
                    }
                }
            }
        ];
        const [oeeStates, liveBuildStatuses] = await all([
            this.queryRunner.runQuery.perform('OeeStates', oeeStatesQuery),
            this.queryRunner.runQuery.perform('LiveBuildStatuses', [])
        ]);
        const stations = this.params.stationIds.length > 0 ? this.params.stationIds.map((stationId)=>this.store.peekRecord('station', stationId)) : this.stationRepo.stations;
        let filteredBuildStatuses = stations.map((station)=>{
            const liveBuildStatus = liveBuildStatuses.filter((item)=>item.status !== TaskStatuses.STOPPED).find((item)=>item.location?._id === station.id);
            const oeeState = this.licensing.license.oeeEnabled ? oeeStates?.find((item)=>item.station?._id === station.id) : null;
            const stationCycleTime = moment.duration(moment().diff(moment(liveBuildStatus?.timestamp), 'milliseconds'));
            return {
                liveBuildStatus,
                stationName: station.name,
                oeeState,
                stationCycleTime,
                stationStateTranslationKey: this.setStationStateTranslationKey(liveBuildStatus)
            };
        });
        if (this.params.stationFilter === STATION_FILTERS.oee) {
            filteredBuildStatuses = filteredBuildStatuses.filter((buildStatus)=>buildStatus.oeeState != null);
        } else if (this.params.stationFilter === STATION_FILTERS.jem) {
            filteredBuildStatuses = filteredBuildStatuses.filter((buildStatus)=>buildStatus.stationStateTranslationKey != 'idle');
        }
        return filteredBuildStatuses.map((status)=>{
            return {
                station: status.stationName,
                operator: status.liveBuildStatus?.userName,
                model: status.liveBuildStatus?.model?.code,
                state: status.oeeState,
                oeeStateDuration: moment.duration(status.oeeState?.totalTimeInState),
                faults: status.oeeState?.faultCodes?.codes?.map((faultCode)=>`${faultCode.code} - ${faultCode.description}`).join(', '),
                stationState: this.intl.t(status.stationStateTranslationKey),
                stationCycleTime: status.stationCycleTime,
                targetCycleTime: status.liveBuildStatus?.totalTargetCycleTime * 1000
            };
        }).toSorted((a, b)=>a.station.localeCompare(b.station));
    }));
    oeeStateAndLiveBuildStatusData = trackedTask(this, this.getOeeStateData, ()=>[
            this.params,
            this.args.refresher
        ]);
    formatStateDuration(cell) {
        const { state, oeeStateDuration } = cell.getData();
        Object.assign(cell.getElement().style, {
            whiteSpace: 'pre-wrap'
        });
        // render nothing instead of 0.0s
        return state ? oeeStateDuration.format('d[d] h[h] m[m] s[s]', {
            precision: 1,
            trim: 'both mid'
        }) : null;
    }
    formatCycleTime(cell) {
        const cellData = cell.getData();
        const { stationCycleTime, targetCycleTime } = cellData;
        // render nothing instead of 0.0s
        if (!stationCycleTime._milliseconds) {
            return null;
        }
        if (targetCycleTime && stationCycleTime._milliseconds > targetCycleTime) {
            Object.assign(cell.getElement().style, {
                color: echartsTheme.colorPalette.danger,
                fontWeight: 'bolder',
                whiteSpace: 'pre-wrap'
            });
        }
        return stationCycleTime.format('d[d] h[h] m[m] s[s]', {
            precision: 1,
            trim: 'both mid'
        });
    }
    setStationStateTranslationKey(liveBuildStatus) {
        if (liveBuildStatus && liveBuildStatus.status !== TaskStatuses.STOPPED) {
            return `status.${liveBuildStatus.status}`;
        } else {
            return 'idle';
        }
    }
    static{
        template_602fb4a17d994446a1798a868357b03c(`
    {{#if this.oeeStateAndLiveBuildStatusData.value}}
      <TabulatorTable
        class="bi-tabulator component-bi-andon-station-summary d-flex flex-column h-100"
        @tableConfiguration={{this.tableConfiguration}}
        @updateColumns={{true}}
        @data={{this.oeeStateAndLiveBuildStatusData.value}}
        {{didInsert this.startAutoRefresh.perform}}
        ...attributes
      />
    {{else if this.oeeStateAndLiveBuildStatusData.isRunning}}
      <Spinner />
    {{else}}
      <BiNoDataPlaceholder />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
