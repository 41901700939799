import GridRoute from 'eflex/util/grid-route';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugPacketsRoute extends GridRoute {
  @service store;

  getData = task(waitFor(async (params = {}) => {
    if (params.stationIds != null) {
      params.stationFacsIds = params.stationIds.map(stationId =>
        this.store.peekRecord('station', stationId).facsId,
      );
    }

    const results = await this.store.query('packet', params);
    const data = new TrackedArray(results);
    data.meta = { count: results.meta?.count };

    return data;
  }));
}
