/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { all, task, waitForProperty, waitForQueue } from 'ember-concurrency';
import { fabric } from 'fabric';
import { TrackedArray } from 'tracked-built-ins';
import { pipe, sortBy, descend, prop, forEach } from 'ramda';
import { IconFileNames, EflexObjTypes, FABRIC_FILL_HEX_LENGTH } from 'eflex/constants/work-instructions/tool-props';
import { updateObjectProperty } from 'eflex/util/fabric-helpers';

export default class WorkInstructionEditorToolPropertiesIcons extends Component {
  @service imageEditor;
  @service store;
  @service systemConfig;
  @service workInstructionRepo;

  @tracked selectedFillColor = '#000000';
  @tracked selectedFillOpacity = 100;
  @tracked selectDisabled = false;
  @tracked icons = new TrackedArray();
  @tracked selectedIcon;
  @tracked link = '';
  @tracked dynamicObjectName;
  @tracked startHidden = false;
  @tracked calculatedFillColor = '#000000FF';

  onDidInsert = task(waitFor(async () => {
    await all([
      waitForProperty(this.imageEditor, 'canvas'),
      waitForQueue('afterRender'),
    ]);

    pipe(
      sortBy(descend(prop('createdAt'))),
      forEach(icon => {
        this._addIcon(icon.id, icon.createdAt, icon.code);
      }),
    )(this.workInstructionRepo.workInstructionIcons);

    await this._loadSvgs.perform();

    this.selectedIcon = this.icons[0][0];

    if (this._activeObject() != null) {
      this.updateSelectedProperties();
    } else {
      this.updateFromSystemConfig();
    }

    this.imageEditor
      .on('selection:updated', this.updateSelectedProperties)
      .on('selection:cleared', this.#handleClearSelection);

    this.imageEditor.canvas.on('drop', ({ e }) => {
      this._doPlace.perform(e);
    });
  }));

  addIcon = task(waitFor(async icon => {
    let createdAt, id;

    let code = await icon.text();

    const workInstructionIcon = this.store.createRecord('workInstructionIcon', { code });
    workInstructionIcon.setStyle('fill', '#000000');
    workInstructionIcon.setStyle('stroke', '#000000');

    await workInstructionIcon.save();

    ({ id, createdAt, code } = workInstructionIcon);

    this._addIcon(id, createdAt, code, true);
  }));

  deleteIcon = task(waitFor(async icon => {
    this.icons.splice(this.icons.indexOf(icon), 1);
    const record = this.store.peekRecord('workInstructionIcon', icon.id);
    await record.destroyRecord();
  }));

  _doPlace = task(waitFor(async e => {
    if (!this.imageEditor.inToolMode || this.imageEditor.canvas.getActiveObject() != null) {
      return;
    }

    const coords = this.imageEditor.canvas.getPointer(e);

    const icon = await this.imageEditor.addIcon.perform(this.selectedIcon, {
      left: coords.x,
      top: coords.y,
      fill: this.calculatedFillColor,
      opacity: this.selectedFillOpacity / 100,
      eflex: {
        type: EflexObjTypes.ICON,
        dynamicObjectName: this.dynamicObjectName,
        startHidden: this.startHidden,
        link: this.link,
      },
    });

    this.args.objectCreated?.(icon);
  }));

  _loadSvgs = task(waitFor(async () => {
    const results = await all(IconFileNames.map((iconFileName, i) =>
      this._fetchSvg.perform(i, `/images/icons/imageEditor/${iconFileName}.svg`, 'same-origin'),
    ));

    results.forEach(result => {
      this._addIcon(null, result.key, result.image);
    });
  }));

  _fetchSvg = task(waitFor(async (key, url, mode) => {
    const res = await fetch(url, {
      method: 'GET',
      cache: 'default',
      mode,
      headers: {
        Accept: 'image/svg+xml',
      },
    });

    const image = await res.text();

    return {
      image,
      key,
    };
  }));

  willDestroy() {
    super.willDestroy(...arguments);

    this.imageEditor
      .off('selection:updated', this.updateSelectedProperties)
      .off('selection:cleared', this.#handleClearSelection);

    this.imageEditor.canvas?.off('drop');
  }

  updateFromSystemConfig() {
    const config = this.systemConfig.getWieDefaults('icons');

    Object.assign(this, {
      dynamicObjectName: null,
      link: '',
      calculatedFillColor: `${config.fill?.color}ff`,
      selectedFillColor: config.fill?.color,
      selectedFillOpacity: config.fill?.opacity ?? 0,
    });
  }

  @action
  updateSelectedProperties() {
    const obj = this._activeObject();

    if (obj == null) {
      return;
    }

    let fill;

    if(isPresent(obj.fill)) {
      fill = obj.fill;
    } else if (isPresent(obj.stroke)) {
      fill = obj.stroke;
    }

    if (fill == null) {
      return;
    }

    Object.assign(this, {
      calculatedFillColor: fill,
      selectDisabled: true,
      dynamicObjectName: obj.eflex?.dynamicObjectName,
      startHidden: obj.eflex?.startHidden,
      link: obj.eflex?.link,
    });

    if (fill.slice) {
      Object.assign(this, {
        selectedFillColor: fill.slice(0, FABRIC_FILL_HEX_LENGTH),
        selectedFillOpacity: obj.opacity * 100,
      });
    }
  }

  #handleClearSelection = () => {
    this.selectDisabled = false;
    this.updateFromSystemConfig();
  };

  _isIcon(obj) {
    return obj?.eflex?.type === EflexObjTypes.ICON;
  }

  _activeObject() {
    const obj = this.imageEditor.canvas?.getActiveObject();
    if (this._isIcon(obj)) {
      return obj;
    } else {
      return null;
    }
  }

  _updateIconStyle(obj, propName, val) {
    if (obj == null) {
      return;
    }

    if (propName === 'fill') {
      val = `${val.slice(0, FABRIC_FILL_HEX_LENGTH)}ff`;
    }

    if (obj.type === 'group') {
      updateObjectProperty(obj, propName, val);
      if (propName !== 'opacity') {
        obj.getObjects().forEach(subObj => {
          this._updateIconStyle(subObj, propName, val);
        });
      }
    } else if (propName === 'fill' && isPresent(obj.stroke)) {
      if (isPresent(obj[propName])) {
        updateObjectProperty(obj, propName, val);
      }
      updateObjectProperty(obj, 'stroke', val);
    } else {
      updateObjectProperty(obj, propName, val);
    }
  }

  _addIcon(id, name, svg, unshift = false) {
    if (!svg) {
      return;
    }

    const icon = { id, name, svg, urlEncodedSvg: encodeURIComponent(svg) };

    if (unshift) {
      this.icons.unshift(icon);
    } else {
      this.icons.push(icon);
    }

    fabric.loadSVGFromString(svg, (objects, options) => {
      icon.group = fabric.util.groupSVGElements(objects, options);
      icon.group.fill = this.calculatedFillColor;
    });
  }

  @action
  updateLink(val) {
    this.link = val;
    const obj = this.imageEditor.canvas?.getActiveObject();
    this._updateIconStyle(obj?.eflex, 'link', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateDynamicObjectName(val) {
    this.dynamicObjectName = val;
    const obj = this.imageEditor.canvas?.getActiveObject();
    this._updateIconStyle(obj?.eflex, 'dynamicObjectName', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStartHidden(val) {
    this.startHidden = val;
    const obj = this.imageEditor.canvas?.getActiveObject();
    this._updateIconStyle(obj?.eflex, 'startHidden', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateFillColor(color, opacity, combinedColor) {
    Object.assign(this, {
      selectedFillColor: color,
      selectedFillOpacity: opacity,
      calculatedFillColor: combinedColor,
    });

    const obj = this.imageEditor.canvas?.getActiveObject();

    if (obj?.fill) {
      this._updateIconStyle(obj, 'fill', combinedColor);
    }

    if (obj?.stroke) {
      this._updateIconStyle(obj, 'stroke', combinedColor);
    }

    if (obj?.opacity) {
      this._updateIconStyle(obj, 'opacity', opacity / 100);
    }

    this.imageEditor.notifyModified(obj);
  }

  @action
  onDragStart(icon, e) {
    if (this.selectDisabled) {
      e.preventDefault();
      return false;
    }

    icon.target = e.target;
    this.selectedIcon = icon;
  }

  @action
  onDragEnd() {
    this.selectedIcon = null;
  }

  @action
  onMakeDefault() {
    const iconDefault = this.store.createRecord('wieConfig/icons', {
      fill: this.store.createRecord('wieConfig/fill', {
        color: this.selectedFillColor,
        opacity: this.selectedFillOpacity,
      }),
    });

    this.systemConfig.config.wie.editorDefaults.icons = iconDefault;
    return this.args.saveDefault();
  }
}
