import { template as template_73e91981d3b94336bed1ac902a445573 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ref } from 'ember-ref-bucket';
import { waitFor } from '@ember/test-waiters';
import { task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import createRef from 'ember-ref-bucket/modifiers/create-ref';
export default class ConfigTable extends Component {
    @ref('scrollArea')
    scrollArea;
    @tracked
    topVisibility = 'opacity-0';
    @tracked
    bottomVisibility = 'opacity-1';
    onTopIntersection = task({
        keepLatest: true
    }, waitFor(async ([{ isIntersecting }])=>{
        if (isIntersecting) {
            this.topVisibility = 'opacity-0';
        } else {
            this.topVisibility = 'opacity-1';
        }
        await timeout(getDelayTime(150));
    }));
    onBottomIntersection = task({
        keepLatest: true
    }, waitFor(async ([{ isIntersecting }])=>{
        if (isIntersecting) {
            this.bottomVisibility = 'opacity-0';
        } else {
            this.bottomVisibility = 'opacity-1';
        }
        await timeout(getDelayTime(150));
    }));
    static{
        template_73e91981d3b94336bed1ac902a445573(`
    <div
      class="config-table bootstrap-table main-overflow h-100"
      ...attributes
      {{createRef "scrollArea" bucket=this}}
    >
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
