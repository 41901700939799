import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { service } from '@ember/service';

@buildValidations({
  name: [validator('presence', true)],
  edhrUser: [validator('presence', true)],
  password: [validator('presence', true)],
  url: [validator('presence', true)],
})
class Edhr extends Hardware {
  @attr('string') edhrUser;
  @attr('string') password;
  @attr('string') url;

  @service intl;

  get isConfigured() {
    return !isBlank(this.edhrUser) && !isBlank(this.password) && !isBlank(this.url);
  }

  get isConnected() {
    return true;
  }

  get hardwareGroup() {
    return this.intl.t('edhr');
  }
}

export default Edhr;
