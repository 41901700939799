import ComponentOption from 'eflex/models/component-option';
import { belongsTo, hasMany, attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isPresent } from '@ember/utils';

@buildValidations({
  name: [validator('presence', true)],
})
class ComplexComponentOption extends ComponentOption {
  @attr('string') operatorName; //'equals' or 'not_equal'
  @attr('string') condition; //'AND' or 'OR'

  @belongsTo('complexComponentOption', { async: false, inverse: 'rules' }) parent;
  @belongsTo('component', { async: false, inverse: null }) comparedComponent;
  @belongsTo('componentOption', { async: false, polymorphic: true, inverse: null }) componentOption;
  @hasMany('complexComponentOption', { async: false, inverse: 'parent', embedded: true }) rules;

  get isDirty() {
    return super.isDirty || this.rules.some(item => item.isDirty);
  }

  get isSimple() {
    return false;
  }

  //for queryBuilder's sake
  get queryBuilderType() {
    return 'string';
  }

  get input() {
    return 'select';
  }

  get nonDeletedRules() {
    return this.rules.filter(item => !item.isDeleted);
  }

  get rootComponent() {
    return this.rootRule.component;
  }

  get value() {
    return this.componentOption?.id;
  }

  set value(val) {
    this.componentOption.id = val;
  }

  get componentOptionsComponentId() {
    return this.componentOption?.component?.id ?? this.parent?.componentOptionsComponentId;
  }

  get rootRule() {
    if (this.parent?.content == null) {
      return this;
    } else {
      return this.parent?.rootRule;
    }
  }

  toRulesObject() {
    const rules = {
      type: this.queryBuilderType,
      input: this.input,
    };

    if (this.value) {
      rules.value = this.value;
    }

    if (this.componentOptionsComponentId) {
      rules.id = this.componentOptionsComponentId;
    }

    if (this.condition) {
      rules.condition = this.condition;
    }

    if (this.operatorName) {
      rules.operator = this.operatorName;
    }

    const children = this.nonDeletedRules.map(nonDeletedRule => nonDeletedRule.toRulesObject());

    if (isPresent(children)) {
      rules.rules = children;
    }

    return rules;
  }
}

export default ComplexComponentOption;
