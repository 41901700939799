export const PERMISSION_NAMES = {
  repairTasks: 'repairTasks',
  hardware: 'hardware',
  reasonCodes: 'reasonCodes',
  bomSources: 'bomSources',
  bomManager: 'bomManager',
  productionScheduler: 'productionScheduler',
  schedules: 'schedules',
  containment: 'containment',
};

const PERMISSION_OPTIONS = [
  {
    value: PERMISSION_NAMES.repairTasks,
    name: 'permissions.repairTasks',
  },
  {
    value: PERMISSION_NAMES.hardware,
    name: 'permissions.hardware',
  },
  {
    value: PERMISSION_NAMES.reasonCodes,
    name: 'permissions.reasonCodes',
  },
  {
    value: PERMISSION_NAMES.bomSources,
    name: 'permissions.bomSources',
  },
  {
    value: PERMISSION_NAMES.bomManager,
    name: 'permissions.bomManager',
  },
  {
    value: PERMISSION_NAMES.productionScheduler,
    name: 'permissions.productionScheduler',
  },
  {
    value: PERMISSION_NAMES.schedules,
    name: 'permissions.schedules',
  },
  {
    value: PERMISSION_NAMES.containment,
    name: 'permissions.containment',
  },
];

export default PERMISSION_OPTIONS;
