import { template as template_be7ad40375384a6c8c4e3c0efa15fac6 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import FormatDurationAxisBi from 'eflex/util/bi-duration-axis-formatter';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import Spinner from 'eflex/components/spinner';
import EChart from 'eflex/components/e-chart';
import { get } from '@ember/helper';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
export default class CycleTimeModel extends Component {
    @service
    queryRunner;
    @service
    intl;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params)=>{
        const modelCycleTimeQuery = await this.queryRunner.queryWithParams.perform(params, [
            {
                $match: {
                    'model._id': {
                        $exists: true
                    }
                }
            },
            {
                $group: {
                    _id: {
                        serialNumber: '$serialNumber',
                        modelId: '$model._id',
                        modelCode: '$model.code',
                        modelName: '$model.name'
                    },
                    totalCyclesPerSerialNumber: {
                        $sum: 1
                    },
                    totalCycleTimePerSerialNumber: {
                        $sum: '$cycleTime'
                    }
                }
            },
            {
                $group: {
                    _id: {
                        modelId: '$_id.modelId',
                        modelCode: '$_id.modelCode',
                        modelName: '$_id.modelName'
                    },
                    totalCyclesUniqueSerialNumbers: {
                        $sum: 1
                    },
                    totalCycleTimeModelPerStation: {
                        $sum: '$totalCycleTimePerSerialNumber'
                    }
                }
            },
            {
                $project: {
                    modelName: '$_id.modelName',
                    modelCode: '$_id.modelCode',
                    averageTimeToBuildModel: {
                        $divide: [
                            '$totalCycleTimeModelPerStation',
                            '$totalCyclesUniqueSerialNumbers'
                        ]
                    }
                }
            },
            {
                $sort: {
                    averageTimeToBuildModel: 1
                }
            }
        ]);
        const modelCycleTimeData = await this.queryRunner.runQuery.perform('BuildStatuses', modelCycleTimeQuery);
        return {
            chartData: this.getEchartOptions(modelCycleTimeData),
            exportData: ()=>this.formatModelCycleTimeData(clone(modelCycleTimeData))
        };
    }));
    modelCycleTimeData = trackedTask(this, this.getData, ()=>[
            this.params,
            this.args.refresher
        ]);
    getEchartOptions(cycleTimeData) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.cycleTimeModels')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params)=>{
                    const tooltipData = params[0];
                    return `<div style="min-width:285px;white-space: pre;">${tooltipData.marker} ${this.intl.t('model')} ${tooltipData.name}: <span style="float:right;font-weight:bold;">${moment.duration(tooltipData.value, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                        precision: 1,
                        trim: 'both mid'
                    })}</span></div>`;
                }
            },
            yAxis: {
                type: 'category',
                boundaryGap: true,
                data: cycleTimeData.map((model)=>`${model.modelCode} - ${model.modelName}`)
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    formatter: (value)=>FormatDurationAxisBi(value)
                }
            },
            series: [
                {
                    name: this.intl.t('bi.chartLegend.avgCycleTimeAll'),
                    data: cycleTimeData.map((m)=>m.averageTimeToBuildModel),
                    type: 'bar',
                    color: echartsTheme.colorPalette.success
                }
            ]
        };
    }
    formatModelCycleTimeData(exportData) {
        exportData.forEach((model)=>{
            model.averageTimeToBuildModel = moment.duration(model.averageTimeToBuildModel, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                precision: 1,
                trim: 'both mid'
            });
            delete model._id;
        });
        return exportData;
    }
    static{
        template_be7ad40375384a6c8c4e3c0efa15fac6(`
    <div ...attributes>
      {{#if this.modelCycleTimeData.isRunning}}
        <Spinner />
      {{else if (get this.modelCycleTimeData.value.chartData.series '0.data.length')}}
        <div class="row h-100">
          <div class="col d-flex justify-content-between">
            <EChart
              @option={{this.modelCycleTimeData.value.chartData}}
              class="h-100 w-100 cycle-time-model-chart"
              @exportData={{this.modelCycleTimeData.value.exportData}}
            />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
