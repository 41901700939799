import { template as template_af2e37180cb94d858bfb8ab27ca03dc6 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
export default class InputsLayerArrangement extends Component {
    @service
    imageEditor;
    sendToBack = ()=>{
        const activeObject = this.imageEditor.canvas.getActiveObject();
        this.imageEditor.sendToBack(activeObject);
    };
    bringToFront = ()=>{
        const activeObject = this.imageEditor.canvas.getActiveObject();
        this.imageEditor.bringToFront(activeObject);
    };
    sendBackwards = ()=>{
        const activeObject = this.imageEditor.canvas.getActiveObject();
        this.imageEditor.sendBackwards(activeObject);
    };
    bringForward = ()=>{
        const activeObject = this.imageEditor.canvas.getActiveObject();
        this.imageEditor.bringForward(activeObject);
    };
    static{
        template_af2e37180cb94d858bfb8ab27ca03dc6(`
    <div class="component-inputs-layer-arrangement" ...attributes>
      <div>
        <div class="property-button-group row g-2">
          <div class="col-auto">
            <BsButton
              disabled={{@disabled}}
              class="app-layer-back"
              @type="secondary"
              @onClick={{this.sendToBack}}
              @size="sm"
            >
              {{t "back"}}
            </BsButton>
          </div>
          <div class="col-auto">
            <BsButton
              disabled={{@disabled}}
              class="app-layer-front"
              @type="secondary"
              @onClick={{this.bringToFront}}
              @size="sm"
            >
              {{t "front"}}
            </BsButton>
          </div>
          <div class="col-auto">
            <BsButton
              disabled={{@disabled}}
              class="app-layer-backward"
              @type="secondary"
              @onClick={{this.sendBackwards}}
              @size="sm"
            >
              {{t "backward"}}
            </BsButton>
          </div>
          <div class="col-auto">
            <BsButton
              disabled={{@disabled}}
              class="app-layer-forward"
              @type="secondary"
              @onClick={{this.bringForward}}
              @size="sm"
            >
              {{t "forward"}}
            </BsButton>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
