import Model, { belongsTo, attr } from '@ember-data/model';
import { service } from '@ember/service';
import { copyable } from 'eflex/decorators';
import EdhrProcessDataTypes from 'eflex/constants/edhr-process-data-types';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { rollbackBelongsTo, isBelongsToRefDirty } from 'eflex/util/relationship-helpers';
import EdhrMethodTypes from 'eflex/constants/tasks/edhr-method-types';

const DATA_CAN_BE_NUMBER = new Set([
  EdhrProcessDataTypes.boltAngle,
  EdhrProcessDataTypes.boltTorque,
  EdhrProcessDataTypes.variableDef,
]);

@copyable
@buildValidations({
  processDataType: [
    validator('presence', {
      presence: true,
      get disabled() {
        return this.model.task != null && this.model.task.edhrMethod !== EdhrMethodTypes.sendData;
      },
    }),
  ],

  fieldName: [
    validator('presence', {
      presence: true,
      get disabled() {
        if (this.model.isSendImage) {
          return true;
        }

        return this.model.task != null && this.model.task.edhrMethod !== EdhrMethodTypes.sendData;
      },
    }),
  ],
})
class EdhrMapping extends Model {
  @attr('string') fieldName;
  @attr('string') processDataType;
  @attr('string') customValue;
  @attr('number') boltIndex;
  @attr('string') decimalPrecision;

  @belongsTo('variableDef', { async: false, inverse: 'edhrMappings' }) variableDef;
  @belongsTo('edhrTrigger', { async: false, inverse: 'edhrMappings' }) edhrTrigger;
  @belongsTo('task', { async: false, inverse: 'edhrMappings' }) task;
  @belongsTo('task', { async: false, inverse: null }) dataTask;

  @service store;

  get isDirty() {
    return super.isDirty ||
      isBelongsToRefDirty(this, 'variableDef') ||
      isBelongsToRefDirty(this, 'dataTask');
  }

  get errorIdentifier() {
    return this.task?.name ?? this.edhrTrigger?.errorIdentifier;
  }

  get isUserDefinedString() {
    return this.processDataType === EdhrProcessDataTypes.userDefinedString;
  }

  get isSendImage() {
    return this.processDataType === EdhrProcessDataTypes.sendImage;
  }

  get canBeNumber() {
    return DATA_CAN_BE_NUMBER.has(this.processDataType);
  }

  get dataTaskRow() {
    return this.dataTask?.row;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['edhrTrigger', 'task']),
      copyByReference: new Set(['dataTask', 'variableDef']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'variableDef');
    rollbackBelongsTo(this, 'dataTask');
    super.rollbackAttributes();
  }
}

export default EdhrMapping;
