import { ALLOWED_EXTENSION_MAPPINGS } from 'eflex/constants/file-types';
import { waitForPromise } from '@ember/test-waiters';

export default async (url) => {
  const extension = url.split('.').pop().split(/[#?]/)[0]?.toLowerCase();

  if (ALLOWED_EXTENSION_MAPPINGS[extension] != null) {
    return ALLOWED_EXTENSION_MAPPINGS[extension];
  }

  try {
    const response = await waitForPromise(fetch(url, { method: 'HEAD' }));
    return response.headers.get('content-type');
  } catch {
    return 'text/html';
  }
};
