const decOpacityToHex = function (decimalOpacity) {
  if (decimalOpacity == null) {
    return;
  }
  const adjusted = Math.ceil(255 * (decimalOpacity / 100));
  const hex = adjusted.toString(16);

  return hex.padStart(2, '0');
};

const hexOpacityToDec = function (hexOpacity) {
  if (hexOpacity == null) {
    return;
  }
  const ratio = Number.parseInt(hexOpacity, 16) / 255;

  return Math.floor(ratio * 100);
};

export { decOpacityToHex, hexOpacityToDec };
