import { template as template_5bdc5f6620c942c8a7cb4db0b6a80318 } from "@ember/template-compiler";
import { THREED_MIMES, IMAGE_MIMES, VIDEO_MIMES } from 'eflex/constants/file-types';
import getUrlType from 'eflex/util/get-url-type';
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';
import { task, timeout } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { modifier } from 'ember-modifier';
import { onResize } from 'eflex/modifiers';
import { isTesting, macroCondition } from '@embroider/macros';
class ThreeDViewer extends Component {
    #previousUrl;
    #viewer;
    #didSetup = false;
    willDestroy() {
        super.willDestroy(...arguments);
        this.#viewer?.dispose();
    }
    resize = task({
        keepLatest: true
    }, waitFor(async ()=>{
        await timeout(0);
        this.#viewer.engine.resize(true);
    }));
    setup = modifier((element, [url])=>{
        if (macroCondition(isTesting())) {
            return;
        }
        if (this.#didSetup && this.#previousUrl === url) {
            return;
        }
        this.#didSetup = true;
        this.#previousUrl = url;
        this.#viewer?.dispose();
        this._renderViewer.perform(element, url);
    });
    _renderViewer = task({
        enqueue: true
    }, waitFor(async (element, url)=>{
        const { DefaultViewer } = await import(/* webpackExports: ["DefaultViewer"] */ '@babylonjs/viewer');
        this.#viewer = new DefaultViewer(element, {
            model: url,
            templates: {
                main: {
                    params: {
                        fillScreen: true
                    }
                },
                navBar: {
                    params: {
                        hideVr: true,
                        hideHd: true,
                        hideHelp: true,
                        hideAnimations: true,
                        hideLogo: true,
                        hideFullscreen: true
                    }
                },
                help: false,
                share: false
            },
            camera: {
                behaviors: {
                    autoRotate: false
                }
            },
            environmentMap: {
                texture: 'babylon/envmap.env'
            },
            skybox: {
                cubeTexture: {
                    url: 'babylon/skybox.dds'
                }
            },
            ground: {
                texture: 'babylon/ground.png'
            },
            scene: {
                assetsRootURL: '/'
            }
        });
    }));
    static{
        template_5bdc5f6620c942c8a7cb4db0b6a80318(`
      <div class="threed-outer-wrapper">
        <div class="threed-inner-wrapper">
          <div
            class="threed-viewer"
            {{onResize this.resize.perform}}
            {{this.setup @url}}
          />
        </div>
      </div>
    `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const isPdf = (type)=>type.includes('pdf');
const isSvg = (type)=>type.includes('svg');
const isImage = (type)=>IMAGE_MIMES.some((extension)=>type.includes(extension));
const is3d = (type)=>THREED_MIMES.some((extension)=>type.includes(extension));
const isVideo = (type)=>VIDEO_MIMES.some((videoType)=>type.includes(videoType));
export default class UrlViewer extends Component {
    type = trackedFunction(this, async ()=>{
        const url = this.args.url;
        if (url == null) {
            return;
        }
        return await getUrlType(url);
    });
    static{
        template_5bdc5f6620c942c8a7cb4db0b6a80318(`
    {{#let this.type.value as |type|}}
      {{#if type}}
        {{#if (isPdf type)}}
          <div class="w-100 h-100">
            <object class="w-100 h-100" data={{@url}} type="application/pdf"></object>
          </div>
        {{else if (is3d type)}}
          <ThreeDViewer @url={{@url}} />
        {{else if (isVideo type)}}
          <video
            src={{@url}}
            type={{type}}
            autoplay
            controls
            class="url-viewer-video"
          ></video>
        {{else if (isImage type)}}
          <img src={{@url}} class="img-responsive img-fluid" />
        {{else if (isSvg type)}}
          <object data={{@url}} type="image/svg+xml"></object>
        {{else}}
          <iframe class="w-100 h-100" src={{@url}}></iframe>
        {{/if}}
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
