import { template as template_6b177b9eeaf3450d8f0e6414ddbadbda } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import filterByIds from 'eflex/helpers/filter-by-ids';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
export default class BiPanelProcessData extends Component {
    @service
    variableDefRepo;
    get controlType() {
        return this.args.controlType || 'power-select-multiple';
    }
    get filteredVariableDefs() {
        let variableDefs = this.variableDefRepo.variableDefs.filter((item)=>item.type === 'number' || item.isFromDevice);
        if (isPresent(this.args.stationIds)) {
            variableDefs = variableDefs.filter((variableDef)=>this.args.stationIds.includes(variableDef.task?.station?.id));
        }
        return variableDefs;
    }
    get selected() {
        if (this.controlType === 'power-select-multiple') {
            return filterByIds(this.filteredVariableDefs, this.args.variableDefIds);
        }
        const selectedId = this.args.variableDefIds[0];
        if (!selectedId) {
            return null;
        }
        return this.filteredVariableDefs.find((dataDef)=>dataDef.id === selectedId);
    }
    onChange = (value)=>{
        if (this.controlType === 'power-select-multiple') {
            value = value.map((item)=>item.id);
        } else {
            value = [
                value.id
            ];
        }
        this.args.onChange(value);
    };
    static{
        template_6b177b9eeaf3450d8f0e6414ddbadbda(`
    <BsForm as |form|>
      <form.element
        class="process-data-select"
        @controlType={{this.controlType}}
        @label={{t "bi.biPanel.process-data"}}
        @options={{this.filteredVariableDefs}}
        @optionLabelPath="name"
        @value={{this.selected}}
        @onChange={{this.onChange}}
      as |el|>
        <el.control
          @searchEnabled={{true}}
          @searchField="name"
        />
      </form.element>
    </BsForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
