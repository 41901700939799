import Service, { service } from '@ember/service';
import { camelize, dasherize } from '@ember/string';
import { isString } from 'ramda-adjunct';

export const deleteTrigger = (trigger) => {
  trigger.triggerConfigs.forEach(triggerConfig => { triggerConfig.deleteRecord(); });
  trigger.deleteRecord();
};

export const createTriggerConfig = (trigger, parent) => {
  const store = trigger.store;
  const triggerConfigType = `${trigger.constructor.modelName}-config`;
  const triggerConfig = store.createRecord(triggerConfigType, {
    parentTrigger: trigger,
  });

  if (parent.type === 'station') {
    triggerConfig.station = parent;
  } else if (parent.isButton) {
    triggerConfig.oeeButton = parent;
  } else {
    triggerConfig.taskConfig = parent;
  }

  return triggerConfig;
};

export const createTrigger = (parent, event, hardwareOrType) => {
  let hardwareType;

  if (
    parent.type === 'task' &&
    parent.usesOperations &&
    isString(hardwareOrType)
  ) {
    hardwareType = dasherize(hardwareOrType);
  } else {
    hardwareType = hardwareOrType.constructor.modelName;
  }

  const triggerType = `${hardwareType}-trigger`;
  const store = parent.store;
  const trigger = store.createRecord(triggerType, { event });

  if (parent.type === 'task') {
    trigger.task = parent;

    if (parent.usesOperations) {
      Object.assign(trigger, {
        hardwareAssignedType: camelize(hardwareType),
        hardwareIndex: 1,
      });
    } else {
      trigger.hardware = hardwareOrType;
    }

    parent.taskConfigs.forEach((taskConfig) => {
      createTriggerConfig(trigger, taskConfig);
    });
  } else if (parent.isButton) {
    Object.assign(trigger, {
      oeeButton: parent,
      hardware: hardwareOrType,
    });

    createTriggerConfig(trigger, parent);
  } else {
    Object.assign(trigger, {
      station: parent,
      hardware: hardwareOrType,
    });
    createTriggerConfig(trigger, parent);
  }
};

export default class TriggerRepoService extends Service {
  @service store;

  adamIoTriggers = this.store.peekAll('adamIoTrigger');
  audioTriggers = this.store.peekAll('audioTrigger');
  edhrTriggers = this.store.peekAll('edhrTrigger');
  lightGuideTriggers = this.store.peekAll('lightGuideTrigger');
  plcHardwareTriggers = this.store.peekAll('plcHardwareTrigger');
  nodeRedTriggers = this.store.peekAll('nodeRedTrigger');
  turckIoTriggers = this.store.peekAll('turckIoTrigger');
  workInstructionHardwareTriggers = this.store.peekAll('workInstructionHardwareTrigger');

  get allTriggers() {
    return this.adamIoTriggers
      .concat(this.audioTriggers)
      .concat(this.edhrTriggers)
      .concat(this.lightGuideTriggers)
      .concat(this.plcHardwareTriggers)
      .concat(this.nodeRedTriggers)
      .concat(this.turckIoTriggers)
      .concat(this.workInstructionHardwareTriggers);
  }
}
