import { service } from '@ember/service';
import Controller from '@ember/controller';

export default class EvisionCameraCorrectFileNamingController extends Controller {
  @service systemConfig;
  @service taskRepo;

  get selectedOptions() {
    return this.systemConfig.config.evision.namingConvention.selectedOptions;
  }

  get fieldSeparator() {
    return this.systemConfig.config.evision.namingConvention.fieldSeparator;
  }

  get tasks() {
    return this.taskRepo.tasks.filter(item => item.isVision);
  }

  get fileFormat() {
    return this.selectedOptions;
  }

  getFormattedFilename = (task) => {
    return this.fileFormat
      .join(this.fieldSeparator ?? '_')
      .replace('taskId', task.facsId)
      .replace('stationId', task.parent.facsId);
  };
}
