import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import SyncStatuses from 'eflex/constants/integration-sync-statuses';

export default class KineticRepoService extends Service {
  @service systemConfig;
  @service eflexAjax;
  @service store;

  kineticJobs = this.store.peekAll('kineticJob');
  kineticJobAssemblies = this.store.peekAll('kineticJobAssembly');
  kineticAssemblyOperations = this.store.peekAll('kineticAssemblyOperation');
  parts = this.store.peekAll('kineticPart');
  partRevs = this.store.peekAll('kineticPartRev');

  get systemConfiguration() {
    return this.systemConfig.config;
  }

  get kinetic() {
    return this.systemConfiguration.kinetic;
  }

  get bomOnlyParts() {
    return this.parts.filter(item => item.isFromBom);
  }

  syncBom = task(waitFor(async () => {
    this.kinetic.bom.syncStatus = SyncStatuses.SYNCING;
    await this.eflexAjax.post.perform('integrationOutboxes', {
      eventName: 'SyncBOM_1.0',
      data: {
        siteId: this.kinetic.siteId,
        company: this.kinetic.companyId,
      },
    });
  }));
}
