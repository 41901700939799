import { template as template_e4e4946ff24c4b1aa98eec0e1b04d7c3 } from "@ember/template-compiler";
import PowerSelect from 'eflex/components/power-select';
import { t } from 'ember-intl';
export default template_e4e4946ff24c4b1aa98eec0e1b04d7c3(`
<div class="component-bi-report-dropdown section-name d-flex align-items-center" ...attributes>
  <span class="fs-2 me-3">•</span>
  <PowerSelect
    @options={{@options}}
    @selected={{@selected}}
    @placeholder={{t "bi.nav.selectReport" }}
    @onChange={{@onChange}}
  as |navSelect|>
    {{t navSelect.label}}
  </PowerSelect>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
