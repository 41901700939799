import Trigger from 'eflex/models/trigger';
import { hasMany, attr } from '@ember-data/model';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { getOwner } from '@ember/application';

@buildValidations({
  reasonCode: [validator('presence', true)],
  operation: [validator('presence', true)],

  event: [
    validator('inline', {
      validate(event, options, edhrTrigger) {
        if (event === 'start' && edhrTrigger.edhrMappings.some(item => item.isSendImage)) {
          return getOwner(edhrTrigger).lookup('service:intl').t('validations.edhrMapping.sendFile');
        }

        return true;
      },
    }),
  ],
})
class EdhrTrigger extends Trigger {
  @attr('string') reasonCode;
  @attr('string') operation;

  @hasMany('edhrMapping', { async: false, inverse: 'edhrTrigger', embedded: true }) edhrMappings;

  get isDirty() {
    return super.isDirty || this.edhrMappings.some(item => item.isDirty);
  }

  get edhrMappingsInvalid() {
    return anyInvalid(this.edhrMappings);
  }

  get isSelfOrChildInvalid() {
    return this.isInvalid || this.edhrMappingsInvalid;
  }

  get validatedRelationships() {
    return ['edhrMappings'];
  }

  get copyableOptions() {
    const options = super.copyableOptions;
    options.ignoreAttributes.add('edhrMappings');
    return options;
  }
}

export default EdhrTrigger;
