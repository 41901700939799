const THREED_MAPPINGS = {
  stl: 'model/stl',
  gltf: 'model/gltf+json',
  glb: 'model/gltf-binary',
  obj: 'model/obj',
};

const IMAGE_MAPPINGS = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
  bmp: 'image/bmp',
  gif: 'image/gif',
};

const VIDEO_MAPPINGS = {
  mp4: 'video/mp4',
  ogg: 'video/ogg',
  ogv: 'video/ogg',
  webm: 'video/webm',
};

export const ALLOWED_EXTENSION_MAPPINGS = {
  pdf: 'application/pdf',
  html: 'text/html',
  htm: 'text/html',
  svg: 'image/svg+xml',
  ...IMAGE_MAPPINGS,
  ...THREED_MAPPINGS,
  ...VIDEO_MAPPINGS,
};

export const IMAGE_MIMES = Object.values(IMAGE_MAPPINGS);
export const THREED_MIMES = Object.values(THREED_MAPPINGS);
export const VIDEO_MIMES = Object.values(VIDEO_MAPPINGS);

export const ALLOWED_EXTENSIONS = Object.keys(ALLOWED_EXTENSION_MAPPINGS).map(ext => `.${ext}`);

export const MAX_UPLOAD_SIZE_MB = 100;
export const MAX_UPLOAD_SIZE = MAX_UPLOAD_SIZE_MB * 1024 * 1024;
