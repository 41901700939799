import { template as template_87e9904d82374ef2bfd551fb6678c321 } from "@ember/template-compiler";
import { service } from '@ember/service';
// eslint-disable-next-line no-restricted-imports
import $ from 'jquery';
import Component from '@glimmer/component';
import { dequal } from 'dequal';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { task, timeout, waitForQueue } from 'ember-concurrency';
import { waitForPromise, waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';
import { modifier } from 'ember-modifier';
import { onResize, eventBus } from 'eflex/modifiers';
import { eq } from 'ember-truth-helpers';
import TreeSelect from 'eflex/components/tree-select';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import IconButton from 'eflex/components/icon-button';
import { serviceHelper } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { getConfig } from 'eflex/services/task-repo';
const getStartAndEndRows = (treeTask)=>{
    if (treeTask.sizeY === 1) {
        return treeTask.row;
    }
    const x = Number.parseInt(treeTask.row);
    const y = Number.parseInt(treeTask.sizeY) - 1;
    return `${treeTask.row}-${x + y}`;
};
const onMoveTaskToStation = (treeTask, store, onMoveTaskToStationFn, { id })=>{
    const station = store.peekRecord('station', id);
    return onMoveTaskToStationFn(treeTask, station);
};
export const TaskCard = template_87e9904d82374ef2bfd551fb6678c321(`
<li
  class="
    gridster-card-holder
    gs-w
    panel
    {{if @isSelected 'is-selected'}}
    {{if @task.validations.isInvalid "is-invalid"}}
  "
  data-row={{@task.row}}
  data-col={{@task.column}}
  data-sizex={{1}}
  data-sizey={{@task.sizeY}}
  data-test-card-name={{@task.name}}
  data-task-id={{@task.id}}
  ...attributes
>
  {{#let
    (serviceHelper 'store')
  as |store|}}
    <div class="card task-card">
      <div class="card-header border-bottom-0 {{if @taskConfigDisabled "disabled"}}">
        <div class="tool-number d-flex align-items-center">
          <div class="task-card-start-end-rows d-flex ms-1">
            {{getStartAndEndRows @task}}
          </div>
          {{#if @hasAuthorization}}
            {{#if @groupedSiblingStations}}
              <TreeSelect
                class="d-flex ms-auto me-2 move-to-station"
                @sourceTree={{@groupedSiblingStations}}
                @maxDepth={{1}}
                @disabled={{@disabled}}
                @onClick={{fn onMoveTaskToStation @task store @moveTaskToStation}}
              >
                <i title={{t 'plant.task.move'}} class="icon icon-drag-drop"></i>
              </TreeSelect>
            {{else}}
              <div class="d-flex ms-auto me-2"></div>
            {{/if}}
            <IconButton
              class='me-2 copy-task'
              title={{t "plant.task.copy"}}
              @icon='copy'
              @prefix='fas'
              @disabled={{@disabled}}
              @onClick={{fn @copyTask @task}}
            />
            <IconButton
              title={{t 'plant.task.delete'}}
              class="d-flex icon-trash me-1 delete-task-card"
              @disabled={{@disabled}}
              @onClick={{fn @deleteTask @task}}
            />
          {{/if}}
        </div>
      </div>
      <div
        class="card-body d-inline-flex align-items-center"
        {{on "click" (fn @openTaskPanel @task)}}
      >
        <div class="task-icon {{@task.iconClass}} ms-0 me-2"></div>
        <div title={{@task.name}} class="task-name text-truncate pe-1">
          {{@task.name}}
        </div>
      </div>
    </div>
  {{/let}}
</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const destroyGridster = (element, isFullDestroy)=>{
    /* eslint-disable-next-line ember/no-jquery */ $(element.querySelector('.gridster-cards')).data('gridster')?.destroy(isFullDestroy);
};
const getTaskConfig = (treeTask, selectedContext)=>{
    if (treeTask.usesComponents) {
        return getConfig(treeTask, treeTask.component?.options[0]);
    } else {
        return getConfig(treeTask, selectedContext);
    }
};
export default class TaskCardGrid extends Component {
    @service
    store;
    #createdDestructor = false;
    previousCardIds = this.args.taskParent.children.map((item)=>item.id);
    reloadGrid = task(waitFor(async (element)=>{
        await waitForQueue('afterRender');
        destroyGridster(element, false);
        this.loadGridster(element);
    }));
    _updateAllCoordinates = task(waitFor(async (ui)=>{
        await this._scrollGrid.cancelAll({
            resetState: true
        });
        ui.$player[0].closest('.task-card-grid').querySelectorAll('.gridster-card-holder').forEach((taskCard)=>{
            const dimensions = {
                column: Number.parseInt(taskCard.dataset.col),
                row: Number.parseInt(taskCard.dataset.row),
                sizeY: Number.parseInt(taskCard.dataset.sizey)
            };
            const treeTask = this.store.peekRecord('task', taskCard.dataset.taskId);
            const update = {};
            for(const prop in dimensions){
                if (treeTask[prop] != dimensions[prop]) {
                    update[prop] = dimensions[prop];
                }
            }
            if (isNotNilOrEmpty(update)) {
                Object.assign(treeTask, update);
            }
        });
    }));
    _scrollGrid = task(waitFor(async (ui)=>{
        const parent = ui.$player[0].closest('.task-grid-wrapper');
        const top = ui.pointer?.top ?? 0;
        if (top > window.innerHeight - 100) {
            await this._scrollLoop.perform(parent, 5);
        } else if (top < parent.getBoundingClientRect().top + 100) {
            await this._scrollLoop.perform(parent, -5);
        } else {
            await this._scrollLoop.cancelAll({
                resetState: true
            });
        }
    }));
    _scrollLoop = task({
        drop: true
    }, waitFor(async (parent, scrollAmount)=>{
        while(true){
            await timeout(10);
            parent.scrollTop += scrollAmount;
        }
    }));
    initializeGridster = modifier(async (element)=>{
        await waitForPromise(import('@eflexsystems/gridster/src/jquery.gridster.js'));
        if (this.isDestroying || this.isDestroyed) {
            return;
        }
        this.loadGridster(element);
    });
    updateGridster = modifier(async (element, [tasks])=>{
        const cardIds = tasks.map((item)=>item.id);
        if (!this.previousCardIds) {
            this.previousCardIds = cardIds;
            return;
        }
        if (!dequal(this.previousCardIds, cardIds)) {
            this.previousCardIds = cardIds;
            await this.reloadGrid.perform(element);
        }
    });
    loadGridster(element) {
        /* eslint-disable-next-line ember/no-jquery */ const gridsterElem = $(element.querySelector('.gridster-cards'));
        const gridster = gridsterElem?.gridster({
            min_rows: 256,
            max_rows: 600,
            max_cols: 256,
            autogrow_cols: true,
            shift_widgets_up: false,
            move_widgets_down_only: true,
            shift_larger_widgets_down: false,
            widget_margins: [
                6,
                6
            ],
            widget_base_dimensions: [
                225,
                62
            ],
            ignore_self_occupied: true,
            resize: {
                enabled: true,
                axes: [
                    'y'
                ],
                remove_holes: false,
                handle_append_to: '.task-card',
                handle_class: 'task-resize-handle',
                stop: (e, ui)=>{
                    this._updateAllCoordinates.perform(ui);
                }
            },
            draggable: {
                /* eslint-disable-next-line ember/no-jquery */ scroll_target: $(element),
                autoscroll: true,
                stop: (e, ui)=>{
                    this._updateAllCoordinates.perform(ui);
                },
                drag: (e, ui)=>{
                    this._scrollGrid.perform(ui);
                },
                ignore_dragging: [
                    '.task-card-dropdown-caret',
                    'input',
                    'textarea',
                    'select',
                    'button',
                    '.ember-power-select-option',
                    '.ember-power-select-options',
                    '.ember-basic-dropdown-trigger',
                    'i',
                    'svg',
                    'path'
                ]
            },
            collision: {
                wait_for_mouseup: true
            }
        });
        if (!this.#createdDestructor) {
            this.#createdDestructor = true;
            registerDestructor(this, ()=>{
                destroyGridster(element, true);
            });
        }
        const gridsterData = gridster?.data('gridster');
        if (this.args.disabled) {
            gridsterData.disable();
            gridsterData.disable_resize();
        } else {
            gridsterData.enable_resize();
        }
    }
    static{
        template_87e9904d82374ef2bfd551fb6678c321(`
    <div
      class="task-card-grid"
      {{this.initializeGridster}}
      {{this.updateGridster @taskParent.children}}
      {{onResize this.reloadGrid.perform}}
      {{eventBus taskCardsRolledBack=this.reloadGrid.perform}}
      ...attributes
    >
      <div class="gridster-wrapper">
        <div class="gridster">
          <ul class="gridster-cards">
            {{#each @taskParent.tasks as |treeTask|}}
              {{#unless treeTask.isDeleted}}
                {{#let (getTaskConfig treeTask @selectedContext) as |taskConfig|}}
                  <TaskCard
                    isSelected={{eq treeTask.id @selectedTask.id}}
                    @task={{treeTask}}
                    @taskConfigDisabled={{taskConfig.disabled}}
                    @disabled={{@disabled}}
                    @groupedSiblingStations={{@groupedSiblingStations}}
                    @openTaskPanel={{@openTaskPanel}}
                    @moveTaskToStation={{@moveTaskToStation}}
                    @copyTask={{@copyTask}}
                    @deleteTask={{@deleteTask}}
                    @hasAuthorization={{@hasAuthorization}}
                  />
                {{/let}}
              {{/unless}}
            {{/each}}
          </ul>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
