import { template as template_afff25e2210d42468888ecfce3ad4fa6 } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
export default template_afff25e2210d42468888ecfce3ad4fa6(`
<div class='row form-row'>
  <div class='col'>
    <@form.element
      class='hardware-endpoint is-required'
      @controlType='text'
      @property='endpoint'
      @label={{t 'endpoint'}}
    />
  </div>
</div>

<div class='row form-row'>
  <div class='col'>
    <@form.element
      class='hardware-security-policy'
      @controlType='power-select'
      @property='securityPolicy'
      @label={{t 'securityPolicy'}}
      @options={{array
        'None'
        'Basic128'
        'Basic192'
        'Basic192Rsa15'
        'Basic256Rsa15'
        'Basic256Sha256'
        'Aes128_Sha256'
        'Aes128_Sha256_RsaOaep'
        'Aes256_Sha256_RsaPss'
        'PubSub_Aes128_CTR'
        'PubSub_Aes256_CTR'
        'Basic256'
      }}
    as |el|>
      <el.control @triggerClass='form-control' as |opt|>
        {{#if (eq opt 'None')}}
          {{t 'none'}}
        {{else}}
          {{opt}}
        {{/if}}
      </el.control>
    </@form.element>
  </div>
  <div class='col'>
    <@form.element
      class='hardware-security-mode'
      @controlType='power-select'
      @label={{t 'securityMode'}}
      @property='securityMode'
      @options={{array
        'None'
        'Sign'
        'SignAndEncrypt'
      }}
    as |el|>
      <el.control @triggerClass='form-control' as |opt|>
        {{t opt}}
      </el.control>
    </@form.element>
  </div>
</div>

<div class="row form-row">
  <div class="col">
    <@form.element
      @controlType="text"
      @property="username"
      @label={{t "username"}}
      class="hardware-user"
    />
  </div>
  <div class="col">
    <@form.element
      @controlType="password"
      @property="password"
      @label={{t "password"}}
      class="hardware-password"
    />
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
