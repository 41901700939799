import Service from '@ember/service';
import Evented from '@ember/object/evented';
import { task, waitForEvent } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { isTesting, macroCondition } from '@embroider/macros';

export default class ResizeListener extends Service.extend(Evented) {
  @tracked navCompressed = false;
  @tracked navOpen = true;

  start = task({ onState: null }, async () => {
    if (macroCondition(isTesting())) {
      return;
    }

    while (true) {
      await waitForEvent(globalThis, 'resize');
      this.trigger('resized');
    }
  });

  triggerResize = () => {
    this.trigger('resized');
  };

  expandNav = () => {
    this.navCompressed = false;
  };

  compressNav = () => {
    this.navCompressed = true;
  };

  openNav = () => {
    this.navOpen = true;
  };

  closeNav = () => {
    this.navOpen = false;
  };
}
