import ApplicationSerializer from 'eflex/serializers/application';

export default class HardwareSerializer extends ApplicationSerializer {
  attrs = {
    hardwareIos: {
      deserialize: false,
      serialize: false,
    },
    tasks: {
      deserialize: false,
      serialize: false,
    },
    triggers: {
      deserialize: false,
      serialize: false,
    },
    stationHardwares: {
      deserialize: false,
      serialize: false,
    },
  };
}
