import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { caption, arrayAttr } from 'eflex/decorators';

@buildValidations({
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
    }),
  ],
})
class CustomIdentifier extends Model {
  @attr('boolean', { defaultValue: true }) enabled;
  @arrayAttr captions;
  @caption name;

  @belongsTo('area', { inverse: 'customIdentifiers', async: false }) area;
}

export default CustomIdentifier;
