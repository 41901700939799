import moment from 'moment-timezone';

export default function formatDate(value, dateFormat, timeFormat) {
  if (!value) {
    return null;
  }

  dateFormat ??= 'L';
  timeFormat ??= 'LTS';

  return moment(new Date(value)).format(`${dateFormat} ${timeFormat}`);
}
