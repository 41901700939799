import { template as template_e6230d0bc16b4e63b197b62e78e3942b } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
// eslint-disable-next-line no-restricted-imports
import $ from 'jquery';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { without, pluck, zipObj } from 'ramda';
import { registerDestructor } from '@ember/destroyable';
import StandardModal from 'eflex/components/standard-modal';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
let loadedJqueryQueryBuilder = false;
const createRecordFromRule = (rule, parent, options, store)=>{
    const componentOption = options.find((item)=>item.id === rule.value);
    const newRule = store.createRecord('complexComponentOption', {
        parent,
        componentOption,
        comparedComponent: componentOption?.component
    });
    if (rule.condition) {
        newRule.condition = rule.condition;
    }
    if (rule.operator) {
        newRule.operatorName = rule.operator;
    }
    rule.rules?.forEach((nestedRule)=>{
        createRecordFromRule(nestedRule, newRule, options, store);
    });
};
export default class ComponentComplexOptionBuilder extends Component {
    @service
    store;
    @service
    notifier;
    #queryBuilder;
    loadQueryBuilder = task(waitFor(async (element)=>{
        if (!loadedJqueryQueryBuilder) {
            await import('jQuery-QueryBuilder');
            loadedJqueryQueryBuilder = true;
        }
        const rules = this.args.selectedOption.toRulesObject();
        rules.rules ??= []; // ensure top level has rules
        // eslint-disable-next-line ember/no-jquery
        $(element).queryBuilder({
            rules,
            default_condition: this.args.selectedOption.condition,
            allow_empty: true,
            allow_invalid: true,
            filters: this.args.components.map((component)=>{
                const options = without([
                    this.args.selectedOption
                ], component.options);
                return {
                    id: component.id,
                    label: component.name,
                    type: 'string',
                    input: 'select',
                    operators: [
                        'equal',
                        'not_equal'
                    ],
                    values: zipObj(pluck('id', options), pluck('name', options))
                };
            })
        });
        registerDestructor(this, ()=>{
            element.queryBuilder.destroy();
        });
        this.#queryBuilder = element.queryBuilder;
    }));
    updateComplexOption = ()=>{
        const queryBuilder = this.#queryBuilder;
        if (!queryBuilder.validate()) {
            this.notifier.sendError('components.options.invalidComplexOption');
            return;
        }
        const updated = queryBuilder.getRules({
            allow_invalid: true
        });
        const allOptions = this.args.components.flatMap((component)=>component.options);
        const selectedOption = this.args.selectedOption;
        selectedOption.condition = updated.condition;
        selectedOption.rules.forEach((rule)=>{
            rule.deleteRecord();
        });
        updated.rules.forEach((rule)=>{
            createRecordFromRule(rule, selectedOption, allOptions, this.store);
        });
        this.args.onConfirm?.(selectedOption);
    };
    static{
        template_e6230d0bc16b4e63b197b62e78e3942b(`
    <StandardModal
      class="complex-option-modal"
      @onConfirm={{this.updateComplexOption}}
      @onCancel={{@onCancel}}
      @size="lg"
    as |modal|>
      <modal.header @text={{t "plant.areas.components.rulesBuilder"}} />
      <modal.body class="complex-option-builder">
        <BsForm @model={{@selectedOption}} class="mb-4" as |form|>
          <form.element
            @controlType="text"
            @label={{t "description"}}
            @property="name"
          as |el|>
            <el.control @disabled={{@disabled}} class="builder-complex-option-name" />
          </form.element>
        </BsForm>
        <div
          class="query-builder {{if @disabled "pe-none"}}"
          {{didInsert this.loadQueryBuilder.perform}}
          {{didUpdate this.updateQueryBuilder}}
        >
        </div>
      </modal.body>
      <modal.footer/>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
