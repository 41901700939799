import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { copyable, arrayAttr, caption } from 'eflex/decorators';
import { rollbackBelongsTo, isBelongsToRefDirty } from 'eflex/util/relationship-helpers';
import { isPresent } from '@ember/utils';
import { taskTypes } from 'eflex/constants/tasks/task-types';

@copyable
@buildValidations({
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
    }),
  ],

  opcUaNodeId: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      get disabled() {
        const task = this.model.task;
        return task?.opcUaType !== 'variable' || task.taskType !== taskTypes.opcUa;
      },
    }),
  ],

  type: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      get disabled() {
        return this.model.isFromDevice && !this.model.task?.isPlc;
      },
    }),
  ],

  length: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      get disabled() {
        return !this.model.task?.isPlc || this.model.type !== 'string';
      },
    }),
  ],

  variableDefForValue: [
    validator('presence', {
      presence: true,
      get disabled() {
        return this.model.type !== 'variable' ||
          isPresent(this.model.variableDefConfigs);
      },
    }),
  ],
})
class VariableDef extends Model {
  @attr('string', { defaultValue: 'string' }) type;
  @attr('number') length;
  @attr('boolean', { defaultValue: false }) isFromDevice;
  @attr('string') opcUaNodeId;
  // only for non-model specific
  @attr value;

  @arrayAttr captions;
  @arrayAttr unitCaptions;
  @arrayAttr falseUnitCaptions;

  @caption name;
  @caption('unitCaptions') unit;
  @caption('falseUnitCaptions') falseUnit;

  @belongsTo('task', { async: false, inverse: 'variableDefs' }) task;
  @hasMany('variableDefConfig', { async: false, inverse: 'variableDef', cascadeDelete: true }) variableDefConfigs;

  @hasMany('edhrMapping', { async: false, inverse: 'variableDef', cascadeDelete: true }) edhrMappings;

  @hasMany('variableDef', {
    async: false,
    inverse: 'variableDefForValue',
    cascadeDelete: true,
  }) variableDefsWithReference;

  @belongsTo('variableDef', { async: false, inverse: 'variableDefsWithReference' }) variableDefForValue;

  get isDirty() {
    return super.isDirty || isBelongsToRefDirty(this, 'variableDefForValue');
  }

  get errorIdentifier() {
    return this.task?.name;
  }

  get selectedValue() {
    return this.variableDefForValue;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['task', 'variableDefConfigs', 'edhrMappings', 'variableDefsWithReference']),
      copyByReference: new Set(['variableDefForValue']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'variableDefForValue');
    super.rollbackAttributes();
  }
}

export default VariableDef;
