import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';

export default class KineticOperationsOperationRoute extends AuthenticatedRoute {
  @service store;

  model(params) {
    return this.store.peekRecord('kineticOperation', params.id);
  }

  setupController(controller, operation) {
    Object.assign(controller, {
      operation,
      activeTaskPanelTab: 'task-device',
    });

    controller.loadAssemblyOps.perform(operation?.part);
  }

  resetController(controller) {
    controller.closeAllPanels();

    Object.assign(controller, {
      taskPanelModel: null,
      selectedTask: null,
    });
  }
}
