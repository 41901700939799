import Controller, { inject as controller } from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { pluck } from 'ramda';
import { waitFor } from '@ember/test-waiters';

const operationDeletedAndActive = (operation, activeAssemblyOps) => {
  return operation.kineticDeleted && pluck('operation', activeAssemblyOps)?.includes(operation);
};

export default class KineticOperationsController extends Controller {
  @service kineticRepo;
  @service router;
  @service store;
  @controller('kineticOperations/operation') operationController;

  @tracked showDeletePartModal = false;
  @tracked showDeletePartRevModal = false;
  @tracked showDeleteOperationModal = false;

  get activeAssemblyOps() {
    return this.operationController.activeAssemblyOps;
  }

  get bomIsSyncing() {
    return this.kineticRepo.kinetic.bom.isSyncing;
  }

  get partSelectDisabled() {
    return this.bomIsSyncing || !isEmpty(this.operationController.operation?.sourceJobNumber);
  }

  get partDeleteDisabled() {
    if (isEmpty(this.activeAssemblyOps)) {
      return false;
    }

    const deletedActiveOps = this.operationController.selectedPart?.operations?.filter(operation => {
      return operationDeletedAndActive(operation, this.activeAssemblyOps);
    });

    return !isEmpty(deletedActiveOps);
  }

  get partRevSelectDisabled() {
    return this.partSelectDisabled || isEmpty(this.operationController.selectedPart);
  }

  get partRevDeleteDisabled() {
    if (isEmpty(this.activeAssemblyOps)) {
      return false;
    }

    const deletedActiveOps = this.operationController.selectedPartRev?.operations?.filter(operation => {
      return operationDeletedAndActive(operation, this.activeAssemblyOps);
    });

    return !isEmpty(deletedActiveOps);
  }

  get operationSelectDisabled() {
    return this.partRevSelectDisabled || isEmpty(this.operationController.selectedPartRev);
  }

  get operationDeleteDisabled() {
    if (isEmpty(this.activeAssemblyOps)) {
      return false;
    }

    return operationDeletedAndActive(this.operationController.operation, this.activeAssemblyOps);
  }

  loadPart = task(waitFor(async (part) => {
    this.router.transitionTo('kineticOperations');

    Object.assign(this.operationController, {
      selectedPart: part,
      selectedPartRev: null,
      operation: null,
      activeAssemblyOps: null,
    });

    if (!isEmpty(part)) {
      await this.operationController.loadAssemblyOps.perform(part);
    }
  }));

  @action
  loadPartRev(partRev) {
    this.router.transitionTo('kineticOperations');

    Object.assign(this.operationController, {
      selectedPartRev: partRev,
      operation: null,
    });
  }

  @action
  loadOperation(operation) {
    if (isEmpty(operation)) {
      this.operationController.operation = null;
      this.router.transitionTo('kineticOperations');
    } else {
      this.router.transitionTo('kineticOperations.operation', operation);
    }
  }

  sync = task(async () => {
    await this.kineticRepo.syncBom.perform();
  });

  @action
  deletePart() {
    this.showDeletePartModal = false;
    this.operationController.selectedPart.destroyRecord();
    this.loadPart.perform(null);
  }

  @action
  deletePartRev() {
    this.showDeletePartRevModal = false;
    this.operationController.selectedPartRev.destroyRecord();
    this.loadPartRev(null);
  }

  @action
  deleteOperation() {
    this.showDeleteOperationModal = false;
    this.operationController.operation.destroyRecord();
    this.operationController.operation = null;
    this.router.transitionTo('kineticOperations');
  }
}
