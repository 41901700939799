import { template as template_50acc3017e664518ae15291110674364 } from "@ember/template-compiler";
import UserPermissions from 'eflex/constants/user-permissions';
import { userRoles, userRolesValues } from 'eflex/constants/user-roles';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { pipe, map } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import SidePanel from 'eflex/components/side-panel';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { or } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import BsButton from 'eflex/components/bs-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import BsTooltip from 'eflex/components/bs-tooltip';
import TagsSelect from 'eflex/components/tags-select';
export default class UserConfigPanel extends Component {
    @service
    intl;
    @service
    licensing;
    @service
    workInstructionRepo;
    @service
    userRepo;
    @service
    currentUser;
    get userRoleOptions() {
        return userRolesValues;
    }
    get permissionOptions() {
        return pipe(map((permission)=>{
            permission.translatedName = this.intl.t(permission.name);
            return permission;
        }), sortByProp('translatedName'))([
            ...UserPermissions
        ]);
    }
    get selectedPermissions() {
        return this.args.selectedUser?.permissions.map((permission)=>UserPermissions.find((item)=>item.value === permission));
    }
    setPermissions = (permissions)=>{
        this.args.selectedUser.permissions = permissions.map((item)=>item.value);
    };
    setUserRole = (role)=>{
        if (role === userRoles.ViewOnly.label) {
            Object.assign(this.args.selectedUser, {
                visibilityTags: [],
                authorizationTags: [],
                wieEditorTags: [],
                wieApproverTags: [],
                wieDeployerTags: [],
                configEditorTags: []
            });
        }
        this.args.selectedUser.role = role;
    };
    static{
        template_50acc3017e664518ae15291110674364(`
    <SidePanel
      class="user-config-panel"
      @header={{if @selectedUser.isNew (t "newUser") (t "users.editUser")}}
      @subheader={{unless @selectedUser.isNew @selectedUser.userName}}
      @onClose={{@onClose}}
      ...attributes
    >
      <div class="user-config-panel h-100">
        <BsForm
          @model={{@selectedUser}}
          @showValidations={{@showValidations}}
          class="d-flex flex-column h-100"
        as |form|>
          <div class="p-3 mb-0">
            <div class="row form-row gy-3">
              <div class="col-6">
                <form.element
                  @controlType="text"
                  @property="userName"
                  @label={{t "username"}}
                  @onChange={{fn (mut @selectedUser.userName)}}
                  class="user-name is-required"
                as |el|>
                  <el.control disabled={{@selectedUser.isSso}} />
                </form.element>
              </div>
              <div class="col-6">
                <form.element
                  @controlType="power-select"
                  @options={{this.userRoleOptions}}
                  @property="role"
                  @label={{t "settings.users.userRole"}}
                  @onChange={{this.setUserRole}}
                  class="roles is-required"
                />
              </div>
              <div class="col-6">
                <form.element
                  @controlType="text"
                  @property="email"
                  @label={{t "settings.users.userEmail"}}
                  @onChange={{fn (mut @selectedUser.email)}}
                  class="email"
                />
              </div>
              <div class="col-6">
                <form.element
                  @controlType="textarea"
                  @property="notes"
                  @label={{t "notes"}}
                  @onChange={{fn (mut @selectedUser.notes)}}
                  class="notes"
                as |el|>
                  <el.control rows={{1}} />
                </form.element>
              </div>

              {{#if @selectedUser.isNew}}
                <div class="col-12">
                  <div class="row form-row">
                    <div class="col-6">
                      <form.element
                        @controlType="password"
                        @property="password"
                        @label={{t "password"}}
                        class="password is-required"
                      />
                    </div>
                    <div class="col-6">
                      <form.element
                        @controlType="password"
                        @property="passwordConfirmation"
                        @label={{t "settings.users.user.confirmpassword"}}
                        class="confirm-password is-required"
                      as |el|>
                        <el.control
                          disabled={{this.disabled}}
                        />
                      </form.element>
                    </div>
                  </div>
                </div>
              {{/if}}
              <div class="col-6">
                <form.element
                  @property="timeoutLength"
                  @controlType="number"
                  @label={{t "settings.users.timeoutLength"}}
                  @onChange={{fn (mut @selectedUser.timeoutLength)}}
                  class="timeout-length"
                />
              </div>
              <div class="col-6 align-self-end">
                <form.element
                  @controlType="checkbox"
                  @property="timeoutWarning"
                  @label={{t "settings.users.showWarning"}}
                  class="enable-checkbox mb-2"
                />
              </div>
              <div class="col-6 mx-auto">
                <div class="row my-3">
                  <div class="col d-flex">
                    <BsButton
                      @type="outline-danger"
                      @onClick={{@openChangeEncryptedPasswordModal}}
                      @disabled={{@selectedUser.isDirty}}
                      class="change-password ms-auto"
                    >
                      <FaIcon @icon="key" @fas="solid" @size="xs" class="me-1"/>
                      {{t "users.changePassword"}}
                    </BsButton>
                    {{#if @selectedUser.isDirty}}
                      <BsTooltip @placement="top">
                        {{t "users.passwordDisabled"}}
                      </BsTooltip>
                    {{/if}}
                  </div>
                  {{#if this.currentUser.user.isAdmin}}
                    <div class="col">
                      <BsButton
                        @type="outline-danger"
                        @onClick={{@openChangeEncryptedBadgeIdModal}}
                        @disabled={{@selectedUser.isDirty}}
                        class="change-badge"
                      >
                        {{t "users.changeBadgeId"}}
                      </BsButton>
                      {{#if @selectedUser.isDirty}}
                        <BsTooltip @placement="top">
                          {{t "users.badgeIdDisabled"}}
                        </BsTooltip>
                      {{/if}}
                    </div>
                  {{/if}}
                </div>
              </div>
            </div>
          </div>

          <div class="bg-gray-100 flex-fill p-3 mb-0">
            <div class="row form-row gy-3">
              {{#if this.licensing.license.edhrEnabled}}
                <div class="col-6">
                  <form.element
                    @controlType="password"
                    @property='encryptedPassword'
                    @onChange={{fn (mut @selectedUser.encryptedPassword)}}
                    @label={{t "settings.users.user.encryptedPassword"}}
                    class="encrypted-password"
                  as |el|>
                    <el.control disabled={{this.disabled}} />
                  </form.element>
                </div>
              {{/if}}

              <div class="col-6">
                <form.element
                  @controlType="power-select-multiple"
                  @options={{this.permissionOptions}}
                  @value={{this.selectedPermissions}}
                  @label={{t "settings.users.permissions"}}
                  @onChange={{this.setPermissions}}
                  @optionLabelPath="translatedName"
                  @searchEnabled={{true}}
                  @selected={{this.selectedPermissions}}
                  class="permission-select" as |el|
                >
                  <el.control
                    @triggerClass="form-control"
                    @searchField="translatedName"
                  />
                </form.element>
              </div>
              <div class="col-6">
                <form.element class="authorization-select" @label={{t "settings.users.authorizationTags"}}>
                  <TagsSelect
                    @siblings={{this.userRepo.users}}
                    @taggedModel={{@selectedUser}}
                    @fieldName="authorizationTags"
                    @disabled={{or this.disabled @selectedUser.isNotUser}}
                  />
                </form.element>
              </div>
              <div class="col-6 mb-4">
                <form.element class="visibility-select" @label={{t "settings.users.visibilityTags"}}>
                  <TagsSelect
                    @siblings={{this.userRepo.users}}
                    @taggedModel={{@selectedUser}}
                    @fieldName="visibilityTags"
                    @disabled={{or this.disabled @selectedUser.isNotUser}}
                  />
                </form.element>
              </div>

              <div class="col-6">
                <form.element class="wie-editor-select" @label={{t "settings.users.wieEditorTags"}}>
                  <TagsSelect
                    @siblings={{this.userRepo.users}}
                    @taggedModel={{@selectedUser}}
                    @fieldName="wieEditorTags"
                    @disabled={{or this.disabled @selectedUser.isNotUser}}
                    @additionalOptions={{this.workInstructionRepo.systemFolderWieEditorTags}}
                  />
                </form.element>
              </div>

              <div class="col-6">
                <form.element class="wie-approver-select" @label={{t "settings.users.wieApproverTags"}}>
                  <TagsSelect
                    @siblings={{this.userRepo.users}}
                    @taggedModel={{@selectedUser}}
                    @fieldName="wieApproverTags"
                    @disabled={{or this.disabled @selectedUser.isNotUser}}
                    @additionalOptions={{this.workInstructionRepo.systemFolderWieApproverTags}}
                  />
                </form.element>
              </div>

              <div class="col-6">
                <form.element class="wie-deployer-select" @label={{t "settings.users.wieDeployerTags"}}>
                  <TagsSelect
                    @siblings={{this.userRepo.users}}
                    @taggedModel={{@selectedUser}}
                    @fieldName="wieDeployerTags"
                    @disabled={{or this.disabled @selectedUser.isNotUser}}
                    @additionalOptions={{this.workInstructionRepo.systemFolderWieDeployerTags}}
                  />
                </form.element>
              </div>
            </div>
            {{#if @selectedUser.isNotAdmin}}
              <div class="row mt-4 pt-3">
                <div class="col-6">
                  <form.element
                    class="configuration-editor-select mb-2"
                    @label={{t "settings.users.configurationEditorTags"}}
                  >
                    <TagsSelect
                      @siblings={{this.userRepo.users}}
                      @taggedModel={{@selectedUser}}
                      @fieldName="configEditorTags"
                      @disabled={{or this.disabled @selectedUser.isNotUser}}
                    />
                  </form.element>
                </div>
              </div>
            {{/if}}
          </div>
        </BsForm>
      </div>
    </SidePanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
