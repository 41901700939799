import { template as template_21efbdc5242649a3926959bf1cfc804c } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import TaskStatuses from 'eflex/constants/task-statuses';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import BsButton from 'eflex/components/bs-button';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import { isNull } from 'eflex/helpers';
import PortalTarget from 'ember-stargate/components/portal-target';
import StandardModal from 'eflex/components/standard-modal';
import CodeConfirmationModal from 'eflex/components/modals/code-confirmation';
class JemActions extends Component {
    @tracked
    showScrapModal = false;
    @tracked
    showScrapCodeModal = false;
    @tracked
    showStopModal = false;
    @tracked
    showRejectModal = false;
    onScrap = ()=>{
        if (this.args.station.confirmScrapCode) {
            this.showScrapCodeModal = true;
            return;
        }
        this.showScrapModal = true;
    };
    onReject = ()=>{
        if (this.args.station.confirmRejects) {
            this.showRejectModal = true;
            return;
        }
        return this.args.setStatusAndSave(TaskStatuses.REJECTED);
    };
    onConfirmScrap = task({
        drop: true
    }, waitFor(async ()=>{
        await this.args.setStatusAndSave(TaskStatuses.SCRAPPED);
        this.showScrapModal = false;
    }));
    onConfirmScrapCode = task({
        drop: true
    }, waitFor(async (codes)=>{
        await this.args.setStatusAndSave(TaskStatuses.SCRAPPED, codes);
        this.showScrapCodeModal = false;
    }));
    onConfirmStop = task({
        drop: true
    }, waitFor(async ()=>{
        await this.args.setStatusAndSave(TaskStatuses.STOPPED);
        this.showStopModal = false;
    }));
    onRejectConfirm = task({
        drop: true
    }, waitFor(async (codes)=>{
        await this.args.setStatusAndSave(TaskStatuses.REJECTED, codes);
        this.showRejectModal = false;
    }));
    static{
        template_21efbdc5242649a3926959bf1cfc804c(`
    {{#if @station.jemShowReject}}
      <BsButton
        class="reject flex-fill mt-3"
        @type="danger"
        @size="lg"
        @disabled={{or @disabled @isHolding}}
        @onClick={{this.onReject}}
      >
        {{t "plant.station.jem.rejectPart"}}
      </BsButton>
    {{/if}}

    {{#if @station.jemShowScrap}}
      <BsButton
        class="scrap flex-fill mt-3"
        @type="danger"
        @size="lg"
        @disabled={{@disabled}}
        @onClick={{this.onScrap}}
      >
        {{t "plant.station.jem.scrapPart"}}
      </BsButton>
    {{/if}}

    {{#if @station.jemShowStop}}
      <BsButton
        class="stop flex-fill mt-3"
        @type="stop"
        @size="lg"
        @disabled={{@disabled}}
        @onClick={{fn (mut this.showStopModal) true}}
      >
        {{t "stop"}}
      </BsButton>
    {{/if}}

    {{#if @station.jemShowHold}}
      <BsButton
        class="hold flex-fill mt-3"
        @type="hold"
        @size="lg"
        @disabled={{or @disabled (isNull @taskConfig)}}
        @onClick={{@onHold}}
      >
        {{#if @isHolding}}
          {{t "unhold"}}
        {{else}}
          {{t "hold"}}
        {{/if}}
      </BsButton>
    {{/if}}

    {{#if @isListView}}
      <PortalTarget @name="jem-ok-button-portal" class="jem-ok-button-portal flex-fill w-100 mt-3">
        <div class="extra-ok-button h-100">
          <BsButton @type="success" @size="lg" @disabled={{true}} class="ok">
            {{t "ok"}}
          </BsButton>
        </div>
      </PortalTarget>
    {{/if}}

    {{#if this.showScrapModal}}
      <StandardModal
        class="jem jem-confirm-scrap"
        @size="sm"
        @onConfirm={{this.onConfirmScrap.perform}}
        @onCancel={{fn (mut this.showScrapModal) false}}
      as |modal|>
        <modal.header @text={{t "plant.station.jem.scrapPart"}} />
        <modal.body>
          <h4>
            {{t "jem.scrapWarning" serialNumber=@serialNumber}}
          </h4>
        </modal.body>
        <modal.footer as |modal-footer|>
          <modal-footer.confirm>
            {{t "scrap"}}
          </modal-footer.confirm>
          <modal-footer.close />
        </modal.footer>
      </StandardModal>
    {{/if}}

    {{#if this.showScrapCodeModal}}
      <CodeConfirmationModal
        class="jem scrap-code-confirm"
        @codeType="scrap"
        @station={{@station}}
        @modalHeader={{t "scrapCodesAndNotes"}}
        @selectLabel={{t "scrapCodes"}}
        @onCancel={{fn (mut this.showScrapCodeModal) false}}
        @onConfirm={{this.onConfirmScrapCode.perform}}
      />
    {{/if}}

    {{#if this.showStopModal}}
      <StandardModal
        class="jem jem-confirm-stop"
        @onConfirm={{this.onConfirmStop.perform}}
        @onCancel={{fn (mut this.showStopModal) false}}
        @size="sm" as |modal|
      >
        <modal.header @text={{t "plant.station.jem.stopPart"}} />
        <modal.body>
          <h4>
            {{t "jem.stopWarning" serialNumber=@serialNumber}}
          </h4>
        </modal.body>
        <modal.footer as |modal-footer|>
          <modal-footer.confirm>
            {{t "stop"}}
          </modal-footer.confirm>
          <modal-footer.close />
        </modal.footer>
      </StandardModal>
    {{/if}}

    {{#if this.showRejectModal}}
      <CodeConfirmationModal
        class="jem"
        @codeType="reject"
        @station={{@station}}
        @modalHeader={{t "rejectCodesAndNotes"}}
        @selectLabel={{t "rejectCodes"}}
        @onCancel={{fn (mut this.showRejectModal) false}}
        @onConfirm={{this.onRejectConfirm.perform}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default JemActions;
