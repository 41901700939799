import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import modelDisplayName from 'eflex/helpers/model-display-name';
import { getOwner } from '@ember/application';
import nonUnique from 'eflex/util/non-unique';

@buildValidations({
  code: [
    validator('presence', { presence: true }),

    validator('inline', {
      validate(code, options, model) {
        const modelCodeLength = model.area?.modelCodeLength;
        if (modelCodeLength !== code?.length && !model.area?.advancedModelCodeEnabled) {
          return getOwner(model).lookup('service:intl').t('plant.models.code.exactLength', { count: modelCodeLength });
        }
        return true;
      },
    }),

    validator('inline', {
      validate(code, options, model, attribute) {
        const nonUniqueModelCodes = nonUnique(model.area?.models.filter(item => !item.isDeleted), attribute);

        if (nonUniqueModelCodes?.includes(code)) {
          return getOwner(model).lookup('service:intl').t('logs.nonUniqueFields');
        } else {
          return true;
        }
      },
    }),
  ],
})
class EflexModel extends Model {
  @attr('string') name;
  @attr('string') code;
  @attr('string') description;

  @belongsTo('area', { async: false, inverse: 'models' }) area;
  @hasMany('taskConfig', { async: false, inverse: 'configModel' }) taskConfigs;
  @hasMany('productionSchedule', { async: false, inverse: 'model', cascadeDelete: true }) productionSchedules;
  @hasMany('jemConfiguration', { polymorphic: true, async: false, inverse: 'model' }) jemConfigurations;
  @hasMany('jemPrerequisite', { async: false, inverse: 'configModel', cascadeDelete: true }) jemPrerequisites;

  get displayName() {
    return modelDisplayName(this);
  }

  asHistory() {
    return {
      id: this.id,
      area: this.area?.id,
      name: this.name,
      code: this.code,
    };
  }
}

export default EflexModel;
