import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class PartGenealogyController extends Controller {
  queryParams = ['searchTerm', 'searchMode', 'beginDate', 'endDate'];

  @tracked searchMode = 'descendants';
  @tracked beginDate;
  @tracked endDate;
  @tracked searchTerm;

  onParamChange = (key, value) => {
    this[key] = value;
  };
}
