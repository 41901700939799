import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';

export default class SettingsKineticRoute extends AuthenticatedRoute {
  @service licensing;
  @service router;

  async beforeModel() {
    await super.beforeModel(...arguments);
    if (this.licensing.license.kineticDisabled) {
      this.router.transitionTo('settings.licensing');
    }
  }
}
