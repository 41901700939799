import { template as template_ed943d34593b420291159cd7131b423d } from "@ember/template-compiler";
import TaskVariablesConfig from 'eflex/components/task-variables-config';
export default template_ed943d34593b420291159cd7131b423d(`
<TaskVariablesConfig
  @form={{@form}}
  @disabled={{@disabled}}
  @task={{@task}}
  @taskConfig={{@taskConfig}}
/>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
