import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isBlank, isPresent } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { service } from '@ember/service';
import { validIpAddress } from 'eflex/constants/regex';
import { portValidator, checkIpUnique } from 'eflex/util/validators';
import { getOwner } from '@ember/application';

@buildValidations({
  name: [validator('presence', true)],

  port: [
    portValidator,
    validator('inline', {
      validate(port, options, plcHardware) {
        return checkIpUnique(
          plcHardware,
          getOwner(plcHardware).lookup('service:hardwareRepo').plcHardwares,
        );
      },
    }),
    validator('presence', true),
  ],

  ipAddress: [
    validator('format', {
      regex: validIpAddress,
      allowBlank: false,
    }),
    validator('inline', {
      validate(ipAddress, options, plcHardware) {
        return checkIpUnique(
          plcHardware,
          getOwner(plcHardware).lookup('service:hardwareRepo').plcHardwares,
        );
      },
    }),
  ],
})
class PlcHardware extends Hardware {
  @attr('string') ipAddress;
  @attr('number', { defaultValue: 2013 }) port;
  @attr('number', { defaultValue: 4 }) plcManufacturer; // default to 4 for rockwell

  @service intl;

  get isPlc() {
    return true;
  }

  get hardwareGroup() {
    return this.intl.t('plcHardware');
  }

  get fullAddress() {
    if (isBlank(this.ipAddress) || isBlank(this.port)) {
      return null;
    }

    return `${this.ipAddress}:${this.port}`;
  }

  get isConfigured() {
    return isPresent(this.ipAddress) && isPresent(this.port);
  }
}

export default PlcHardware;
