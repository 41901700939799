import { template as template_563b77d1510d4447b061247bbfa22077 } from "@ember/template-compiler";
import { isBlank } from '@ember/utils';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import BsButtonGroup from 'ember-bootstrap/components/bs-button-group';
import BsPopover from 'eflex/components/bs-popover';
import BsTooltip from 'eflex/components/bs-tooltip';
import { caption } from 'eflex/helpers';
import { isPresent } from '@eflexsystems/ember-composable-helpers';
import { fn, hash } from '@ember/helper';
import { t } from 'ember-intl';
import { or, not, eq, and } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import onKey from 'ember-keyboard/modifiers/on-key';
const datumIsRequired = (taskConfig, datum, isReadOnly)=>{
    if (!taskConfig) {
        return false;
    }
    if (isReadOnly) {
        return false;
    }
    return datum.required;
};
const getBitOptions = (datum)=>{
    return [
        {
            label: datum.unitName,
            value: true
        },
        {
            label: datum.falseUnitName,
            value: false
        }
    ];
};
class JemTaskButtonDetails extends Component {
    #elem;
    get processData() {
        return this.args.childStatus?.processData ?? this.args.variableDefs.map(({ name, type })=>({
                name,
                type
            }));
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.#elem = null;
    }
    selectNextFocusable(previousIndex) {
        this.#elem.querySelector(`.process-data-input[tabindex='${previousIndex + 1}']`)?.focus();
    }
    tryCompleteTask() {
        if (!this.args.isAuto || this.processData.some((item)=>item.isInvalid)) {
            return;
        }
        this.args.onTaskComplete(TaskStatuses.GOOD);
    }
    onDidInsert = (element)=>{
        this.#elem = element;
        this.focusOnStart(element, [
            this.args.childStatus?.isFinished
        ]);
    };
    focusOnStart = (element, [isFinished])=>{
        if (isFinished == null || isFinished) {
            return;
        }
        this.selectNextFocusable(-1);
    };
    updateProcessDatum = (datum, { target })=>{
        let value = target?.value;
        // eslint-disable-next-line no-useless-escape
        const negativeDecimalRegex = /^-0[\.]?[0]*$/;
        if (datum.type === 'number' && !isBlank(value) && !isNaN(value) && !negativeDecimalRegex.test(value)) {
            value = Number(value);
        }
        Object.assign(datum, {
            wasFocused: true,
            value
        });
    };
    didSelectProcessDatumButton = (datum, previousIndex, value)=>{
        datum.value = value;
        this.selectNextFocusable(previousIndex);
        this.tryCompleteTask();
    };
    onEnter = (previousIndex)=>{
        this.selectNextFocusable(previousIndex);
        this.tryCompleteTask();
    };
    static{
        template_563b77d1510d4447b061247bbfa22077(`
    {{#if (or @variableDefs @childStatus.processData)}}
      <div ...attributes>
        <div
          class="jem-process-data-inputs mt-2"
          {{didInsert this.onDidInsert}}
          {{didUpdate this.focusOnStart @childStatus.isFinished @disabled}}
        >
          {{#each this.processData as |datum index|}}
            {{#let
              (isPresent datum.lowerLimit)
              (isPresent datum.upperLimit)
              (if @taskConfig datum.isInvalid)
              (eq datum.variableDefRecord.type "number")
              (or (not @childStatus) @childStatus.isFinished)
            as |hasLowerLimit hasUpperLimit isInvalid isNumber isReadOnly|}}
              <div class="jem-process-data mb-2">
                <div class="d-flex">
                  <div class="process-data-name form-label text-gray-800 mb-0">
                    {{datum.name}}
                    {{#if (datumIsRequired @taskConfig datum isReadOnly)}}
                      <span class="text-danger">
                        *
                      </span>
                    {{/if}}
                  </div>
                  {{#if (and isNumber (or hasLowerLimit hasUpperLimit))}}
                    <div class="variable-def-limits fs-4 fw-semibold text-gray-800 ms-2">
                      &#40;
                      {{#if (and hasLowerLimit hasUpperLimit)}}
                        {{t "lowerUpper"}}:
                        <span class="text-primary fw-bold">{{datum.lowerLimit}}</span> -
                        <span class="text-primary fw-bold">{{datum.upperLimit}}</span>
                      {{else if hasLowerLimit}}
                        {{t "lowerLimit"}}: <span class="text-primary fw-bold">{{datum.lowerLimit}}</span>
                      {{else if hasUpperLimit}}
                        {{t "upperLimit"}}: <span class="text-primary fw-bold">{{datum.upperLimit}}</span>
                      {{/if}}
                      &#41;
                    </div>
                  {{/if}}
                </div>
                <div class="process-data-value detail-text-value fw-bold text-gray-900">
                  {{#if isReadOnly}}
                    <span class="fw-bold">{{datum.displayValue}}</span>
                  {{else if (eq datum.type "boolean")}}
                    <BsButtonGroup
                      class="radio-btn-group z-0 process-data-bit process-data-input mt-1"
                      @type="radio"
                      @value={{datum.value}}
                      @onChange={{fn this.didSelectProcessDatumButton datum index}}
                      tabindex={{index}}
                    as |bg|>
                      {{#each (getBitOptions datum) as |option|}}
                        <bg.button
                          @value={{option.value}}
                          disabled={{@disabled}}
                        >
                          <div class="px-2 py-1">{{option.label}}</div>
                        </bg.button>
                      {{/each}}
                    </BsButtonGroup>
                  {{else}}
                    <div class="d-flex align-items-center mt-1">
                      <input
                        class="form-control {{if isInvalid "is-invalid"}} process-data-input"
                        value={{datum.value}}
                        type={{if isNumber "number"}}
                        step={{if isNumber "any"}}
                        disabled={{@disabled}}
                        tabindex={{index}}
                        {{on "input" (fn this.updateProcessDatum datum)}}
                        {{onKey 'Enter' (fn this.onEnter index)}}
                      />
                      {{#if isInvalid}}
                        <BsPopover
                          class="process-data-invalid-popover"
                          @visible={{datum.wasFocused}}
                          @placement="bottom"
                          @triggerEvents="hover"
                        >
                          {{datum.validations.attrs.value.message}}
                        </BsPopover>
                      {{/if}}
                      <div class="detail-text-value fw-bold text-gray-900 ms-2 me-3">
                        {{caption datum.unitCaptions}}
                      </div>
                    </div>
                  {{/if}}
                  {{#if @notAuthorized}}
                    <BsTooltip>
                      {{t "notAuthorized"}}
                    </BsTooltip>
                  {{/if}}
                </div>
              </div>
            {{/let}}
          {{/each}}
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default template_563b77d1510d4447b061247bbfa22077(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskButtonDetails
        disabled=@disabled
        notAuthorized=@notAuthorized
        isAuto=@task.isAuto
        variableDefs=@task.variableDefs
        childStatus=@childStatus
        taskConfig=@taskConfig
        onTaskComplete=@onTaskComplete
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
