import QueryParams from 'eflex/constants/bi-query-params';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { tracked } from '@glimmer/tracking';

export default class BiProductionRoute extends AuthenticatedRoute {
  @tracked queryParams = {
    ...QueryParams,
    useTotalCycles: {
      refreshModel: true,
    },
  };
}
