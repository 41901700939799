const bomSources = {
  rest: 'restBomSource',
  restPull: 'restPullBomSource',
  barcode: 'barcodeBomSource',
  oracleDellBoomi: 'oracleDellBoomiBomSource',
  gepics: 'gepicsBomSource',
};

export const bomSourceModelNames = Object.values(bomSources);

export const bomSourceDropdownOptions = [
  {
    id: bomSources.rest,
    translationKey: 'bomSources.rest',
  },
  {
    id: bomSources.restPull,
    translationKey: 'bomSources.restPull',
  },
  {
    id: bomSources.barcode,
    translationKey: 'bomSources.barcode',
  },
  {
    id: bomSources.oracleDellBoomi,
    translationKey: 'bomSources.oracleDellBoomi',
  },
  {
    id: bomSources.gepics,
    translationKey: 'bomSources.gepics',
  },
];

export default bomSources;
