import { template as template_6c8870d5dcb44f5c9ce1529db0fa0062 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { selectVal } from 'eflex/helpers';
import { fn } from '@ember/helper';
const SOUND_OPTIONS = [
    'pass',
    'fail',
    'warning',
    'click'
];
export default class AudioTriggerConfig extends Component {
    @service
    intl;
    get audioTypes() {
        return [
            {
                label: this.intl.t('sound'),
                value: 'sound'
            },
            {
                label: this.intl.t('text'),
                value: 'text'
            }
        ];
    }
    static{
        template_6c8870d5dcb44f5c9ce1529db0fa0062(`
    <div class="row form-row align-nested-header pt-0">
      <div class="col-4 {{if @useModelSpecific "model-specific"}}">
        <div class="nested-header">
          {{t "type"}}
        </div>
        <@form.element
          @model={{@triggerConfig}}
          @controlType="power-select"
          @optionLabelPath="label"
          @options={{this.audioTypes}}
          @value={{findBy "value" @triggerConfig.audioType this.audioTypes}}
          @onChange={{selectVal (fn (mut @triggerConfig.audioType))}}
          class="trigger-audio-type"
        as |el|>
          <el.control
            @triggerClass="form-control form-control-sm"
            @disabled={{@disabled}}
          />
        </@form.element>
      </div>
      <div class="col-8 {{if @useModelSpecific "model-specific"}}">
        {{#if @triggerConfig.isText}}
          <div class="nested-header">
            {{t "textToSpeech"}}
          </div>
          <@form.element
            @model={{@triggerConfig}}
            @controlType="text"
            @size="sm"
            @property="text"
            class="trigger-audio-text"
          as |el|>
            <el.control @disabled={{@disabled}} />
          </@form.element>
        {{else}}
          <div class="nested-header">
            {{t "sound"}}
          </div>
          <@form.element
            @model={{@triggerConfig}}
            @controlType="power-select"
            @options={{SOUND_OPTIONS}}
            @property="sound"
            class="trigger-audio"
          as |el|>
            <el.control
              @triggerClass="form-control form-control-sm"
              @disabled={{@disabled}}
            as |sound|>
              {{t sound}}
            </el.control>
          </@form.element>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
