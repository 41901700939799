import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { arrayAttr, caption } from 'eflex/decorators';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { without, reject, prop } from 'ramda';

export default class ComponentOption extends Model {
  @attr('number') priority;
  @arrayAttr captions;
  @caption name;

  @belongsTo('component', { async: false, inverse: 'options', as: 'component-option' }) component;

  @hasMany('taskConfig', {
    async: false,
    inverse: 'configOption',
    as: 'component-option',
    cascadeDelete: true,
  }) taskConfigs;

  @hasMany('jemPrerequisite', {
    async: false,
    inverse: 'configOption',
    as: 'component-option',
    cascadeDelete: true,
  }) jemPrerequisites;

  get polymorphic() {
    return true;
  }

  get displayName() {
    return this.name;
  }

  get isComplex() {
    return !this.isSimple;
  }

  get peers() {
    const options = this.isSimple ? this.component.simpleOptions : this.component.complexOptions;

    return intoArray(
      without([this]),
      reject(prop('isDeleted')),
    )(options);
  }

  get cannotDelete() {
    return (this.component.tasks.length > 0 && this.component.nonDeletedOptions.length === 1);
  }
}
