import ApplicationSerializer from 'eflex/serializers/application';

export default class KineticPartSerializer extends ApplicationSerializer {
  attrs = {
    partRevs: {
      deserialize: false,
      serialize: false,
    },
  };
}
