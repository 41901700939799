import { template as template_1b4277e09b204889b2b3f76d8d14440d } from "@ember/template-compiler";
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import TextControl from 'eflex/components/bs-form/element/control/text';
export default class NumberControl extends Component {
    onChange = (value)=>{
        if (isPresent(value)) {
            value = Number(value);
        }
        this.args.onChange(value);
    };
    static{
        template_1b4277e09b204889b2b3f76d8d14440d(`
    <TextControl
      @type='number'
      @id={{@id}}
      @disabled={{@disabled}}
      @readonly={{@readonly}}
      @ariaDescribedBy={{@ariaDescribedBy}}
      @value={{@value}}
      @size={{@size}}
      @validationType={{@validationType}}
      @onChange={{this.onChange}}
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
