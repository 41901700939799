import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { arrayAttr, caption } from 'eflex/decorators';

const HAS_PIN_TYPE = new Set(['turck-io']);

@buildValidations({
  inputName: [validator('presence', true)],
  outputName: [validator('presence', true)],
})
class HardwareIo extends Model {
  @attr('number') index;
  @attr('boolean', { defaultValue: false }) outputOnly;
  @arrayAttr inputCaptions;
  @arrayAttr outputCaptions;
  @caption('inputCaptions') inputName;
  @caption('outputCaptions') outputName;

  @belongsTo('hardware', { async: false, polymorphic: true, inverse: 'hardwareIos' }) hardware;
  @hasMany('hardwareInputDef', { inverse: 'input', async: false, cascadeDelete: true }) hardwareInputDefs;

  get isConnected() {
    return this.hardware?.isConnected;
  }

  get hardwareName() {
    return this.hardware?.name;
  }

  get hardwareId() {
    return this.hardware?.id;
  }

  get inputOnly() {
    return !this.outputOnly && HAS_PIN_TYPE.has(this.hardware.constructor.modelName);
  }
}

export default HardwareIo;
