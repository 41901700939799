import Hardware from 'eflex/models/hardware';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { getOwner } from '@ember/application';
import { checkUniqueField } from 'eflex/util/validators';
import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';

@buildValidations({
  name: [validator('presence', true)],

  endpoint: [
    validator('presence', true),
    validator('inline', {
      validate(endpoint, options, opcUa, attribute) {
        const checked = getOwner(opcUa).lookup('service:hardwareRepo').opcUas.filter(item => !item.isDeleted);
        return checkUniqueField(opcUa, checked, attribute).result;
      },
    }),
  ],
})
class OpcUa extends Hardware {
  @attr('string') endpoint;
  @attr('string', { defaultValue: 'None' }) securityPolicy;
  @attr('string', { defaultValue: 'None' }) securityMode;
  @attr('string') username;
  @attr('string') password;

  get isConfigured() {
    return isPresent(this.endpoint);
  }

  get hardwareGroup() {
    return this.intl.t('opcUa');
  }
}

export default OpcUa;
