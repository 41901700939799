import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import moment from 'moment-timezone';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isHasManyRefDirty, rollbackHasMany } from 'eflex/util/relationship-helpers';
import { getOwner } from '@ember/application';

@buildValidations({
  text: [
    validator('presence', {
      presence: true,
    }),
  ],

  stations: [
    validator('presence', {
      get disabled() {
        return this.model.isArchived;
      },
      presence: true,
    }),
  ],

  startDate: [
    validator('presence', {
      presence: true,
    }),
    validator('date', {
      before: '2033',
    }),
    validator('inline', {
      get disabled() {
        return !this.model.canEditStart;
      },
      validate(startDate, options, schedule) {
        const now = moment();
        if (now.isAfter(startDate) && !now.isSame(startDate, 'day')) {
          return getOwner(schedule).lookup('service:intl').t('schedules.startDatePastValidation');
        }

        if (moment(startDate).isAfter(schedule.endDate)) {
          return getOwner(schedule).lookup('service:intl').t('schedules.startDateValidation');
        }

        return true;
      },
    }),
  ],

  endDate: [
    validator('presence', {
      presence: true,
    }),
    validator('date', {
      before: '2033',
    }),
    validator('inline', {
      get disabled() {
        return this.model.isArchived;
      },
      validate(endDate, options, schedule) {
        const now = moment();
        if (now.isAfter(endDate) && !now.isSame(endDate, 'day')) {
          return getOwner(schedule).lookup('service:intl').t('schedules.endDatePastValidation');
        }

        if (moment(endDate).isBefore(schedule.startDate)) {
          return getOwner(schedule).lookup('service:intl').t('schedules.endDateValidation');
        }

        return true;
      },
    }),
  ],
})
class Schedule extends Model {
  @attr('string') text;
  @attr('date', { defaultValue() { return moment().startOf('day').toDate(); } }) startDate;
  @attr('date', { defaultValue() { return moment().endOf('day').toDate(); } }) endDate;
  @attr('string', { defaultValue: Intl.DateTimeFormat().resolvedOptions().timeZone }) timezone;
  @hasMany('scheduleDay', { async: false, inverse: 'schedule', embedded: true }) days;
  @hasMany('station', { async: false, inverse: null }) stations;
  @belongsTo('oee', { async: false, inverse: 'schedule' }) oee;

  get hasInvalidDays() {
    //no anyInvalid because we need to check the days even if they're not dirty
    return this.isDirty && this.days.some(item => item.isInvalid);
  }

  get isDirty() {
    return super.isDirty ||
      isHasManyRefDirty(this, 'stations') ||
      this.days.some(item => item.isDirty);
  }

  get isSelfOrChildInvalid() {
    return this.isInvalid || this.hasInvalidDays;
  }

  get stationIds() {
    return this.stations?.map(item => item.id) ?? [];
  }

  get stationsDisplay() {
    if (this.stations.length === 0) {
      return '---';
    }

    return this.stations
      .filter(item => !item.isDeleted)
      .map(item => item.name)
      .join(', ');
  }

  get isPast() {
    return moment().isAfter(this.endDate);
  }

  get isFuture() {
    return moment().isBefore(this.startDate);
  }

  get isArchived() {
    if (this.changedAttributes()?.endDate) {
      return false; //prevents invalid archiving of schedules
    }

    return this.isPast;
  }

  get isActive() {
    return !this.isArchived;
  }

  get canEditStart() {
    if (this.isActive && (this.isNew || this.isFuture || this.changedAttributes()?.startDate)) {
      return true;
    }

    return false;
  }

  rollbackAttributes() {
    rollbackHasMany(this, 'stations');
    super.rollbackAttributes();
  }
}

export default Schedule;
