import { template as template_f3b58964ba4f41989f2f40cf73b866ba } from "@ember/template-compiler";
import Component from '@glimmer/component';
import onKey from 'ember-keyboard/modifiers/on-key';
import { on } from '@ember/modifier';
import { inputVal } from 'eflex/helpers';
import { fn } from '@ember/helper';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import BsButton from 'eflex/components/bs-button';
const ClearButton = template_f3b58964ba4f41989f2f40cf73b866ba(`
<BsButton
  @type="peripheral"
  class="clear-icon position-absolute end-0 px-2"
  {{on "click" @onClick}}
  ...attributes
>
  <FaIcon @icon="xmark" @prefix="fas" @size="xs" />
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const SearchButton = template_f3b58964ba4f41989f2f40cf73b866ba(`
<FaIcon
  @icon="magnifying-glass"
  @prefix="fas"
  @size="xs"
  class="search-icon text-gray-900 position-absolute fs-6 start-0 px-2"
/>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class InputsLiveSearch extends Component {
    onInput = (val)=>{
        if (this.args.disabled) {
            return;
        }
        this.args.onInput?.(val);
    };
    onSearch = ()=>{
        if (this.args.disabled) {
            return;
        }
        this.args.onSearch?.(this.args.searchValue);
    };
    static{
        template_f3b58964ba4f41989f2f40cf73b866ba(`
    <div
      class="component-inputs-search-box input-search-box position-relative d-flex"
      {{onKey 'Enter' this.onSearch}}
      ...attributes
    >
      <div
        class="search-box d-flex position-relative align-items-center w-100
        {{if @disabled "disabled"}}"
      >
        <input
          placeholder={{@placeholder}}
          value={{@searchValue}}
          disabled={{@disabled}}
          class="{{@inputClass}} search-term search-input border-gray-300 fs-4 form-control form-control-sm px-4"
          {{on "input" (inputVal this.onInput)}}
        />
        {{#if @searchValue}}
          <ClearButton @onClick={{fn this.onInput null}} />
        {{/if}}
        <SearchButton @onClick={{this.onSearch}} />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
