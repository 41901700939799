import { template as template_d56ee98ce5784124984750ddefbc7d6d } from "@ember/template-compiler";
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import TaskStatuses from 'eflex/constants/task-statuses';
const getBadgeColor = (status)=>{
    if (status == null || status === TaskStatuses.STARTED || status === TaskStatuses.REJECTED || status === TaskStatuses.GOOD || status === TaskStatuses.SCRAPPED) {
        return null;
    }
    if (TaskStatuses.isRejected(status)) {
        return 'bg-danger';
    }
    if (TaskStatuses.isGoodOrNotRequired(status)) {
        return 'bg-success';
    }
    return 'bg-warning';
};
const showWarningIcon = (status)=>{
    return TaskStatuses.isRejected(status) || !TaskStatuses.isGoodOrNotRequired(status);
};
export default template_d56ee98ce5784124984750ddefbc7d6d(`
{{#let (getBadgeColor @childStatus.status) as |badgeColor|}}
  {{#if badgeColor}}
    <div class="badge status-badge {{badgeColor}} text-wrap text-start ps-1 ms-1 mt-2">
      {{#if (showWarningIcon @childStatus.status)}}
        <FaIcon @icon="triangle-exclamation" class="warning-icon mx-1" @size="sm" />{{@childStatus.statusTranslation}}
      {{else}}
        {{@childStatus.statusTranslation}}
      {{/if}}
    </div>
  {{/if}}
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
