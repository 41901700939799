import { dedupeTracked } from 'tracked-toolbox';

class State {
  @dedupeTracked _value;

  get value() {
    return this._value;
  }

  constructor(initial) {
    this._value = initial;
  }

  update = (newValue) => {
    this._value = newValue;
  };
}

export default function dedupedTrack(value) {
  return new State(value);
}
