export const STATION_LOAD_OPTIONS = {
  scan: 0,
  modelGenerateSerialNumber: 1,
  selectModel: 2,
  scanOrder: 3,
  webCamScan: 4,
  bomSourceLookup: 5,
  bomSourceLookupAuto: 6,
  operationScan: 7,
  operationSelectAuto: 8,
};

export const MODEL_LOAD_OPTIONS = [
  { value: STATION_LOAD_OPTIONS.scan, label: 'plant.station.opt.scan' },
  { value: STATION_LOAD_OPTIONS.modelGenerateSerialNumber, label: 'plant.station.opt.auto' },
  { value: STATION_LOAD_OPTIONS.selectModel, label: 'plant.station.opt.selectModel' },
  { value: STATION_LOAD_OPTIONS.scanOrder, label: 'plant.station.opt.scanOrder' },
  { value: STATION_LOAD_OPTIONS.webCamScan, label: 'webCam' },
];

export const COMPONENT_LOAD_OPTIONS = [
  { value: STATION_LOAD_OPTIONS.scan, label: 'plant.station.opt.scan' },
  { value: STATION_LOAD_OPTIONS.webCamScan, label: 'webCam' },
  { value: STATION_LOAD_OPTIONS.bomSourceLookup, label: 'plant.station.opt.bomSourceLookup' },
  { value: STATION_LOAD_OPTIONS.bomSourceLookupAuto, label: 'plant.station.opt.bomSourceLookupAuto' },
];

export const OPERATION_LOAD_OPTIONS = [
  { value: STATION_LOAD_OPTIONS.operationScan, label: 'plant.station.opt.operationScan' },
  { value: STATION_LOAD_OPTIONS.operationSelectAuto, label: 'plant.station.opt.operationSelectAuto' },
];

export const REGEX_ALLOWED_LOAD_OPTIONS = new Set([
  STATION_LOAD_OPTIONS.scan,
  STATION_LOAD_OPTIONS.webCamScan,
]);

export const generatesSerialNumber = (station) =>
  station.loadOption === STATION_LOAD_OPTIONS.modelGenerateSerialNumber ||
  station.loadOption === STATION_LOAD_OPTIONS.bomSourceLookupAuto ||
  station.loadOption === STATION_LOAD_OPTIONS.operationScan ||
  station.loadOption === STATION_LOAD_OPTIONS.operationSelectAuto;

export const usesBomSourceLookup = (station) =>
  station.loadOption === STATION_LOAD_OPTIONS.bomSourceLookup ||
  station.loadOption === STATION_LOAD_OPTIONS.bomSourceLookupAuto;
