import { template as template_dcb599489abb4c5388fec5ff07ed203e } from "@ember/template-compiler";
import { task, timeout } from 'ember-concurrency';
import EchartsTheme from 'eflex/echarts-theme';
import Component from '@glimmer/component';
import getDelayTime from 'eflex/util/get-delay-time';
import { waitFor } from '@ember/test-waiters';
import { unregisterDestructor, registerDestructor } from '@ember/destroyable';
import exportToCsv from 'eflex/util/export-to-csv';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { onResize } from 'eflex/modifiers';
import { eventBus } from 'eflex/helpers';
import { isTesting, macroCondition } from '@embroider/macros';
export default class EChart extends Component {
    #destructor;
    onResize = task({
        restartable: true
    }, waitFor(async (element)=>{
        await timeout(getDelayTime(100));
        const ECharts = await this._loadEcharts.perform();
        ECharts.getInstanceByDom(element)?.resize();
    }));
    startAutoRefresh = task({
        restartable: true
    }, waitFor(async (element, [onClick, option])=>{
        if (macroCondition(isTesting())) {
            return;
        }
        if (globalThis.isIntegrationTest) {
            return;
        }
        while(this.args.autoRefresh > 0){
            await timeout(this.args.autoRefresh);
            await this.createChart.perform(element, [
                onClick,
                option
            ]);
        }
    }));
    createChart = task({
        enqueue: true
    }, waitFor(async (element, [onClick, option])=>{
        const ECharts = await this._loadEcharts.perform();
        let chart = ECharts.getInstanceByDom(element);
        this.#destroyChart(chart);
        const options = {
            renderer: 'canvas',
            useDirtyRect: true
        };
        if (macroCondition(isTesting())) {
            options.width = 1;
            options.height = 1;
        }
        chart = ECharts.init(element, EchartsTheme, options);
        this.#destructor = registerDestructor(this, ()=>{
            this.#destroyChart(chart);
        });
        chart.setOption(option ?? {});
        if (onClick) {
            chart.on('click', (param)=>{
                onClick(param?.dataIndex, chart);
            });
        }
    }));
    _loadEcharts = task(waitFor(async ()=>{
        const echarts = await import('echarts');
        globalThis.ECharts ??= echarts;
        return echarts;
    }));
    #destroyChart(chart) {
        if (!this.isDestroyed && !this.isDestroying && this.#destructor) {
            unregisterDestructor(this, this.#destructor);
        }
        if (!chart) {
            return;
        }
        chart.off('click');
        chart.dispose();
    }
    exportToCsv = task(waitFor(async ()=>{
        await exportToCsv(this.args.exportData, this.args.option?.title?.text);
    }));
    static{
        template_dcb599489abb4c5388fec5ff07ed203e(`
    {{eventBus exportToCsv=this.exportToCsv.perform}}

    <div
      {{didInsert this.createChart.perform @onClick @option}}
      {{didUpdate this.createChart.perform @onClick @option}}
      {{didInsert this.startAutoRefresh.perform @onClick @option}}
      {{didUpdate this.startAutoRefresh.perform @onClick @option}}
      {{onResize this.onResize.perform}}
      ...attributes
    >
      <div class="echarts-chart">
        {{yield}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
