import { template as template_28f84d84873f46aa831dbbf845a9b819 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import KeyboardWedgeEvent from 'eflex/components/keyboard-wedge-event';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
import BsButton from 'eflex/components/bs-button';
import { tracked } from '@glimmer/tracking';
export default class JemAuthPanel extends Component {
    @service
    eflexAjax;
    @service
    notifier;
    @service
    intl;
    @tracked
    username;
    @tracked
    password;
    authorize = dropTask(async (badgeId)=>{
        let authorizingUser;
        if (this.args.childStatus.parent.isHolding) {
            return;
        }
        try {
            ({ authorizingUser } = await this.eflexAjax.post.perform('jemAuthorize', {
                jemAuthorize: {
                    username: this.username,
                    password: this.password,
                    badgeId,
                    tags: [
                        ...this.args.jemAuthorizedTags
                    ]
                }
            }));
        } catch (e) {
            const message = this.intl.t(e.message);
            this.notifier.sendError('taskAuthorizationFailed', {
                error: message
            });
            return;
        }
        this.args.childStatus.authorizedAt = new Date();
        this.args.childStatus.authorizedBy = authorizingUser;
        this.notifier.sendSuccess('taskAuthorized');
        this.args.onTaskComplete(TaskStatuses.AUTHORIZED, this.args.childStatus);
        this.args.onAuthSuccess();
    });
    static{
        template_28f84d84873f46aa831dbbf845a9b819(`
    <KeyboardWedgeEvent @onScan={{this.authorize.perform}} />
    <div class="p-3">
      <div class="alert alert-warning">
        {{t "jem.requiresAuthorization"}}
      </div>

      <BsForm @model={{this}} as |form|>
        <form.element
          @controlType="text"
          class="authorize-input-username"
          @label={{t "username"}}
          @property="username"
          autocomplete="off"
        />
        <form.element
          @controlType="password"
          class="authorize-input-password"
          @label={{t "password"}}
          @property="password"
          autocomplete="off"
        />
      </BsForm>

      <div class="d-flex justify-content-center mt-4">
        <BsButton
          @type="primary"
          @size="lg"
          class="text-uppercase authorize-button"
          @onClick={{this.authorize.perform}}
          @disabled={{@childStatus.parent.isHolding}}
        >
          {{t "authorize"}}
        </BsButton>
      </div>

    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
