import { template as template_63951f6948a04cc38986ef2762dd7b67 } from "@ember/template-compiler";
import { assert } from '@ember/debug';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { filterOptions, defaultMatcher } from 'ember-power-select/utils/group-utils';
import { ensureSafeComponent } from '@embroider/util';
import PowerSelect from 'eflex/components/power-select';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
const SuggestedOptionComponent = template_63951f6948a04cc38986ef2762dd7b67(`
  {{@option.text}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class PowerSelectWithCreateComponent extends Component {
    matcher = defaultMatcher;
    @tracked
    searchEnabled = true;
    constructor(){
        super(...arguments);
        assert('<PowerSelectWithCreate> requires an `onCreate` function', this.args.onCreate && typeof this.args.onCreate === 'function');
    }
    get powerSelectComponent() {
        return ensureSafeComponent(this.args.powerSelectComponent ?? PowerSelect, this);
    }
    get suggestedOptionComponent() {
        return ensureSafeComponent(this.args.suggestedOptionComponent ?? SuggestedOptionComponent, this);
    }
    shouldShowCreateOption(term, options) {
        return this.args.showCreateWhen ? this.args.showCreateWhen(term, options) : true;
    }
    addCreateOption(term, results) {
        if (this.shouldShowCreateOption(term, results)) {
            if (this.args.showCreatePosition === 'bottom') {
                results.push(this.buildSuggestionForTerm(term));
            } else {
                results.unshift(this.buildSuggestionForTerm(term));
            }
        }
    }
    searchAndSuggest = task(waitFor(async (term, select)=>{
        let newOptions = await this.args.options;
        if (term.length === 0) {
            return newOptions;
        }
        const searchAction = this.args.search;
        if (searchAction) {
            const results = await searchAction(term, select);
            this.addCreateOption(term, results);
            return results;
        }
        newOptions = this.filter(newOptions, term);
        this.addCreateOption(term, newOptions);
        return newOptions;
    }));
    selectOrCreate = (selection, select, e)=>{
        if (selection && selection.__isSuggestion__) {
            this.args.onCreate(selection.__value__, select, e);
        } else {
            this.args.onChange(selection, select, e);
        }
    };
    // Methods
    filter(options, searchText) {
        let matcher;
        if (this.args.searchField) {
            matcher = (option, text)=>this.matcher(option[this.args.searchField], text);
        } else {
            matcher = (option, text)=>this.matcher(option, text);
        }
        return filterOptions(options || [], searchText, matcher);
    }
    buildSuggestionForTerm(term) {
        return {
            __isSuggestion__: true,
            __value__: term,
            text: this.buildSuggestionLabel(term)
        };
    }
    buildSuggestionLabel(term) {
        if (this.args.buildSuggestion) {
            return this.args.buildSuggestion(term);
        }
        return `Add "${term}"...`;
    }
    static{
        template_63951f6948a04cc38986ef2762dd7b67(`
    <this.powerSelectComponent
      @afterOptionsComponent={{@afterOptionsComponent}}
      @allowClear={{@allowClear}}
      @ariaDescribedBy={{@ariaDescribedBy}}
      @ariaInvalid={{@ariaInvalid}}
      @ariaLabel={{@ariaLabel}}
      @ariaLabelledBy={{@ariaLabelledBy}}
      @beforeOptionsComponent={{this.beforeOptionsComponent}}
      @buildSelection={{@buildSelection}}
      @calculatePosition={{@calculatePosition}}
      @class={{@class}}
      @closeOnSelect={{@closeOnSelect}}
      @defaultHighlighted={{@defaultHighlighted}}
      @destination={{@destination}}
      @dir={{@dir}}
      @disabled={{@disabled}}
      @dropdownClass={{@dropdownClass}}
      @extra={{@extra}}
      @groupComponent={{@groupComponent}}
      @highlightOnHover={{@highlightOnHover}}
      @horizontalPosition={{@horizontalPosition}}
      @initiallyOpened={{@initiallyOpened}}
      @loadingMessage={{@loadingMessage}}
      @matchTriggerWidth={{@matchTriggerWidth}}
      @matcher={{this.matcher}}
      @noMatchesMessage={{@noMatchesMessage}}
      @onBlur={{@onBlur}}
      @onChange={{this.selectOrCreate}}
      @onClose={{@onClose}}
      @onFocus={{@onFocus}}
      @onInput={{@onInput}}
      @onKeydown={{@onKeydown}}
      @onOpen={{@onOpen}}
      @options={{@options}}
      @optionsComponent={{@optionsComponent}}
      @placeholder={{@placeholder}}
      @placeholderComponent={{@placeholderComponent}}
      @preventScroll={{@preventScroll}}
      @registerAPI={{@registerAPI}}
      @renderInPlace={{@renderInPlace}}
      @scrollTo={{@scrollTo}}
      @search={{this.searchAndSuggest.perform}}
      @searchEnabled={{this.searchEnabled}}
      @searchField={{@searchField}}
      @searchMessage={{@searchMessage}}
      @searchMessageComponent={{@searchMessageComponent}}
      @searchPlaceholder={{@searchPlaceholder}}
      @selected={{@selected}}
      @selectedItemComponent={{@selectedItemComponent}}
      @tabindex={{@tabindex}}
      @triggerClass={{@triggerClass}}
      @triggerComponent={{@triggerComponent}}
      @triggerId={{@triggerId}}
      @triggerRole={{@triggerRole}}
      @typeAheadMatcher={{@typeAheadMatcher}}
      @verticalPosition={{@verticalPosition}}
      ...attributes
    as |option term|>
      {{#if option.__isSuggestion__}}
        <this.suggestedOptionComponent @option={{option}} @term={{term}} />
      {{else}}
        {{yield option term}}
      {{/if}}
    </this.powerSelectComponent>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
