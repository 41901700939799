import { template as template_2949231aa4bf4fe68b070e809ce3eeeb } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_2949231aa4bf4fe68b070e809ce3eeeb(`
<div
  class="total-cycles-label-wrapper component-bi-total-cycles-label"
  ...attributes
>
  <h1>
    <span class="label">
      {{t "reporting.totalparts"}}:
    </span>
    <span class="total-cycles">
      {{@total}}
    </span>
    <span class="total-rejects pe-0">
      {{@rejectCount}}
    </span>
  </h1>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
