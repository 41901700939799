import { template as template_c4c0f6b0967d49ec999dc78e9e952f26 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';
import { service } from '@ember/service';
import SearchTag from 'eflex/components/criteria-visor/search-tag';
import FilterWrapper from 'eflex/components/criteria-visor/filter-wrapper';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
const LogLevels = [
    'All',
    'Alarm',
    'Warn',
    'Error'
];
export default class CriteriaVisorLogLevel extends Component {
    @service
    intl;
    @tracked
    isDisplayed = Boolean(this.args.logLevel);
    @tracked
    includeInactive = Boolean(this.args.includeInactive);
    get includeInactiveBreadcrumb() {
        if (!this.args.includeInactive) {
            return null;
        }
        return this.intl.t('breadcrumbs.includeInactive');
    }
    get logLevelBreadcrumb() {
        const logLevel = this.args.logLevel;
        if (isBlank(logLevel) || logLevel === 'All') {
            return null;
        }
        return this.intl.t('breadcrumbs.logLevel', {
            logLevel
        });
    }
    constructor(){
        super(...arguments);
        this.args.initParam('logLevel', this.args.logLevel);
        this.args.initParam('includeInactive', this.args.includeInactive);
    }
    onDisplayedChanged = (isDisplayed)=>{
        this.isDisplayed = isDisplayed;
        this.args.updateParam('logLevel', null);
        this.args.updateParam('includeInactive', null);
    };
    onSelectedLevelChanged = (logLevel)=>{
        if (logLevel === 'All') {
            this.args.updateParam('logLevel', null);
            return;
        }
        this.args.updateParam('logLevel', logLevel);
    };
    onIncludeInactiveChanged = (includeInactive)=>{
        if (includeInactive) {
            this.args.updateParam('includeInactive', true);
        } else {
            this.args.updateParam('includeInactive', null);
        }
    };
    static{
        template_c4c0f6b0967d49ec999dc78e9e952f26(`
    <SearchTag class='logLevel' @isDisplayed={{this.isDisplayed}} @text={{this.logLevelBreadcrumb}} />
    <SearchTag class='includeInactive' @isDisplayed={{this.isDisplayed}} @text={{this.includeInactiveBreadcrumb}} />

    <FilterWrapper
      class="eflex-log-level-criteria"
      @form={{@form}}
      @label="logs.level"
      @isDisplayed={{this.isDisplayed}}
      @onChecked={{this.onDisplayedChanged}}
      ...attributes
    >
      <@form.element
        @controlType="power-select"
        @options={{LogLevels}}
        @value={{or @logLevel "All"}}
        @onChange={{this.onSelectedLevelChanged}}
      />

      <@form.element
        @controlType="checkbox"
        @label={{t "controls.criteria.includeinactive"}}
        @value={{this.includeInactive}}
        @onChange={{this.onIncludeInactiveChanged}}
      as |el|>
        <el.control class="include-inactive" />
      </@form.element>
    </FilterWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
