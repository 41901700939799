import ApplicationSerializer from 'eflex/serializers/application';

const cleanId = (hash) => {
  if (!hash || hash._id == null) {
    return;
  }

  hash.id ??= hash._id;
  hash._id = undefined;
};

export default class WorkInstructionSerializer extends ApplicationSerializer {
  attrs = {
    jemConfigurations: {
      serialize: false,
      deserialize: false,
    },
  };

  normalize(typeClass, hash, prop) {
    hash.canvas?.objects?.forEach(obj => {
      cleanId(obj);
    });

    hash.deployedCanvas?.objects?.forEach(obj => {
      cleanId(obj);
    });

    return super.normalize(typeClass, hash, prop);
  }
}
