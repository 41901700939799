import { template as template_9671de9b57664dacb0a826ae0b58491f } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { get } from '@ember/helper';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
export default class BiRejectCodePieChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    getData = task({
        restartable: true
    }, waitFor(async (params)=>{
        const query = await this.queryRunner.queryWithParams.perform(params, [
            {
                $match: {
                    status: {
                        $gte: TaskStatuses.REJECT_RANGE_START,
                        $lte: TaskStatuses.REJECT_RANGE_END
                    }
                }
            },
            {
                $unwind: {
                    path: '$rejectCodes.codes',
                    preserveNullAndEmptyArrays: true
                }
            },
            {
                $group: {
                    _id: '$rejectCodes.codes.code',
                    count: {
                        $sum: 1
                    }
                }
            },
            {
                $lookup: {
                    from: 'BuildStatuses',
                    as: 'totalFailedParts',
                    pipeline: [
                        {
                            $match: {
                                status: {
                                    $gte: TaskStatuses.REJECT_RANGE_START,
                                    $lte: TaskStatuses.REJECT_RANGE_END
                                }
                            }
                        },
                        {
                            $count: 'totalFailedParts'
                        }
                    ]
                }
            },
            {
                $unwind: '$totalFailedParts'
            },
            {
                $sort: {
                    _id: 1
                }
            },
            {
                $project: {
                    _id: 0,
                    code: {
                        $ifNull: [
                            '$_id',
                            'Other'
                        ]
                    },
                    count: '$count'
                }
            }
        ]);
        const rejectCodeData = await this.queryRunner.runQuery.perform('BuildStatuses', query);
        return {
            chartData: this.getRejectCodePieChartOptions(rejectCodeData),
            exportData: ()=>rejectCodeData
        };
    }));
    rejectCodeData = trackedTask(this, this.getData, ()=>[
            {
                stationIds: this.args.stationIds,
                modelIds: this.args.modelIds,
                userIds: this.args.userIds,
                tags: this.args.tags,
                startDate: this.args.startDate,
                endDate: this.args.endDate,
                shiftNames: this.args.shiftNames
            },
            this.args.refresher
        ]);
    getRejectCodePieChartOptions(rejectCodeData) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.rejectCodeBreakdown')
            },
            tooltip: {
                trigger: 'item',
                formatter (params) {
                    return `<div style="min-width:100px;">
              ${params.name}: <span style="font-weight:bold;"> ${params.value}</span> <br>
            </div>`;
                }
            },
            series: [
                {
                    label: {
                        color: '#fff'
                    },
                    name: this.intl.t('bi.pieChart.legend'),
                    type: 'pie',
                    radius: '75%',
                    center: [
                        '50%',
                        '50%'
                    ],
                    color: echartsTheme.excessBiColors,
                    data: rejectCodeData.map((m)=>({
                            value: m.count,
                            name: m.code
                        }))
                }
            ]
        };
    }
    static{
        template_9671de9b57664dacb0a826ae0b58491f(`
    <div class="reject-code-chart-wrapper h-100">
      {{#if this.rejectCodeData.isRunning}}
        <Spinner />
      {{else if (get this.rejectCodeData.value.chartData.series '0.data.length')}}
        <EChart
          class="h-100 w-100 reject-code-chart"
          @option={{this.rejectCodeData.value.chartData}}
          @exportData={{this.rejectCodeData.value.exportData}}
        />
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
