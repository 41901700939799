import LocationRepoBase from 'eflex/services/location-repo-base';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class AreaRepoService extends LocationRepoBase {
  areas = this.store.peekAll('area');

  create(properties = {}) {
    return super.create('area', properties);
  }

  createAndSave = task(waitFor(async (properties = {}) => {
    const area = this.create(properties);
    await area.save();
    return area;
  }));
}
