import { template as template_567178d0fa084a209db3199975641c66 } from "@ember/template-compiler";
import TriggerEventValues, { TriggerEvents } from 'eflex/constants/tasks/trigger-events';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import PowerSelect from 'eflex/components/power-select';
import { fn } from '@ember/helper';
import WorkInstructionEditorLibraryTask from 'eflex/components/work-instruction-editor/library/task';
import HostedFilePicker from 'eflex/components/hosted-file-picker';
import BsButton from 'eflex/components/bs-button';
import JemDynamicInstruction from 'eflex/components/jem/dynamic-instruction';
import { and, eq, or } from 'ember-truth-helpers';
import UrlViewer from 'eflex/components/url-viewer';
import Link from 'eflex/components/link';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import TrashCan from 'eflex/components/trash-can';
import { optional, withDefault } from '@eflexsystems/ember-composable-helpers';
import JemInstructionsAnimation from 'eflex/components/jem/instructions/animation';
import { service } from '@ember/service';
import { createTrigger } from 'eflex/services/trigger-repo';
export const deleteJemImage = (jemConfig)=>{
    Object.assign(jemConfig, {
        externalImageUrl: null,
        workInstruction: null,
        page: null,
        animations: []
    });
};
const getTriggerConfig = (triggerConfigs, triggerEvent)=>{
    return triggerConfigs?.find((triggerConfig)=>triggerConfig.constructor.modelName === 'work-instruction-hardware-trigger-config' && triggerConfig.event === triggerEvent);
};
export default class JemInstructionsConfiguration extends Component {
    @tracked
    triggerEvent = TriggerEventValues.START;
    @tracked
    animationsToRun;
    @service
    hardwareRepo;
    playAnimation = (animation)=>{
        this.animationsToRun = [
            animation
        ];
    };
    #getOrCreateTriggerAndConfig = (config, taskConfig, triggerEvent, triggerConfig, useEvent)=>{
        if (useEvent && triggerConfig == null) {
            createTrigger(taskConfig?.task, triggerEvent, this.hardwareRepo.workInstructionHardware);
            config = getTriggerConfig(taskConfig?.triggerConfigs, triggerEvent);
        }
        return config;
    };
    setWorkInstruction = (config, taskConfig, useEvent, triggerConfig, triggerEvent, workInstruction)=>{
        const workInstructionConfig = this.#getOrCreateTriggerAndConfig(config, taskConfig, triggerEvent, triggerConfig, useEvent);
        workInstructionConfig.setWorkInstruction(workInstruction);
    };
    setExternalImageUrl = (config, taskConfig, triggerEvent, triggerConfig, useEvent, url)=>{
        const workInstructionConfig = this.#getOrCreateTriggerAndConfig(config, taskConfig, triggerEvent, triggerConfig, useEvent);
        workInstructionConfig.setExternalImageUrl(url);
    };
    static{
        template_567178d0fa084a209db3199975641c66(`
    <div class="component-jem-instructions-configuration jem-task-config" ...attributes>
      {{#if @useEvent}}
        <div class="row form-row g-0 mb-2">
          <div class="col">
            <div class="instruction-event">
              <label class="form-label">
                {{t "event"}}
              </label>
              <PowerSelect
                @triggerClass="form-control"
                @options={{TriggerEvents}}
                @selected={{this.triggerEvent}}
                @renderInPlace={{true}}
                @onChange={{fn (mut this.triggerEvent)}}
                class="instruction-event-select"
              as |triggerEvent|>
                {{t triggerEvent}}
              </PowerSelect>
            </div>
          </div>
        </div>
      {{/if}}

      {{#let
        @taskConfig.task
        (getTriggerConfig @taskConfig.triggerConfigs this.triggerEvent)
      as |task triggerConfig|}}
        {{#let (withDefault triggerConfig @jemConfiguration) as |config|}}
          <div>
            <WorkInstructionEditorLibraryTask
              class="h-100 task-library"
              @currentWorkInstruction={{config.workInstruction}}
              @config={{config}}
              @disabled={{@disabled}}
              @loadWorkInstruction={{
                fn
                this.setWorkInstruction
                config
                @taskConfig
                @useEvent
                triggerConfig
                this.triggerEvent
              }}
            />
            <div class="row form-row g-0 mt-2 mb-2">
              <HostedFilePicker
                class="pe-2"
                @externalImageUrl={{config.externalImageUrl}}
                @pdfPage={{config.page}}
                @disabled={{@disabled}}
                @placeholder={{t "imageEditor.urlPlaceholder"}}
                @onPdfPageInput={{fn (mut config.page)}}
                @onChange={{
                  fn
                  this.setExternalImageUrl
                  config
                  @taskConfig
                  this.triggerEvent
                  triggerConfig
                  @useEvent
                }}
              />
              {{#if (withDefault @showCopyToAll true)}}
                <div class="col-auto">
                  <BsButton
                    @onClick={{fn (optional @copyToAll) config this.triggerEvent}}
                    @disabled={{@disabled}}
                    @type='secondary'
                    class="copy-to-all text-capitalize w-100"
                  >
                    {{#if @usesComponents}}
                      {{t "copyOptionSpecific"}}
                    {{else}}
                      {{t "copyModelSpecific"}}
                    {{/if}}
                  </BsButton>
                </div>
              {{/if}}
            </div>
            {{#if (or config.workInstruction config.externalImageUrl)}}
              <div class="image-wrapper d-flex flex-column align-items-center">
                <div class="name-wrapper d-flex mb-1">
                  <div class="name">
                    {{#if config.workInstruction}}
                      {{config.workInstruction.deployedName}}
                    {{else}}
                      {{config.externalImageUrl}}
                    {{/if}}
                  </div>
                  {{#if config.workInstruction.isDynamic}}
                    <div
                      title={{t "jem.dynamicWorkInstruction"}}
                      class="icon icon-dynamic"
                    ></div>
                  {{/if}}
                </div>
                <div class="image flex-fill d-flex justify-content-center">
                  {{#if config.workInstruction}}
                    {{#if config.workInstruction.isDynamic}}
                      <JemDynamicInstruction
                        @workInstruction={{config.workInstruction}}
                        @animations={{this.animationsToRun}}
                        @previewMode={{true}}
                        @bypassDelayTime={{@bypassDelayTime}}
                        @resetTimeout={{withDefault @resetTimeout 10000}}
                      />
                    {{else}}
                      <img
                        src={{config.workInstruction.deployedImageUrl}}
                        title={{config.workInstruction.deployedName}}
                        class="img-responsive"
                      />
                    {{/if}}
                  {{else if config.hasExternalUrl}}
                    <UrlViewer @url={{config.fullExternalUrl}} />
                  {{/if}}
                  <div class="links-container">
                    {{#if config.workInstruction}}
                      <div class="edit pointer">
                        <Link
                          @route="workInstructions.workInstruction"
                          @model={{config.workInstruction.id}}
                        >
                          <FaIcon @icon="pencil" @prefix="fas" class="fa" />
                        </Link>
                      </div>
                    {{/if}}
                    <TrashCan
                      class='delete-image'
                      @onDelete={{fn deleteJemImage config}}
                      disabled={{@disabled}}
                    />
                  </div>
                </div>
              </div>
            {{else}}
              <div class="placeholder-image">
                <div class="placeholder-text w-50">
                  {{t "plant.station.jem.imagePlaceholderAbove"}}
                </div><FaIcon @icon="file-image" @prefix="far" class="fa placeholder-icon" />
              </div>
            {{/if}}
          </div>
          {{#if (and triggerConfig.workInstruction.isDynamic (eq task.taskType taskTypes.torque))}}
            <div class="row mt-2">
              <div class="col-12">
                <JemInstructionsAnimation
                  @triggerConfig={{config}}
                  @boltCount={{@taskConfig.boltCount}}
                  @disabled={{@disabled}}
                  @playAnimation={{this.playAnimation}}
                />
              </div>
            </div>
          {{/if}}
        {{/let}}
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
