import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';

export default class CodesRoute extends AuthenticatedRoute {
  @service store;
  @service router;

  model() {
    return this.store.findAll('code');
  }

  redirect(codes, transition) {
    if (transition.to.name === 'codes.index') {
      this.router.replaceWith('codes.reject');
    }
  }
}
