import { template as template_34d7607100e64b31a0881a0b132972df } from "@ember/template-compiler";
import { NoteTypes, SelectNoteTypes } from 'eflex/constants/jem/note-types';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { fn, concat, get } from '@ember/helper';
import { formatDate, inputVal } from 'eflex/helpers';
import { t } from 'ember-intl';
import StandardModal from 'eflex/components/standard-modal';
import { eq } from 'ember-truth-helpers';
import { scrollIntoView } from 'eflex/modifiers';
import { isLast } from '@eflexsystems/ember-composable-helpers';
import PowerSelect from 'eflex/components/power-select';
import BsButton from 'eflex/components/bs-button';
import { on } from '@ember/modifier';
const JemNotesViewModes = {
    view: 'view',
    add: 'add'
};
export default class JemNotesModal extends Component {
    @tracked
    mode = JemNotesViewModes.view;
    @tracked
    noteText = {
        content: null
    };
    @tracked
    noteType = SelectNoteTypes[0];
    @service
    store;
    @service
    currentUser;
    save = task(waitFor(async ()=>{
        const record = this.store.createRecord('jemNote', {
            station: this.args.station,
            content: this.noteText.content,
            user: this.currentUser.user,
            type: this.noteType.value
        });
        await record.save();
        this.clearNote();
    }));
    clearNote = ()=>{
        Object.assign(this, {
            noteText: {
                content: null
            },
            mode: JemNotesViewModes.view
        });
    };
    static{
        template_34d7607100e64b31a0881a0b132972df(`
    <StandardModal
      class="jem jem-notes-modal"
      @onCancel={{@onCancel}}
      @size="lg"
      ...attributes
    as |modal|>
      <modal.header @text={{t "plant.station.jem.notes"}} />
      <modal.body>
        {{#if (eq this.mode "view")}}
          {{#each @station.jemNotes as |note|}}
            <div
              class="component-jem-note jem-note"
              {{scrollIntoView shouldScroll=(isLast note @station.jemNotes)}}
            >
              <div class="row">
                <div class="col-sm-12">
                  <strong class="jem-note-user">
                    {{note.user.userName}}
                  </strong>
                  <p class="jem-note-minor">
                    - {{formatDate note.timestamp}} -
                  </p>
                  <p class="jem-note-minor">
                    {{#let (get NoteTypes note.type) as |noteType|}}
                      {{#if noteType}}
                        {{t (concat "plant.jem.notes.types." noteType)}}
                      {{/if}}
                    {{/let}}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p class="jem-note-text">
                    {{!-- template-lint-disable no-triple-curlies --}}
                    {{{note.content}}}
                    {{!-- template-lint-enable no-triple-curlies --}}
                  </p>
                </div>
              </div>
            </div>
          {{/each}}
        {{else if (eq this.mode "add")}}
          <PowerSelect
            @options={{SelectNoteTypes}}
            @selected={{this.noteType}}
            @renderInPlace={{true}}
            @onChange={{fn (mut this.noteType)}}
          as |noteType|>
            {{t noteType.label}}
          </PowerSelect>
          <div class="jem-content-container">
            <textarea
              value={{this.noteText.content}}
              class="jem-notes-text h-100 w-100"
              {{on "input" (inputVal (fn (mut this.noteText.content)))}}
            />
          </div>
        {{/if}}
      </modal.body>
      <modal.footer>
        {{#if (eq this.mode "view")}}
          <BsButton @type="primary" @size="lg" class="app-jem-save-notes" @onClick={{@onCancel}}>
            OK
          </BsButton>
          <BsButton
            @type="secondary"
            @size="lg"
            class="app-add-note-button"
            @onClick={{fn (mut this.mode) JemNotesViewModes.add}}
          >
            {{t "add"}}
          </BsButton>
        {{else if (eq this.mode "add")}}
          <BsButton
            @type="primary"
            @size="lg"
            class="app-save-added-note-button"
            @onClick={{this.save.perform}}
          >
            {{t "save"}}
          </BsButton>
          <BsButton
            @type="secondary"
            @size="lg"
            class="app-jem-discard-added-note-button"
            @onClick={{this.clearNote}}
          >
            {{t "discard"}}
          </BsButton>
        {{/if}}
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
