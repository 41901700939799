import Model, { attr } from '@ember-data/model';

export default class KineticMaterialQuantity extends Model {
  @attr('string') partNumber;
  @attr('string') fromWarehouseCode;
  @attr('string') fromBinNum;
  @attr('string') toWarehouseCode;
  @attr('string') toBinNum;
  @attr('number') transactionQuantity;
}
