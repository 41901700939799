import { template as template_3ecf261516444f82bf10882f83b359de } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import eflexFeatures from 'eflex/constants/eflex-features';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import RouteTemplate from 'ember-route-template';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { filterBy } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
import { formatDateWithoutTime } from 'eflex/helpers';
import BsTooltip from 'eflex/components/bs-tooltip';
import Breadcrumbs from 'eflex/components/breadcrumbs';
import FileInput from 'eflex/components/file-input';
import { not } from 'ember-truth-helpers';
import { concat, get } from '@ember/helper';
import { camelize } from '@ember/string';
const LicensingDisplay = template_3ecf261516444f82bf10882f83b359de(`
<div ...attributes>
  <div class="fw-semibold mb-3">
    {{@groupName}}
  </div>
  <div class="mb-3">
    <div class="row form-row header-row">
      <div class="col">
        {{t "licensing.productsFeatures"}}
      </div>
    </div>
    {{#each @options as |option|}}
      <div class="row form-row">
        <div class="col">
          <div
            data-feature-key={{option.key}}
            class="
              license-feature
              {{if
                (not
                  (get
                    @license
                    (concat
                      (camelize option.key)
                      "Licensed")
                    )
                  )
                  "disabled opacity-50"
              }}
            "
          >
            {{option.label}}
          </div>
        </div>
      </div>
    {{/each}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
class SettingsLicensingTemplate extends Component {
    @service
    eflexAjax;
    @service
    notifier;
    @service
    licensing;
    @service
    currentUser;
    @tracked
    confirmDisabled = true;
    @tracked
    licenseFilename;
    @tracked
    licenseText;
    onSelectedLicenseFile = task(waitFor(async (files)=>{
        if (files == null || files[0] == null) {
            return;
        }
        const licenseFile = files[0];
        if (licenseFile.type !== 'text/plain') {
            this.notifier.sendError('logs.licensing.invalid.license');
            Object.assign(this, {
                licenseFilename: null,
                confirmDisabled: true
            });
            return;
        }
        Object.assign(this, {
            confirmDisabled: false,
            licenseFilename: licenseFile.name,
            licenseText: await licenseFile.text()
        });
        try {
            await this.eflexAjax.post.perform('licenses', {
                licenseText: this.licenseText
            });
            this.notifier.sendSuccess('licensing.licenseUploaded');
            await this.licensing.loadLicense.perform();
        } catch (error) {
            this.notifier.sendError(error);
        }
    }));
    static{
        template_3ecf261516444f82bf10882f83b359de(`
    <Breadcrumbs @navKey="settings" @page={{t "navigation.settings"}} />
    <div class="settings-licensing h-100 d-flex flex-column">
      {{#let this.licensing.license as |license|}}
        <div class="bg-gray-100 mx-n3 mt-n3 p-3 mb-3">
          <div class="row align-items-center">
            <div class="col-4 col-md-3">
              <div class="fs-4 fw-semibold text-gray-800">{{t "organization"}}</div>
              <h5 class="license-organization fw-semibold">
                {{license.organization.name}}
              </h5>
            </div>
            <div class="col-4 col-md-3">
              <div class="fs-4 fw-semibold text-gray-800">{{t "license"}}</div>
              <h5 class="license-label fw-semibold">
                {{license.label}}
              </h5>
            </div>
            <div class="col-4 col-md d-flex justify-content-md-end align-items-center">
              <FileInput @onChange={{this.onSelectedLicenseFile.perform}}>
                <div class="btn btn-secondary">
                  <FaIcon @icon="upload" @prefix="fas" @size="sm" class="me-1" />
                  {{t "licensing.uploadLicense"}}
                </div>
              </FileInput>
            </div>
          </div>
          <div class="row">
            {{#if this.licensing.unlicensed}}
              <div class="col-4 fw-bold text-danger">
                <FaIcon @icon="circle-exclamation" @prefix="fas" class="me-2 text-danger" />
                {{t "settings.licensing.unlicensed"}}
              </div>
            {{else}}
              <div class="license-property col-4 col-md-3">
                <small class="fs-4 fw-semibold text-gray-800">
                  {{t "settings.license.licenseNumber"}}
                </small>
                <div class="license-number prop-value fw-semibold">
                  {{license.licenseId}}
                </div>
              </div>
              <div class="license-property col-4 col-md-3">
                <small class="fs-4 fw-semibold text-gray-800">
                  {{t "licensing.stationsConfigured"}}
                </small>
                <div class="stations-configured prop-value fw-semibold">
                  {{license.activeStations}}
                </div>
              </div>
              <div class="license-property col-4 col-md-3">
                <small class="fs-4 fw-semibold text-gray-800">
                  {{t "licensing.concurrentStationLimit"}}
                </small>
                <div class="station-limit prop-value fw-semibold">
                  {{license.stationLimit}}
                </div>
              </div>
              <div class="license-property col">
                <small class="fs-4 fw-semibold text-gray-800">
                  {{t "settings.licensing.expirationDate"}}
                </small>
                <div class="expiration-date prop-value fw-semibold">
                  {{formatDateWithoutTime license.expirationDate "YYYY-MM-DD"}}
                  {{#if license.licenseWarningEnabled}}
                    <i class="expiration-warning pointer ms-2">
                      <FaIcon @icon="circle-exclamation" @prefix="fas" class="text-danger" />
                      <BsTooltip>
                        {{t
                          "settings.licensing.expiration.warning"
                          product=(t "license")
                          days=license.daysTillExpired
                        }}
                      </BsTooltip>
                    </i>
                  {{/if}}
                </div>
              </div>
            {{/if}}
          </div>
        </div>
        <div class="features-wrapper row h-100 overflow-y-auto mb-3">
          <LicensingDisplay
            class='col-4 col-md-3'
            @license={{license}}
            @groupName={{t "licensing.level" num=1}}
            @options={{filterBy "group" "level-1" eflexFeatures}}
          />
          <LicensingDisplay
            class='col-4 col-md-3'
            @license={{license}}
            @groupName={{t "licensing.level" num=2}}
            @options={{filterBy "group" "level-2" eflexFeatures}}
          />
          <LicensingDisplay
            class='col-4 col-md-3'
            @license={{license}}
            @groupName={{t "licensing.level" num=3}}
            @options={{filterBy "group" "level-3" eflexFeatures}}
          />
          <LicensingDisplay
            class='col-4 col-md-3'
            @license={{license}}
            @groupName={{t "licensing.addOnModules"}}
            @options={{filterBy "group" "addon" eflexFeatures}}
          />
        </div>
      {{/let}}
    </div>

    <div class="position-absolute bg-white bottom-0 py-2">
      <FaIcon @icon="circle-info" @prefix="fas" class="me-1" />
      {{t "licensing.epiccareRequest"}}
      {{! eslint-disable-next-line max-len }}
      <a href="https://epicorcs.service-now.com/epiccare?id=epiccare_catalog_page&sys_id=eea69110db12a744f3f5c553059619fe">EpicCare.</a>
      {{t "licensing.epiccareRequest.click"}}
      Wayfinder
      {{t "licensing.epiccareRequest.then"}}
      License and Media
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SettingsLicensingTemplate);
