import Model, { attr, hasMany } from '@ember-data/model';

export default class BackupConfig extends Model {
  @attr('string', { defaultValue: 'full' }) type;
  @attr('number', { defaultValue: 7 }) retain;
  @attr('date', { defaultValue() { return new Date(2016, 1, 1, 2, 0, 0); } }) time;
  @attr('date', { defaultValue() { return new Date(); } }) saved;
  @attr('string', { defaultValue: '' }) remoteUrl;
  @attr('string', { defaultValue: '' }) remoteUsername;
  @attr('string', { defaultValue: '' }) remotePassword;

  @hasMany('dayConfig', { inverse: null, async: false, embedded: true }) days;

  get isDirty() {
    return super.isDirty || this.days.some(day => day.isDirty);
  }
}
