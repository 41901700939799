import { template as template_1a660f9c67dd4d509915254409bcecf3 } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import BsModal from 'eflex/components/bs-modal';
import { t } from 'ember-intl';
export default template_1a660f9c67dd4d509915254409bcecf3(`
<BsModal
  @open={{@open}}
  @onHidden={{@onHidden}}
  class="standard-popover danger"
as |modal|>
  <div class="side-panel">
    <i class="eflex-icon danger"></i>
  </div>
  <modal.header>
    {{t (concat @messagePrefix ".header")}}
  </modal.header>
  <modal.body>
    <div class="main-panel ms-5">
      <div class="jem-alert-body ms-2">
        {{t (concat @messagePrefix ".text")}}
      </div>
    </div>
  </modal.body>
</BsModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
