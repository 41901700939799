import { isBlank } from '@ember/utils';
import { isString, isNumber } from 'ramda-adjunct';
import { getOwner } from '@ember/application';
import { validator } from '@eflexsystems/ember-tracked-validations';

export const checkUniqueField = (model, collection, field, fieldIsNumber = false) => {
  const target = model[field];
  if (isBlank(target)) {
    return { result: true, duplicate: null };
  }

  let ownValue = target;

  if (isString(ownValue)) {
    ownValue = ownValue.trim();
  }

  if (fieldIsNumber) {
    ownValue = Number.parseInt(ownValue);
  }

  const inUseItem = collection?.find((item) => {
    if (item === model) {
      return false;
    }

    let toCheck = item[field];

    if (isString(toCheck)) {
      toCheck = toCheck.trim();
    }

    if (fieldIsNumber) {
      toCheck = Number.parseInt(toCheck);
    }
    return toCheck === ownValue;
  });

  if (inUseItem != null) {
    return {
      result: getOwner(model).lookup('service:intl').t('logs.nonUniqueFields'),
      duplicate: inUseItem,
    };
  } else {
    return {
      result: true,
      duplicate: null,
    };
  }
};

export const checkUniqueNumber = (model, collection, field) => {
  return checkUniqueField(model, collection, field, true).result;
};

export const rangeCorrectness = function (model, lowerLimit, upperLimit, errorMessage) {
  const lowerNum = Number.parseFloat(lowerLimit);
  const upperNum = Number.parseFloat(upperLimit);

  if (isNumber(lowerNum) && isNumber(upperNum) && lowerNum > upperNum) {
    return getOwner(model).lookup('service:intl').t(errorMessage);
  }
  return true;
};

export const checkIpUnique = (model, siblings) => {
  if (isBlank(model.fullAddress)) {
    return true;
  }

  const hardware = siblings.filter(item => !item.isDeleted);
  const conflicting = checkUniqueField(model, hardware, 'fullAddress');

  if (conflicting.duplicate) {
    return getOwner(model).lookup('service:intl').t('station.ipAndPortAreInUse', { item: conflicting.name });
  }

  return true;
};

export const checkMqttTopicUnique = (model) => {
  const owner = getOwner(model);

  const tasks = owner.lookup('service:taskRepo').tasks;
  const checkedTasks = tasks
    .filter(item => item.isNodeRed)
    .filter(item => !item.isDeleted);

  const nodeRedTriggers = owner.lookup('service:triggerRepo').nodeRedTriggers;
  const checkedTriggers = nodeRedTriggers.filter(item => !item.isDeleted);

  const checked = checkedTasks.concat(checkedTriggers);

  return checkUniqueField(model, checked, 'mqttTopic').result;
};

export const portValidator = validator('number', {
  integer: true,
  allowString: true,
  allowBlank: true,
  gt: 0,
  lte: 65_535,
});
