import { template as template_5170781803474b0dac29cd02ee0acd24 } from "@ember/template-compiler";
import { StrokeOptions } from 'eflex/constants/work-instructions/tool-props';
import { generateStrokeDashArray } from 'eflex/util/stroke-helper';
import PowerSelect from 'eflex/components/power-select';
import { inputNum } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { fn } from '@ember/helper';
const getStrokeLabelClass = (strokeWidth)=>{
    strokeWidth = Number.parseInt(strokeWidth);
    if (strokeWidth < 10) {
        return 'ones';
    } else if (strokeWidth < 100) {
        return 'tens';
    } else {
        return null;
    }
};
const setStrokeStyle = (setStrokeAction, strokeWidth, style)=>{
    setStrokeAction(generateStrokeDashArray(style, strokeWidth), style, strokeWidth);
};
const setStrokeWidth = (setStrokeAction, minimum, maximum, strokeStyle, width)=>{
    if (width < minimum || isNaN(width)) {
        width = minimum;
    } else if (width > maximum) {
        width = maximum;
    }
    setStrokeAction(generateStrokeDashArray(strokeStyle, width), strokeStyle, width);
};
export default template_5170781803474b0dac29cd02ee0acd24(`
<div class="component-inputs-stroke-select" ...attributes>
  {{#let
    (withDefault @minimum 1)
    (withDefault @maximum 50)
  as |minimum maximum|}}
    <PowerSelect
      @dropdownClass="stroke-style-options"
      @options={{StrokeOptions.options}}
      @onChange={{fn setStrokeStyle @setStroke @strokeWidth}}
      @selected={{@strokeStyle}}
      @disabled={{@disabled}}
      @matchTriggerWidth={{true}}
      class="stroke-style-select"
    as |style|>
      <label data-style={{style}} class="stroke-option {{style}}"></label>
    </PowerSelect>

    <div class="stroke-width">
      <input
        type="number"
        value={{@strokeWidth}}
        min={{minimum}}
        max={{maximum}}
        disabled={{@disabled}}
        class="stroke-width-input form-control"
        {{on "input" (inputNum (fn setStrokeWidth @setStroke minimum maximum @strokeStyle))}}
      />
      {{#if @strokeWidth}}
        <label class="{{getStrokeLabelClass @strokeWidth}} {{if @disabled "disabled"}}">
          pt
        </label>
      {{/if}}
    </div>
  {{/let}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
