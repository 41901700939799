import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugHardwareMessagesController extends Controller {
  queryParams = ['searchTerm', 'beginDate', 'endDate', 'type', 'command', 'stationIds', 'taskIds', 'page'];

  @tracked showJsonModal = false;
  @tracked displayedMessage;
  @tracked searchTerm;
  @tracked model = new TrackedArray();
  @tracked beginDate;
  @tracked endDate;
  @tracked type;
  @tracked command;
  @tracked stationIds = [];
  @tracked taskIds = [];
  @tracked page = 1;

  @action
  onShowJsonModal(msg) {
    if (msg == null) {
      return;
    }

    Object.assign(this, {
      showJsonModal: true,
      displayedMessage: msg,
    });
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
