import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthenticatedRoute extends Route {
  @service session;

  beforeModel(transition) {
    return this.session.requireAuthentication(transition, this.session.loginRoute);
  }
}
