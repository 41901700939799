import Service, { service } from '@ember/service';

export default class VariableDefRepoService extends Service {
  @service store;

  variableDefs = this.store.peekAll('variableDef');

  createVariableDef(defParams) {
    const def = this.store.createRecord('variableDef', defParams);
    def.task.taskConfigs.forEach((taskConfig) => {
      this.store.createRecord('variableDefConfig', {
        variableDef: def,
        taskConfig,
      });
    });
  }

  setVariableValue = (variableDefConfig, value) => {
    if (value == null) {
      Object.assign(variableDefConfig, {
        variableDefForValue: null,
        value: null,
      });
      return;
    }

    if (value.constructor?.modelName === 'variable-def') {
      Object.assign(variableDefConfig, {
        variableDefForValue: value,
        value: null,
      });
    } else {
      Object.assign(variableDefConfig, {
        variableDefForValue: null,
        value,
      });

    }
  };

  deleteVariable = (variableDef) => {
    variableDef.variableDefConfigs.forEach(config => { config.deleteRecord(); });
    variableDef.deleteRecord();
  };
}
