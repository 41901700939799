import { template as template_fea0a03f17b04591981de25ba5cbfba0 } from "@ember/template-compiler";
const getDisplayVariableValue = (jemDisplayVariableId, buildDatumVariables)=>{
    return buildDatumVariables?.find((item)=>item.id === jemDisplayVariableId)?.rawValue;
};
export default template_fea0a03f17b04591981de25ba5cbfba0(`
<div ...attributes>
  {{#if @jemDisplayVariable}}
    <label class="form-label app-selected-variable-name">
      {{@jemDisplayVariable.name}}
    </label>
    <div class="form-control form-control-lg border-0 jem-bom-variables" >
      {{getDisplayVariableValue @jemDisplayVariable.id @buildDatumVariables}}
    </div>
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
