import TaskParent from 'eflex/models/task-parent';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { STATION_LOAD_OPTIONS, REGEX_ALLOWED_LOAD_OPTIONS } from 'eflex/constants/station-options';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { cached, tracked } from '@glimmer/tracking';
import {
  rollbackBelongsTo,
  isBelongsToRefDirty,
  isHasManyRefDirty,
} from 'eflex/util/relationship-helpers';
import { copyable, arrayAttr } from 'eflex/decorators';
import { pluck, clone } from 'ramda';
import { getOwner } from '@ember/application';
import validateTaskSequence from 'eflex/util/validate-task-sequence';
import { checkUniqueNumber } from 'eflex/util/validators';

@buildValidations({
  name: [validator('presence', true)],

  facsId: [
    validator('number', {
      gte: 1,
      integer: true,
      allowString: true,
      allowNone: false,
    }),

    validator('inline', {
      validate(facsId, options, station, attribute) {
        return checkUniqueNumber(station, getOwner(station).lookup('service:stationRepo').stations, attribute);
      },
    }),
  ],

  taskSequence: [
    validator('inline', { validate: validateTaskSequence }),
  ],
})
@copyable
class Station extends TaskParent {
  @attr('boolean', { defaultValue: false }) usesComponents;
  @attr('number') facsId;
  @attr('boolean', { defaultValue: true }) oee;
  @attr('boolean', { defaultValue: true }) jem;
  @attr('boolean', { defaultValue: true }) showTimer;
  @attr('boolean', { defaultValue: true }) jemShowTaskCycle;
  @attr('boolean', { defaultValue: true }) jemShowReject;
  @attr('boolean', { defaultValue: false }) jemShowStop;
  @attr('boolean', { defaultValue: true }) jemShowHold;
  @attr('boolean', { defaultValue: true }) jemShowOeeButton;
  @attr('boolean', { defaultValue: true }) jemShowContain;
  @attr('boolean', { defaultValue: false }) jemShowScrap;
  @attr('boolean', { defaultValue: true }) jemShowNotes;
  @attr('boolean', { defaultValue: true }) jemShowHeaderButtons;
  @attr('boolean', { defaultValue: false }) jemNotViewFutureTasks;
  @attr('boolean', { defaultValue: false }) usesOperations;
  @attr('boolean', { defaultValue: false }) kineticDeleted;

  @attr('string') resourceId;
  @attr('string') jemLoadRegex;
  @attr('string', { defaultValue: 'station' }) type;
  @attr('boolean', { defaultValue: false }) hideFromOperator;
  @attr('boolean', { defaultValue: false }) confirmRejects;
  @attr('boolean', { defaultValue: false }) confirmScrapCode;
  @attr('boolean', { defaultValue: false }) productionScheduleEnabled;
  @attr('boolean', { defaultValue: true }) useGlobalEdhrLogin;
  @attr('number', { defaultValue: 0 }) loadOption;
  @attr('number') order;
  @attr('number') barcodeSymbology;
  @arrayAttr jemAuthorizedTags;
  @arrayAttr configEditorTags;

  @belongsTo('group', { inverse: 'stations', async: false }) parent;
  @belongsTo('webCam', { async: false, inverse: null }) webCam;
  @belongsTo('workInstructionHardwareTriggerConfig', { async: false, inverse: null }) currentInstructionTriggerConfig;

  @hasMany('jemConfiguration', {
    async: false,
    polymorphic: true,
    inverse: 'station',
    embedded: true,
  }) jemConfigurations;

  @hasMany('jemNote', { inverse: 'station', async: false }) jemNotes;
  @hasMany('jemPrerequisite', { async: false, inverse: 'station', embedded: true }) jemPrerequisites;
  @hasMany('code', { async: false, polymorphic: true, inverse: 'stations' }) codes;
  @hasMany('jemPartMarriage', { async: false, inverse: 'stations' }) jemPartMarriages;
  @hasMany('trigger', { async: false, polymorphic: true, inverse: 'station', embedded: true }) triggers;
  @hasMany('productionSchedule', { async: false, inverse: 'station' }) productionSchedules;
  @hasMany('triggerConfig', { async: false, polymorphic: true, inverse: 'station', embedded: true }) triggerConfigs;
  @hasMany('stationHardware', { async: false, embedded: true, inverse: 'station' }) stationHardwares;

  @service licensing;
  @service intl;

  @tracked isChecked = false;

  get station() {
    return this;
  }

  get treeIconClass() {
    return 'tree-genericstation-icon';
  }

  @cached
  get kineticIdDisplay() {
    if (isEmpty(this.resourceId)) {
      return null;
    }

    return this.intl.t('resourceId', { id: this.resourceId });
  }

  get hardwares() {
    return pluck('hardware', this.stationHardwares);
  }

  @cached
  get loadJemConfiguration() {
    return this.jemConfigurations?.find(item => item.constructor.modelName === 'station-load-jem-configuration');
  }

  get stationJemConfigurations() {
    return this.jemConfigurations?.filter(item => item.constructor.modelName === 'station-jem-configuration');
  }

  get area() {
    return this.group?.area;
  }

  set area(val) {
    this.group.area = val;
  }

  get group() {
    return this.parent;
  }

  set group(val) {
    this.parent = val;
  }

  get models() {
    return this.area?.models;
  }

  get usesModels() {
    return !this.usesComponents && !this.usesOperations;
  }

  get isWebCamScan() {
    return this.loadOption === STATION_LOAD_OPTIONS.webCamScan;
  }

  get isDirty() {
    return super.isDirty ||
      isBelongsToRefDirty(this, 'webCam') ||
      isHasManyRefDirty(this, 'jemConfigurations') ||
      this.stationHardwares.some(item => item.isDirty) ||
      this.jemConfigurations.some(item => item.isDirty) ||
      this.jemPrerequisites.some(item => item.isDirty) ||
      this.triggers.some(item => item.isDirty) ||
      this.triggerConfigs.some(item => item.isDirty);
  }

  @cached
  get isSelfOrChildInvalid() {
    return super.isSelfOrChildInvalid || this.isTriggerInvalid || this.isTriggerConfigInvalid;
  }

  @cached
  get isTriggerInvalid() {
    return anyInvalid(this.triggers);
  }

  @cached
  get isTriggerConfigInvalid() {
    return anyInvalid(this.triggerConfigs);
  }

  get oeeEnabled() {
    return this.oee && this.licensing.license?.oeeEnabled && this.area?.oeeEnabled;
  }

  @cached
  get currentProductionSchedule() {
    return this.productionSchedules.find(item => item.isRunning);
  }

  get displayName() {
    return this.name;
  }

  @cached
  get repairCodes() {
    return this.codes?.filter(item => item.constructor.modelName === 'repair-code') ?? [];
  }

  @cached
  get rejectCodes() {
    return this.codes?.filter(item => item.constructor.modelName === 'reject-code') ?? [];
  }

  @cached
  get faultCodes() {
    return this.codes?.filter(item => item.constructor.modelName === 'fault-code') ?? [];
  }

  @cached
  get scrapCodes() {
    return this.codes?.filter(item => item.constructor.modelName === 'scrap-code') ?? [];
  }

  @cached
  get configuredJemTabs() {
    return this.jemConfigurations
      .filter(item => !item.isDeleted)
      .filter(item => item.constructor.modelName === 'custom-tab-jem-configuration') ?? [];
  }

  get jemLoadRegexIsAllowed() {
    return REGEX_ALLOWED_LOAD_OPTIONS.has(this.loadOption);
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set([
        'tasks',
        'jemNotes',
        'jemPartMarriages',
        'triggers',
        'triggerConfigs',
        'currentInstructionTriggerConfig',
        'facsId',
        'productionSchedules',
        'order',
      ]),
      copyByReference: new Set(['codes', 'webCam']),
      overwrite: {
        captions: [{ language: 'english', text: `Copy of ${this.name}` }],
      },
    };
  }

  asHistory() {
    return {
      id: this.id,
      type: 'station',
      order: this.order,
      facsId: this.facsId,
      usesComponents: this.usesComponents,
      usesOperations: this.usesOperations,
      captions: clone(this.captions),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'webCam');
    // prevents a double unload from cascadeDelete and embedded
    this.triggers.forEach(trigger => { trigger.rollbackAttributes(); });
    super.rollbackAttributes();
  }
}

export default Station;
