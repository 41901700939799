import { dasherize } from '@ember/string';

export default function getHardware(station, obj) {
  if (!station || !obj) {
    return null;
  }

  const { hardware, hardwareIndex, hardwareAssignedType } = obj;

  if (hardwareAssignedType) {
    const hardwareType = dasherize(hardwareAssignedType);

    return station.stationHardwares.find(stationHardware =>
      stationHardware.index === hardwareIndex &&
      stationHardware.hardware.constructor.modelName === hardwareType,
    )?.hardware;
  }

  return hardware;
}
