import { template as template_31129941f67348a7ae5745918d3d217c } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import DecisionTypes from 'eflex/constants/tasks/decision-types';
import Component from '@glimmer/component';
import { notEq, eq } from 'ember-truth-helpers';
import BsButtonGroup from 'ember-bootstrap/components/bs-button-group';
class JemTaskDecisionDetails extends Component {
    decisionTypes = DecisionTypes;
    get selectedOptions() {
        if (this.args.childStatus == null) {
            return [];
        }
        if (this.args.task.decisionType === DecisionTypes.singleChoice) {
            return this.args.childStatus.decisionProcessData.find((item)=>item.isSelected);
        } else {
            return this.args.childStatus.decisionProcessData.filter((item)=>item.isSelected);
        }
    }
    selectDecision = (decisions)=>{
        if (!this.args.childStatus?.isStarted) {
            return;
        }
        this.args.childStatus.decisionProcessData.forEach((decisionProcessDatum)=>{
            if (decisions.includes?.(decisionProcessDatum) || decisions === decisionProcessDatum) {
                decisionProcessDatum.isSelected = true;
            } else {
                decisionProcessDatum.isSelected = false;
            }
        });
    };
    static{
        template_31129941f67348a7ae5745918d3d217c(`
    <div ...attributes>
      <div class="mt-2">
        <div class="fs-4 fw-semibold text-gray-800 mb-2">
          {{@task.decisionLabel}}
        </div>
        {{#if (notEq @task.decisionType this.decisionTypes.passFail)}}
          <BsButtonGroup
            class="radio-btn-group z-0 d-flex flex-column"
            @type={{if (eq @task.decisionType this.decisionTypes.singleChoice) "radio" "checkbox"}}
            @value={{this.selectedOptions}}
            @onChange={{this.selectDecision}}
            @vertical={{true}}
          as |bg|>
            {{#each @childStatus.decisionProcessData as |decision|}}
              <bg.button
                @value={{decision}}
                disabled={{@disabled}}
                class="decision-button detail-text-value fw-bold text-gray-900"
              >
                <div title={{decision.name}} class="decision-option">
                  {{decision.name}}
                </div>
              </bg.button>
            {{/each}}
          </BsButtonGroup>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default template_31129941f67348a7ae5745918d3d217c(`
{{yield
  (hash
    taskDetails=(component
      JemTaskDecisionDetails
      disabled=@disabled
      task=@task
      childStatus=@childStatus
    )
  )
}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
