import { template as template_3e28d5ba93d9484e8542a6e2cfb13fbf } from "@ember/template-compiler";
import { serviceHelper as service } from 'eflex/helpers';
import SidePanel from 'eflex/components/side-panel';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import BsButton from 'eflex/components/bs-button';
import NoRouteTabs from 'eflex/components/no-route-tabs';
import { array, fn } from '@ember/helper';
import track from 'ember-simple-track-helper/helpers/track';
import PowerSelect from 'eflex/components/power-select';
import EmberFlatpickr from 'ember-flatpickr/components/ember-flatpickr';
const BackupOptions = template_3e28d5ba93d9484e8542a6e2cfb13fbf(`
<div ...attributes>
  <form>
    <label class="form-label">
      {{t "settings.backup.numBackups"}}
    </label>
    <PowerSelect
      @options={{array 1 2 3 4 5 6 7}}
      @selected={{@config.retain}}
      @disabled={{@user.isNotUser}}
      @onChange={{fn (mut @config.retain)}}
    as |num|>
      {{num}}
    </PowerSelect>
    <label class="form-label">
      {{t "settings.backup.time"}}
    </label>
    <div class="time-picker mb-3">
      <EmberFlatpickr
        class="backup-time"
        @allowInput={{true}}
        @altInput={{true}}
        @enableTime={{true}}
        @noCalendar={{true}}
        @date={{@config.time}}
        @onChange={{@onDatePickerChange}}
        @disabled={{@user.isNotUser}}
      />
    </div>
    <BsForm
      class="row"
      @model={{this}}
    as |form|>
      <label class="form-label mb-2">
        {{t "settings.backup.days"}}
      </label>
      {{#each @config.days as |day|}}
        <label class="settings-backup-day-lbl col-auto mb-0">
          <form.element
            @controlType='checkbox'
            @value={{day.isChecked}}
            @onChange={{fn (mut day.isChecked)}}
            @label={{t day.value}}
          as |el|>
            <el.control @disabled={{@user.isNotUser}} />
          </form.element>
        </label>
      {{/each}}
    </BsForm>
  </form>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template_3e28d5ba93d9484e8542a6e2cfb13fbf(`
<SidePanel
  class="backup-config-panel"
  @header={{t "settings.backup.backupOptions"}}
  @onClose={{@onClose}}
  ...attributes
>
  <div class="backup-config-panel h-100">
    <BsForm
      class="d-flex flex-column h-100"
      @model={{@full}}
      @showValidations={{@showValidations}}
    as |form|>
      <div class="bg-gray-100 px-3 mb-0">
        <div class="row form-row">
          <div class="col">
            <form.element
              @label={{t "username"}}
              @property="remoteUsername"
              @onChange={{@onRemoteUsernameInput}}
            />
          </div>
          <div class="col">
            <form.element
              @type="password"
              @label={{t "password"}}
              @property="remotePassword"
              @onChange={{@onRemotePasswordInput}}
            />
          </div>
        </div>
      </div>
      {{#let (service 'currentUser') as |currentUser|}}
        <div class="bg-gray-100 p-3 mb-0">
          <div class="row form-row mb-3">
            <div class="col">
              <form.element
                @label={{t "settings.backup.path"}}
                @property="remoteUrl"
                @onChange={{@onRemoteUrlInput}}
              as |el|>
                <div class="input-group row form-row gx-0">
                  <div class="col"><el.control class="network-field rounded-end-0" /></div>
                  <div class="col-auto">
                    <BsButton
                      class="rounded"
                      @disabled={{currentUser.user.isNotUser}}
                      @type="secondary"
                      @onClick={{@sendTest.perform}}
                    >
                      {{t "test"}}
                    </BsButton>
                  </div>
                </div>
              </form.element>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column flex-fill mb-0">
          {{#let (track 'full-backup') as |activeTab|}}
            <NoRouteTabs
              @activeTab={{activeTab.value}}
              @tabBecameActive={{activeTab.update}}
            as |tabs|>
              <div class="bg-gray-100">
                <tabs.navigation
                as |nav|>
                  <nav.tab @href="full-backup">
                    {{t "settings.backup.fullTitle"}}
                  </nav.tab>
                  <nav.tab @href="config-backup">
                    {{t "settings.backup.configOnlyTitle"}}
                  </nav.tab>
                </tabs.navigation>
              </div>

              <tabs.panes class="overflow-hidden p-3" as |panes|>
                <panes.pane @id="full-backup">
                  <BackupOptions
                    class="full-backup-schedule mb-3"
                    @user={{currentUser.user}}
                    @config={{@full}}
                    @disabled={{@disabled}}
                    @onDatePickerChange={{fn @setBackupTime @full}}
                  />
                </panes.pane>

                <panes.pane @id="config-backup">
                  <BackupOptions
                    class="config-backup-schedule"
                    @user={{currentUser.user}}
                    @config={{@config}}
                    @disabled={{@disabled}}
                    @onDatePickerChange={{fn @setBackupTime @config}}
                  />
                </panes.pane>
              </tabs.panes>
            </NoRouteTabs>
          {{/let}}
        </div>
      {{/let}}
    </BsForm>
  </div>
</SidePanel>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
