import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class FileDownloaderService extends Service {
  @service eflexAjax;
  @service notifier;

  getFile = task(waitFor(async (url, accept, filename, params = {}) => {
    const fullUrl = this.eflexAjax.getUrl(url, params);
    const response = await this.eflexAjax.sendRawRequest.perform(fullUrl, 'GET', null, accept);
    if (!response.ok) {
      const { error: { message } } = await response.json();
      const error = new Error(message);
      this.notifier.sendError(error);
      return;
    }
    const responseBlob = await response.blob();

    await this._saveAs.perform(responseBlob, filename);
  }));

  _saveAs = task(waitFor(async (blob, filename) => {
    const { default: saveAs } = await import('file-saver');
    saveAs(blob, filename);
  }));
}
