/* eslint-disable ember/no-runloop */
import { default as OrigBsContextualHelp } from 'ember-bootstrap/components/bs-contextual-help';
import { next, schedule, cancel } from '@ember/runloop';
import { action } from '@ember/object';

export default class BsContextualHelp extends OrigBsContextualHelp {
  #setupTimer;
  #showTimer;
  #renderTimer;

  get fade() {
    return false;
  }

  get usesTransition() {
    return false;
  }

  get triggerEvents() {
    return this.args.triggerEvents ?? 'hover';
  }

  show() {
    cancel(this.#renderTimer);
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    if (this.args.onShow?.(this) === false) {
      return;
    }

    this.inDom = true;
    this.#renderTimer = schedule('afterRender', this, this._show);
  }

  @action
  setup() {
    this._parent = this._parentFinder.parentNode;

    // Look for target element after rendering has finished, in case the target DOM element is rendered *after* us
    // see https://github.com/kaliber5/ember-bootstrap/issues/1329
    this.#setupTimer = schedule('afterRender', () => {
      this.triggerTargetElement = this.getTriggerTargetElement();
      this.addListeners();
      if (this.visible) {
        cancel(this.#showTimer);
        this.#showTimer = next(this, this.show);
      }
    });
  }

  @action
  showOrHide() {
    cancel(this.#showTimer);

    if (this.args.visible) {
      this.#showTimer = next(this, this.show);
    } else {
      this.#showTimer = next(this, this.hide);
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    cancel(this.timer);
    cancel(this.#setupTimer);
    cancel(this.#showTimer);
    cancel(this.#renderTimer);
  }
}
