import getHardware from 'eflex/helpers/get-hardware';

export default function getTaskHardwareInput(station, task) {
  if (!station || !task) {
    return null;
  }

  if (task.hardwareAssignedType) {
    const hardware = getHardware(station, task);
    return hardware?.hardwareIos[task.ioIndex - 1];
  }

  return task.hardwareInput;
}
