import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TrackedArray } from 'tracked-built-ins';
import moment from 'moment-timezone';

export default class DebugKineticIntegrationLogsIntegrationInboxController extends Controller {
  // jscpd:ignore-start
  queryParams = ['searchTerm', 'beginDate', 'endDate', 'Data', 'EventType', 'id', 'page'];

  @tracked showJsonModal = false;
  @tracked model = new TrackedArray();
  @tracked displayedValue;
  @tracked searchTerm;
  @tracked beginDate;
  @tracked endDate;
  @tracked Data;
  @tracked EventType;
  @tracked id;
  @tracked page = 1;

  @action
  onShowJsonModal(obj) {
    if (obj == null) {
      return;
    }

    Object.assign(this, {
      showJsonModal: true,
      displayedValue: obj,
    });
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }

  formatDuration = (start, end) => {
    if (end == null) {
      return '';
    }

    const startDate = moment(new Date(start));
    const endDate = moment(new Date(end));

    if (!startDate.isValid() || !endDate.isValid()) {
      return 'Invalid Date';
    }

    const dur = endDate.diff(startDate);
    return moment.duration(dur, 'milliseconds').format('DD:HH:mm:ss');
  };
  // jscpd:ignore-end
}
