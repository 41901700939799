const MarriageStatuses = {
  UNKNOWN: 0,
  GOOD: 200,
  DIVORCED: 300,
  REJECTED: 400,
  EXTERNAL: 500,
  BYPASSED: 600,
};

MarriageStatuses.isMarried = (status) =>
  status === MarriageStatuses.GOOD || status === MarriageStatuses.EXTERNAL;

MarriageStatuses.createStatusRecord = function (userName, timestamp, scannedBarcode, status = null) {
  return {
    userName,
    timestamp,
    scannedBarcode,
    status,
  };
};

export default MarriageStatuses;
