import { template as template_912e4415caed407aafa7dfa73218f06e } from "@ember/template-compiler";
import TaskStatuses from 'eflex/constants/task-statuses';
import JemTaskOkButton from 'eflex/components/jem/task-ok-button';
import { RETRY_STRATEGY_VALUES, REJECT_ALLOWED_RETRY_STRATEGIES } from 'eflex/constants/retry-strategy-values';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import BsTooltip from 'eflex/components/bs-tooltip';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
const RetryButton = template_912e4415caed407aafa7dfa73218f06e(`
<BsButton
  class='reject-jem-task retry-button px-3 py-2'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete TaskStatuses.RESTART}}
  ...attributes
>
  {{yield}}
  {{t 'retry'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const RestartButton = template_912e4415caed407aafa7dfa73218f06e(`
<BsButton
  class='restart-jem-task restart-button px-3 py-2'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete TaskStatuses.RESTART}}
  ...attributes
>
  {{yield}}
  {{t 'status.restart'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getButtonComponent = (task, childStatus, retryButtonOverride, okButtonOverride)=>{
    if (!childStatus.isRunning && !childStatus.isRestart) {
        if (childStatus.isGood && task.retryStrategy === RETRY_STRATEGY_VALUES.always) {
            return RestartButton;
        }
        if (childStatus.isRejected && REJECT_ALLOWED_RETRY_STRATEGIES.has(task.retryStrategy)) {
            return retryButtonOverride ?? RetryButton;
        }
    }
    return okButtonOverride ?? JemTaskOkButton;
};
export default template_912e4415caed407aafa7dfa73218f06e(`
{{#let (getButtonComponent @task @childStatus @retryButtonOverride @okButtonOverride) as |ButtonComponent|}}
  <ButtonComponent
    class="jem-status-button text-capitalize h-100 mb-0"
    @type={{withDefault @type "secondary"}}
    @size={{withDefault @size "md"}}
    @disabled={{@disabled}}
    @onTaskComplete={{@onTaskComplete}}
    ...attributes
  >
    {{#if @notAuthorized}}
      <BsTooltip>
        {{t "notAuthorized"}}
      </BsTooltip>
    {{/if}}
  </ButtonComponent>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
