import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import { service } from '@ember/service';
import TorqueFormats from 'eflex/constants/torque-formats';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class SettingsJemController extends Controller {
  @service store;
  @service eflexAjax;
  @service audioPlayer;
  @service licensing;
  @service currentUser;
  @service intl;
  @service systemConfig;
  @service workInstructionRepo;
  @service configurationHistoryRepo;

  @tracked isShowingWieApprovalModal = false;

  torqueFormatOptions = [
    {
      formatLabel: TorqueFormats.NEWTON_METER.label,
      radioLabel: this.intl.t('config.torqueValue', TorqueFormats.NEWTON_METER.label.split()),
    },
    {
      formatLabel: TorqueFormats.INCH_POUND.label,
      radioLabel: this.intl.t('config.torqueValue', TorqueFormats.INCH_POUND.label.split()),
    },
  ];

  get systemConfiguration() {
    return this.systemConfig.config;
  }

  get isDirty() {
    return this.systemConfiguration.isDirty;
  }

  save = task({ drop: true }, waitFor(async () => {
    if (!this.systemConfiguration.enableWieApprovals) {
      await this.eflexAjax.post.perform('workInstructions/bulkApprove');
    }

    if (this.systemConfiguration.jem.changedAttributes()?.configurationHistory) {
      const note = this.systemConfiguration.jem.changedAttributes().configurationHistory[1]
        ? this.intl.t('configurationHistory.enabled') : this.intl.t('configurationHistory.disabled');
      await Promise.all(this.store.peekAll('station').map((station) => {
        return this.configurationHistoryRepo.createNewVersion.perform(station, note);
      }));
    }

    await this.systemConfiguration.save();
  }));

  @action
  rollback() {
    this.systemConfiguration.rollbackAttributes();
  }

  @action
  playSample() {
    const sample = this.intl.t('config.voiceSample');
    this.audioPlayer.textToSpeech(sample);
  }

  @action
  toggleWieApprovals(val) {
    this.systemConfiguration.enableWieApprovals = val;

    if (!val && this.isDirty) {
      this.isShowingWieApprovalModal = true;
    }
  }

  @action
  cancelDisableWieApprovals() {
    this.isShowingWieApprovalModal = false;
    this.systemConfiguration.enableWieApprovals = true;
  }

  @action
  confirmDisableWieApprovals() {
    this.isShowingWieApprovalModal = false;
    this.systemConfiguration.enableWieApprovals = false;
  }

  @action
  setVoiceUri({ voiceURI }) {
    this.systemConfiguration.jem.textToSpeechVoice = voiceURI;
  }

  @action
  setTorqueFormat({ formatLabel }) {
    this.systemConfiguration.jem.torqueFormat = formatLabel;
  }
}
