import Model, { attr, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { tracked } from '@glimmer/tracking';
import { arrayAttr } from 'eflex/decorators';
import { getOwner } from '@ember/application';

@buildValidations({
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
    }),
    validator('inline', {
      validate(name, options, workInstructionFolder) {
        const intl = getOwner(workInstructionFolder).lookup('service:intl');
        if (name === intl.t('none')) {
          return intl.t('workInstructions.folderNameInvalid');
        }

        return true;
      },
    }),
  ],
})
class WorkInstructionFolder extends Model {
  @attr('string') name;
  @attr('date') createdAt;
  @attr('date') updatedAt;

  @hasMany('workInstruction', { async: false, inverse: 'folder' }) workInstructions;

  @arrayAttr wieEditorTags;
  @arrayAttr wieApproverTags;
  @arrayAttr wieDeployerTags;

  @tracked isSelected = false;

  get isFolder() {
    return true;
  }
}

export default WorkInstructionFolder;
