import { template as template_f4b2be6f10b9425c855e6ceb449a4147 } from "@ember/template-compiler";
import track from 'ember-simple-track-helper/helpers/track';
import StandardModal from 'eflex/components/standard-modal';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
export default template_f4b2be6f10b9425c855e6ceb449a4147(`
{{#let
  (track null)
  (track false)
as |activityNotes activityComplete|}}
  <StandardModal
    class="end-activity-modal"
    @onConfirm={{fn @onConfirm activityNotes.value activityComplete.value}}
    @onCancel={{@onCancel}}
    @size="md"
    ...attributes
  as |modal|>
    <modal.header @text={{t "kinetic.endActivity"}} />
    <modal.body>
      <BsForm class="mb-4" as |form|>
        <div class="row">
          <div class="form-group col d-flex flex-column">
            <label class="form-label">
              {{t "kinetic.completedQuantity"}}
            </label>
            <div class="completed-quantity h6">
              {{@assemblyOperation.quantityToReport}}
            </div>
          </div>
          <div class="form-group col d-flex flex-column">
            <label class="form-label">
              {{t "kinetic.previouslyReportedQuantity"}}
            </label>
            <div class="previous-reported-quantity h6">
              {{@assemblyOperation.reportedQuantity}}
            </div>
          </div>
        </div>

        <form.element
          class='test-activity-complete'
          @controlType="checkbox"
          @value={{activityComplete.value}}
          @label={{t "kinetic.completeOperation"}}
          @onChange={{activityComplete.update}}
        />

        <form.element
          class='test-activity-notes'
          @controlType="textarea"
          @value={{activityNotes.value}}
          @label={{t "notes"}}
          @onChange={{activityNotes.update}}
        />

        {{#if @assemblyOperation.nextAssemblyOp}}
          <div class="form-group d-flex flex-column">
            <label class="form-label">
              {{t "kinetic.nextOperation"}}
            </label>
            <div class="next-operation h6">
              {{@assemblyOperation.nextAssemblyOp.name}}
            </div>
          </div>
        {{/if}}
      </BsForm>
    </modal.body>
    <modal.footer/>
  </StandardModal>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
