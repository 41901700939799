import { template as template_d0cf3a2156fa4ccea5f6070c7ffd6b2c } from "@ember/template-compiler";
import { service } from '@ember/service';
import EdhrProcessDataTypes from 'eflex/constants/edhr-process-data-types';
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { CAN_SEND_IMAGES_TASK_TYPES } from 'eflex/constants/tasks/task-types';
import { range, pipe, concat } from 'ramda';
import { sortByProp, concatRight } from 'ramda-adjunct';
import { t } from 'ember-intl';
import QuantitySelect from 'eflex/components/quantity-select';
import { fn } from '@ember/helper';
import TrashCan from 'eflex/components/trash-can';
export default class EdhrTriggerConfig extends Component {
    @service
    store;
    @service
    intl;
    get parentTrigger() {
        return this.args.triggerConfig.parentTrigger;
    }
    get nonDeletedMappings() {
        return this.parentTrigger.edhrMappings.filter((item)=>!item.isDeleted);
    }
    get task() {
        return this.parentTrigger.task;
    }
    get showMappings() {
        return !this.args.isStationEvent && isPresent(this.nonDeletedMappings);
    }
    get processDataOptions() {
        const variableDefs = this.task.variableDefs.filter((item)=>item.isFromDevice).map((variableDef)=>({
                id: variableDef.id,
                processDataType: EdhrProcessDataTypes.variableDef,
                name: variableDef.name,
                variableDef
            }));
        let bolts = [];
        if (this.task.isTorque) {
            bolts = range(1, this.task.maxBoltCount + 1).flatMap((i)=>[
                    this._boltToProcessDatum(i, EdhrProcessDataTypes.boltTorque),
                    this._boltToProcessDatum(i, EdhrProcessDataTypes.boltAngle)
                ]);
        }
        let options = pipe(concatRight(variableDefs), sortByProp('name'), // these should always be last
        concatRight([
            this._getMappingOption(EdhrProcessDataTypes.currentDateTime),
            this._getMappingOption(EdhrProcessDataTypes.hardwareName),
            this._getMappingOption(EdhrProcessDataTypes.userDefinedString)
        ]))(bolts);
        if (CAN_SEND_IMAGES_TASK_TYPES.has(this.task.taskType)) {
            options = concat(options, [
                this._getMappingOption(EdhrProcessDataTypes.sendImage)
            ]);
        }
        if (this.task.isBarcode) {
            options = concat(options, [
                this._getMappingOption(EdhrProcessDataTypes.scannedBarcode)
            ]);
        }
        if (this.task.isDecision) {
            options = concat(options, [
                this._getMappingOption(EdhrProcessDataTypes.selectedDecision)
            ]);
        }
        return options;
    }
    _boltToProcessDatum(boltNumber, processDataType) {
        return {
            id: this._getBoltId(boltNumber, processDataType),
            processDataType,
            name: `${this.intl.t('bolt')} ${boltNumber} ${this.intl.t(processDataType)}`,
            boltIndex: boltNumber
        };
    }
    _getBoltId(boltNumber, boltFieldType) {
        return String(boltNumber) + boltFieldType;
    }
    _getMappingOption(processDataType) {
        return {
            id: processDataType,
            processDataType,
            name: this.intl.t(processDataType)
        };
    }
    addMapping = (count)=>{
        for(let i = 1; i <= count; i++){
            this.store.createRecord('edhrMapping', {
                edhrTrigger: this.parentTrigger
            });
        }
    };
    removeMapping = (mapping)=>{
        mapping.deleteRecord();
    };
    setProcessDatum = (mapping, processDatum)=>{
        Object.assign(mapping, {
            boltIndex: processDatum.boltIndex,
            processDataType: processDatum.processDataType,
            variableDef: processDatum.variableDef
        });
    };
    getDataMappingReference = (mapping)=>{
        switch(mapping.processDataType){
            case EdhrProcessDataTypes.variableDef:
                {
                    return this.processDataOptions.find((item)=>item.id === mapping.variableDef?.id);
                }
            case EdhrProcessDataTypes.boltTorque:
            case EdhrProcessDataTypes.boltAngle:
                {
                    return this.processDataOptions.find((item)=>item.id === this._getBoltId(mapping.boltIndex, mapping.processDataType));
                }
            default:
                {
                    return this.processDataOptions.find((item)=>item.id === mapping.processDataType);
                }
        }
    };
    static{
        template_d0cf3a2156fa4ccea5f6070c7ffd6b2c(`
    <div class="row gx-2 form-row align-nested-header pt-0">
      <div class="col">
        <div class="nested-header">
          {{t "operation"}}
        </div>
        <@form.element
          @model={{this.parentTrigger}}
          @controlType="text"
          @property="operation"
          @size="sm"
          class="trigger-operation"
        />
      </div>
      <div class="col-4">
        <div class="nested-header">
          {{t "reasonCode"}}
        </div>
        <@form.element
          @model={{this.parentTrigger}}
          @controlType="text"
          @property="reasonCode"
          @size="sm"
          class="trigger-reason-code"
        as |el|>
          <el.control disabled={{@disabled}} />
        </@form.element>
      </div>
    </div>
    {{#if this.showMappings}}
      <div class="row gx-2 form-row mt-2 mb-0">
        <div class="col">
          <div class="nested-header">
            {{t "processData"}}
          </div>
        </div>
        <div class="col-3">
          <div class="nested-header">
            {{t "userDefinedString"}}
          </div>
        </div>
        <div class="col-3">
          <div class="nested-header">
            {{t "fieldName"}}
          </div>
        </div>
        <div class="col-icon" />
      </div>
      {{#each this.nonDeletedMappings as |mapping|}}
        <div class="edhr-row row gx-2 form-row position-relative pt-0">
          <div class={{if mapping.isUserDefinedString "col" "col"}}>
            <@form.element
              @model={{this.parentTrigger}}
              @controlType="power-select"
              @options={{this.processDataOptions}}
              @optionLabelPath="name"
              @value={{this.getDataMappingReference mapping}}
              @onChange={{fn this.setProcessDatum mapping}}
              class="trigger-mapping-key"
            as |el|>
              <el.control
                @triggerClass="trigger-mapping-select form-control form-control-sm"
                @searchField="name"
                @disabled={{@disabled}}
              />
            </@form.element>
          </div>
          {{#if mapping.isUserDefinedString}}
            <div class="col-3">
              <@form.element
                @controlType="text"
                @model={{mapping}}
                @property="customValue"
                @size="sm"
                class="trigger-mapping-value"
              as |el|>
                <el.control disabled={{@disabled}} />
              </@form.element>
            </div>
          {{/if}}
          {{#unless mapping.isSendFile}}
            <div class="col-3">
              <@form.element
                @controlType="text"
                @model={{mapping}}
                @property="fieldName"
                @size="sm"
                class="trigger-mapping-name"
              as |el|>
                <el.control disabled={{@disabled}} />
              </@form.element>
            </div>
          {{/unless}}
          <div class="col-icon">
            <TrashCan
              class="delete-trigger"
              disabled={{@disabled}}
              @onDelete={{fn this.removeMapping mapping}}
            />
          </div>
        </div>
      {{/each}}
    {{/if}}
    {{#unless @isStationEvent}}
      <div class="row form-row pt-0 add-process-data mt-3">
        <div class="col">
          <QuantitySelect
            class="add-mapping text-uppercase"
            @size="sm"
            @disabled={{@disabled}}
            @onSubmit={{this.addMapping}}
          >
            {{t "addProcessData"}}
          </QuantitySelect>
        </div>
      </div>
    {{/unless}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
