import { template as template_6bd679554dc34684952062045e4b1d5a } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import Split from 'split-grid';
import { modifier } from 'ember-modifier';
import style from 'ember-style-modifier';
import { tracked } from '@glimmer/tracking';
const saveSizes = (elem, name)=>{
    if (!elem) {
        return;
    }
    const sizes = elem.style['grid-template-columns'];
    localStorage.setItem(name, sizes);
};
export default class SplitView extends Component {
    @service
    resizeListener;
    @tracked
    columns;
    constructor(){
        super(...arguments);
        let storedColumns = localStorage.getItem(this.args.name);
        // delete old format
        if (storedColumns?.startsWith('[')) {
            localStorage.removeItem(this.args.name);
            storedColumns = null;
        }
        this.columns = storedColumns ?? this.args.columns;
    }
    setup = modifier((elem, [name])=>{
        const gutters = [
            ...elem.querySelectorAll(':scope > .gutter-col')
        ];
        const opts = {};
        if (this.args.minSize) {
            opts.columnMinSize = this.args.minSize;
        } else if (this.args.minSizes) {
            opts.columnMinSizes = this.args.minSizes;
        }
        const split = Split({
            ...opts,
            columnGutters: gutters.map((gutter, i)=>({
                    element: gutter,
                    track: (i * 2) + 1
                })),
            onDragEnd: ()=>{
                this.resizeListener.triggerResize();
                saveSizes(elem, name);
            },
            onDrag: ()=>{
                this.resizeListener.triggerResize();
            }
        });
        return ()=>{
            split?.destroy(true);
        };
    });
    static{
        template_6bd679554dc34684952062045e4b1d5a(`
    {{#if @disabled}}
      <div ...attributes>
        {{yield}}
      </div>
    {{else}}
      <div
        class="component-split-view"
        {{this.setup @name}}
        {{style gridTemplateColumns=this.columns}}
        ...attributes
      >
        {{yield}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
