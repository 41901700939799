import moment from 'moment-timezone';

export default function formatTime(value, format) {
  if (!value) {
    return null;
  }

  format ??= 'LTS';

  return moment(new Date(value)).format(format);
}
