import Model, { attr } from '@ember-data/model';

export default class KineticShift extends Model {
  @attr('number') shift;
  @attr('string') name;
  @attr('date') startDate;
  @attr('date') endDate;

  get displayName() {
    return `${this.shift} - ${this.name}`;
  }
}
