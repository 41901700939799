import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { tracked } from '@glimmer/tracking';

export default class BiProcessDataRoute extends AuthenticatedRoute {
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.searchTerm = null;
    }
  }

  @tracked queryParams = {
    searchTerm: {
      refreshModel: true,
    },
    isNumericSearch: {
      refreshModel: true,
    },
    beginDate: {
      refreshModel: true,
    },
    endDate: {
      refreshModel: true,
    },
  };
}
