import Model, { attr, belongsTo } from '@ember-data/model';
import { copyable } from 'eflex/decorators';
import { rollbackBelongsTo, isBelongsToRefDirty } from 'eflex/util/relationship-helpers';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { rangeCorrectness } from 'eflex/util/validators';

@copyable
@buildValidations({
  lowerLimit: [
    validator('number', {
      allowString: true,
      allowBlank: true,
      get disabled() {
        return this.model.isForButton;
      },
    }),
  ],

  upperLimit: [
    validator('number', {
      allowString: true,
      allowBlank: true,
      get disabled() {
        return this.model.isForButton;
      },
    }),
    validator('inline', {
      validate(upperLimit, options, variableDefConfig) {
        return rangeCorrectness(
          variableDefConfig,
          variableDefConfig.lowerLimit,
          upperLimit,
          'plant.taskConfig.limitOverlapUpper',
        );
      },
      get disabled() {
        return this.model.isForButton;
      },
    }),
  ],

  decimalLength: [
    validator('number', {
      allowString: true,
      allowBlank: false,
      integer: true,
      gte: 0,
      lte: 10,
      get disabled() {
        return this.model.isForButton;
      },
    }),
  ],
})
class VariableDefConfig extends Model {
  @attr('boolean', { defaultValue: true }) enabled;
  @attr('boolean', { defaultValue: true }) required;
  @attr('number') upperLimit;
  @attr('number') lowerLimit;
  @attr('number', { defaultValue: 2 }) decimalLength;
  @attr value;

  @belongsTo('taskConfig', { async: false, inverse: 'variableDefConfigs' }) taskConfig;
  @belongsTo('variableDef', { async: false, inverse: 'variableDefConfigs' }) variableDef;
  @belongsTo('variableDef', { async: false, inverse: null }) variableDefForValue;

  get isDirty() {
    return super.isDirty || isBelongsToRefDirty(this, 'variableDefForValue');
  }

  get errorIdentifier() {
    return this.taskConfig?.errorIdentifier;
  }

  get selectedValue() {
    return this.variableDefForValue ?? this.formattedValue;
  }

  get isForButton() {
    return this.taskConfig?.task?.isButton ?? false;
  }

  get formattedValue() {
    const value = this.value;

    if (value == null) {
      return null;
    }

    return { name: this.value };
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['variableDef', 'taskConfig']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'variableDefForValue');
    super.rollbackAttributes();
  }
}

export default VariableDefConfig;
