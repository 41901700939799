import { template as template_5dadc816fa9e489fa6a7b83e87832e15 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import Portal from 'ember-stargate/components/portal';
import { or } from 'ember-truth-helpers';
import { breakLines } from 'eflex/helpers';
import { isTesting as isTestingMacro, macroCondition } from '@embroider/macros';
const DELAY_BEFORE_SPINNER = 1000;
// eslint-disable-next-line no-unneeded-ternary
const isTesting = macroCondition(isTestingMacro()) ? true : false;
export default class SpinnerOverlay extends Component {
    get isIntegrationTests() {
        return globalThis.isIntegrationTest;
    }
    spinnerDelay = task(async ()=>{
        await timeout(getDelayTime(DELAY_BEFORE_SPINNER));
    });
    constructor(){
        super(...arguments);
        if (!this.isIntegrationTests && !isTesting) {
            this.spinnerDelay.perform();
        }
    }
    static{
        template_5dadc816fa9e489fa6a7b83e87832e15(`
    {{#unless (or isTesting this.spinnerDelay.isRunning)}}
      <Portal @target="spinner-portal">
        <div class="
          spinner-overlay
          opacity-75
          h2
          d-flex
          flex-column
          align-items-center
          justify-content-center
          {{unless this.isIntegrationTests "modal-backdrop"}}"
        >
          <div class="spinner spinner-border text-secondary" role="status"></div>
          {{#if @message}}
            <div class="text text-center text-secondary saving mt-4">
              {{!-- template-lint-disable no-triple-curlies --}}
              {{{breakLines @message}}}
              {{!-- template-lint-enable no-triple-curlies --}}
            </div>
          {{/if}}
        </div>
      </Portal>
    {{/unless}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
