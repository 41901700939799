import { template as template_9f08afa4b83e4939a56a1e93a85f6e25 } from "@ember/template-compiler";
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { task, taskGroup, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import getDelayTime from 'eflex/util/get-delay-time';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';
import { addObjectsIfNotPresent } from 'eflex/util/array-helpers';
import { sortByProp } from 'ramda-adjunct';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import BsButton from 'eflex/components/bs-button';
import Link from 'eflex/components/link';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { not, and, eq } from 'ember-truth-helpers';
import Spinner from 'eflex/components/spinner';
import { onResize } from 'eflex/modifiers';
import WorkInstructionEditorLibraryCard from 'eflex/components/work-instruction-editor/library-card';
import { fn } from '@ember/helper';
const TASK_CARD_WIDTH = 124;
export default class WorkInstructionEditorTaskLibrary extends Component {
    @service
    workInstructionRepo;
    @service
    workInstructionCache;
    @tracked
    pageNumber = 1;
    @tracked
    recordCount = 0;
    @tracked
    filteredFolders = new TrackedArray();
    @tracked
    filteredWorkInstructions = new TrackedArray();
    @tracked
    searchTerm;
    @tracked
    currentIndex = 0;
    @tracked
    maxCardsDisplayed = 5;
    @tracked
    selectedFolder = this.args.currentWorkInstruction?.folder;
    previousConfig = this.args.config;
    previousCurrentWorkInstruction = this.args.currentWorkInstruction;
    @taskGroup({
        restartable: true
    })
    loadTasks;
    get allCards() {
        return this._selectableFolders.concat(this._selectableWorkInstructions);
    }
    get visibleCards() {
        return this.allCards.slice(this.currentIndex, this.currentIndex + this.numCards);
    }
    get disableNext() {
        const { showLoadButton, allCards, currentIndex, numCards } = this;
        if (showLoadButton) {
            return false;
        }
        return allCards.length <= currentIndex + numCards;
    }
    get showSpinner() {
        return this._search.isRunning || this.loadFolder.isRunning || this._resetList.isRunning || this._loadMore.isRunning;
    }
    get numCards() {
        const { selectedFolder, maxCardsDisplayed } = this;
        if (selectedFolder != null) {
            return maxCardsDisplayed - 1;
        } else {
            return maxCardsDisplayed;
        }
    }
    get _selectableFolders() {
        const { selectedFolder, filteredFolders } = this;
        if (selectedFolder != null) {
            return [];
        }
        return sortByProp('name', filteredFolders);
    }
    get _selectableWorkInstructions() {
        const { filteredWorkInstructions, selectedFolder, searchTerm } = this;
        let instructions = filteredWorkInstructions.filter((workInstruction)=>workInstruction.deployed);
        if (selectedFolder) {
            instructions = instructions.filter((workInstruction)=>isPresent(workInstruction.folder));
        } else if (isBlank(searchTerm)) {
            instructions = instructions.filter((workInstruction)=>workInstruction.folder == null);
        }
        return sortByProp('name', instructions);
    }
    get showLoadButton() {
        const { workInstructionCount, recordCount } = this;
        return workInstructionCount > 0 && workInstructionCount < recordCount;
    }
    get workInstructionCount() {
        return this._selectableWorkInstructions.length + this._selectableFolders.length;
    }
    onDidInsert = task({
        group: 'loadTasks'
    }, waitFor(async (element)=>{
        await this._resetList.perform();
        this.onResize(element);
        const { allCards, numCards, args: { currentWorkInstruction } } = this;
        this._jumpCarousel(allCards, numCards, currentWorkInstruction);
    }));
    onDidUpdate = task({
        group: 'loadTasks'
    }, waitFor(async (element, [currentWorkInstruction, config])=>{
        const { previousConfig, previousCurrentWorkInstruction } = this;
        const configChanged = previousConfig !== config || config?.isDirty;
        const currentWorkInstructionChanged = currentWorkInstruction !== previousCurrentWorkInstruction;
        Object.assign(this, {
            previousConfig: config,
            previousCurrentWorkInstruction: currentWorkInstruction,
            selectedFolder: currentWorkInstruction?.folder
        });
        if (configChanged) {
            await this._resetList.perform();
            const { allCards, numCards } = this;
            this._jumpCarousel(allCards, numCards, currentWorkInstruction);
        } else if (currentWorkInstruction && currentWorkInstructionChanged) {
            this._addRecordToFilteredInstructions();
        }
    }));
    clickNext = task(waitFor(async ()=>{
        const { visibleCards, numCards, workInstructionCount, showLoadButton } = this;
        const cardLength = visibleCards.length;
        let currentIndex = this.currentIndex;
        if (cardLength === numCards) {
            this.currentIndex = currentIndex = currentIndex + numCards;
        }
        if (currentIndex + cardLength >= workInstructionCount && showLoadButton) {
            this.pageNumber += 1;
            await this._loadMore.perform();
        }
    }));
    onSearchKeyUp = task({
        restartable: true
    }, waitFor(async ({ target: { value: searchTerm } })=>{
        Object.assign(this, {
            searchTerm,
            currentIndex: 0
        });
        if (isBlank(searchTerm)) {
            await this._resetList.perform();
            return;
        }
        await timeout(getDelayTime(300));
        await this._search.perform();
    }));
    onClearSearch = task({
        restartable: true
    }, waitFor(async ()=>{
        this.currentIndex = 0;
        await this._resetList.perform();
    }));
    onSearchClicked = task(waitFor(async ()=>{
        this.currentIndex = 0;
        await this._search.perform();
    }));
    loadFolder = task({
        restartable: true
    }, waitFor(async (folder)=>{
        const { selectedFolder } = this;
        this.currentIndex = 0;
        if (folder == null && selectedFolder.isInvalid) {
            // Reset the folder to have its previous (i.e. non-blank) name.
            selectedFolder.rollbackAttributes();
        }
        Object.assign(this, {
            searchTerm: null,
            pageNumber: 1,
            selectedFolder: folder
        });
        if (folder == null) {
            await this._resetList.perform();
            return;
        }
        const results = await this._query.perform();
        this.#setQueryResults(results);
    }));
    _search = task({
        restartable: true
    }, waitFor(async ()=>{
        const { searchTerm } = this;
        this.pageNumber = 1;
        if (isBlank(searchTerm)) {
            return;
        }
        const folders = this.workInstructionRepo.searchFolders(searchTerm);
        const results = await this._query.perform();
        this.#setQueryResults({
            folders,
            ...results
        });
    }));
    _loadMore = task(waitFor(async ()=>{
        const { searchTerm, selectedFolder, filteredWorkInstructions, filteredFolders } = this;
        const folders = selectedFolder ? [] : this.workInstructionRepo.searchFolders(searchTerm);
        const { instructions, count } = await this._query.perform();
        addObjectsIfNotPresent(filteredWorkInstructions, instructions);
        addObjectsIfNotPresent(filteredFolders, folders);
        this.recordCount = count + folders.length;
    }));
    _resetList = task(waitFor(async ()=>{
        Object.assign(this, {
            searchTerm: null,
            pageNumber: 1
        });
        const folders = this.selectedFolder ? [] : this.workInstructionRepo.searchFolders();
        const results = await this._query.perform();
        this.#setQueryResults({
            folders,
            ...results
        });
        this._addRecordToFilteredInstructions();
    }));
    _query = task(waitFor(async ()=>{
        return await this.workInstructionCache.queryInstructions.perform({
            searchTerm: this.searchTerm,
            pageNumber: this.pageNumber,
            folder: this.selectedFolder?.id,
            deployed: true
        });
    }));
    #setQueryResults({ count, instructions, folders = [] }) {
        Object.assign(this, {
            recordCount: count + folders.length,
            filteredWorkInstructions: new TrackedArray(instructions),
            filteredFolders: new TrackedArray(folders)
        });
    }
    onCardDoubleClick = (workInstruction)=>{
        const { args: { isDisabled, currentWorkInstruction } } = this;
        if (isDisabled) {
            return;
        }
        if (workInstruction.id === currentWorkInstruction?.id) {
            return;
        }
        this.args.loadWorkInstruction?.(workInstruction);
    };
    onResize = (element)=>{
        const { maxCardsDisplayed } = this;
        const { width } = element.getBoundingClientRect();
        const numCardsToFit = Math.floor(width / TASK_CARD_WIDTH);
        if (numCardsToFit === maxCardsDisplayed) {
            return;
        }
        this.maxCardsDisplayed = numCardsToFit;
    };
    onClickPrevious = (currentIndex, numCards)=>{
        this.currentIndex = currentIndex - numCards;
    };
    _addRecordToFilteredInstructions() {
        const { args: { currentWorkInstruction }, filteredWorkInstructions, showLoadButton } = this;
        if (currentWorkInstruction == null || currentWorkInstruction.isDestroyed || filteredWorkInstructions.includes(currentWorkInstruction)) {
            return;
        }
        this.filteredWorkInstructions.push(currentWorkInstruction);
        if (showLoadButton) {
            this.recordCount += 1;
        }
    }
    _jumpCarousel(allCards, numCards, currentWorkInstruction) {
        if (currentWorkInstruction == null) {
            return;
        }
        let index = allCards.indexOf(currentWorkInstruction);
        if (index === -1) {
            return;
        }
        while(index % numCards !== 0){
            index -= 1;
        }
        this.currentIndex = index;
    }
    static{
        template_9f08afa4b83e4939a56a1e93a85f6e25(`
    <div
      class="component-work-instruction-editor-library-task"
      {{didUpdate this.onDidUpdate.perform @currentWorkInstruction @config}}
      ...attributes
    >
      <div class="input-group flex-nowrap bg-white mb-2">
        <div class="d-flex position-relative w-100">
          <input
            value={{this.searchTerm}}
            placeholder={{t "searchFor"}}
            class="library-search form-control"
            {{on "keyup" this.onSearchKeyUp.perform}}
          />
          {{#if this.searchTerm.length}}
            <button
              class="clear form-control form-control-sm"
              type="button"
              {{on "click" this.onClearSearch.perform}}
            >
              <div class="icon icon-clear"></div>
            </button>
          {{/if}}
        </div>
        <BsButton
          class="input-group-append search"
          @type="secondary"
          @onClick={{this.onSearchClicked.perform}}
        >
          <FaIcon @icon="magnifying-glass" @prefix="fas" />
        </BsButton>
      </div>
      <div class="library-carousel-container d-flex rounded bg-white">
        <div class="carousel-arrow carousel-prev">
          <button
            disabled={{eq this.currentIndex 0}}
            class="btn btn-lg icon icon-carousel-prev"
            type="button"
            {{on "click" (fn this.onClickPrevious this.currentIndex this.numCards)}}
          ></button>
        </div>
        {{#if this.showSpinner}}
          <Spinner class="position-absolute" />
        {{/if}}
        <div
          class="library-carousel flex-fill d-flex"
          {{onResize this.onResize}}
          {{didInsert this.onDidInsert.perform}}
        >
          {{#if this.selectedFolder}}
            <div class="return-card d-flex flex-column">
              <div title={{this.selectedFolder.name}} class="folder-title">
                <div class="icon icon-folder me-1"></div>{{this.selectedFolder.name}}
              </div>
              <WorkInstructionEditorLibraryCard
                @workInstruction={{null}}
                @isReadOnly={{true}}
                {{on "click" (fn this.loadFolder.perform null)}}
              />
            </div>
          {{/if}}

          {{#if (and (isEmpty this.allCards) (not this.showSpinner))}}
            <div class="jem-library-empty p-2">
              {{#if this.selectedFolder}}
                {{t "workInstructions.folderIsEmpty"}}
              {{else if this.searchTerm.length}}
                {{t "workInstructions.noResults"}}
              {{else}}
                <div class="empty-intro">
                  {{t "plant.station.jem.emptyLibrary"}}
                </div>
                {{t "plant.station.jem.createInstruction-before"}}
                <Link @route="workInstructions">
                  {{t "plant.station.jem.createInstruction-link"}}
                </Link>
                {{t "plant.station.jem.createInstruction-after"}}
              {{/if}}
            </div>
          {{else}}
            <div class="library-carousel-inner d-flex">
              {{#each this.visibleCards as |card|}}
                {{#if card.isFolder}}
                  <WorkInstructionEditorLibraryCard
                    @workInstruction={{card}}
                    @isReadOnly={{true}}
                    {{on "dblclick" (fn this.loadFolder.perform card)}}
                  />
                {{else}}
                  <WorkInstructionEditorLibraryCard
                    @workInstruction={{card}}
                    @isActive={{eq card.id @currentWorkInstruction.id}}
                    @isReadOnly={{true}}
                    {{on "click" (fn this.onCardDoubleClick card)}}
                  />
                {{/if}}
              {{/each}}
            </div>
          {{/if}}
        </div>
        <div class="carousel-arrow carousel-next">
          <button
            disabled={{this.disableNext}}
            class="btn btn-lg icon icon-carousel-next"
            type="button"
            {{on "click" this.clickNext.perform}}
          ></button>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
