import { service } from '@ember/service';
import ApplicationSerializer from 'eflex/serializers/application';

export default class BuildDatumSerializer extends ApplicationSerializer {
  @service intl;

  extractErrors(store, typeClass, payload) {
    const detail = payload.errors?.[0]?.detail;
    if (detail != null) {
      payload.errors[0].detail = this.intl.t(detail);
    }

    return super.extractErrors(...arguments);
  }
}
