import { template as template_48e37c0613fe4030aa4805ffe21a4b74 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task, waitForProperty } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { or } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
export default class InputsGroupObject extends Component {
    @service
    imageEditor;
    @tracked
    grouped = false;
    onDidInsert = task(waitFor(async ()=>{
        await waitForProperty(this.imageEditor, 'canvas');
        this.#checkAndSetGrouped();
        this.imageEditor.on('selection:created', this.#checkAndSetGrouped).on('selection:updated', this.#checkAndSetGrouped);
    }));
    setGrouped = (grouped)=>{
        const selectedObjects = this.imageEditor.canvas.getActiveObject();
        if (selectedObjects == null) {
            return;
        }
        this.grouped = grouped;
        if (grouped) {
            this.imageEditor.groupSelection();
        } else {
            this.imageEditor.ungroupSelection();
        }
    };
    willDestroy() {
        super.willDestroy(...arguments);
        this.imageEditor.off('selection:created', this.#checkAndSetGrouped).off('selection:updated', this.#checkAndSetGrouped);
    }
    #checkAndSetGrouped = ()=>{
        const selectedObjects = this.imageEditor.canvas.getActiveObject();
        if (selectedObjects == null) {
            return;
        }
        this.grouped = selectedObjects.type === 'group';
    };
    static{
        template_48e37c0613fe4030aa4805ffe21a4b74(`
    <div
      {{didInsert this.onDidInsert.perform}}
      ...attributes
    >
      <div class="property-button-group">
        <button
          disabled={{or @disabled.group @locked}}
          class="btn app-group {{if this.grouped "btn-primary selected"}}"
          type="button"
          {{on "click" (fn this.setGrouped true)}}
        >
          <div class="icon icon-wie-group"></div>
        </button>
        <button
          disabled={{or @disabled.ungroup @locked}}
          class="btn app-ungroup {{unless this.grouped "btn-primary selected"}}"
          type="button"
          {{on "click" (fn this.setGrouped false)}}
        >
          <div class="icon icon-wie-ungroup"></div>
        </button>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
