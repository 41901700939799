import { template as template_54ccc4ecde964d1ba59eeb3fc98c4acc } from "@ember/template-compiler";
import { service } from '@ember/service';
import { ErrorProofingStates } from 'eflex/constants/tasks/error-proofing-states';
import { ErrorProofingTriggerValues } from 'eflex/constants/tasks/trigger-types';
import Component from '@glimmer/component';
import { pluck, pipe, prop, reject } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact, sortByPaths } from 'ramda-adjunct';
import { t } from 'ember-intl';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { fn } from '@ember/helper';
import { selectVal } from 'eflex/helpers';
import HardwareSelect from 'eflex/components/hardware-select';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { or, lt } from 'ember-truth-helpers';
const ErrorProofingHardware = [
    'adam-io',
    'turck-io'
];
export default class TaskContextConfigErrorProofing extends Component {
    @service
    hardwareRepo;
    @service
    hardwareInputDefRepo;
    get showChangeState() {
        return this.args.task.triggerType === ErrorProofingTriggerValues.taskActive;
    }
    get hardwareInputDefs() {
        return this.args.task.hardwareInputDefs.filter((item)=>!item.isDeleted);
    }
    get hardwareInputs() {
        return this.hardwareRepo.getHardwareByType(ErrorProofingHardware).flatMap((hardware)=>hardware.hardwareIos);
    }
    get remainingInputs() {
        return this.hardwareInputs.length - this.hardwareInputDefs.length;
    }
    get remainingHardwareInputs() {
        const selectedHardwareInputs = intoArray(pluck('input'), compact)(this.hardwareInputDefs ?? []);
        return pipe(intoArray(reject(prop('outputOnly')), reject((hardwareInput)=>selectedHardwareInputs.includes(hardwareInput))), sortByPaths([
            [
                'hardware',
                'name'
            ],
            [
                'hardware',
                'id'
            ],
            [
                'index'
            ]
        ]))(this.hardwareInputs);
    }
    addMultipleInputs = (inputQuantity)=>{
        for(let i = 0; i < inputQuantity; i++){
            if (this.remainingInputs) {
                this.hardwareInputDefRepo.createHardwareInputDef({
                    task: this.args.task
                });
            }
        }
    };
    deleteHardwareInputDef = (hardwareInputDef)=>{
        this.hardwareInputDefRepo.deleteHardwareInputDef(hardwareInputDef);
    };
    hardwareSearchMatcher(input, term) {
        if (input.hardware.name.includes(term) || input.inputName.includes(term)) {
            return 1;
        } else {
            return -1;
        }
    }
    static{
        template_54ccc4ecde964d1ba59eeb3fc98c4acc(`
    <div class="bootstrap-table">
      <div class="row form-row header-row">
        {{#if @task.usesOperations}}
          <div class="col-5">
            {{t "hardware"}}
          </div>
          <div class="col-1">
            {{t "hardwareNumber"}}
          </div>
          <div class="col-1">
            {{t "input"}}
          </div>
        {{else}}
          <div class="col-7">
            {{t "input"}}
          </div>
        {{/if}}
        {{#if this.showChangeState}}
          <div class="col-2 model-specific">
            {{t "plant.task.errorproofing.changeOfState"}}
          </div>
        {{/if}}
        <div class="col model-specific">
          {{t "state"}}
        </div>
        <div class="col-icon border-0" />
      </div>

      {{#each this.hardwareInputDefs as |hardwareInputDef|}}
        {{#let
          (findBy "hardwareInputDef" hardwareInputDef @taskConfig.hardwareInputDefConfigs)
        as |hardwareInputDefConfig|}}
          <div class="row form-row errorproofing-input">
            {{#if @task.usesOperations}}
              <HardwareSelect
                @form={{@form}}
                @model={{hardwareInputDef}}
                @usesOperations={{true}}
                @types={{ErrorProofingHardware}}
                @invisibleLabel={{true}}
              as |hardwareSelect|>
                <hardwareSelect.select class='col-5' />
                <hardwareSelect.index class='col-1' />
                <hardwareSelect.ioIndex class='col-1' />
              </HardwareSelect>
            {{else}}
              <div class="col-7">
                <@form.element
                  @controlType="power-select"
                  @value={{hardwareInputDef.input}}
                  @options={{this.remainingHardwareInputs}}
                  @onChange={{fn (mut hardwareInputDef.input)}}
                as |el|>
                  <el.control
                    class="select-hardware-io"
                    @triggerClass="form-control"
                    @searchEnabled={{true}}
                    @matcher={{this.hardwareSearchMatcher}}
                  as |hardwareInput|>
                    {{hardwareInput.hardware.name}} - {{hardwareInput.inputName}}
                  </el.control>
                </@form.element>
              </div>
            {{/if}}
            {{#if this.showChangeState}}
              <@form.element
                class="col-2 model-specific d-flex justify-content-center"
                @controlType="checkbox"
                @property="changeState"
                @model={{hardwareInputDefConfig}}
              />
            {{/if}}
            <@form.element
              class='col model-specific'
              @controlType="power-select"
              @options={{ErrorProofingStates}}
              @value={{
                findBy
                "value"
                hardwareInputDefConfig.state
                ErrorProofingStates
              }}
              @onChange={{selectVal (fn (mut hardwareInputDefConfig.state))}}
            as |el|>
              <el.control @triggerClass="form-control" as |dataType|>
                {{t dataType.label}}
              </el.control>
            </@form.element>
            <div class="col-icon hardware-delete">
              <TrashCan @onDelete={{fn this.deleteHardwareInputDef hardwareInputDef}} />
            </div>
          </div>
        {{/let}}
      {{/each}}

      <div class="row form-row no-hover-row">
        <div class="col-auto">
          <QuantitySelect
            class="px-2 text-uppercase test-add-error-proofing-input"
            @disabled={{or @disabled (lt this.remainingInputs 1)}}
            @max={{this.remainingInputs}}
            @onSubmit={{this.addMultipleInputs}}
          >
            {{t "addInput"}}
          </QuantitySelect>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
