import { template as template_a3940662f6a74a928b22a37ce9169e5e } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { ObjectId } from 'bson';
import { waitFor } from '@ember/test-waiters';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
export default class BiPanelBoltData extends Component {
    @service
    intl;
    @service
    queryRunner;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            fasteners: this.args.fasteners
        };
    }
    get selectedFasteners() {
        return this.args.fasteners;
    }
    getFastenerData = task({
        drop: true
    }, waitFor(async (params)=>{
        const boltDataQuery = [
            {
                $match: {
                    taskType: 'torque'
                }
            },
            this.getStationMatch(params.stationIds),
            {
                $lookup: {
                    from: 'LocationBases',
                    localField: '_id',
                    foreignField: 'parent',
                    as: 'taskConfigs'
                }
            },
            {
                $unwind: {
                    path: '$taskConfigs'
                }
            },
            {
                $group: {
                    _id: '$_id',
                    taskName: {
                        $first: {
                            $first: '$captions.text'
                        }
                    },
                    maxBoltNumber: {
                        $max: '$taskConfigs.boltCount'
                    }
                }
            },
            {
                $sort: {
                    taskName: 1
                }
            }
        ];
        let fastenerData = await this.queryRunner.runQuery.perform('LocationBases', boltDataQuery);
        fastenerData = fastenerData.flatMap(({ taskName, maxBoltNumber, _id })=>{
            const fastenersArray = [];
            for(let i = 1; i <= maxBoltNumber; i++){
                fastenersArray.push({
                    _id,
                    name: `${taskName} ${this.intl.t('fastener')} ${i}`,
                    idAndFastener: `${_id}${i}`
                });
            }
            fastenersArray.toSorted((a, b)=>a.name - b.name);
            return fastenersArray;
        });
        return fastenerData;
    }));
    fastenerOptions = trackedTask(this, this.getFastenerData, ()=>[
            this.params
        ]);
    getStationMatch(stationIds) {
        if (stationIds.length > 0) {
            return {
                $match: {
                    parent: {
                        $in: stationIds.map((stationId)=>new ObjectId(stationId))
                    }
                }
            };
        } else {
            return {
                $match: {}
            };
        }
    }
    static{
        template_a3940662f6a74a928b22a37ce9169e5e(`
    <BsForm as |form|>
      <form.element
        class="fastener-select"
        @controlType="power-select-multiple"
        @label={{t "fasteners"}}
        @value={{this.selectedFasteners}}
        @optionLabelPath="name"
        @options={{this.fastenerOptions.value}}
        @onChange={{@onChange}}
      as |el|>
        <el.control
            @searchEnabled={{true}}
         />
      </form.element>
    </BsForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
