import Service, { service } from '@ember/service';

export default class HardwareInputDefRepoService extends Service {
  hardwareInputDefs = this.store.peekAll('hardwareInputDef');

  @service store;

  createHardwareInputDef(defParams) {
    const def = this.store.createRecord('hardwareInputDef', defParams);

    def.task.taskConfigs.forEach((taskConfig) => {
      this.store.createRecord('hardwareInputDefConfig', {
        hardwareInputDef: def,
        taskConfig,
      });
    });
    return def;
  }

  deleteHardwareInputDef(def) {
    def.hardwareInputDefConfigs.forEach(config => { config.deleteRecord(); });
    def.deleteRecord();
  }
}
