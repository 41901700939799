import { template as template_103ff8b6ff124c128872f524b11fff31 } from "@ember/template-compiler";
import BsDropdown from 'ember-bootstrap/components/bs-dropdown';
import BsButton from 'eflex/components/bs-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
export default template_103ff8b6ff124c128872f524b11fff31(`
<BsDropdown
  class="options-dropdown-menu z-top-menu"
  @direction="bottom"
as |dd|>
  <dd.toggle class="options-dropdown d-block h-100" ...attributes>
    <BsButton @size="md" @type="secondary-dark">
      <FaIcon @icon="ellipsis" @prefix="fas" class="fa-fw"/>
    </BsButton>
  </dd.toggle>

  <dd.menu class="dropdown-menu-dark fs-3 me-4" as |menu|>
    {{#if @isSingleView}}
      <menu.item>
        <div
          class="options-option dropdown-item list-view"
          {{on "click" @onSetTaskListView}}
        >
          <FaIcon @icon="list-ol" @prefix="fas" class="me-2"/> {{t "jem.listTaskView"}}
        </div>
      </menu.item>
    {{/if}}

    {{#if @isListView}}
      <menu.item>
        <div
          class="options-option dropdown-item single-view"
          {{on "click" @onSetSingleTaskView}}
        >
          <FaIcon @icon="minus" @prefix="fas" @size="sm" class="me-3"/> {{t "jem.singleTaskView"}}
        </div>
      </menu.item>
    {{/if}}

    {{#if @showNotes}}
      <menu.item>
        <div
          class="options-option dropdown-item jem-open-notes"
          {{on "click" @onShowNotesModal}}
        >
          <FaIcon @icon="note-sticky" @prefix="fas" @size="sm" class="me-3"/> {{t "notes"}}
        </div>
      </menu.item>
    {{/if}}
  </dd.menu>
</BsDropdown>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
