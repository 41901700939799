import { service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import numbro from 'numbro';

export default class BoltProcessDatum extends Model {
  @attr('number') boltNumber;
  @attr('number') torqueStatus;
  @attr('number') angleStatus;
  @attr('number') tighteningStatus;
  @attr('number') torque;
  @attr('number') angle;
  @attr('number') errorCode;
  @attr('string') torqueFormat;

  @belongsTo('buildStatusChild', { inverse: 'boltProcessData', async: false }) buildStatusChild;

  @service intl;
  @service systemConfig;

  get timestamp() {
    return this.buildStatusChild?.timestamp;
  }

  get name() {
    return this.buildStatusChild.taskName;
  }

  get isPassed() {
    if (
      this.errorCode == null &&
      this.torqueStatus == null &&
      this.angleStatus == null &&
      this.tighteningStatus == null
    ) {
      return null;
    }

    return (
      this.errorCode == null &&
      this.torqueStatus === 1 &&
      this.angleStatus === 1 &&
      this.tighteningStatus === 1
    );
  }

  get isRejected() {
    return this.isPassed != null && !this.isPassed;
  }

  get isStarted() {
    return this.isPassed == null && this.buildStatusChild.isStarted;
  }

  get isFinished() {
    return !this.isStarted;
  }

  get translatedError() {
    if (this.errorCode == null) {
      return null;
    }

    const key = `stitchError.${this.errorCode}`;

    if (this.intl.exists(key)) {
      return this.intl.t(key);
    }

    return this.intl.t('stitchError.unknown', { code: this.errorCode });
  }

  get formattedData() {
    const boltTranslation = this.intl.t('bolt');
    const torqueTranslation = this.intl.t('torque');
    const angleTranslation = this.intl.t('angle');
    const torqueStatus = this.torqueStatus === 1 && this.errorCode == null;
    const angleStatus = this.angleStatus === 1 && this.errorCode == null;
    const torqueFormat =
      this.torqueFormat ??
      this.systemConfig.jem.torqueFormat;

    return [
      {
        timestamp: this.timestamp,
        displayName: `${this.name} - ${boltTranslation} ${this.boltNumber} ${torqueTranslation}`,
        displayValue: `${numbro(this.torque ?? 0).format({ mantissa: 3 })} ${torqueFormat}`,
        displayClass: torqueStatus ? 'good' : 'bad',
        torqueStatus,
      },
      {
        timestamp: this.timestamp,
        displayName: `${this.name} - ${boltTranslation} ${this.boltNumber} ${angleTranslation}`,
        displayValue: `${this.angle}°`,
        displayClass: angleStatus ? 'good' : 'bad',
        angleStatus,
      },
    ];
  }
}
