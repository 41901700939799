import TriggerConfig from 'eflex/models/trigger-config';
import { belongsTo, attr } from '@ember-data/model';
import { isBelongsToRefDirty } from 'eflex/util/relationship-helpers';

export default class IoTriggerConfig extends TriggerConfig {
  @attr('string') action;
  @attr('number', { defaultValue: 1 }) ioIndex;

  @belongsTo('hardwareIo', { inverse: null, async: false }) hardwareIo;

  get isDirty() {
    return super.isDirty || isBelongsToRefDirty(this, 'hardwareIo');
  }
}
