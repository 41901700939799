import { service } from '@ember/service';
import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';

export default class EventBusHelper extends Modifier {
  @service eventBus;

  #didSetup = false;
  #eventHandlers = new Map();

  modify(element, positional, actions) {
    if (this.#didSetup) {
      return;
    }

    this.#didSetup = true;
    const events = Object.keys(actions);

    registerDestructor(this, () => {
      this.#eventHandlers.forEach((handler, event) => {
        this.eventBus.off(event, handler);
      });
      this.#eventHandlers.clear();
    });

    for (const event of events) {
      const handler = (...args) => {
        actions[event](element, ...args);
      };
      this.#eventHandlers.set(event, handler);
      this.eventBus.on(event, handler);
    }
  }
}
