import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { updateLocationPaths, getDescendants, recursiveSave } from 'eflex/util/tree-helpers';

const setPropIfChanged = (treeItem, propName, value) => {
  if (treeItem[propName] !== value) {
    treeItem[propName] = value;
  }
};

export default class LocationRepoBaseService extends Service {
  @service store;
  @service validationErrorNotifier;

  deleteAndSave = task(waitFor(async deleted => {
    const { parent } = deleted;

    deleted.deleteRecord();

    if (parent != null && parent.isSelfOrChildInvalid) {
      deleted.rollbackAttributes();
      this.validationErrorNotifier.sendErrors([parent]);
      return;
    }

    await deleted.save();

    if (!parent) {
      return;
    }

    this._reorder(parent);
    await recursiveSave(parent);
  }));

  _reorder(treeItem) {
    if (treeItem == null) {
      return;
    }

    let a = -1;
    let g = -1;
    let s = -1;

    getDescendants(treeItem, 'station')
      .forEach(loc => {
        switch (loc.type) {
          case 'area': {
            a += 1;
            setPropIfChanged(loc, 'order', a);
            break;
          }
          case 'group': {
            g += 1;
            setPropIfChanged(loc, 'order', g);
            break;
          }
          case 'station': {
            s += 1;
            setPropIfChanged(loc, 'order', s);
            break;
          }
        }
      });
  }

  create(type, properties = {}) {
    const newLocation = this.store.createRecord(type, properties);
    if (!properties.path) {
      updateLocationPaths(newLocation);
    }
    return newLocation;
  }
}
