import getTaskHardwareInput from 'eflex/helpers/get-task-hardware-input';
import getTaskHardwareOutput from 'eflex/helpers/get-task-hardware-output';

export default function isPickWithNoConfiguredInput(station, task) {
  if (!task.isPick) {
    return false;
  }

  const hardwareInput = getTaskHardwareInput(station, task);
  const hardwareOutput = getTaskHardwareOutput(station, task);

  return (
    hardwareInput == null &&
    hardwareOutput?.isConnected === true
  );
}
