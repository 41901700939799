import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { capitalize, decamelize } from '@ember/string';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { pipe, split, join, map } from 'ramda';

export default class SettingsEvisionController extends Controller {
  @service systemConfig;
  @service notifier;
  @service currentUser;

  get evisionConfig() {
    return this.systemConfig.config.evision;
  }

  get isDirty() {
    return this.systemConfig.config.isDirty;
  }

  get namingConvention() {
    return this.evisionConfig.namingConvention;
  }

  get fileRetention() {
    return this.evisionConfig.fileRetention;
  }

  get disabled() {
    return this.currentUser.isNotUser;
  }

  get isInvalid() {
    return this.fileRetention.isInvalid || this.namingConvention.isInvalid;
  }

  get namePreview() {
    return this.namingConvention.selectedOptions
      ?.map((format) => this.getDisplayedFileFormat(format))
      .join(this.namingConvention.fieldSeparator);
  }

  getDisplayedFileFormat = (format) => {
    return pipe(
      decamelize,
      split('_'),
      map(capitalize),
      join(' '),
    )(format);
  };

  @action
  updateFormatOrder(formats) {
    this.namingConvention.selectedOptions = formats;
  }

  save = task(waitFor(async () => {
    if (this.fileRetention.isInvalid) {
      this.notifier.sendError('logs.maxHardDriveMustExceedDeletion');
      return;
    }

    if (this.namingConvention.isInvalid) {
      this.notifier.sendError('evision.settings.fieldSeparator.error');
      return;
    }

    await this.systemConfig.config.save();
  }));

  _isAlphaNum(str) {
    const letters = /^[\d A-Za-z]+$/;
    return str.trim().match(letters) != null;
  }

  @action
  rollback() {
    this.systemConfig.config.rollbackAttributes();
  }
}
