import Model, { attr, belongsTo } from '@ember-data/model';
import numbro from 'numbro';
import { arrayAttr, caption } from 'eflex/decorators';

export default class SpindleProcessDatum extends Model {
  @attr('number') spindleNumber;
  @attr('number', { defaultValue: -1 }) overallStatus;
  @attr('number') torqueStatus;
  @attr('number') torque;
  @attr('number') angleStatus;
  @attr('number') angle;
  @arrayAttr captions;
  @caption name;

  @belongsTo('multispindleProcessDatum', { async: false, inverse: 'spindles' }) multispindleProcessDatum;

  get formattedTorque() {
    if (this.torque == null) {
      return '-';
    }

    return `${numbro(this.torque).format({ mantissa: 3 })} ${this.multispindleProcessDatum.selectedTorqueFormat}`;
  }

  get formattedAngle() {
    return (this.angle != null) ? `${this.angle}°` : '-';
  }

  get isPassed() {
    return this.overallStatus === 1;
  }

  get isRejected() {
    return this.overallStatus === 0 ||
      (this.isNotReceived && this.multispindleProcessDatum.buildStatusChild.isRejected);
  }

  get isNotReceived() {
    return this.overallStatus === -1;
  }
}
