import { template as template_f0088bbe234d433c8086abab13aa3155 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_f0088bbe234d433c8086abab13aa3155(`
<div class="col d-flex h-100 justify-content-center align-items-center bi-no-data" ...attributes>
  {{t "status.0"}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
