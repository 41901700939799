import LocationRepoBase from 'eflex/services/location-repo-base';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class GroupRepoService extends LocationRepoBase {
  create(properties = {}) {
    return super.create('group', properties);
  }

  createAndSave = task(waitFor(async (properties = {}) => {
    const group = this.create(properties);
    await group.save();
    return group;
  }));
}
