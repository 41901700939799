import Service, { service } from '@ember/service';

export default class SpindleRepoService extends Service {
  @service store;

  createSpindle(params) {
    const spindle = this.store.createRecord('spindle', params);

    spindle.task.taskConfigs.forEach((taskConfig) => {
      this.store.createRecord('spindleConfig', {
        spindle,
        taskConfig,
        name: `Spindle ${spindle.task.nonDeletedSpindles.length}`,
      });
    });

    return spindle;
  }

  deleteSpindle(spindle) {
    if (spindle.task.nonDeletedSpindles.length <= 1) {
      return;
    }

    spindle.spindleConfigs.forEach(spindleConfig => { spindleConfig.deleteRecord(); });
    spindle.deleteRecord();
  }
}
