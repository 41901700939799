import BomConfig from 'eflex/models/bom-config';
import { attr } from '@ember-data/model';
import BomConfigValidations from 'eflex/util/bom-config-validations';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { DataTypes } from 'eflex/constants/component-data-types';
import { without, reject, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';

const NUMERIC_DATA_TYPES = new Set(['dataType.decimal', 'dataType.integer']);

@buildValidations({ ...BomConfigValidations })
class BomVariable extends BomConfig {
  @attr('string', { defaultValue: DataTypes[0] }) dataType;

  get isVariable() {
    return true;
  }

  get isNumeric() {
    return NUMERIC_DATA_TYPES.has(this.dataType);
  }

  get peers() {
    return intoArray(
      without([this]),
      reject(prop('isDeleted')),
    )(this.bomSource?.variables ?? []);
  }
}

export default BomVariable;
