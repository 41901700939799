import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { caption, arrayAttr } from 'eflex/decorators';

@buildValidations({
  value: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
    }),
  ],
})
class CustomIdentifierDatum extends Model {
  @attr('string') customIdentifier;
  @attr value;
  @caption name;

  @arrayAttr captions;
  @belongsTo('buildStatus', { inverse: 'customIdentifierData', async: false }) buildStatus;
  @belongsTo('liveBuildStatus', { inverse: 'customIdentifierData', async: false }) liveBuildStatus;

  get timestamp() {
    return this.buildStatus?.timestamp ?? this.liveBuildStatus?.timestamp;
  }
}

export default CustomIdentifierDatum;
