import { service } from '@ember/service';
import Helper from '@ember/component/helper';

export default class RefreshHelper extends Helper {
  @service router;

  compute([routeName]) {
    return () => {
      this.router.refresh(routeName);
    };
  }
}
