import Model, { attr } from '@ember-data/model';
import TorqueFormats from 'eflex/constants/torque-formats';

export default class JemSystemConfig extends Model {
  @attr('string', { defaultValue: TorqueFormats.NEWTON_METER.label }) torqueFormat;
  @attr('string') textToSpeechVoice;
  @attr('number', { defaultValue: 1 }) textToSpeechRate;
  @attr('number', { defaultValue: 1 }) textToSpeechPitch;
  @attr('number', { defaultValue: 1 }) textToSpeechVolume;
  @attr('boolean', { defaultValue: false }) changeNotifications;
  @attr('boolean', { defaultValue: false }) configurationHistory;
}
