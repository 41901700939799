import { template as template_feec9684be4c44e3827b2a5b31305175 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { isBlank, isEmpty } from '@ember/utils';
import { t } from 'ember-intl';
import CriteriaVisor from 'eflex/components/criteria-visor';
import Breadcrumbs from 'eflex/components/breadcrumbs';
import BsButtonGroup from 'eflex/components/bs-button-group';
import { fn } from '@ember/helper';
import { gt, or, eq } from 'ember-truth-helpers';
import SpinnerOverlay from 'eflex/components/spinner-overlay';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import formatDate from 'eflex/helpers/format-date';
import { waitFor } from '@ember/test-waiters';
import { refresh, set } from 'eflex/helpers';
import { tracked } from '@glimmer/tracking';
import { getCaptionText } from 'eflex/util/translation-helper';
import { removeObject } from 'eflex/util/array-helpers';
import style from 'ember-style-modifier';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import Spinner from 'eflex/components/spinner';
import getCytoscape from 'eflex/util/get-cytoscape';
import { add, range } from 'ramda';
const NODE_DEPTH_COLOR = [
    '#daf1fd',
    '#8fd6f6',
    '#56a3d9',
    '#426070',
    '#000000'
];
const KLAY_NODE_LIMIT = 25;
const COSE_LAYOUT = {
    name: 'cose',
    animate: true,
    nodeOverlap: 85,
    componentSpacing: 10,
    directed: true,
    fit: true,
    nodeDimensionsIncludeLabels: true
};
const KLAY_LAYOUT = {
    name: 'klay',
    animate: true,
    directed: true,
    fit: true,
    nodeDimensionsIncludeLabels: true
};
const getCsvFilename = (type, { searchTerm, searchMode, beginDate, endDate })=>{
    const name = [
        searchTerm.replace(/[^\s\w-]/gi, ''),
        searchMode,
        type
    ];
    if (beginDate && endDate) {
        name.push(formatDate(beginDate, 'YYYY-MM-DD', 'hh-mma'), formatDate(endDate, 'YYYY-MM-DD', 'hh-mma'));
    }
    return [
        ...name,
        'marriage-history.csv'
    ].join('_');
};
const getTargetTooltipId = (target)=>{
    const validCharacters = /[\w.:-]/g;
    const id = target.id().match(validCharacters)?.join('') ?? '';
    return `popper-target-${id}`;
};
const addPopperTableRow = (table, title, value)=>{
    const tr = table.insertRow();
    const tdTitle = tr.insertCell();
    tdTitle.classList.add('popper-title');
    tdTitle.textContent = title;
    const tdValue = tr.insertCell();
    tdValue.classList.add('popper-value');
    tdValue.textContent = value;
};
const getPopperElement = (target, tooltipId, intl)=>{
    const tooltip = document.createElement('div');
    tooltip.id = tooltipId;
    tooltip.classList.add('target-popper', 'border');
    const table = document.createElement('table');
    tooltip.append(table);
    const targetData = target.data();
    const popperContent = new Map([
        [
            intl.t('serialNumber'),
            targetData.id
        ],
        [
            intl.t('marriageTask'),
            getCaptionText(targetData.locationCaptions)
        ],
        [
            intl.t('plant.task.barcode.marriageLabel'),
            getCaptionText(targetData.marriageCaptions)
        ],
        [
            intl.t('timestamp'),
            targetData.timestamp
        ],
        [
            intl.t('depth'),
            targetData.depth
        ]
    ]);
    for (const [label, value] of popperContent){
        if (value) {
            addPopperTableRow(table, label, value);
        }
    }
    return tooltip;
};
const createPopper = (wrapperElement, target, intl)=>{
    const tooltipId = getTargetTooltipId(target);
    target.popper({
        content: ()=>{
            const tooltip = getPopperElement(target, tooltipId, intl);
            wrapperElement.append(tooltip);
            return tooltip;
        }
    });
    return tooltipId;
};
const removePopper = (poppers, wrapperElement, tooltipId)=>{
    const existingTarget = wrapperElement.querySelector(`#${tooltipId}`);
    if (!existingTarget) {
        return;
    }
    existingTarget.remove();
    removeObject(poppers, tooltipId);
};
const removePopperByTarget = (poppers, wrapperElement, target)=>{
    if (!target || !target.id()) {
        return;
    }
    const tooltipId = getTargetTooltipId(target);
    removePopper(poppers, wrapperElement, tooltipId);
};
const buildNodes = (buildStatuses)=>{
    const nodes = [];
    const seenSerials = new Set();
    buildStatuses.forEach((buildStatus)=>{
        const serialNumber = buildStatus.serialNumber;
        if (!seenSerials.has(serialNumber)) {
            seenSerials.add(serialNumber);
            nodes.push({
                data: {
                    id: serialNumber,
                    depth: buildStatus.depth
                }
            });
        }
    });
    return {
        nodes,
        seenSerials
    };
};
const buildEdges = (children, seenSerials)=>{
    const edges = [];
    children.forEach((child)=>{
        // only create an edge when both the source and target exist
        if (seenSerials.has(child.scannedBarcode) && seenSerials.has(child.serialNumber)) {
            edges.push({
                data: {
                    id: `${child.scannedBarcode}_${child.serialNumber}`,
                    source: child.scannedBarcode,
                    target: child.serialNumber
                }
            });
        }
    });
    return edges;
};
const getGraphData = (marriageData)=>{
    const { nodes, seenSerials } = buildNodes(marriageData.buildStatuses);
    const edges = buildEdges(marriageData.children, seenSerials);
    const hashMap = new Map();
    marriageData.children.forEach((child)=>{
        if (child.scannedBarcode) {
            hashMap.set(child.scannedBarcode, child);
            if (!seenSerials.has(child.scannedBarcode) && seenSerials.has(child.serialNumber)) {
                seenSerials.add(child.scannedBarcode);
                nodes.push({
                    data: {
                        id: child.scannedBarcode
                    }
                });
                edges.push({
                    data: {
                        id: `${child.scannedBarcode}_${child.serialNumber}`,
                        source: child.scannedBarcode,
                        target: child.serialNumber
                    }
                });
            }
        }
    });
    nodes.forEach((node)=>{
        const details = hashMap.get(node.data.id);
        node.classes = [
            `depth-${node.data.depth}`
        ];
        if (details) {
            Object.assign(node.data, details);
            node.classes.push('is-married');
        } else {
            node.classes.push('not-married');
        }
    });
    return {
        nodes,
        edges
    };
};
const cleanup = (cy)=>{
    if (!cy) {
        return;
    }
    cy.removeAllListeners();
    cy.destroy();
};
class Diagram extends Component {
    @service
    intl;
    @service
    router;
    #currentPoppers = [];
    #cy;
    @tracked
    cursorType;
    renderGraph = task({
        enqueue: true
    }, waitFor(async (element, [marriageData])=>{
        cleanup(this.#cy);
        if (isEmpty(marriageData?.buildStatuses)) {
            return;
        }
        const cytoscape = await getCytoscape();
        const graphData = getGraphData(marriageData);
        let layout = KLAY_LAYOUT;
        if (graphData.nodes.length > KLAY_NODE_LIMIT) {
            layout = COSE_LAYOUT;
        }
        const cy = this.#cy = cytoscape({
            container: element.querySelector('.cytoscape-graph'),
            elements: graphData,
            style: [
                {
                    selector: 'node',
                    style: {
                        'background-color': '#77778c',
                        label: 'data(id)',
                        height: 30,
                        width: 30,
                        'min-zoomed-font-size': 10,
                        'text-valign': 'center',
                        'line-color': '#a8eae5',
                        'font-size': '8px',
                        'font-weight': 'bold',
                        'text-outline-width': 2,
                        'text-outline-color': '#fff',
                        shape: 'ellipse'
                    }
                },
                {
                    selector: 'edge',
                    style: {
                        width: 3,
                        'curve-style': 'bezier',
                        'line-color': '#dedede',
                        'target-arrow-color': '#aaa',
                        'source-arrow-color': '#aaa',
                        'target-arrow-shape': 'triangle'
                    }
                },
                {
                    selector: 'node.not-married',
                    style: {
                        'border-width': '2px',
                        'border-style': 'solid',
                        'border-color': '#666666',
                        shape: 'rectangle'
                    }
                },
                {
                    selector: 'node.depth-0',
                    style: {
                        height: 80,
                        width: 80
                    }
                },
                {
                    selector: 'node.depth-1',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[0],
                        height: 50,
                        width: 50
                    }
                },
                {
                    selector: 'node.depth-2',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[1]
                    }
                },
                {
                    selector: 'node.depth-3',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[2]
                    }
                },
                {
                    selector: 'node.depth-4',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[3]
                    }
                },
                {
                    selector: 'node.depth-5',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[4]
                    }
                }
            ],
            maxZoom: 3,
            minZoom: 0.25,
            layout
        });
        await cy.panzoom({
            sliderHandleIcon: 'icon icon-fa-minus',
            zoomInIcon: 'icon icon-fa-plus',
            zoomOutIcon: 'icon icon-fa-minus',
            resetIcon: 'icon icon-fa-up-right-and-down-left-from-center'
        }).on('click', ()=>{
            this.#currentPoppers.forEach((tooltipId)=>{
                removePopper(this.#currentPoppers, element, tooltipId);
            });
        }).on('mouseover mouseup', 'node', (e)=>{
            this.cursorType = 'pointer';
            this.#currentPoppers.push(createPopper(element, e.target, this.intl));
        }).on('mouseout', 'node', (e)=>{
            this.cursorType = 'default';
            removePopperByTarget(this.#currentPoppers, element, e.target);
        }).on('mousedown', 'node', (e)=>{
            this.cursorType = 'grabbing';
            removePopperByTarget(this.#currentPoppers, element, e.target);
        }).on('click', 'node', (e)=>{
            this.router.transitionTo('parts.buildHistory', {
                queryParams: {
                    search: e.target.data('id')
                }
            });
        }).promiseOn('layoutstop');
    }));
    willDestroy() {
        super.willDestroy(...arguments);
        cleanup(this.#cy);
    }
    static{
        template_feec9684be4c44e3827b2a5b31305175(`
    <div
      class="component-genealogy-diagram"
      {{style cursor=this.cursorType}}
      {{didInsert this.renderGraph.perform @marriageData}}
      {{didUpdate this.renderGraph.perform @marriageData}}
      ...attributes
    >
      {{#if this.renderGraph.isRunning}}
        <Spinner class="position-absolute" />
      {{else}}
        <div class="graph-legend">
          <div class="legend-row">
            {{t 'reporting.parts.partGenealogy.legendMain'}}
            <div class="item depth-0"></div>
          </div>

          {{#each (range 1 (add @marriageData.maxDepth 1)) as |i|}}
            <div class="legend-row">
              {{t 'reporting.parts.partGenealogy.legendDepth' depth=i}}
              <div class='item depth-{{i}}'></div>
            </div>
          {{/each}}

          <div class="legend-row">
            {{t 'reporting.parts.partGenealogy.legendEnd'}}
            <div class='item end'></div>
          </div>

          <div class="legend-row total">
            {{t "reporting.parts.partGenealogy.legendTotal" total=@totalNodes}}
          </div>
        </div>
      {{/if}}

      <div class="cytoscape-graph h-100"/>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
class GenealogyTemplate extends Component {
    @service
    fileDownloader;
    downloadExport = task({
        drop: true
    }, waitFor(async (type, searchMode, params = {})=>{
        if (isBlank(params.searchTerm)) {
            return;
        }
        params = {
            ...params,
            searchMode,
            type
        };
        await this.fileDownloader.getFile.perform('marriages', 'text/csv', getCsvFilename(type, params), params);
    }));
    static{
        template_feec9684be4c44e3827b2a5b31305175(`
    <Breadcrumbs @navKey="reporting" @page={{t "navigation.reporting.parts.partGenealogy"}} />

    <div class="part-genealogy-page h-100 d-flex flex-column">
      {{#let
        @controller.searchMode
        @controller.beginDate
        @controller.endDate
        @controller.searchTerm
        @controller.model.count
        @controller.model.marriageData
        @controller.model.limit
      as |searchMode beginDate endDate searchTerm count marriageData limit|}}
        <CriteriaVisor
          @inputClass="search-genealogy"
          @onSearch={{refresh 'parts.genealogy'}}
          @searchTerm={{searchTerm}}
          @placeholder="{{t "reporting.parts.partGenealogy.searchPlaceholder"}}..."
          @searchWrapClass="col-5"
          @onParamChange={{@controller.onParamChange}}
        >
          <:default as |Criteria|>
            <Criteria.DateRange
              @beginDate={{beginDate}}
              @endDate={{endDate}}
            />
          </:default>
          <:rightOfSearch>
            <div class="col-auto ms-auto">
              <BsButtonGroup
                @type="radio"
                @value={{searchMode}}
                @onChange={{set @controller 'searchMode'}}
                class="radio-btn-group"
              as |bg|>
                <bg.button @value="descendants" class="toggle-live-mode text-uppercase">
                  {{t "reporting.parts.partGenealogy.descendants"}}
                </bg.button>
                <bg.button @value="ancestors" class="text-uppercase">
                  {{t "reporting.parts.partGenealogy.ancestors"}}
                </bg.button>
              </BsButtonGroup>
            </div>
          </:rightOfSearch>
          <:belowSearch as |params|>
            {{#if (gt count 0)}}
              <div class="row w-100 mt-2">
                <div class="col">
                  <BsButtonGroup as |bg|>
                    <span class="input-group-prepend input-group-text px-3 py-1">
                      {{t 'exportCsv'}}
                      <FaIcon @icon="download" @prefix="fas" class="ms-2" />
                    </span>
                    <bg.button
                      @type="primary"
                      @onClick={{fn this.downloadExport.perform 'full' searchMode params}}
                      class="download-full-csv text-uppercase"
                    >
                      {{t 'full'}}
                    </bg.button>
                    <bg.button
                      @type="primary"
                      @onClick={{fn this.downloadExport.perform 'graph' searchMode params}}
                      disabled={{or this.downloadExport.isRunning (eq searchMode "ancestors")}}
                      class="download-graph-csv text-uppercase"
                    >
                      {{t 'graph'}}
                    </bg.button>
                  </BsButtonGroup>
                </div>
              </div>
            {{/if}}
          </:belowSearch>
        </CriteriaVisor>

        <div class="row h-100">
          <div class="col-12">
            {{#let
              (isEmpty count)
              (eq count 0)
              @controller.model.limitReached
            as |searchNotRan noSearchMatch limitReached|}}
              {{#if (or searchNotRan limitReached noSearchMatch)}}
                <div class="w-100 h-100 ms-n3 text-center d-flex justify-content-center align-items-center border">
                  {{#if searchNotRan}}
                    {{t "reporting.parts.partGenealogy.emptyState"}}
                  {{else if limitReached}}
                    {{t "reporting.parts.partGenealogy.limitReached" count=count limit=limit}}
                    <br/>
                    {{t "reporting.parts.partGenealogy.limitReached.export"}}
                  {{else if noSearchMatch}}
                    {{t "reporting.parts.partGenealogy.noResults"}}
                  {{/if}}
                </div>
              {{else}}
                <Diagram
                  class="h-100 border border-info"
                  @marriageData={{marriageData}}
                  @totalNodes={{count}}
                />
              {{/if}}
            {{/let}}
          </div>
        </div>
      {{/let}}
    </div>

    {{#if this.downloadExport.isRunning}}
      <SpinnerOverlay @message={{t "loading.wait"}} />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(GenealogyTemplate);
