import { task, timeout, rawTimeout } from 'ember-concurrency';
import { keyResponder, onKey } from 'ember-keyboard';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

const SKIPPED_SOURCES = new Set(['INPUT', 'SELECT', 'TEXTAREA']);

const EXCLUDED_CHARS = new Set([
  '\t',
  '\r',
  '\n',
  '\f',
  '\v',
]);

@keyResponder
class KeyboardWedgeEvent extends Component {
  #accumulator = '';

  @tracked keyboardPriority = 0;

  #clearAccumalator() {
    this.keyboardPriority = 0;
    this.#accumulator = '';
  }

  _clearTask = task({ restartable: true }, waitFor(async () => {
    await rawTimeout(this.args.eraseTimeout ?? 100);
    this.#clearAccumalator();
    await this._actionTask.cancelAll({ resetState: true });
  }));

  _actionTask = task({ restartable: true }, waitFor(async enterPressed => {
    await timeout(this.args.actionTimeout ?? 50);

    const accumulator = this.#accumulator;
    const minActionLength = this.args.minActionLength ?? 5;

    if (accumulator.length >= minActionLength || (accumulator.length > 0 && enterPressed)) {
      this.args.onScan(accumulator);
      this.#clearAccumalator();
    }
  }));

  @onKey('_all')
  _accumulateKeys(event) {
    if (SKIPPED_SOURCES.has(event.target.tagName)) {
      return;
    }

    const key = event.key;

    if (key.length !== 1 && key !== 'Enter') {
      return;
    }

    if (this.#accumulator.length > 0) {
      this.keyboardPriority = 1; // prevents scan event from being captured by other wedge events
    }

    if (key.length === 1 && !EXCLUDED_CHARS.has(key)) {
      this.#accumulator = this.#accumulator + key;
    }

    this._actionTask.perform(key === 'Enter');
    this._clearTask.perform();
  }
}

export default KeyboardWedgeEvent;
