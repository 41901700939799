import ApplicationSerializer from 'eflex/serializers/application';

export default class KineticJobAssemblySerializer extends ApplicationSerializer {
  attrs = {
    issuedMaterials: {
      deserialize: false,
      serialize: false,
    },
  };
}
