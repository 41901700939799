import { template as template_4ce992c780b54800b655e2f811fc151b } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { t } from 'ember-intl';
export default class BiAverageQualityLabel extends Component {
    get averageReject() {
        return (this.args.rejectCount / this.args.total) * 100;
    }
    get averageRejectRate() {
        return this.averageReject?.toFixed(2).replace(/\.00/, '');
    }
    get averageQuality() {
        return (100 - this.averageReject).toFixed(2).replace(/\.00/, '');
    }
    static{
        template_4ce992c780b54800b655e2f811fc151b(`
    <div
      class="component-bi-average-quality-label"
      ...attributes
    >
      <div class="average-quality-label-wrapper">
        <h1 class="center">
          {{#if @displayQuality}}
            <span class="label fs-3 text-uppercase text-gray-600">
              {{t "bi.chartLabel.quality"}}
            </span>
            <span class="quality fw-bolder text-success">
              {{this.averageQuality}}%
            </span>
          {{/if}}
          <span class="label fs-3 text-uppercase text-gray-600">
            {{t "bi.chartLabel.rejectRate"}}
          </span>
          <span class="rejects pe-0 fw-bolder text-danger">
            {{this.averageRejectRate}}%
          </span>
        </h1>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
