import { template as template_7415a9f787744945a95ed9de4b051c66 } from "@ember/template-compiler";
import Link from 'eflex/components/link';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
const getProductionCountLabel = (productionSchedule, stationIsRunning)=>{
    if (!productionSchedule) {
        return '--/--';
    }
    let passedDisplay;
    if (productionSchedule?.startTime == null) {
        passedDisplay = '--';
    } else if (productionSchedule.passed == null) {
        passedDisplay = 1;
    } else if (stationIsRunning && !productionSchedule.isCompleted) {
        passedDisplay = productionSchedule.passed + 1;
    } else {
        passedDisplay = productionSchedule.passed;
    }
    return `${passedDisplay}/${productionSchedule.target}`;
};
export default template_7415a9f787744945a95ed9de4b051c66(`
<h6 class="production-count text-gray-100 mb-0">
  {{getProductionCountLabel @productionSchedule @stationIsRunning}}
</h6>
<Link @route="productionScheduler" @disabled={{@disabled}}>
  <span class="btn btn-lg btn-peripheral-dark ms-2 production-link {{if @disabled "not-allowed"}}">
    <FaIcon @icon="gear"/>
  </span>
</Link>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
