import { template as template_ef9199552829432699ef4db0bf17f3ec } from "@ember/template-compiler";
import { uniqueId, hash } from '@ember/helper';
import { t } from 'ember-intl';
import { or, eq } from 'ember-truth-helpers';
import { last, reverse } from '@eflexsystems/ember-composable-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import numbro from 'numbro';
import { scrollIntoView } from 'eflex/modifiers';
import { filter, propEq, pipe } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
const getStatusIcon = (status, errorCode)=>{
    if (status == null) {
        return null;
    }
    if (status === 1 && errorCode == null) {
        return 'check';
    } else {
        return 'xmark';
    }
};
const getStatusClass = (status, errorCode)=>{
    if (status == null) {
        return null;
    }
    if (status === 1 && errorCode == null) {
        return 'jem-text-success';
    } else {
        return 'jem-text-danger';
    }
};
const getAngleDisplay = (bolt)=>{
    if (bolt?.angle == null) {
        return null;
    }
    return `${bolt.angle}°`;
};
const getTorqueDisplay = (bolt, torqueFormat)=>{
    if (bolt?.torque == null) {
        return null;
    }
    return `${numbro(bolt.torque).format({
        mantissa: 3
    })} ${torqueFormat}`;
};
const isStaleResult = (bolt, boltNumberGroup)=>{
    if (boltNumberGroup.at(-1) === bolt) {
        return false;
    }
    if (boltNumberGroup.at(-1).isStarted && boltNumberGroup.at(-2) === bolt) {
        return false;
    }
    return true;
};
const getIsCollapsedResult = (bolt, boltNumberGroup)=>{
    const lastBolt = boltNumberGroup.at(-1);
    if (lastBolt.isFinished && lastBolt !== bolt) {
        return true;
    }
    return false;
};
export const JemBolt = template_ef9199552829432699ef4db0bf17f3ec(`
{{#let (getIsCollapsedResult @bolt @boltNumberGroup) as |isCollapsedResult|}}
  <div
    id={{if isCollapsedResult @groupId}}
    class="
      jem-bolt
      {{if @isActive "active"}}
      {{if isCollapsedResult "collapse" "show"}}
      {{if (isStaleResult @bolt @boltNumberGroup) "is-stale"}}
      component-jem-bolt
    "
    {{scrollIntoView
      shouldScroll=@isActive
      options=(hash behavior="smooth" block="center")
    }}
    ...attributes
  >
    {{#unless @bolt.isStarted}}
      <div class="row form-row rounded mb-0">
        <div class="col torque-col">
          <div
            class="
              torque
              detail-text-value
              d-flex
              align-items-center
              fw-bold
              text-body
              me-3
              {{getStatusClass @bolt.torqueStatus @bolt.errorCode}}
            "
          >
            {{getTorqueDisplay @bolt @torqueFormat}}
            {{#let (getStatusIcon @bolt.torqueStatus @bolt.errorCode) as |statusIcon|}}
              {{#if statusIcon}}
                <FaIcon @icon={{statusIcon}} @prefix="fas" class="ms-2" />
              {{/if}}
            {{/let}}
          </div>
        </div>
        <div class="col">
          <div
            class="
              angle
              detail-text-value
              d-flex
              align-items-center
              fw-bold
              text-body
              me-3
              {{getStatusClass @bolt.angleStatus @bolt.errorCode}}
            "
          >
            {{getAngleDisplay @bolt}}
            {{#let (getStatusIcon @bolt.angleStatus @bolt.errorCode) as |statusIcon|}}
              {{#if statusIcon}}
                <FaIcon @icon={{statusIcon}} @prefix="fas" class="ms-2" />
              {{/if}}
            {{/let}}
          </div>
        </div>
        <div class="col-3 tightening-col">
          <div
            class="
              tightening
              detail-text-value
              d-flex
              fw-bold
              text-body
              {{getStatusClass @bolt.tighteningStatus @bolt.errorCode}}
            "
          >
            {{#let (getStatusIcon @bolt.tighteningStatus @bolt.errorCode) as |statusIcon|}}
              {{#if statusIcon}}
                <FaIcon @icon={{statusIcon}} @prefix="fas" />
              {{/if}}
            {{/let}}
          </div>
        </div>
      </div>
    {{/unless}}

    {{#if @bolt.translatedError}}
      <div class="bolt-error badge fs-5 bg-danger rounded-pill">
        <FaIcon @icon="triangle-exclamation" @prefix="fas" @size="sm" class="me-1" />{{@bolt.translatedError}}
      </div>
    {{/if}}

  </div>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getBoltIsRejected = (bolt, childStatus, boltNumberGroup)=>{
    if (childStatus.isRejected && bolt.isRejected) {
        return true;
    }
    if (bolt.isStarted && boltNumberGroup.some((_bolt)=>_bolt.isRejected)) {
        return true;
    }
    return false;
};
const getBadgeClass = (isPassed, isRejected, isActive)=>{
    if (isPassed) {
        return 'bg-success';
    }
    if (isRejected) {
        return 'bg-danger';
    }
    if (isActive) {
        return 'bg-primary';
    }
    return 'bg-gray-200 text-body';
};
const getActiveBolt = (childStatus, boltNumberGroup)=>{
    if (!childStatus.isStarted) {
        return null;
    }
    return boltNumberGroup.find((bolts)=>!bolts.at(-1).isPassed)?.at(-1);
};
const getBoltNumberGroup = (bolt, childStatus)=>{
    return pipe(filter(propEq(bolt.boltNumber, 'boltNumber')), sortByProp('timestamp'))(childStatus?.boltProcessData ?? []);
};
const JemTaskTorqueDetails = template_ef9199552829432699ef4db0bf17f3ec(`
<div ...attributes>
  <div class="mt-2">
    <div class="pset fs-4 fw-semibold text-gray-800">
      {{t "plant.station.program"}}
    </div>
    <div class="fw-bold text-gray-900">
      {{@taskConfig.programNumber}}
    </div>
  </div>
  <div class="jem-bolts">
    {{#let @childStatus.groupedBoltProcessData as |groupedBolts|}}
      {{#let (getActiveBolt @childStatus groupedBolts) as |activeBolt|}}
        {{#each groupedBolts as |boltNumberGroup|}}
          {{#let
            (uniqueId)
            (last boltNumberGroup)
          as |groupId lastBolt|}}
            {{#let (eq lastBolt activeBolt) as |isActive|}}
              <div class="jem-task-table jem-bolt-group bootstrap-table shadow-sm rounded overflow-hidden my-2 px-2">
                <div class="
                  row
                  form-row
                  bolt-row
                  bg-gray-100
                  mb-0
                  {{if isActive 'fw-bold'}}"
                >
                  <div class="bolt-name text-body fw-semibold col p-2">
                    {{t "fastener"}} {{lastBolt.boltNumber}}
                  </div>
                  <div class="col">
                    {{#unless lastBolt.isStarted}}
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm d-flex align-items-center ms-auto"
                        data-bs-target="#{{groupId}}"
                        data-bs-toggle="collapse"
                        disabled={{eq boltNumberGroup.length 1}}
                      >
                        {{boltNumberGroup.length}}
                        <FaIcon class="ms-1" @icon="bars" @size="xs" @prefix="fas"/>
                      </button>
                    {{/unless}}
                  </div>
                  <div class="bolt-status col-3 p-2">
                    {{#let
                      lastBolt.isPassed
                      (getBoltIsRejected lastBolt @childStatus boltNumberGroup)
                    as |isPassed isRejected|}}

                      <div class="
                        badge
                        rounded-pill
                        text-capitalize
                        {{getBadgeClass isPassed isRejected isActive}}"
                      >
                        {{#if isPassed}}
                          {{t "status.251"}}
                        {{else if isRejected}}
                          {{t "rejected"}}
                        {{else if isActive}}
                          {{t "started"}}
                        {{else}}
                          {{t "waiting"}}
                        {{/if}}
                      </div>
                    {{/let}}
                  </div>
                </div>
                <div class="row form-row header-row bg-white pb-0 mb-0">
                  <div class="col torque-col fw-semibold text-capitalize border-0 p-2">{{t "torque"}}</div>
                  <div class="col fw-semibold text-capitalize border-0 p-2">{{t "angle"}}</div>
                  <div class="col-3 tightening-col fw-semibold text-capitalize border-0 p-2">{{t "tightening"}}</div>
                </div>

                <div class="bg-white row flex-column pb-2">
                  {{#each (reverse boltNumberGroup) as |bolt|}}
                    <div class="col">
                      <JemBolt
                        @groupId={{groupId}}
                        @bolt={{bolt}}
                        @boltNumberGroup={{getBoltNumberGroup bolt @childStatus}}
                        @isActive={{eq bolt activeBolt}}
                        @torqueFormat={{or @task.torqueFormat @task.globalTorqueFormat}}
                      />
                    </div>
                  {{/each}}
                </div>
              </div>
            {{/let}}
          {{/let}}
        {{/each}}
      {{/let}}
    {{/let}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template_ef9199552829432699ef4db0bf17f3ec(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskTorqueDetails
        task=@task
        childStatus=@childStatus
        taskConfig=@taskConfig
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
