import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import QueryParams from 'eflex/constants/bi-query-params';
import { torqueDataReports } from 'eflex/constants/bi-report-options';

export default class BiSingleTorqueBolts extends Controller {
  // jscpd:ignore-start
  @tracked drawerCollapsed = false;
  @tracked queryParams = Object.keys(QueryParams);
  @tracked userIds = [];
  @tracked modelIds = [];
  @tracked fasteners = [];
  @tracked stationIds = [];
  @tracked tags = [];
  @tracked shiftNames = [];
  @tracked beginDate = moment().startOf('day').toISOString();
  @tracked endDate = moment().endOf('day').toISOString();
  @tracked refresher = false;
  selectedReport = torqueDataReports.individualBoltDataChart;

  get reportOptions() {
    return Object.values(torqueDataReports);
  }

  @action
  resetCriteria() {
    Object.assign(this, {
      userIds: [],
      modelIds: [],
      stationIds: [],
      fasteners: [],
      tags: [],
      shiftNames: [],
      beginDate: moment().startOf('day').toISOString(),
      endDate: moment().endOf('day').toISOString(),
    });
  }

  @action
  refresh() {
    this.refresher = true;
  }
  // jscpd:ignore-end
}
