import { template as template_03279095d48647ea84ba088cdb7d22f5 } from "@ember/template-compiler";
import HardwareSelect from 'eflex/components/hardware-select';
import { t } from 'ember-intl';
import { array, fn } from '@ember/helper';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { selectVal, triggerTypes } from 'eflex/helpers';
import { CameraTriggerValues } from 'eflex/constants/tasks/trigger-types';
const getShowWebcamDelay = (triggerType)=>{
    return [
        CameraTriggerValues.captureAdvance,
        CameraTriggerValues.taskActive
    ].includes(triggerType);
};
export default template_03279095d48647ea84ba088cdb7d22f5(`
<div class="row form-row">
  <div class="col-6">
    <HardwareSelect
      @types={{array 'web-cam'}}
      @model={{@task}}
      @form={{@form}}
      @default={{unless @task.usesOperations (t "webCam")}}
    />
  </div>
  <div class="col-6">
    <@form.element
      class="select-trigger-type"
      @controlType="power-select"
      @value={{findBy "value" @task.triggerType (triggerTypes "imageCapture")}}
      @label={{t "plant.task.errorproofing.trigger"}}
      @options={{triggerTypes "imageCapture"}}
      @onChange={{selectVal (fn (mut @task.triggerType))}}
    as |el|>
      <el.control as |item|>
        {{t item.label}}
      </el.control>
    </@form.element>
  </div>
</div>
<div class="row form-row">
  {{#if (getShowWebcamDelay @task.triggerType)}}
    <div class="col-6 offset-6">
      <@form.element
        class="webcam-delay"
        @controlType="number"
        @property="webcamDelay"
        @label={{t "taskConfig.delay"}}
      />
    </div>
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
