import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';
import TaskStatuses from 'eflex/constants/task-statuses';
import getJemStatusTranslationOrUnknown from 'eflex/util/get-jem-status-translation-or-unknown';
import { isEmpty, isPresent } from '@ember/utils';
import MarriageStatuses from 'eflex/constants/part-marriage-statuses';
import { concat, flow, prop, collectBy } from 'ramda';
import { getCaptionText } from 'eflex/util/translation-helper';
import { cached } from '@glimmer/tracking';
import config from 'eflex/config/environment';
import modelDisplayName from 'eflex/helpers/model-display-name';
import { objectAttr, arrayAttr, caption } from 'eflex/decorators';
import { concatRight, sortByProp } from 'ramda-adjunct';

export default class BuildStatusChild extends Model {
  @attr('number') status;
  @attr('number') cycleTime;
  @attr('number') startTime;
  @attr('number') endTime;
  @attr('date') timestamp;
  @attr('date') createdAt;
  @attr('date') repairedAt;
  @attr('string') repairedBy;
  @attr('date') authorizedAt;
  @attr('string') authorizedBy;
  @attr('string') scannedBarcode;
  @attr('string') visionUrl;
  @attr('number', { defaultValue: 0 }) elapsedTime;
  @arrayAttr marriageStatuses;
  @arrayAttr marriageCaptions;
  @arrayAttr holds;

  @objectAttr location;
  @objectAttr rejectCodes;
  @objectAttr repair;
  @caption('marriageCaptions') marriageLabel;

  @belongsTo('liveBuildStatus', { async: false, inverse: 'children' }) liveBuildStatus;
  @belongsTo('buildStatus', { async: false, inverse: 'children' }) buildStatus;

  @hasMany('multispindleProcessDatum', {
    inverse: 'buildStatusChild',
    async: false,
    embedded: true,
  }) multispindleProcessData;

  @hasMany('decisionProcessDatum', { inverse: null, async: false, embedded: true }) decisionProcessData;
  @hasMany('jemProcessDatum', { inverse: 'buildStatusChild', async: false, embedded: true }) processData;
  @hasMany('boltProcessDatum', { inverse: 'buildStatusChild', async: false, embedded: true }) boltProcessData;
  @hasMany('errorProofingProcessDatum', { inverse: null, async: false, embedded: true }) errorProofingProcessData;
  @hasMany('visionProcessDatum', { inverse: null, async: false, embedded: true }) visionProcessData;
  @hasMany('scheduleProcessDatum', { async: false, embedded: true, inverse: null }) scheduleProcessData;
  @hasMany('edhrProcessDatum', { async: false, embedded: true, inverse: 'buildStatusChild' }) edhrProcessData;

  @service intl;
  @service currentUser;
  @service store;

  get taskRecord() {
    const taskId = this.taskId;

    if (!taskId) {
      return null;
    }

    return this.store.peekRecord('task', taskId);
  }

  get taskId() {
    return this.location?.id ?? this.location?._id;
  }

  @cached
  get task() {
    return this.taskRecord ?? this.location;
  }

  set task(val) {
    this.location = val;
  }

  get taskName() {
    return getCaptionText(this.task?.captions, this.currentUser);
  }

  get taskType() {
    const taskRecord = this.taskRecord;

    if (taskRecord) {
      return taskRecord.taskType;
    }

    return this.location?.type;
  }

  get iconClass() {
    const taskType = this.taskType;

    if (taskType == null) {
      return 'empty-icon';
    } else {
      return `${taskType.toLowerCase()}-icon`;
    }
  }

  get isStarted() {
    return this.status === TaskStatuses.STARTED || this.needsAuth;
  }

  get isFinished() {
    return this.status > 2;
  }

  get needsAuth() {
    return this.status === TaskStatuses.NEEDS_AUTH;
  }

  get authorized() {
    return this.status === TaskStatuses.AUTHORIZED;
  }

  get parent() {
    return this.liveBuildStatus ?? this.buildStatus;
  }

  get isRestart() {
    return this.status === TaskStatuses.RESTART;
  }

  get isNotReceived() {
    return this.status === TaskStatuses.NOT_RECEIVED;
  }

  get isFinishedPassed() {
    return this.isFinished && this.isGood;
  }

  get isRepaired() {
    return this.status === TaskStatuses.REPAIRED;
  }

  get isScrollPrevented() {
    if (this.activeBolt) {
      return true;
    }

    if (!this.isStarted) {
      return false;
    }

    let latestMultispindleDatum;

    if (this.multispindleProcessData.length === 1) {
      latestMultispindleDatum = this.multispindleProcessData[0];
    } else {
      latestMultispindleDatum = this.multispindleProcessData
        .findLast(item => item.overallStatus !== -1);
    }

    return latestMultispindleDatum?.spindles.some(item => item.isRejected);
  }

  get row() {
    return this.task?.row;
  }

  get column() {
    return this.task?.column;
  }

  get hasRejectCodes() {
    return isPresent(this.rejectCodes);
  }

  get hasRepairCodes() {
    return isPresent(this.repair);
  }

  get hasAuthorization() {
    return isPresent(this.authorizedBy);
  }

  get hasCodes() {
    return this.hasRejectCodes || this.hasRepairCodes;
  }

  get station() {
    return this.buildStatus?.station;
  }

  get isGood() {
    return TaskStatuses.isGood(this.status);
  }

  get isNotRequired() {
    return TaskStatuses.isNotRequired(this.status);
  }

  get isRejected() {
    return TaskStatuses.isRejected(this.status);
  }

  get isRejectedNotRework() {
    return TaskStatuses.isRejected(this.status) && !this.isRework;
  }

  get isRework() {
    return this.status === TaskStatuses.NEEDS_REWORK;
  }

  get statusTranslation() {
    return getJemStatusTranslationOrUnknown(this.intl, `status.${this.status}`, this.status);
  }

  @cached
  get hasActiveHold() {
    return this.holds.some(hold => isEmpty(hold?.endTime));
  }

  get isHolding() {
    return this.parent.isHolding;
  }

  get isRunning() {
    return this.isStarted && !this.parent.isHolding;
  }

  get buildDatum() {
    return this.parent?.buildDatum;
  }

  get fullVisionUrl() {
    return `${config.photoUrlPrefix}/${this.visionUrl}`;
  }

  get taskStatusClass() {
    if (this.isNotRequired) {
      return 'not-required';
    } else if (this.isFinishedPassed) {
      return 'success';
    } else if (this.isStarted) {
      return 'info';
    } else if (this.needsAuth || this.authorized) {
      return 'warning';
    } else if ((this.isFinished && !this.isGood) || this.isRestart) {
      return 'danger';
    } else {
      return null;
    }
  }

  get activeBolt() {
    if (!this.isStarted) {
      return null;
    }

    return this.groupedBoltProcessData
      .find(bolts => !bolts.at(-1).isPassed)
      ?.at(-1);
  }

  get groupedBoltProcessData() {
    return collectBy(
      prop('boltNumber'),
      this.boltProcessData ?? [],
    );
  }

  get selectedDecisions() {
    if (!this.isFinished) {
      return [];
    }

    return this.decisionProcessData
      ?.filter(item => item.isSelected)
      .flatMap(decisionProcessDatum => decisionProcessDatum.decisionTags);
  }

  get formattedProcessData() {
    let processData = this.processData ?? [];

    if (this.scannedBarcode) {
      processData = concat(
        processData,
        [{
          timestamp: this.timestamp,
          displayName: this.taskName,
          displayValue: this.scannedBarcode,
        }],
      );
    }

    return flow(processData, [
      concatRight(this.#formattedBoltProcessData),
      concatRight(this.#formattedMultispindleProcessData),
      concatRight(this.#formattedScheduleProcessData),
      concatRight(this.#formattedEdhrProcessData),
      concatRight(this.#formattedDecisionProcessData),
      sortByProp('timestamp'),
    ]);
  }

  get #formattedEdhrProcessData() {
    return this.edhrProcessData.flatMap(datum => datum.formattedData);
  }

  get #formattedBoltProcessData() {
    return this.boltProcessData.flatMap(boltProcessDatum => boltProcessDatum.formattedData);
  }

  get #formattedMultispindleProcessData() {
    return this.multispindleProcessData.flatMap(multispindleProcessDatum => multispindleProcessDatum.formattedData);
  }

  get #formattedDecisionProcessData() {
    const displayValue = this.decisionProcessData
      .filter(item => item.isSelected)
      .map(item => item.name);

    if (isEmpty(displayValue)) {
      return [];
    }

    return [{
      timestamp: this.timestamp,
      displayName: this.taskName,
      displayValue: displayValue.join(','),
    }];
  }

  get #formattedScheduleProcessData() {
    return this.scheduleProcessData.map((datum) => {
      return {
        timestamp: this.timestamp,
        displayName: `${this.taskName} - ${getCaptionText(datum.station.captions, this.currentUser)}`,
        displayValue: `${datum.scheduleTarget}x ${modelDisplayName(datum.model)}`,
      };
    });
  }

  get sortedPartMarriageStatuses() {
    return sortByProp('timestamp', this.marriageStatuses ?? []).reverse();
  }

  get latestPartMarriageStatus() {
    return this.sortedPartMarriageStatuses[0];
  }

  get lastGoodPartMarriageStatus() {
    return this.sortedPartMarriageStatuses.find((partMarriage) => MarriageStatuses.isMarried(partMarriage?.status));
  }

  get isMarried() {
    return MarriageStatuses.isMarried(this.latestPartMarriageStatus?.status);
  }

  get isDivorced() {
    return this.latestPartMarriageStatus?.status === MarriageStatuses.DIVORCED;
  }

  get formattedMarriage() {
    return this.#formatMarriage(this.latestPartMarriageStatus);
  }

  get allFormattedMarriages() {
    return this.marriageStatuses.map((status) => this.#formatMarriage(status));
  }

  #marriageStatusText(marriageStatuses) {
    switch (marriageStatuses?.status) {
      case MarriageStatuses.GOOD: {
        return 'reporting.parts.good';
      }
      case MarriageStatuses.EXTERNAL: {
        return 'external';
      }
      case MarriageStatuses.DIVORCED: {
        return 'divorced';
      }
      case MarriageStatuses.REJECTED: {
        return 'reporting.parts.bad';
      }
      case MarriageStatuses.BYPASSED: {
        return 'bypassed';
      }
      case MarriageStatuses.UNKNOWN: {
        return 'unknown';
      }
      default: {
        return null;
      }
    }
  }

  #formatMarriage(marriageStatus) {
    if (isEmpty(marriageStatus)) {
      return null;
    }

    return {
      timestamp: marriageStatus.timestamp,
      externalSerial: marriageStatus.status === MarriageStatuses.EXTERNAL,
      userName: marriageStatus.userName,
      childSerialNumber: marriageStatus.scannedBarcode,
      station: this.station,
      description: this.taskName,
      marriageLabel: this.marriageLabel,
      successful: MarriageStatuses.isMarried(marriageStatus.status),
      statusText: this.#marriageStatusText(marriageStatus),
    };
  }

  getRawBuildDataValue(taskConfig) {
    return this.buildDatum?.components?.find(item => item.option?.id === taskConfig?.configOption.id)?.rawValue;
  }
}
