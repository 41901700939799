import { dasherize } from '@ember/string';

export default function getHardwareType(obj) {
  if (!obj) {
    return null;
  }

  if (obj.hardwareAssignedType) {
    return dasherize(obj.hardwareAssignedType);
  }

  return obj.hardware?.constructor.modelName;
}
