export const OUTBOX_EVENT_MAPPINGS = {
  ClockInOut: 'ClockInOut_1.0',
  JobActivity: 'JobActivity_1.0',
  SyncBOM: 'SyncBOM_1.0',
  SyncEmployee: 'SyncEmployee_1.0',
  SyncJob: 'SyncJob_1.0',
  SyncReasonCode: 'SyncReasonCode_1.0',
  SyncShift: 'SyncShift_1.0',
  SyncSite: 'SyncSite_1.0',
  SyncStation: 'SyncStation_1.0',
  SystemConfiguration: 'SystemConfiguration_1.0',
};

export const INBOX_EVENT_MAPPINGS = {
  BOM: 'BOM_1.0',
  Employee: 'Employee_1.0',
  IssueMaterial: 'IssueMaterial_1.0',
  Job: 'Job_1.0',
  ReasonCode: 'ReasonCode_1.0',
  Shift: 'Shift_1.0',
  Site: 'Site_1.0',
  Station: 'Station_1.0',
  SyncSystemConfiguration: 'SyncSystemConfiguration_1.0',
};

export const OUTBOX_EVENTS = Object.values(OUTBOX_EVENT_MAPPINGS);
export const INBOX_EVENTS = Object.values(INBOX_EVENT_MAPPINGS);
