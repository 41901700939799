import RESTAdapter from '@ember-data/adapter/rest';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { ObjectId } from 'bson';
import { InvalidError } from '@ember-data/adapter/error';
import config from 'eflex/config/environment';

const authConfig = config['ember-simple-auth-token'];
const TOKEN_PROPERTY_NAME = authConfig.tokenPropertyName ?? 'token';
const AUTHORIZATION_HEADER_NAME = authConfig.authorizationHeaderName ?? 'Authorization';
const AUTHORIZATION_PREFIX = authConfig.authorizationPrefix ?? 'Bearer ';

export default class ApplicationAdapter extends RESTAdapter {
  host = config.servicePrefix;

  @service currentUser;
  @service session;

  get headers() {
    let headers = {
      'X-ws-session': this.currentUser.tabSession,
      'X-user-name': this.currentUser.user?.userName ?? '',
      accept: 'application/json',
    };

    if (this.session.isOidc) {
      headers = { ...this.session.headers, ...headers };
    } else {
      const token = this.session.data?.authenticated?.[TOKEN_PROPERTY_NAME];

      if (this.session.isAuthenticated && isPresent(token)) {
        headers[AUTHORIZATION_HEADER_NAME] = `${AUTHORIZATION_PREFIX}${token}`;
      }
    }

    return headers;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  shouldReloadAll() {
    return true;
  }

  generateIdForRecord() {
    return new ObjectId().toString();
  }

  handleResponse(status, headers, payload) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
      return;
    }

    if ((status === 400 || status === 422) && payload.error != null) {
      const error = payload.error;
      const invalidError = new InvalidError([error], error.message);
      invalidError.contextVariables = error.contextVariables;
      invalidError.isValidationError = error.isValidationError;
      return invalidError;
    }

    return super.handleResponse(...arguments);
  }

  // make sure native fetch is used
  _fetchRequest(options) {
    return fetch(options.url, options);
  }
}
