import { service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ContainPartsScrappedController extends Controller {
  @service store;
  @service router;

  queryParams = ['serialNumber'];

  @tracked serialNumber;
  jemContainments = this.store.peekAll('jemContainment');

  get displayedContainments() {
    return this.jemContainments?.filter(item => item.scrap) ?? [];
  }

  @action
  searchSerial() {
    this.store.unloadAll('jemContainment');
    this.router.refresh('containParts.scrapped');
  }
}
