import { template as template_e306814e6d9049e099e4eb63b2a1c71b } from "@ember/template-compiler";
import StandardModal from 'eflex/components/standard-modal';
import EmberGrid from 'eflex/components/ember-grid';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import { serviceHelper as service } from 'eflex/helpers';
export default template_e306814e6d9049e099e4eb63b2a1c71b(`
<StandardModal
  @onCancel={{@onCancel}}
  @size="xl"
  ...attributes
as |modal|>
  <modal.header />
  <modal.body>
    <EmberGrid class="w-100">
      <thead>
        <tr>
          <th>
            {{t 'name'}}
          </th>
          <th>
            {{t 'type'}}
          </th>
          <th>
            {{t 'id'}}
          </th>
        </tr>
      </thead>
      <tbody>
        {{#each @metadata as |meta|}}
          <tr class="audit-meta-row bg-white">
            <td class="meta-name">{{meta.name}}</td>
            <td class="meta-type">{{meta.type}}</td>
            <td class="meta-id">
              {{#if (eq meta.type "image")}}
                {{#let (service 'workInstructionImageRepo') as |workInstructionImageRepo|}}
                  <img src={{workInstructionImageRepo.getWieImageUrl meta.metaObjectId}}/>
                {{/let}}
                <br>
              {{/if}}
              {{meta.metaObjectId}}
            </td>
          </tr>
        {{/each}}
      </tbody>
    </EmberGrid>

  </modal.body>
  <modal.footer as |footer|>
    <footer.close>
      {{t "close"}}
    </footer.close>
  </modal.footer>
</StandardModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
