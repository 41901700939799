import { service } from '@ember/service';
import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';

export default class JemController extends Controller {
  @service currentUser;
  @service router;
  @service stationRepo;

  @controller('jem/stations') jemStationsController;

  get station() {
    return this.jemStationsController.station;
  }

  @action
  stationSelected(station) {
    localStorage.setItem('jemSelectedTreeItem', station.id);
    this.router.transitionTo('jem.stations', station.id);
  }
}
