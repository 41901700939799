import Service, { service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { isBlank, isPresent } from '@ember/utils';

export default class BuildStatusRepo extends Service {
  @service store;

  getBuildStatusesAndContainmentForRepair = task(waitFor(async serialNumber => {
    if (isBlank(serialNumber)) {
      return {
        buildStatuses: [],
        jemContainment: null,
      };
    }

    let [buildStatuses, jemContainments] = await all([
      this.store.query('buildStatus', {
        searchTerm: serialNumber,
        latestOnly: true,
      }),
      this.store.query('jemContainment', {
        serialNumber,
      }),
    ]);

    buildStatuses = buildStatuses.filter(status => {
      if (!status.stationId) {
        return false;
      }

      return isPresent(this.store.peekRecord('station', status.stationId));
    });

    buildStatuses.forEach(status => {
      Object.assign(status, {
        areaIsOpen: true,
        groupIsOpen: true,
        stationIsOpen: true,
      });
    });

    return {
      buildStatuses,
      jemContainment: jemContainments[0],
    };
  }));
}
