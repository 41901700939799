import { template as template_1967cb449c284e7c91be3420cca1e5c6 } from "@ember/template-compiler";
import { fn, get, hash } from '@ember/helper';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
import { getHardwareInputDefIo } from 'eflex/helpers';
import { eq } from 'ember-truth-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import BsButton from 'eflex/components/bs-button';
import TaskStatuses from 'eflex/constants/task-statuses';
const CheckButton = template_1967cb449c284e7c91be3420cca1e5c6(`
<BsButton
  class='complete-jem-task check-button px-3 py-2'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete TaskStatuses.NOT_RECEIVED}}
  ...attributes
>
  {{yield}}
  {{t 'check'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const RecheckButton = template_1967cb449c284e7c91be3420cca1e5c6(`
<BsButton
  class='restart-jem-task recheck-button'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete TaskStatuses.NOT_RECEIVED}}
  ...attributes
>
  {{yield}}
  {{t 'recheck'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const JemTaskErrorProofingDetails = template_1967cb449c284e7c91be3420cca1e5c6(`
<div
  class="component-jem-task-error-proofing-details"
  ...attributes
>
  {{#each @task.hardwareInputDefs as |hardwareInputDef index|}}
    {{#let
      (get @childStatus.errorProofingProcessData index)
      (findBy "taskConfig" @taskConfig hardwareInputDef.hardwareInputDefConfigs)
      (getHardwareInputDefIo @station hardwareInputDef)
    as |data config input|}}
      <div class="error-proofing-input mt-2">
        <div>
          <div class="fs-4 fw-semibold text-gray-900 input-name">
            {{input.inputName}}
          </div>
          <div class="d-flex align-items-baseline">
            <div class="col-state expected-state">
              <div class="text-gray-900 opacity-75 fs-4 fw-semibold">
                {{t "expecting"}}
              </div>
              <div class="d-flex align-items-baseline">
                <div class="expected-value fst-normal detail-text-value fw-bold text-gray-900 text-uppercase">
                  {{config.state}}
                </div>
                {{#if config.changeState}}
                  <div class="ms-2">
                    {{#if (eq config.state "on")}}
                      <FaIcon @icon="arrow-up" @prefix="fas" class="text-gray-900 jem-text-success" />
                    {{else}}
                      <FaIcon @icon="arrow-down" @prefix="fas" class="text-gray-900 jem-text-danger" />
                    {{/if}}
                  </div>
                {{/if}}
              </div>
            </div>
            <div class="col-state">
              <div class="text-gray-900 opacity-75 fs-4 fw-semibold">
                {{t "current"}}
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="
                    current-value
                    detail-text-value
                    fw-bold
                    text-gray-900
                    me-2
                    text-uppercase
                    {{if data.isPassed "jem-text-success"}}
                    {{if data.isFailed "jem-text-danger"}}
                  "
                >
                  {{data.state}}
                </div>
                {{#if data.isPassed}}
                  <FaIcon @icon="check" @prefix="fas" class="text-gray-900 jem-text-success" />
                {{/if}}
                {{#if data.isFailed}}
                  <FaIcon @icon="xmark" @prefix="fas" class="text-gray-900 jem-text-danger" />
                {{/if}}
              </div>
            </div>
          </div>
        </div>
      </div>
    {{/let}}
  {{/each}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template_1967cb449c284e7c91be3420cca1e5c6(`
  {{yield
    (hash
      okButtonOverride=CheckButton
      retryButtonOverride=RecheckButton
      taskDetails=(component
        JemTaskErrorProofingDetails
        task=@task
        childStatus=@childStatus
        taskConfig=@taskConfig
        station=@station
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
