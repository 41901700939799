import { getOwner } from '@ember/application';
import { map, add, compose, transduce, filter, reject, prop } from 'ramda';

export const PLC_FLOAT_BYTES = 4;
export const MAX_PLC_BYTES = 408;

export const getBytesConsumed = (variableDef) => {
  switch (true) {
    case variableDef.type === 'string': {
      return variableDef.length ?? 0;
    }
    case variableDef.type === 'number': {
      return PLC_FLOAT_BYTES;
    }
    case (variableDef.variableDefForValue != null): {
      return getBytesConsumed(variableDef.variableDefForValue);
    }
    default: {
      return PLC_FLOAT_BYTES;
    }
  }
};

export const validatePlcVariableDefs = (variableDefs, task) => {
  const toVariablesTotalBytes = transduce(
    compose(
      reject(prop('isFromDevice')),
      map((variableDef) => getBytesConsumed(variableDef)),
    ),
    add,
    0,
    variableDefs,
  );

  if (toVariablesTotalBytes > MAX_PLC_BYTES) {
    return getOwner(task).lookup('service:intl').t('validations.tooManyBytes');
  }

  const fromVariablesTotalBytes = transduce(
    compose(
      filter(prop('isFromDevice')),
      map((variableDef) => getBytesConsumed(variableDef)),
    ),
    add,
    0,
    variableDefs,
  );

  if (fromVariablesTotalBytes > MAX_PLC_BYTES) {
    return getOwner(task).lookup('service:intl').t('validations.tooManyBytes');
  }

  return true;
};
