import { template as template_cbbadf095b434faaa4d15fe3be105c57 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { ObjectId } from 'bson';
import echartsTheme from 'eflex/echarts-theme';
import moment from 'moment-timezone';
import { waitFor } from '@ember/test-waiters';
import { omit } from 'ramda';
import { getCaptionText } from 'eflex/util/translation-helper';
import { get } from '@ember/helper';
import { eq, and } from 'ember-truth-helpers';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
export default class ProcessDatumControlChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    currentUser;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames,
            processData: this.args.processData
        };
    }
    getProcessDatumData = task({
        drop: true
    }, waitFor(async (params)=>{
        const controlChartDataQuery = await this.queryRunner.queryWithParams.perform(params, [
            {
                $match: {
                    'children.processData.0': {
                        $exists: true
                    }
                }
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $unwind: {
                    path: '$children.processData'
                }
            },
            {
                $match: {
                    $and: [
                        {
                            'children.processData.variableDef': {
                                $in: params.processData.map((variableDef)=>new ObjectId(variableDef))
                            }
                        },
                        {
                            'children.processData.type': 'number'
                        }
                    ]
                }
            },
            {
                $sort: {
                    timestamp: 1
                }
            },
            {
                $group: {
                    _id: null,
                    processDataPoints: {
                        $push: '$children.processData'
                    },
                    avg: {
                        $avg: '$children.processData.value'
                    },
                    standardDeviation: {
                        $stdDevPop: '$children.processData.value'
                    }
                }
            },
            {
                $project: {
                    _id: 0,
                    processDataPoints: 1,
                    avg: 1,
                    ucl: {
                        $sum: [
                            {
                                $multiply: [
                                    3,
                                    '$standardDeviation'
                                ]
                            },
                            '$avg'
                        ]
                    },
                    lcl: {
                        $subtract: [
                            '$avg',
                            {
                                $multiply: [
                                    3,
                                    '$standardDeviation'
                                ]
                            }
                        ]
                    }
                }
            }
        ]);
        const preFormattedChartData = await this.queryRunner.runQuery.perform('BuildStatuses', controlChartDataQuery);
        const postFormattedChartData = preFormattedChartData.find((item)=>item.avg)?.processDataPoints?.map((processDataPoint)=>{
            return {
                value: processDataPoint.value,
                timestamp: moment(processDataPoint.timestamp).format('MM/DD/YYYY hh:mm:ss A'),
                unit: getCaptionText(processDataPoint?.unitCaptions, this.currentUser),
                ucl: preFormattedChartData[0].ucl,
                lcl: Math.max(preFormattedChartData[0].lcl, 0),
                upperLimit: processDataPoint?.upperLimit,
                lowerLimit: processDataPoint?.lowerLimit
            };
        });
        return {
            chartData: this.getEchartOptions(postFormattedChartData),
            exportData: ()=>postFormattedChartData?.map(omit([
                    'unit'
                ]), postFormattedChartData)
        };
    }));
    processDatumValue = trackedTask(this, this.getProcessDatumData, ()=>[
            this.params,
            this.args.refresher
        ]);
    getEchartOptions(controlChartData) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.processDatumControlChart')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis',
                formatter (params) {
                    const tooltipItems = params.map(({ marker, seriesName, value })=>`${marker} ${seriesName} <span style="float:right;font-weight:bold;">${value?.toFixed(2).replace(/\.00/, '')}</span>`).join('<br>');
                    return `<div style="min-width:200px;"> <div style="text-align:center"> ${params[0]?.name}
                    </div> ${tooltipItems}</div>`;
                }
            },
            xAxis: {
                type: 'category',
                data: controlChartData?.map((item)=>item.timestamp),
                axisLabel: {
                    show: true,
                    rotate: 75,
                    interval: 'auto'
                }
            },
            yAxis: {
                type: 'value',
                scale: true
            },
            series: [
                {
                    name: controlChartData?.[0]?.unit?.toUpperCase(),
                    data: controlChartData?.map((item)=>item.value),
                    type: 'line',
                    color: echartsTheme.colorPalette.biPurple,
                    markLine: {
                        data: [
                            {
                                type: 'average',
                                name: 'Avg'
                            }
                        ],
                        label: {
                            show: true,
                            position: 'insideEndTop',
                            formatter: (params)=>`${this.intl.t('bi.chartLegend.avg')}: ${params.value} ${controlChartData?.[0]?.unit?.toUpperCase()}`
                        }
                    },
                    symbol: 'emptyCircle',
                    symbolSize: 7
                },
                {
                    name: this.intl.t('bi.chartLegend.lcl'),
                    data: controlChartData?.map((item)=>item.lcl),
                    type: 'line',
                    symbol: 'none',
                    color: echartsTheme.colorPalette.taskRowRed
                },
                {
                    name: this.intl.t('bi.chartLegend.ucl'),
                    data: controlChartData?.map((item)=>item.ucl),
                    type: 'line',
                    symbol: 'none',
                    color: echartsTheme.colorPalette.taskRowRed
                },
                {
                    name: this.intl.t('bi.chartLegend.upperLimit'),
                    data: controlChartData?.map((item)=>item.upperLimit),
                    type: 'line',
                    symbol: 'none',
                    color: echartsTheme.colorPalette.taskRowGreen
                },
                {
                    name: this.intl.t('bi.chartLegend.lowerLimit'),
                    data: controlChartData?.map((item)=>item.lowerLimit),
                    type: 'line',
                    symbol: 'none',
                    color: echartsTheme.colorPalette.taskRowGreen
                }
            ]
        };
    }
    static{
        template_cbbadf095b434faaa4d15fe3be105c57(`
    <div ...attributes>
      {{#if this.processDatumValue.isRunning}}
        <Spinner />
      {{else if (and (eq @processData.length 1) (get this.processDatumValue.value.chartData.series '0.data.length'))}}
        <div class="row h-100">
          <div class="col d-flex justify-content-between">
            <EChart
            @option={{this.processDatumValue.value.chartData}}
            @exportData={{this.processDatumValue.value.exportData}}
            class="h-100 w-100 process-datum-control-chart" />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
