import { template as template_ee8aee08b5634de897a042665e9f87fa } from "@ember/template-compiler";
import { isPresent } from '@ember/utils';
const getOptionsWithDefault = (options, displayedField, defaultOption)=>{
    if (isPresent(defaultOption)) {
        return [
            {
                isDefaultOption: true,
                [displayedField]: defaultOption
            },
            ...options
        ];
    }
    return options;
};
const getSelectedOption = (selected, optionsWithDefault)=>{
    if (selected == null) {
        return optionsWithDefault[0];
    } else {
        return selected;
    }
};
export default template_ee8aee08b5634de897a042665e9f87fa(`
{{#let (getOptionsWithDefault @options @displayedField @defaultOption) as |optionsWithDefault|}}
  {{yield
    optionsWithDefault
    (getSelectedOption @selected optionsWithDefault)
  }}
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
