import { template as template_3fd3c485539a4e73ae11df9dfd30d567 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import moment from 'moment-timezone';
import { clone } from 'ramda';
import OEE_STATES from 'eflex/constants/oee-states';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import TabulatorTable from 'eflex/components/tabulator-table';
import { t } from 'ember-intl';
export default class OeeMetricsTable extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    store;
    get params() {
        return {
            stationIds: this.args.stationIds,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    get tableConfiguration() {
        return {
            columns: [
                {
                    title: this.intl.t('station'),
                    field: 'station',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('date'),
                    field: 'date',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('schedule'),
                    field: 'schedule',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('bi.chartLabel.runTime'),
                    field: 'runTime',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('bi.chartLabel.breakTime'),
                    field: 'breakTime',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('oee.good'),
                    field: 'goodCount',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('oee.rejects'),
                    field: 'rejectCount',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('reporting.totalparts'),
                    field: 'totalCount',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('oee.availability'),
                    field: 'availability',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('oee.performance'),
                    field: 'performance',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('navigation.bi.quality'),
                    field: 'quality',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('oee'),
                    field: 'oee',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                }
            ]
        };
    }
    getOeeMetricsData = task({
        restartable: true
    }, waitFor(async (params = {})=>{
        let sortStep;
        if (params.sort?.length > 0) {
            const dir = params.sort[0].dir === 'asc' ? 1 : -1;
            const field = params.sort[0].field;
            sortStep = {
                [field]: dir
            };
        } else {
            sortStep = {
                date: -1
            };
        }
        const oeeMetricsQuery = await this.queryRunner.queryScheduleHistoryWithParams.perform(this.params, [
            {
                $addFields: {
                    goodCount: {
                        $ifNull: [
                            '$goodCount',
                            0
                        ]
                    },
                    rejectCount: {
                        $ifNull: [
                            '$rejectCount',
                            0
                        ]
                    },
                    totalCount: {
                        $sum: [
                            '$goodCount',
                            '$rejectCount'
                        ]
                    },
                    targetCount: {
                        $ifNull: [
                            '$targetCount',
                            0
                        ]
                    }
                }
            },
            {
                $lookup: {
                    from: 'OeeStates',
                    localField: '_id',
                    foreignField: 'scheduleHistory',
                    pipeline: [
                        {
                            $match: {
                                state: {
                                    $in: Object.values(OEE_STATES.STATES)
                                }
                            }
                        },
                        {
                            $project: {
                                _id: 0,
                                state: 1,
                                station: '$station._id',
                                startDate: 1,
                                endDate: {
                                    $ifNull: [
                                        '$endDate',
                                        '$$NOW'
                                    ]
                                }
                            }
                        }
                    ],
                    as: 'oeeStates'
                }
            },
            {
                $set: {
                    endDate: {
                        $cond: [
                            {
                                $lt: [
                                    '$$NOW',
                                    '$endDate'
                                ]
                            },
                            '$$NOW',
                            '$endDate'
                        ]
                    }
                }
            },
            {
                $addFields: {
                    breakTime: {
                        $sum: {
                            $map: {
                                input: '$oeeStates',
                                as: 'oeeState',
                                in: {
                                    $cond: [
                                        {
                                            $eq: [
                                                '$$oeeState.state',
                                                OEE_STATES.STATES.BREAKS
                                            ]
                                        },
                                        {
                                            $dateDiff: {
                                                startDate: '$$oeeState.startDate',
                                                endDate: '$$oeeState.endDate',
                                                unit: 'millisecond'
                                            }
                                        },
                                        0
                                    ]
                                }
                            }
                        }
                    },
                    timeInAvailability: {
                        $sum: {
                            $map: {
                                input: '$oeeStates',
                                as: 'oeeState',
                                in: {
                                    $cond: [
                                        {
                                            $in: [
                                                '$$oeeState.state',
                                                [
                                                    OEE_STATES.STATES.RUNNING,
                                                    OEE_STATES.STATES.BREAKS
                                                ]
                                            ]
                                        },
                                        0,
                                        {
                                            $dateDiff: {
                                                startDate: '$$oeeState.startDate',
                                                endDate: '$$oeeState.endDate',
                                                unit: 'millisecond'
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                    }
                }
            },
            {
                $addFields: {
                    productionTime: {
                        $subtract: [
                            {
                                $dateDiff: {
                                    startDate: '$startDate',
                                    endDate: '$endDate',
                                    unit: 'millisecond'
                                }
                            },
                            '$breakTime'
                        ]
                    }
                }
            },
            {
                $addFields: {
                    runTime: {
                        $subtract: [
                            '$productionTime',
                            '$timeInAvailability'
                        ]
                    }
                }
            },
            {
                $lookup: {
                    from: 'LocationBases',
                    localField: 'station',
                    foreignField: '_id',
                    pipeline: [
                        {
                            $project: {
                                _id: 0,
                                text: {
                                    $first: '$captions.text'
                                }
                            }
                        }
                    ],
                    as: 'station'
                }
            },
            {
                $addFields: {
                    availability: {
                        $divide: [
                            '$runTime',
                            '$productionTime'
                        ]
                    },
                    performance: {
                        $cond: [
                            {
                                $eq: [
                                    '$targetCount',
                                    0
                                ]
                            },
                            0,
                            {
                                $divide: [
                                    '$goodCount',
                                    '$targetCount'
                                ]
                            }
                        ]
                    },
                    quality: {
                        $cond: [
                            {
                                $eq: [
                                    '$totalCount',
                                    0
                                ]
                            },
                            0,
                            {
                                $divide: [
                                    '$goodCount',
                                    '$totalCount'
                                ]
                            }
                        ]
                    }
                }
            },
            {
                $addFields: {
                    oee: {
                        $multiply: [
                            '$availability',
                            '$performance',
                            '$quality'
                        ]
                    }
                }
            },
            {
                $group: {
                    _id: {
                        schedule: '$schedule',
                        station: {
                            $first: '$station.text'
                        },
                        startDate: '$startDate'
                    },
                    station: {
                        $first: '$station.text'
                    },
                    schedule: {
                        $first: '$text'
                    },
                    date: {
                        $first: '$startDate'
                    },
                    runTime: {
                        $first: '$runTime'
                    },
                    breakTime: {
                        $first: '$breakTime'
                    },
                    goodCount: {
                        $first: '$goodCount'
                    },
                    rejectCount: {
                        $first: '$rejectCount'
                    },
                    totalCount: {
                        $first: '$totalCount'
                    },
                    availability: {
                        $first: '$availability'
                    },
                    performance: {
                        $first: '$performance'
                    },
                    quality: {
                        $first: '$quality'
                    },
                    oee: {
                        $first: '$oee'
                    }
                }
            },
            {
                $project: {
                    _id: 0,
                    station: {
                        $first: '$station'
                    },
                    date: {
                        $dateToString: {
                            date: '$date',
                            format: '%m/%d/%Y'
                        }
                    },
                    availability: {
                        $round: [
                            {
                                $multiply: [
                                    '$availability',
                                    100
                                ]
                            },
                            1
                        ]
                    },
                    performance: {
                        $round: [
                            {
                                $multiply: [
                                    '$performance',
                                    100
                                ]
                            },
                            1
                        ]
                    },
                    quality: {
                        $round: [
                            {
                                $multiply: [
                                    '$quality',
                                    100
                                ]
                            },
                            1
                        ]
                    },
                    oee: {
                        $round: [
                            {
                                $multiply: [
                                    '$oee',
                                    100
                                ]
                            },
                            1
                        ]
                    },
                    schedule: 1,
                    runTime: 1,
                    breakTime: 1,
                    goodCount: 1,
                    rejectCount: 1,
                    totalCount: 1
                }
            },
            {
                $sort: sortStep
            },
            {
                $skip: params.skip ?? 0
            },
            {
                $limit: params.size ?? 100_000
            }
        ]);
        const oeeMetrics = await this.queryRunner.runQuery.perform('ScheduleHistories', oeeMetricsQuery, true);
        const data = oeeMetrics.result?.map((row)=>({
                station: row.station,
                schedule: row.schedule,
                date: row.date,
                runTime: moment.duration(row.runTime, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                    precision: 1,
                    trim: 'both mid'
                }),
                breakTime: moment.duration(row.breakTime, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                    precision: 1,
                    trim: 'both mid'
                }),
                goodCount: row.goodCount,
                rejectCount: row.rejectCount,
                totalCount: row.totalCount,
                availability: `${row.availability}%`,
                performance: `${row.performance}%`,
                quality: `${row.quality}%`,
                oee: `${row.oee}%`
            }));
        return {
            count: oeeMetrics.meta?.count,
            data,
            exportData: ()=>this.formatExportData(clone(data))
        };
    }));
    oeeMetricsData = trackedTask(this, this.getOeeMetricsData, ()=>[
            this.params,
            this.args.refresher
        ]);
    formatExportData(data) {
        return data.map((row)=>{
            const { station, schedule, date, runTime, breakTime, goodCount, rejectCount, totalCount, availability, performance, quality, oee } = row;
            return {
                station,
                schedule,
                date,
                runTime,
                breakTime,
                goodCount,
                rejectCount,
                totalCount,
                availability,
                performance,
                quality,
                oee
            };
        });
    }
    static{
        template_3fd3c485539a4e73ae11df9dfd30d567(`
    <div class="bi-tabulator component-bi-oee-metrics-table" ...attributes>
      {{#if this.oeeMetricsData.isRunning}}
        <Spinner />
      {{else if this.oeeMetricsData.value.count}}
        <TabulatorTable
          @tableConfiguration={{this.tableConfiguration}}
          @getRowData={{this.getOeeMetricsData.perform}}
          @updateDataAttr={{this.params}}
          @exportFilename={{t 'bi.chartLabel.oeeMetrics'}}
          @exportDataFunction={{this.oeeMetricsData.value.exportData}}
        />
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
