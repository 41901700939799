import Controller from '@ember/controller';
import { action } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { chain, filter, last, pipe, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact, sortByProp, sortByPaths } from 'ramda-adjunct';
import TaskStatuses from 'eflex/constants/task-statuses';
import RepairFilters from 'eflex/constants/repair-filters';
import RerunStrategies from 'eflex/constants/rerun-strategy-values';
import MarriageStatuses from 'eflex/constants/part-marriage-statuses';
import { PERMISSION_NAMES } from 'eflex/constants/user-permissions';

export default class RepairController extends Controller {
  @service store;
  @service currentUser;
  @service buildStatusRepo;
  @service intl;

  queryParams = ['serialNumber'];

  @tracked showRepairConfirmationModal = false;
  @tracked showRepairPanel = false;
  @tracked showContainConfirmModal = false;
  @tracked showScrapConfirmModal = false;
  @tracked showMarriageWarningModal = false;
  @tracked marriageWarningMessage;
  @tracked buildStatus;
  @tracked childStatus;
  @tracked buildStatuses = [];
  @tracked jemContainment;
  @tracked serialNumber;
  @tracked selectedFilters = [RepairFilters[0]];
  @tracked codeStation;

  filterOptions = RepairFilters;
  permission = PERMISSION_NAMES.repairTasks;

  get hasPermission() {
    return this.currentUser.user.hasPermission(this.permission);
  }

  get displayedSerial() {
    return this.lastBuildStatus?.serialNumber || null;
  }

  get repairDisabled() {
    return !this.hasPermission || isPresent(this.jemContainment);
  }

  get containScrapDisabled() {
    return isEmpty(this.buildStatusRepairs) || isPresent(this.jemContainment) || !this.hasPermission;
  }

  get buildStatusRepairs() {
    return pipe(
      filter(buildStatus => isPresent(buildStatus.children)),
      sortByPaths([
        ['station', 'area', 'order'],
        ['station', 'group', 'order'],
        ['station', 'order'],
      ]),
    )(this.buildStatuses);
  }

  get lastBuildStatus() {
    return pipe(
      filter(buildStatus => isPresent(buildStatus.children)),
      sortByProp('timestamp'),
      last,
    )(this.buildStatuses);
  }

  get lastStatusIsGood() {
    return TaskStatuses.isGood(this.lastStatus);
  }

  get lastStatus() {
    return this.lastBuildStatus?.status;
  }

  get jemContainmentStatus() {
    if (isPresent(this.lastBuildStatus)) {
      return this.jemContainment?.statusText;
    } else {
      return null;
    }
  }

  shouldReworkBuildStatus(buildStatus) {
    return buildStatus.children.some((c) => c.isRework) && !buildStatus.children.some((c) => c.isRejectedNotRework);
  }

  getBuildStatuses = task({ restartable: true }, waitFor(async () => {
    const buildStatusesAndContainment =
      await this.buildStatusRepo.getBuildStatusesAndContainmentForRepair.perform(this.serialNumber);

    Object.assign(this, buildStatusesAndContainment);
  }));

  sendRepairs = task(waitFor(async (buildStatus, childStatus) => {
    if (this.repairDisabled) {
      return;
    }

    const userName = this.currentUser.user.userName;
    const date = new Date();

    Object.assign(childStatus, {
      status: TaskStatuses.REPAIRED,
      repairedBy: userName,
      repairedAt: date,
    });

    const lastGoodMarriage = childStatus.lastGoodPartMarriageStatus;

    if (lastGoodMarriage) {
      const newMarriageStatus = MarriageStatuses.createStatusRecord(
        userName,
        date.valueOf(),
        lastGoodMarriage.scannedBarcode,
        lastGoodMarriage.status,
      );

      childStatus.marriageStatuses = [...childStatus.marriageStatuses, newMarriageStatus];
    }

    if (buildStatus.children.every(child => TaskStatuses.isGoodOrNotRequired(child.status))) {
      buildStatus.status = TaskStatuses.REPAIRED;
    } else if (this.shouldReworkBuildStatus(buildStatus)) {
      buildStatus.status = TaskStatuses.NEEDS_REWORK;
    }

    await buildStatus.save();
  }));

  rework = task(waitFor(async (buildStatus, childStatus) => {
    if (isPresent(childStatus.formattedMarriage)) {
      this.openRepairPanel(buildStatus, childStatus);
      return;
    }

    childStatus.status = TaskStatuses.NEEDS_REWORK;

    if (this.shouldReworkBuildStatus(buildStatus)) {
      buildStatus.status = TaskStatuses.NEEDS_REWORK;
    }

    await buildStatus.save();
  }));

  reworkAfter = task(waitFor(async (buildStatus, childIndex) => {
    const childrenToRework = buildStatus.children.slice(childIndex);
    const marriages = childrenToRework.filter((c) => c.isMarried);

    if (marriages.length > 0) {
      this.marriageWarningMessage = this.intl.t('repair.marriageReworkWarning', { count: marriages.length });
      this.showMarriageWarningModal = true;
      return;
    }

    childrenToRework.filter((c) => c.isGood).forEach((childStatus) => {
      childStatus.status = TaskStatuses.NEEDS_REWORK;
    });

    if(this.shouldReworkBuildStatus(buildStatus)) {
      buildStatus.status = TaskStatuses.NEEDS_REWORK;
    }

    await buildStatus.save();
  }));

  confirmRepairConfirmationModal = task(waitFor(async repairCodeFragment => {
    this.childStatus.repair = repairCodeFragment;
    await this.sendRepairs.perform(this.buildStatus, this.childStatus);
    this.onCancelRepairModal();
  }));

  containScrapPart = task(waitFor(async (scrap = false) => {
    const jemContainment = this.store.createRecord('jemContainment', {
      serialNumber: this.lastBuildStatus.serialNumber,
      userName: this.currentUser.user.userName,
      scrap,
    });

    await jemContainment.save();

    Object.assign(this, {
      showContainConfirmModal: false,
      showScrapConfirmModal: false,
      jemContainment,
    });
  }));

  @action
  onCancelRepairModal() {
    this.showRepairConfirmationModal = false;
    this.codeStation = null;
  }

  @action
  openRepairConfirmationModal(buildStatus, childStatus) {
    if (buildStatus.station) {
      this.codeStation = buildStatus.station;
    }

    Object.assign(this, {
      buildStatus,
      childStatus,
      showRepairConfirmationModal: true,
    });
  }

  @action
  openRepairPanel(buildStatus, childStatus) {
    Object.assign(this, {
      buildStatus,
      childStatus,
      showRepairPanel: true,
    });
  }

  filterChildren = (children, statusesToFilter) => {
    const statuses = intoArray(
      chain(prop('statuses')),
      compact,
    )(statusesToFilter ?? []);

    if (isPresent(statuses)) {
      children = children.filter(child => statuses.includes(child.status));
    }

    return sortByProp('timestamp', children ?? []);
  };

  getChildStatusClass = (childStatus) => {
    switch (true) {
      case childStatus.status === TaskStatuses.REPAIRED: {
        return 'repaired';
      }
      case childStatus.status === TaskStatuses.NEEDS_REWORK: {
        return 'rework';
      }
      case TaskStatuses.isRejected(childStatus.status): {
        return 'rejected';
      }
      case TaskStatuses.isGood(childStatus.status): {
        return 'good';
      }
      default: {
        return null;
      }
    }
  };

  childRepairDisabled = (childStatus) => {
    return !isEmpty(this.jemContainments?.filter(item => item.serialNumber === childStatus.scannedBarcode));
  };

  reworkAllowed = (childStatus) => {
    if (childStatus.task == null) {
      return false;
    }

    return childStatus.isGood && childStatus.task.rerunStrategy !== RerunStrategies.neverRerun;
  };
}
