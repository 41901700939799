import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';

export default class BomSourceRoute extends AuthenticatedRoute {
  queryParams = {
    selectedConfigId: {
      replace: true,
    },
  };

  @service bomSourceRepo;
  @service router;

  model(params) {
    return this.bomSourceRepo.peekRecord(params.id);
  }

  afterModel(bomSource) {
    if (bomSource == null) {
      this.router.transitionTo('bomSources.index');
    }
  }

  setupController(controller, bomSource) {
    controller.bomSource = bomSource;
  }

  resetController(controller) {
    Object.assign(controller, {
      showOptionsPanel: false,
      selectedConfig: null,
      activeTab: 'components',
    });
  }
}
