import { fabric } from 'fabric';

fabric.Object.NUM_FRACTION_DIGITS = 6;

fabric.Line.prototype.toObject = function (propertiesToInclude) {
  return this.callSuper('toObject', ['x1', 'y1', 'x2', 'y2'].concat(propertiesToInclude));
};

//TODO: fixed in https://github.com/fabricjs/fabric.js/pull/5487
//this can be removed after update to fabric > v2.6.0 AND
//implementing use of srcFromAttribute flag on Image instances
fabric.Image.prototype.getSrc = function (filtered) {
  const element = filtered ? this._element : this._originalElement;
  if (element != null) {
    if (element.toDataURL != null) {
      return element.toDataURL();
    }

    return element.getAttribute('src');
  } else {
    return this.src || '';
  }
};

const originalRenderCanvas = fabric.StaticCanvas.prototype.renderCanvas;

fabric.StaticCanvas.prototype.renderCanvas = function (ctx, objects) {
  if (!ctx) {
    return;
  }
  originalRenderCanvas.call(this, ctx, objects);
};

const originalClearContext = fabric.StaticCanvas.prototype.clearContext;

fabric.StaticCanvas.prototype.clearContext = function (ctx) {
  if (!ctx) {
    return;
  }
  return originalClearContext.call(this, ctx);
};

