const taskTypes = {
  barcode: 'barcode',
  button: 'button',
  cobot: 'cobot',
  decision: 'decision',
  edhr: 'edhr',
  errorProofing: 'errorProofing',
  imageCapture: 'imageCapture',
  lightGuide: 'lightGuide',
  multispindle: 'multispindle',
  nodeRed: 'nodeRed',
  opcUa: 'opcUa',
  pick: 'pick',
  plc: 'plc',
  print: 'print',
  pushToSchedule: 'pushToSchedule',
  serialNumberTransfer: 'serialNumberTransfer',
  timer: 'timer',
  torque: 'torque',
  vision: 'vision',
};

const valuesAndLabels = [
  {
    value: taskTypes.button,
    label: 'plant.task.taskTypes.button',
  },
  {
    value: taskTypes.timer,
    label: 'plant.task.taskTypes.timer',
  },
  {
    value: taskTypes.barcode,
    label: 'plant.task.taskTypes.barcode',
  },
  {
    value: taskTypes.serialNumberTransfer,
    label: 'plant.task.taskTypes.serialNumberTransfer',
  },
  {
    value: taskTypes.errorProofing,
    label: 'plant.task.taskTypes.errorProofing',
  },
  {
    value: taskTypes.vision,
    label: 'plant.task.taskTypes.vision',
  },
  {
    value: taskTypes.plc,
    label: 'plant.task.taskTypes.plc',
  },
  {
    value: taskTypes.imageCapture,
    label: 'plant.task.taskTypes.imageCapture',
  },
  {
    value: taskTypes.torque,
    label: 'plant.task.taskTypes.torque',
  },
  {
    value: taskTypes.cobot,
    label: 'plant.task.taskTypes.cobot',
  },
  {
    value: taskTypes.pick,
    label: 'plant.task.taskTypes.pick',
  },
  {
    value: taskTypes.print,
    label: 'plant.task.taskTypes.print',
  },
  {
    value: taskTypes.lightGuide,
    label: 'plant.task.taskTypes.lightGuide',
  },
  {
    value: taskTypes.nodeRed,
    label: 'plant.task.taskTypes.nodeRed',
  },
  {
    value: taskTypes.decision,
    label: 'plant.task.taskTypes.decision',
  },
  {
    value: taskTypes.edhr,
    label: 'plant.task.taskTypes.edhr',
  },
  {
    value: taskTypes.multispindle,
    label: 'plant.task.taskTypes.multispindle',
  },
  {
    value: taskTypes.pushToSchedule,
    label: 'plant.task.taskTypes.pushToSchedule',
  },
  {
    value: taskTypes.opcUa,
    label: 'plant.task.taskTypes.opcUa',
  },
];

const options = {
  valuesAndLabels,
  taskTypes,
};

export const HARDWARE_REQUIRED_TASK_TYPES = new Set([
  taskTypes.torque,
  taskTypes.vision,
  taskTypes.plc,
  taskTypes.cobot,
  taskTypes.nodeRed,
  taskTypes.print,
  taskTypes.lightGuide,
  taskTypes.edhr,
  taskTypes.multispindle,
  taskTypes.opcUa,
]);

export const HARDWARE_NOT_REQUIRED_TASK_TYPES = new Set([
  taskTypes.imageCapture,
  taskTypes.button,
  taskTypes.timer,
  taskTypes.decision,
  taskTypes.pushToSchedule,
]);

export const HARDWARE_OPTIONAL_TASK_TYPES = new Set([
  taskTypes.serialNumberTransfer,
  taskTypes.barcode,
]);

export const CAN_SEND_IMAGES_TASK_TYPES = new Set([
  taskTypes.imageCapture,
  taskTypes.nodeRed,
  taskTypes.vision,
]);

export const SHOW_OK_TASK_TYPES = new Set([
  taskTypes.barcode,
  taskTypes.imageCapture,
  taskTypes.button,
  taskTypes.timer,
  taskTypes.serialNumberTransfer,
  taskTypes.decision,
]);

export { taskTypes };
export { valuesAndLabels };
export default options;
