import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment-timezone';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { service } from '@ember/service';
import weekdays from 'eflex/constants/weekdays';
import { isEmpty, isPresent } from '@ember/utils';
import { getOwner } from '@ember/application';
import { pipe, join, map, filter, without, reject, prop, intersection } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';

const checkRangeOverlap = function (start, end, startToCompare, endToCompare) {
  if (!startToCompare || !endToCompare) {
    return false;
  }

  return start.isBetween(startToCompare, endToCompare, null, '[)') ||
    end.isBetween(startToCompare, endToCompare, null, '(]') ||
    start.isBefore(startToCompare) && end.isAfter(endToCompare);
};

@buildValidations({
  endTime: [
    validator('inline', {
      get disabled() {
        return this.model.schedule.isArchived || !this.model.isTimeSet;
      },
      validate(endTime, options, scheduleDay) {
        const start = scheduleDay.startTimeWithTimezone;
        const end = scheduleDay.endTimeWithTimezone;

        if (scheduleDay.prevDay.passesMidnight && moment(scheduleDay.startTime).isBefore(scheduleDay.prevDay.endTime)) {
          return getOwner(scheduleDay).lookup('service:intl').t('schedules.daysOverlap', {
            overlappingSchedules: `${scheduleDay.schedule.text} - ${scheduleDay.prevDay.dayName}`,
          });
        }

        if (scheduleDay.schedule.isArchived) {
          return true;
        }

        const stationIds = scheduleDay.schedule.stations.map(item => item.id);
        const scheduleStart = moment(scheduleDay.schedule.startDate);
        const scheduleEnd = moment(scheduleDay.schedule.endDate);

        const schedules = getOwner(scheduleDay).lookup('service:scheduleRepo').schedules;
        const overlappingSchedules = pipe(
          intoArray(
            without([scheduleDay.schedule]),
            reject(prop('isArchived')),
            filter(schedule =>
              intersection(stationIds, schedule.stations.map(item => item.id)).length,
            ),
            filter(schedule =>
              scheduleStart.isBetween(schedule.startDate, schedule.endDate, null, '[)') ||
                end.isBetween(schedule.startDate, schedule.endDate, null, '(]') ||
                scheduleStart.isBefore(schedule.startDate) && scheduleEnd.isAfter(schedule.endDate),
            ),
            map(schedule => {
              const overlaps = [];
              const day = schedule.days.find(item => item.day === scheduleDay.day);

              if (checkRangeOverlap(start, end, day.startTimeWithTimezone, day.endTimeWithTimezone)) {
                overlaps.push(`${schedule.text} - ${day.dayName}`);
              }

              if (checkRangeOverlap(start, end, day.prevStart, day.prevEnd)) {
                overlaps.push(`${schedule.text} - ${day.prevDay.dayName}`);
              }

              if (checkRangeOverlap(start, end, day.nextStart, day.nextEnd)) {
                overlaps.push(`${schedule.text} - ${day.nextDay.dayName}`);
              }

              return overlaps.join(', ');
            }),
            reject((overlap) => isEmpty(overlap)),
          ),
          join(', '),
        )(schedules);

        if (isPresent(overlappingSchedules)) {
          return getOwner(scheduleDay).lookup('service:intl').t('schedules.daysOverlap', { overlappingSchedules });
        }

        return true;
      },
    }),
  ],

})
class ScheduleDayModel extends Model {
  @attr('number') day;
  @attr('number') startHours;
  @attr('number') startMinutes;
  @attr('number') endHours;
  @attr('number') endMinutes;

  @belongsTo('schedule', { async: false, inverse: 'days' }) schedule;

  @service intl;

  get startTime() {
    return moment(`${this.startHours}:${this.startMinutes}`, 'HH:mm').toISOString();
  }

  get endTime() {
    return moment(`${this.endHours}:${this.endMinutes}`, 'HH:mm').toISOString();
  }

  get startTimeWithTimezone() {
    if (!this.isTimeSet) {
      return null;
    }

    const startTime = moment.tz(
      {
        hour: this.startHours,
        minute: this.startMinutes,
      },
      this.schedule.timezone,
    );

    startTime.day(this.day);
    return startTime;
  }

  get endTimeWithTimezone() {
    if (!this.isTimeSet) {
      return null;
    }

    const endTime = moment.tz(
      {
        hour: this.endHours,
        minute: this.endMinutes,
      },
      this.schedule.timezone,
    );

    let day = this.day;

    if (this.passesMidnight) {
      day += 1;
    }

    endTime.day(day);
    return endTime;
  }

  get passesMidnight() {
    return moment(this.endTime).isSameOrBefore(this.startTime);
  }

  get isTimeSet() {
    return this.startTime && this.endTime;
  }

  get isCurrentDay() {
    return moment.tz(this.schedule.timezone).day() === this.day;
  }

  get isRunning() {
    if (!this.isCurrentDay) {
      return false;
    }

    return moment().isBetween(this.startTimeWithTimezone, this.endTimeWithTimezone);
  }

  get nextDay() {
    const nextDay = (this.day === 6) ? 0 : this.day + 1;
    return this.schedule.days.find(item => item.day === nextDay);
  }

  get prevDay() {
    const previousDay = (this.day === 0) ? 6 : this.day - 1;
    return this.schedule.days.find(item => item.day === previousDay);
  }

  get prevStart() {
    const dayAdd = (this.day === 0) ? -7 : 0;
    return this.prevDay.startTimeWithTimezone?.clone().add({ days: dayAdd });
  }

  get prevEnd() {
    const dayAdd = (this.day === 0) ? -7 : 0;
    return this.prevDay.endTimeWithTimezone?.clone().add({ days: dayAdd });
  }

  get nextStart() {
    const dayAdd = (this.day === 6) ? 7 : 0;
    return this.nextDay.startTimeWithTimezone?.clone().add({ days: dayAdd });
  }

  get nextEnd() {
    const dayAdd = (this.day === 6) ? 7 : 0;
    return this.nextDay.endTimeWithTimezone?.clone().add({ days: dayAdd });
  }

  get dayName() {
    return this.intl.t(weekdays.find(item => item.value === this.day).label);
  }

  get duration() {
    if (!this.isTimeSet) {
      return '---';
    }

    const diff = this.endTimeWithTimezone.diff(this.startTimeWithTimezone, 'minutes');
    return `${Number.parseInt(diff / 60)}h ${diff % 60}m`;
  }
}

export default ScheduleDayModel;
