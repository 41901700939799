import { template as template_475fced26ae7421db520978009d24589 } from "@ember/template-compiler";
import { isEmpty } from '@ember/utils';
import { taskGroup, didCancel, task, waitForEvent } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { destroy, associateDestroyableChild, registerDestructor } from '@ember/destroyable';
import { waitFor } from '@ember/test-waiters';
import exportToCsv from 'eflex/util/export-to-csv';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { eventBus } from 'eflex/helpers';
import { t } from 'ember-intl';
import { isTesting, macroCondition } from '@embroider/macros';
import moment from 'moment-timezone';
const setNested = (datum)=>{
    if (isEmpty(datum?.children)) {
        return;
    }
    datum._children = [];
    datum.children.forEach((child)=>{
        setNested(child);
    });
};
const setTableScroll = (element, scrollHeight)=>{
    element.querySelector('.tabulator-tableholder').scrollTop = scrollHeight;
};
const getExtraIcon = (element, name)=>{
    return element.querySelector(`.extra-icons .${name}-icon`);
};
const onDataTreeRowCollapsed = (row)=>{
    const rowElement = row.getElement();
    rowElement.classList.remove('opened');
    destroy(rowElement.destroyChild);
};
const getTabulator = async ()=>{
    const { TabulatorFull } = await import('tabulator-tables');
    return TabulatorFull;
};
const createTabulator = async (element, options)=>{
    const Tabulator = await getTabulator();
    const DateTime = {
        ...moment,
        fromFormat () {
            return DateTime(...arguments);
        }
    };
    return new Tabulator(element, {
        ...options,
        dependencies: {
            DateTime
        }
    });
};
export default class TabulatorTable extends Component {
    //  @tableConfiguration expected format:
    //    columns:  []
    //    options:  null
    //    childConfiguration: null
    @taskGroup({
        enqueue: true
    })
    loadingTasks;
    @tracked
    startCount = 0;
    @tracked
    endCount = 0;
    @tracked
    totalCount = 0;
    scrollHeight = 0;
    createTable = task({
        group: 'loadingTasks'
    }, waitFor(async (element)=>{
        const options = {
            autoResize: macroCondition(isTesting()) ? false : !globalThis.isIntegrationTest,
            ajaxURL: 'blank',
            columnHeaderSortMulti: false,
            columns: this.args.tableConfiguration.columns,
            dataTree: true,
            dataTreeBranchElement: false,
            dataTreeChildIndent: 50,
            dataTreeCollapseElement: getExtraIcon(element, 'collapse'),
            dataTreeExpandElement: getExtraIcon(element, 'expand'),
            height: '100%',
            layout: 'fitColumns',
            layoutColumnsOnNewData: true,
            renderVerticalBuffer: 5000,
            columnDefaults: {
                headerSortTristate: true,
                resizable: false,
                tooltip: true
            },
            rowFormatter: (row)=>{
                this.scrollHeight = element.querySelector('.tabulator-tableholder').scrollTop;
                const rowElement = row.getElement();
                const classes = [
                    'parent-row'
                ];
                if (row._row.modules?.dataTree?.children) {
                    classes.push('has-children');
                }
                rowElement.classList.add(...classes);
            },
            ...this.args.tableConfiguration.options
        };
        if (this.args.data) {
            options.data = this.args.data;
        } else {
            Object.assign(options, {
                sortMode: 'remote',
                pagination: true,
                paginationMode: 'remote',
                paginationButtonCount: 0,
                paginationSize: 30,
                paginationElement: element.querySelector('.table-footer'),
                ajaxRequestFunc: async (url, config, params)=>{
                    try {
                        return await this._requestData.perform(element, params);
                    } catch (e) {
                        if (!didCancel(e)) {
                            throw e;
                        }
                        return {
                            data: [],
                            exportData: ()=>[],
                            last_page: 0
                        };
                    }
                }
            });
        }
        let table = await createTabulator(element.querySelector('.tabulator-table'), options);
        table.on('dataLoaded', (data)=>{
            if (!data) {
                return;
            }
            // hack to use built-in expandable rows with nested tables
            data?.forEach((datum)=>{
                setNested(datum);
            });
        });
        table.on('dataTreeRowCollapsed', (row)=>{
            setTableScroll(element, this.scrollHeight);
            onDataTreeRowCollapsed(row);
        });
        table.on('dataTreeRowExpanded', (row)=>{
            setTableScroll(element, this.scrollHeight);
            this._onDataTreeRowExpanded.perform(row, {
                rowData: row.getData(),
                tableConfiguration: this.args.tableConfiguration.childConfiguration,
                expandElement: getExtraIcon(element, 'expand'),
                collapseElement: getExtraIcon(element, 'collapse')
            });
        });
        registerDestructor(this, ()=>{
            table.destroy();
            table = null;
        });
        await this._afterTableRender.perform(table);
        if (!this.args.data) {
            element.querySelector('.tabulator-page[data-page="first"]').innerHTML = '<div class="icon icon-previous-double-arrow"/>';
            element.querySelector('.tabulator-page[data-page="prev"]').innerHTML = '<div class="icon icon-previous-arrow"/>';
            element.querySelector('.tabulator-page[data-page="next"]').innerHTML = '<div class="icon icon-next-arrow"/>';
            element.querySelector('.tabulator-page[data-page="last"]').innerHTML = '<div class="icon icon-next-double-arrow"/>';
        }
    }));
    _onDataTreeRowExpanded = task(waitFor(async (parentRow, args)=>{
        const parentElement = parentRow.getElement();
        parentElement.classList.add('opened');
        parentElement.destroyChild = {};
        associateDestroyableChild(this, parentElement.destroyChild);
        let childContainer = document.createElement('div');
        childContainer.classList.add('child-container');
        let childElement = document.createElement('div');
        if (args.rowData) {
            childElement.classList.add('child-table', `child-${args.rowData.id}`);
        }
        childContainer.append(childElement);
        parentElement.append(childContainer);
        let table = await createTabulator(childElement, {
            autoResize: macroCondition(isTesting()) ? false : !globalThis.isIntegrationTest,
            columnHeaderSortMulti: false,
            columns: args.tableConfiguration.columns,
            data: args.rowData?.children,
            dataTree: true,
            dataTreeBranchElement: false,
            dataTreeChildIndent: 50,
            dataTreeCollapseElement: args.collapseElement,
            dataTreeExpandElement: args.expandElement,
            height: '100%',
            layout: 'fitColumns',
            layoutColumnsOnNewData: true,
            columnDefaults: {
                headerSortTristate: true,
                resizable: false,
                tooltip: true
            },
            rowFormatter: (row)=>{
                const rowElement = row.getElement();
                const classes = [
                    'child-row'
                ];
                if (row._row.modules?.dataTree?.children) {
                    classes.push('has-children');
                }
                rowElement.classList.add(...classes);
            },
            ...args.tableConfiguration.options
        });
        registerDestructor(table, ()=>{
            table.destroy();
            childElement.remove();
            childContainer.remove();
            childElement = null;
            childContainer = null;
            table = null;
        });
        associateDestroyableChild(parentElement.destroyChild, table);
        table.on('dataTreeRowCollapsed', (row)=>{
            onDataTreeRowCollapsed(row);
        });
        table.on('dataTreeRowExpanded', (row)=>{
            this._onDataTreeRowExpanded.perform(row, {
                rowData: row.getData(),
                tableConfiguration: args.tableConfiguration.childConfiguration,
                expandElement: args.expandElement,
                collapseElement: args.collapseElement
            });
        });
        await this._afterTableRender.perform(table);
    }));
    _afterTableRender = task(waitFor(async (table)=>{
        table.on('rowClick', (e, row)=>{
            e.stopPropagation();
            e.preventDefault();
            const headerClick = e.target.parentElement.className.startsWith('tabulator-col');
            if (!headerClick && !isEmpty(row.getData()?.children)) {
                row.treeToggle();
            }
        });
        await waitForEvent(table, 'tableBuilt');
    }));
    _requestData = task(waitFor(async (element, params)=>{
        const skip = (params.page - 1) * params.size;
        params.skip = skip;
        const result = await this.args.getRowData?.(params);
        if (result == null) {
            return {
                data: [],
                exportData: ()=>[],
                last_page: 0
            };
        }
        const { data, count } = result;
        let endCount = skip + params.size;
        if (endCount > count) {
            endCount = count;
        }
        Object.assign(this, {
            startCount: skip + 1,
            endCount,
            totalCount: count
        });
        const table = await this._getTable.perform(element);
        return {
            data,
            last_page: Math.ceil(count / table.options?.paginationSize)
        };
    }));
    onDidUpdate = task({
        group: 'loadingTasks'
    }, waitFor(async (element, [data])=>{
        const table = await this._getTable.perform(element);
        table?.setData(data);
        if (this.args.updateColumns) {
            table?.setColumns(this.args.tableConfiguration?.columns);
        }
    }));
    _getTable = task(waitFor(async (element)=>{
        const Tabulator = await getTabulator();
        return Tabulator.findTable(element.querySelector('.tabulator-table'))[0];
    }));
    exportToCsv = task(waitFor(async ()=>{
        await exportToCsv(this.args.exportDataFunction, this.args.exportFilename);
    }));
    static{
        template_475fced26ae7421db520978009d24589(`
    {{eventBus exportToCsv=this.exportToCsv.perform}}

    <div
      class="component-tabulator-table"
      {{didInsert this.createTable.perform}}
      {{didUpdate this.onDidUpdate.perform @data @updateDataAttr}}
      ...attributes
    >
      <div class="tabulator-table h-100"></div>

      <div class="extra-icons d-none">
        <FaIcon @icon="caret-down" @prefix="fas" class="expand-icon me-2" />
        <FaIcon @icon="caret-up" @prefix="fas" class="collapse-icon me-2" />
      </div>

      {{#unless @data}}
        <div class="table-footer tabulator-footer d-flex align-items-baseline">
          <div class="page-count ms-auto me-3">
            {{t "table.pageCount" start=this.startCount end=this.endCount total=this.totalCount}}
          </div>
        </div>
      {{/unless}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
