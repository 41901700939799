import { template as template_d50acbe333c34059ae9922e2ca641506 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { sortByProp } from 'ramda-adjunct';
import SearchTag from 'eflex/components/criteria-visor/search-tag';
import FilterWrapper from 'eflex/components/criteria-visor/filter-wrapper';
import { t } from 'ember-intl';
import { notEq, and, eq } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { inputVal } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { isPresent as isPresentHelper } from '@eflexsystems/ember-composable-helpers';
import CheckboxInput from 'eflex/components/checkbox-input';
class TreeItem extends Component {
    @tracked
    _expanded = false;
    get isChecked() {
        let children;
        const treeItem = this.args.treeItem;
        if (treeItem.type === this.args.type) {
            children = [
                treeItem
            ];
        } else if (this.args.type === 'station') {
            children = treeItem.stations;
        } else {
            children = treeItem.tasks;
        }
        const selectedTreeItems = this.args.selectedTreeItems;
        return children.some((child)=>selectedTreeItems.has(child));
    }
    get expanded() {
        return this._expanded || !isBlank(this.args.searchTerm);
    }
    get filteredChildren() {
        let sortedChildren;
        if (this.args.treeItem.type === 'station') {
            sortedChildren = this.args.treeItem.sortedChildren;
        } else {
            sortedChildren = sortByProp('order', this.args.treeItem.children ?? []);
        }
        if (isBlank(this.args.searchTerm)) {
            return sortedChildren;
        }
        const searchRegex = new RegExp(this.args.searchTerm, 'i');
        return sortedChildren.filter((treeItem)=>searchRegex.test(treeItem.name) || treeItem.children?.some((_treeItem)=>searchRegex.test(_treeItem.name)) || treeItem.tasks?.some((_treeItem)=>searchRegex.test(_treeItem.name)));
    }
    get hasChildren() {
        if (this.args.treeItem.type === this.args.type) {
            return false;
        }
        return !isEmpty(this.args.treeItem.children);
    }
    get expandedClass() {
        if (!this.expanded) {
            return null;
        }
        if (this.args.treeItem.type === 'area' || this.args.treeItem.type === 'group') {
            return 'tree-toggle-down';
        } else {
            return 'tree-station-opened';
        }
    }
    constructor(){
        super(...arguments);
        this._expanded = this.isChecked;
    }
    expand = ()=>{
        this._expanded = !this._expanded;
    };
    static{
        template_d50acbe333c34059ae9922e2ca641506(`
    <li
      class="plant-{{@treeItem.type}} text-nowrap fs-4"
      ...attributes
    >
      {{#if this.hasChildren}}
        <i
          class="toggle-children float-start position-relative d-block tree-{{@treeItem.type}} {{this.expandedClass}}"
          {{on "click" this.expand}}
        ></i>
      {{/if}}
      <span class="tree-menu-item position-relative d-block">
        <label class="tree-item-label text-truncate">
          <CheckboxInput
            class="eflex-tree-checkbox"
            @checked={{this.isChecked}}
            @label={{@treeItem.name}}
            @onChange={{fn @onTreeItemChecked @treeItem}}
          />
        </label>
      </span>
      {{#if (and (notEq @treeItem.type 'task') this.hasChildren this.expanded)}}
        <ul class='{{@treeItem.type}}-children list-unstyled'>
          {{#each this.filteredChildren as |child|}}
            <TreeItem
              @type={{@type}}
              @selectedTreeItems={{@selectedTreeItems}}
              @treeItem={{child}}
              @searchTerm={{@searchTerm}}
              @onTreeItemChecked={{@onTreeItemChecked}}
            />
          {{/each}}
        </ul>
      {{/if}}
    </li>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default class CriteriaVisorTree extends Component {
    @tracked
    searchTerm;
    @tracked
    isDisplayed;
    @service
    intl;
    @service
    areaRepo;
    @service
    store;
    @service
    stationRepo;
    @service
    taskRepo;
    get #paramName() {
        if (this.args.type === 'station') {
            return 'stationIds';
        } else {
            return 'taskIds';
        }
    }
    get #allTreeItems() {
        if (this.args.type === 'station') {
            return this.stationRepo.stations;
        } else {
            return this.taskRepo.tasks;
        }
    }
    get #selectedTreeItemIds() {
        return this.args[this.#paramName];
    }
    get #allChecked() {
        return this.#allTreeItems.length === this.selectedTreeItems.size;
    }
    @cached
    get selectedTreeItems() {
        const treeItems = this.#selectedTreeItemIds?.map((treeItemId)=>this.store.peekRecord(this.args.type, treeItemId));
        return new Set(treeItems ?? []);
    }
    get breadcrumb() {
        if (this.#allChecked) {
            return null;
        }
        return this.selectedTreeItemsText;
    }
    get selectedTreeItemsText() {
        const selected = [
            ...this.selectedTreeItems
        ];
        const length = selected.length;
        if (length === 1) {
            return selected[0].name;
        }
        let caption;
        if (this.args.type === 'station') {
            caption = 'criteria.treeSearchStations';
        } else {
            caption = 'criteria.treeSearchTasks';
        }
        return this.intl.t(caption, {
            length
        });
    }
    get filteredAreas() {
        let areas = this.areaRepo.areas;
        if (!isBlank(this.searchTerm)) {
            const searchRegex = new RegExp(`${this.searchTerm}`, 'i');
            areas = areas.filter((area)=>searchRegex.test(area.name) || area.children.some((group)=>searchRegex.test(group.name)) || area.stations.some((station)=>searchRegex.test(station.name)) || area.tasks.some((treeTask)=>searchRegex.test(treeTask.name)));
        }
        return sortByProp('order', areas ?? []);
    }
    constructor(){
        super(...arguments);
        const treeItemIds = this.#selectedTreeItemIds;
        this.args.initParam(this.#paramName, treeItemIds);
        this.isDisplayed = isPresent(treeItemIds);
    }
    onDisplayedChanged = (isDisplayed)=>{
        this.isDisplayed = isDisplayed;
        this.#setAllTreeItemsChecked(isDisplayed);
    };
    toggleAll = ()=>{
        this.#setAllTreeItemsChecked(!this.#allChecked);
    };
    onTreeItemChecked = (treeItem, isChecked)=>{
        const selectedTreeItems = new Set(this.selectedTreeItems);
        let children;
        if (treeItem.type === this.args.type) {
            children = [
                treeItem
            ];
        } else if (this.args.type === 'station') {
            children = treeItem.stations;
        } else {
            children = treeItem.tasks;
        }
        children.forEach((child)=>{
            if (isChecked) {
                selectedTreeItems.add(child);
            } else {
                selectedTreeItems.delete(child);
            }
        });
        this.#updateTreeItemIds(selectedTreeItems);
    };
    #setAllTreeItemsChecked(checkAll) {
        if (!checkAll) {
            this.#updateTreeItemIds([]);
            return;
        }
        this.#updateTreeItemIds(this.#allTreeItems);
    }
    #updateTreeItemIds(selectedTreeItems) {
        selectedTreeItems = [
            ...selectedTreeItems
        ];
        if (isEmpty(selectedTreeItems)) {
            this.args.updateParam(this.#paramName, null);
            return;
        }
        const treeItemIds = selectedTreeItems.map((item)=>item.id);
        this.args.updateParam(this.#paramName, treeItemIds);
    }
    static{
        template_d50acbe333c34059ae9922e2ca641506(`
    <SearchTag
      class={{if (eq @type 'stations') 'stationIds' 'taskIds'}}
      @isDisplayed={{this.isDisplayed}}
      @text={{this.breadcrumb}}
    />

    <FilterWrapper
      class="eflex-tree-criteria"
      @form={{@form}}
      @label={{@type}}
      @isDisplayed={{this.isDisplayed}}
      @type={{@type}}
      @onChecked={{this.onDisplayedChanged}}
      ...attributes
    >
      <div class="row">
        <div class='col-9'>
          <input
            class="app-search-box form-control"
            value={{this.searchTerm}}
            placeholder={{t "controls.tree.plantsearch"}}
            {{on "input" (inputVal (fn (mut this.searchTerm)))}}
          />
        </div>
        <div class='ms-auto col-3 align-self-center text-end'>
          <em class="app-selection-label">
            {{this.selectedTreeItemsText}}
          </em>
        </div>
      </div>

      <button
        class="btn btn-secondary btn-sm app-select-all"
        type="button"
        {{on "click" this.toggleAll}}
      >
        {{t "controls.tree.toggleall"}}
      </button>

      <hr class="divider mb-0" />

      <ul
        class="
          tree-list
          list-unstyled
          overflow-x-hidden
          overflow-y-auto
          {{if (isPresentHelper this.searchTerm) "searching"}}
        "
      >
        {{#each this.filteredAreas as |treeItem|}}
          <TreeItem
            @type={{@type}}
            @selectedTreeItems={{this.selectedTreeItems}}
            @treeItem={{treeItem}}
            @searchTerm={{this.searchTerm}}
            @onTreeItemChecked={{this.onTreeItemChecked}}
          />
        {{/each}}
      </ul>
    </FilterWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
