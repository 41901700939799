import { template as template_65f9116296df4287b3692c530ea76da4 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { isBlank } from '@ember/utils';
import { ObjectId } from 'bson';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import Spinner from 'eflex/components/spinner';
import EChart from 'eflex/components/e-chart';
import NoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import { fn, get } from '@ember/helper';
const getProcessDatumMatch = (processData)=>{
    if (isBlank(processData)) {
        return {
            $match: {}
        };
    }
    return {
        $match: {
            'children.processData.variableDef': {
                $in: processData.map((variableDef)=>new ObjectId(variableDef))
            }
        }
    };
};
const getEchartOptions = (chartData, unitMapping, intl)=>{
    return {
        title: {
            text: intl.t('bi.chartLabel.processDataAvgBarChart')
        },
        legend: {
            show: true,
            bottom: 5
        },
        tooltip: {
            trigger: 'axis',
            formatter (params) {
                // the key is the processDataName only
                const axisLabelPartsArray = params[0].name.split(':');
                const mapKey = axisLabelPartsArray.at(-1).trim();
                const tooltipItems = params.map(({ marker, value })=>`<div style="text-align:center">${unitMapping.get(mapKey).stationName}</div><br>${marker} ${unitMapping.get(mapKey).processDataName}: <span style="float:right;font-weight:bold;">${value} <span style="font-weight:normal;">${unitMapping.get(mapKey).unit}</span></span>`).join('<br>');
                return `<div style="min-width:200px;">${tooltipItems}</div>`;
            }
        },
        yAxis: {
            type: 'category',
            boundaryGap: true,
            data: chartData.map((data)=>`${data.stationName}: ${data.taskName}: ${data.processDataName}`)
        },
        xAxis: [
            {
                type: 'value',
                position: 'bottom',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: intl.t('bi.chartLegend.avgProcessData'),
                data: chartData.map((m)=>m?.avg?.toFixed(2)),
                type: 'bar',
                xAxisIndex: 0,
                color: echartsTheme.colorPalette.biPurple
            }
        ],
        variableDefIds: chartData.map((item)=>item._id)
    };
};
export default class ProcessDataAvgBarChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    router;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames,
            processData: this.args.processData
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params)=>{
        const averageProcessDataAggregation = [
            {
                $match: {
                    'children.processData.0': {
                        $exists: true
                    }
                }
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $unwind: {
                    path: '$children.processData'
                }
            },
            {
                $match: {
                    'children.processData.value': {
                        $type: 'number'
                    }
                }
            },
            getProcessDatumMatch(params.processData),
            {
                $addFields: {
                    processDataName: '$children.processData.captions.text',
                    taskName: '$children.location.captions.text',
                    unit: '$children.processData.unitCaptions.text'
                }
            },
            {
                $group: {
                    _id: '$children.processData.variableDef',
                    station: {
                        $first: '$location'
                    },
                    processDataName: {
                        $first: '$processDataName'
                    },
                    taskName: {
                        $first: '$taskName'
                    },
                    unit: {
                        $first: '$unit'
                    },
                    avg: {
                        $avg: '$children.processData.value'
                    }
                }
            },
            {
                $project: {
                    stationName: {
                        $first: '$station.captions.text'
                    },
                    processDataName: {
                        $first: '$processDataName'
                    },
                    taskName: {
                        $first: '$taskName'
                    },
                    avg: true,
                    unit: {
                        $first: '$unit'
                    }
                }
            },
            {
                $sort: {
                    avg: 1
                }
            }
        ];
        const processDataQuery = await this.queryRunner.queryWithParams.perform(params, averageProcessDataAggregation);
        const chartData = await this.queryRunner.runQuery.perform('BuildStatuses', processDataQuery);
        const unitMapping = new Map();
        chartData.forEach((data)=>{
            unitMapping.set(data.processDataName, {
                processDataName: data.processDataName ?? '',
                unit: data.unit ?? '',
                stationName: data.stationName ?? ''
            });
        });
        return {
            chartData: getEchartOptions(chartData, unitMapping, this.intl),
            exportData: ()=>chartData.map(({ _id, ...relevantData })=>relevantData)
        };
    }));
    processData = trackedTask(this, this.getData, ()=>[
            this.params,
            this.args.refresher
        ]);
    processDatumDrillDown = (processData, dataIndex)=>{
        this.router.transitionTo('bi.processData.processDatum', {
            queryParams: {
                variableDefIds: [
                    processData.chartData.variableDefIds[dataIndex]
                ],
                stationIds: this.args.stationIds,
                modelIds: this.args.modelIds,
                userIds: this.args.userIds,
                tags: this.args.tags,
                beginDate: this.args.startDate,
                endDate: this.args.endDate,
                shiftNames: this.args.shiftNames
            }
        });
    };
    static{
        template_65f9116296df4287b3692c530ea76da4(`
    <div ...attributes>
      {{#let
        this.processData.isRunning
        this.processData.value
      as |isRunning processData|}}
        {{#if isRunning}}
          <Spinner />
        {{else if (get processData.chartData.series '0.data.length')}}
          <div class="row h-100">
            <div class="col d-flex justify-content-between">
              <EChart
                class="h-100 w-100 process-data-avg-bar-chart"
                @option={{processData.chartData}}
                @exportData={{processData.exportData}}
                @onClick={{fn this.processDatumDrillDown processData}}
              />
            </div>
          </div>
        {{else}}
          <NoDataPlaceholder />
        {{/if}}
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
