import config from 'eflex/config/environment';
import moment from 'moment-timezone';

const getProductVersion = (versionArg) => {
  const version = versionArg ?? config.APP.version;
  const match = version.match(/^(\d+)\.(\d+)\.(\d{8})-(.+)(s|d)$/);

  if (!match) {
    return version;
  }

  const [_, majorVersion, minorVersion, YYYYMMDD, buildNumber, buildType] = match;

  const YY = YYYYMMDD.slice(2, 4);
  const quarter = moment(YYYYMMDD, 'YYYYMMDD').quarter();

  return `${majorVersion}.${minorVersion}.${YY}.${quarter}-${buildNumber}${buildType}`;
};

export default getProductVersion;
