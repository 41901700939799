import { template as template_de4d84bb722c4bb0b956bbbc6ab111c4 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import OEE_STATES from 'eflex/constants/oee-states';
import { waitFor } from '@ember/test-waiters';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
export default class BiPanelStates extends Component {
    @service
    intl;
    @service
    queryRunner;
    get params() {
        return {
            startDate: this.args.startDate,
            endDate: this.args.endDate
        };
    }
    getOeeButtonNames = task({
        drop: true
    }, waitFor(async (params)=>{
        const defaultOeeStateNames = OEE_STATES.BUTTONS.map((button)=>{
            return this.intl.t(button.label).toUpperCase();
        });
        const customOeeStateQuery = await this.queryRunner.queryWithParams.perform(params, [
            {
                $match: {
                    customButtonColor: {
                        $ne: null
                    }
                }
            },
            {
                $group: {
                    _id: '$state'
                }
            },
            {
                $project: {
                    _id: 0,
                    state: '$_id'
                }
            }
        ], true);
        const customOeeStates = await this.queryRunner.runQuery.perform('OeeStates', customOeeStateQuery);
        return customOeeStates.map((result)=>result.state.toUpperCase()).concat(defaultOeeStateNames).toSorted((a, b)=>a.localeCompare(b));
    }));
    oeeStateNames = trackedTask(this, this.getOeeButtonNames, ()=>[
            this.params
        ]);
    get selectedStates() {
        return this.args.states;
    }
    static{
        template_de4d84bb722c4bb0b956bbbc6ab111c4(`
    <div class="oee-button-select" ...attributes>
      <BsForm as |form|>
        <form.element
          class="oee-button-select"
          @controlType="power-select-multiple"
          @label={{t "bi.biPanel.states"}}
          @value={{this.selectedStates}}
          @options={{this.oeeStateNames.value}}
          @onChange={{@onChange}}
        as |el|>
          <el.control
            @searchEnabled={{true}}
          />
        </form.element>
      </BsForm>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
