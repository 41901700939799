import { template as template_7dadfb31db1d4aa4b76cbbace66e0707 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';
import { waitForPromise } from '@ember/test-waiters';
import { t } from 'ember-intl';
import StandardModal from 'eflex/components/standard-modal';
export default class DiffModal extends Component {
    formattedDiff = trackedFunction(this, async ()=>{
        const left = this.args.left;
        const right = this.args.right;
        const [jsondiffpatch, htmlFormatter] = await waitForPromise(Promise.all([
            import('jsondiffpatch'),
            import('jsondiffpatch/formatters/html')
        ]));
        const delta = jsondiffpatch.create({
            objectHash (obj, index) {
                return obj.id ?? obj._id ?? obj?.eflex?.id ?? obj?.eflex?._id ?? `$$index:${index}`;
            }
        }).diff(left, right);
        htmlFormatter.hideUnchanged();
        return htmlFormatter.format(delta, left);
    });
    static{
        template_7dadfb31db1d4aa4b76cbbace66e0707(`
    <StandardModal
      class="component-modals-diff"
      @onCancel={{@onCancel}}
      @size="xl"
      ...attributes
    as |modal|>
      <modal.header />
      <modal.body>
        {{!-- template-lint-disable no-triple-curlies --}}
        {{{this.formattedDiff.value}}}
        {{!-- template-lint-enable no-triple-curlies --}}
      </modal.body>
      <modal.footer as |footer|>
        <footer.close>
          {{t "close"}}
        </footer.close>
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
