import { anyInvalid, anyTreeItemDirty } from 'eflex/util/getter-helpers';
import RerunStrategyValues from 'eflex/constants/rerun-strategy-values';
import RetryStrategyValues from 'eflex/constants/retry-strategy-values';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import RejectStrategies from 'eflex/constants/tasks/reject-strategies';
import DecisionTypes from 'eflex/constants/tasks/decision-types';
import EdhrMethodTypes from 'eflex/constants/tasks/edhr-method-types';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { cached, tracked } from '@glimmer/tracking';
import {
  rollbackHasMany,
  rollbackBelongsTo,
  isBelongsToRefDirty,
  isHasManyRefDirty,
} from 'eflex/util/relationship-helpers';
import { copyable, arrayAttr, caption } from 'eflex/decorators';
import { validIpAddress } from 'eflex/constants/regex';
import { checkMqttTopicUnique, checkUniqueNumber } from 'eflex/util/validators';
import { clone } from 'ramda';
import { getOwner } from '@ember/application';
import { hardwareTypes, BARCODE_HARDWARE } from 'eflex/constants/hardware-types';
import getHardwareType from 'eflex/helpers/get-hardware-type';
import { validatePlcVariableDefs } from 'eflex/util/plc-variable-def-helpers';

const EDHR_REASON_CODE_METHODS = new Set([EdhrMethodTypes.sendData, EdhrMethodTypes.completeOperation]);

const opcUaMethodModeRequired = () => {
  return [validator('presence', {
    presence: true,
    get disabled() {
      const task = this.model;
      return task.opcUaType !== 'method' || task.taskType !== taskTypes.opcUa;
    },
  })];
};

const opcUaVariableModeRequired = () => {
  return [validator('presence', {
    presence: true,
    get disabled() {
      const task = this.model;
      return task.opcUaType !== 'variable' || task.taskType !== taskTypes.opcUa;
    },
  })];
};

@copyable
@buildValidations({
  name: [validator('presence', true)],
  rerunStrategy: [validator('presence', true)],

  torqueFormat: [validator('length', {
    allowNone: true,
    min: 1,
  })],

  opcUaObjectNodeId: opcUaMethodModeRequired(),
  opcUaMethodNodeId: opcUaMethodModeRequired(),

  opcUaResultVariableNodeId: opcUaVariableModeRequired(),
  opcUaStartVariableNodeId: opcUaVariableModeRequired(),
  opcUaResultVariableType: opcUaVariableModeRequired(),
  opcUaResultVariablePassedValue: opcUaVariableModeRequired(),
  opcUaResultVariableFailedValue: opcUaVariableModeRequired(),

  ipAddress: [
    validator('format', {
      regex: validIpAddress,
      allowBlank: true,
    }),
  ],

  facsId: [
    validator('number', {
      integer: true,
      allowString: true,
      gte: 1,
      allowBlank: true,
      allowNone: true,
    }),

    validator('inline', {
      validate(facsId, options, task, attribute) {
        return checkUniqueNumber(task, task.siblings, attribute);
      },
    }),
  ],

  taskType: [validator('presence', true)],

  printTemplate: [
    validator('presence', {
      presence: true,
      get disabled() {
        return !this.model.isPrint;
      },
    }),
  ],

  printCopies: [
    validator('number', {
      allowString: true,
      allowNone: false,
      integer: true,
      gte: 1,
      lte: 999,
      get disabled() {
        return !this.model.isPrint;
      },
    }),
  ],

  reasonCode: [
    validator('presence', {
      presence: true,
      get disabled() {
        return (
          !this.model.isEdhr ||
          !EDHR_REASON_CODE_METHODS.has(this.model.edhrMethod)
        );
      },
    }),
  ],

  operation: [
    validator('presence', {
      presence: true,
      get disabled() {
        return !this.model.isEdhr;
      },
    }),
  ],

  nonDeletedVariableDefs: [
    validator('inline', {
      get disabled() {
        return !this.model.isPlc;
      },
      validate(nonDeletedVariableDefs, options, task) {
        return validatePlcVariableDefs(nonDeletedVariableDefs, task);
      },
    }),
  ],

  mqttTopic: [
    validator('inline', {
      get disabled() {
        return !this.model.isNodeRed;
      },
      validate(mqttTopic, options, task) {
        return checkMqttTopicUnique(task);
      },
    }),
    validator('presence', {
      get disabled() {
        return !this.model.isNodeRed;
      },
      presence: true,
    }),
  ],

  targetQuality: [validator('number', { gte: 0, lte: 100, allowString: true })],
  targetOverCycle: [validator('number', { gte: 0, lte: 100, allowString: true })],

  edhrMappings: [
    validator('inline', {
      get disabled() {
        return !this.model.isEdhr;
      },
      validate(edhrMappings, options, task) {
        const anyWrongOrder = task.nonDeletedEdhrMappings.some(mapping =>
          mapping.dataTask != null &&
          (mapping.dataTaskRow >= task.row || mapping.dataTask.parent !== task.parent),
        );

        if (anyWrongOrder) {
          return getOwner(task).lookup('service:intl').t('validations.edhrProcessDataOrder');
        }

        return true;
      },
    }),
  ],

  webcamDelay: [validator('number', { gte: 0, allowString: true })],
})
class Task extends Model {
  @service systemConfig;
  @service taskRepo;

  @attr('string') path;
  @attr('number', { defaultValue: 1 }) row;
  @attr('number', { defaultValue: 1 }) column;
  @attr('number', { defaultValue: 0 }) webcamDelay;
  @attr('number', { defaultValue: 1 }) sizeY;
  @attr('number') facsId;
  @attr('string') taskType;
  @attr('string') ipAddress;
  @attr('number') port;
  @attr('number', { defaultValue: 1 }) hardwareIndex;
  @attr('number', { defaultValue: 1 }) hardwareOutputIndex;
  @attr('boolean', { defaultValue: false }) isConnected;
  @attr('number', { defaultValue: RerunStrategyValues.alwaysRerun }) rerunStrategy;
  @attr('number', { defaultValue: RetryStrategyValues.always }) retryStrategy;
  @attr('string', { defaultValue: RejectStrategies.advance }) rejectStrategy;
  @attr('string') hardwareAssignedType;
  @attr('string') hardwareOutputAssignedType;
  @attr('string', { defaultValue: 'task' }) type;
  @attr('boolean', { defaultValue: false }) isVision;
  @attr('string', { defaultValue: 'taskActive' }) triggerType;

  @attr('boolean', { defaultValue: true }) isAuto;
  @attr('boolean', { defaultValue: true }) showOkButton;

  // opcua
  @attr('string', { defaultValue: 'method' }) opcUaType;
  @attr('string') opcUaObjectNodeId;
  @attr('string') opcUaMethodNodeId;
  @attr('string') opcUaResultVariableNodeId;
  @attr('string', { defaultValue: 'number' }) opcUaResultVariableType;
  @attr opcUaResultVariablePassedValue;
  @attr opcUaResultVariableFailedValue;
  @attr('string') opcUaStartVariableNodeId;

  // print
  @attr('number', { defaultValue: 1 }) printCopies;
  @attr('string') printTemplate;
  @attr('string') printTimezone;

  // torque
  @attr('boolean', { defaultValue: false }) torqueUseEflexLimits;
  @attr('string', { defaultValue: null }) torqueFormat;

  // barcode
  @attr('boolean', { defaultValue: false }) passThrough;
  @attr('number') barcodeSymbology;

  // node red
  @attr('string') mqttTopic;

  // decision
  @attr('string', { defaultValue: DecisionTypes.passFail }) decisionType;
  @attr('boolean', { defaultValue: false }) decisionRequired;

  // serialNumberTransfer
  @attr('boolean', { defaultValue: false }) generateSerialNumber;

  //edhr
  @attr('string', { defaultValue: EdhrMethodTypes.sendData }) edhrMethod;
  @attr('string') operation;
  @attr('string') reasonCode;
  @attr('boolean', { defaultValue: false }) operationUsesModel;

  @attr('boolean', { defaultValue: false }) marryPart;
  @attr('boolean', { defaultValue: false }) uniqueMarriageSerial;
  @attr('boolean', { defaultValue: false }) requireRepairCodes;

  @attr('number', { defaultValue: 100 }) targetQuality;
  @attr('number', { defaultValue: 0 }) targetOverCycle;
  @attr('number', { defaultValue: 1 }) ioIndex;
  @attr('number', { defaultValue: 1 }) ioOutputIndex;

  @arrayAttr decisionTags;
  @arrayAttr jemVisibilityTags;
  @arrayAttr jemAuthorizedTags;
  @arrayAttr tags;
  @arrayAttr marriageCaptions;
  @arrayAttr decisionCaptions;
  @arrayAttr captions;

  @caption name;
  @caption('decisionCaptions') decisionLabel;
  @caption('marriageCaptions') marriageLabel;

  @belongsTo('taskParent', { inverse: 'tasks', async: false, polymorphic: true }) parent;
  @belongsTo('hardwareIo', { async: false, inverse: null }) hardwareInput;
  @belongsTo('hardware', { polymorphic: true, async: false, inverse: 'tasks' }) hardware;
  @belongsTo('component', { async: false, inverse: 'tasks' }) component;
  @belongsTo('cameraConfiguration', { inverse: null, async: false, embedded: true }) cameraConfiguration;

  @hasMany('task', { inverse: null, async: false }) tasksToRepair;

  @hasMany('edhrMapping', { async: false, inverse: 'task', embedded: true }) edhrMappings;
  @hasMany('taskConfig', { inverse: 'parent', async: false, cascadeDelete: true }) taskConfigs;
  @hasMany('hardwareInputDef', { async: false, inverse: 'task', embedded: true }) hardwareInputDefs;
  @hasMany('hardwareIo', { async: false, inverse: null }) hardwareOutputs;
  @hasMany('variableDef', { async: false, inverse: 'task', embedded: true }) variableDefs;
  @hasMany('jemPartMarriage', { async: false, inverse: 'task', embedded: true }) jemPartMarriages;
  @hasMany('trigger', { async: false, polymorphic: true, inverse: 'task', embedded: true }) triggers;
  @hasMany('decisionDef', { async: false, inverse: 'task', embedded: true }) decisionDefs;
  @hasMany('spindle', { async: false, inverse: 'task', embedded: true }) spindles;
  @hasMany('barcodeString', { inverse: 'task', async: false, embedded: true }) strings;

  @tracked isChecked = false;

  get isDirty() {
    return super.isDirty ||
      isBelongsToRefDirty(this, 'hardware') ||
      isBelongsToRefDirty(this, 'hardwareInput') ||
      isBelongsToRefDirty(this, 'component') ||
      isHasManyRefDirty(this, 'strings') ||
      isHasManyRefDirty(this, 'hardwareOutputs') ||
      isHasManyRefDirty(this, 'jemPartMarriages') ||
      isHasManyRefDirty(this, 'tasksToRepair') ||
      (this.cameraConfiguration?.isDirty ?? false) ||
      this.strings.some(item => item.isDirty) ||
      this.edhrMappings.some(item => item.isDirty) ||
      this.hardwareInputDefs.some(item => item.isDirty) ||
      this.variableDefs.some(item => item.isDirty) ||
      this.jemPartMarriages.some(item => item.isDirty) ||
      this.triggers.some(item => item.isDirty) ||
      this.decisionDefs.some(item => item.isDirty) ||
      this.spindles.some(item => item.isDirty);
  }

  get icon() {
    return 'fa fa-camera';
  }

  get childType() {
    return 'taskConfig';
  }

  get area() {
    return this.station?.area;
  }

  set area(val) {
    this.station.area = val;
  }

  get group() {
    return this.station?.group;
  }

  set group(val) {
    this.station.group = val;
  }

  get station() {
    return this.parent;
  }

  set station(val) {
    this.parent = val;
  }

  @cached
  get children() {
    return this.taskConfigs.filter(item => !item.isDeleted);
  }

  get allChildren() {
    return this.taskConfigs;
  }

  get usesComponents() {
    return this.parent?.usesComponents ?? false;
  }

  get usesModels() {
    return !this.usesComponents && !this.usesOperations;
  }

  get displayName() {
    return this.name;
  }

  @cached
  get siblings() {
    if (this.usesOperations) {
      return this.taskRepo.tasks.filter(task => task.usesOperations);
    }

    return this.area.tasks;
  }

  get usesOperations() {
    return this.parent?.type === 'kineticOperation';
  }

  @cached
  get nonDeletedVariableDefs() {
    return this.variableDefs.filter(item => !item.isDeleted);
  }

  @cached
  get nonDeletedDecisionDefs() {
    return this.decisionDefs.filter(item => !item.isDeleted);
  }

  @cached
  get nonDeletedHardwareInputDefs() {
    return this.hardwareInputDefs.filter(item => !item.isDeleted);
  }

  @cached
  get nonDeletedEdhrMappings() {
    return this.edhrMappings.filter(item => !item.isDeleted);
  }

  @cached
  get nonDeletedSpindles() {
    return this.spindles.filter(item => !item.isDeleted);
  }

  @cached
  get isSelfOrChildDirty() {
    return this.isDirty || anyTreeItemDirty(this.taskConfigs);
  }

  @cached
  get isSelfOrChildInvalid() {
    return this.isInvalid ||
      this.hasInvalidTaskConfig ||
      this.hasInvalidVariableDefs ||
      this.hasInvalidDecisionDefs ||
      this.hasInvalidEdhrMappings ||
      this.hasInvalidTriggers ||
      this.hasInvalidStrings;
  }

  @cached
  get hasInvalidTriggers() {
    return anyInvalid(this.triggers);
  }

  @cached
  get hasInvalidTaskConfig() {
    return anyInvalid(this.children);
  }

  @cached
  get hasInvalidStrings() {
    return anyInvalid(this.strings);
  }

  @cached
  get hasInvalidVariableDefs() {
    return anyInvalid(this.variableDefs);
  }

  @cached
  get hasInvalidDecisionDefs() {
    return anyInvalid(this.decisionDefs);
  }

  @cached
  get hasInvalidEdhrMappings() {
    return anyInvalid(this.edhrMappings);
  }

  get isErrorProofing() {
    return this.taskType === taskTypes.errorProofing;
  }

  get isButton() {
    return this.taskType === taskTypes.button;
  }

  get isTimer() {
    return this.taskType === taskTypes.timer;
  }

  get isTorque() {
    return this.taskType === taskTypes.torque;
  }

  get isPrint() {
    return this.taskType === taskTypes.print;
  }

  get isBarcode() {
    return this.taskType === taskTypes.barcode;
  }

  get isPick() {
    return this.taskType === taskTypes.pick;
  }

  get isNodeRed() {
    return this.taskType === taskTypes.nodeRed;
  }

  get isPlc() {
    return this.taskType === taskTypes.plc;
  }

  get isSerialNumberTransfer() {
    return this.taskType === taskTypes.serialNumberTransfer;
  }

  get isDecision() {
    return this.taskType === taskTypes.decision;
  }

  get isEdhr() {
    return this.taskType === taskTypes.edhr;
  }

  get isNonInterlocked() {
    return this.isButton || this.isTimer;
  }

  get usesWebCam() {
    if (this.taskType === taskTypes.imageCapture) {
      return true;
    }

    return getHardwareType(this) === hardwareTypes.webCam;
  }

  get iconClass() {
    if (this.taskType == null) {
      return 'empty-icon';
    } else {
      return `${this.taskType.toLowerCase()}-icon`;
    }
  }

  get validatedRelationships() {
    return [
      'taskConfigs',
      'triggers',
      'variableDefs',
      'decisionDefs',
      'edhrMappings',
      'hardwareInputDefs',
      'jemPartMarriages',
    ];
  }

  get hideBarcodeInput() {
    return (
      (
        (this.isBarcode || this.isSerialNumberTransfer) &&
        BARCODE_HARDWARE.has(getHardwareType(this))
      ) ||
      (this.isSerialNumberTransfer && this.generateSerialNumber)
    );
  }

  get globalTorqueFormat() {
    return this.systemConfig.jem?.torqueFormat;
  }

  @cached
  get maxBoltCount() {
    return Math.max(...this.taskConfigs.map(item => item.boltCount));
  }

  get rowEnd() {
    return this.row + this.sizeY - 1;
  }

  get treeIconClass() {
    return `${this.iconClass} light`;
  }

  get confirmRepair() {
    return (!isEmpty(this.tasksToRepair) && this.requireRepairCodes);
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['parent', 'facsId', 'taskConfigs', 'edhrMappings', 'triggers']),
      copyByReference: new Set(['hardwareInput', 'hardwareOutputs', 'hardware', 'component', 'tasksToRepair']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'component');
    rollbackBelongsTo(this, 'hardwareInput');
    rollbackBelongsTo(this, 'hardware');
    rollbackHasMany(this, 'hardwareOutputs');
    rollbackHasMany(this, 'tasksToRepair');
    this.taskConfigs.forEach(taskConfig => { taskConfig.rollbackAttributes(); });
    super.rollbackAttributes();
  }

  asHistory() {
    return {
      id: this.id,
      type: this.taskType,
      facsId: this.facsId,
      row: this.row,
      column: this.column,
      tags: [...this.tags],
      captions: clone(this.captions),
    };
  }
}

export default Task;
