import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { getOwner } from '@ember/application';

@buildValidations({
  deleteToHardDrivePercentage: [
    validator('number', {
      gte: 0,
      lt: 100,
      allowString: true,
      allowNone: false,
    }),

    validator('inline', {
      validate(deleteToHardDrivePercentage, options, fileRetention) {
        if (deleteToHardDrivePercentage >= fileRetention.maxHardDriveUsage) {
          return getOwner(fileRetention).lookup('service:intl').t('settings.evision.fileRetentionError');
        }
        return true;
      },
    }),
  ],

  maxHardDriveUsage: [
    validator('number', {
      gte: 1,
      lte: 100,
      allowString: true,
      allowNone: false,
    }),
  ],
})
class FileRetention extends Model {
  @belongsTo('cameraConfiguration', { inverse: null, async: false, embedded: true }) defaultCameraConfiguration;
  @attr('number') maxHardDriveUsage;
  @attr('number') deleteToHardDrivePercentage;
  @attr('string') archiveDirectory;

  get isDirty() {
    return super.isDirty || this.defaultCameraConfiguration?.isDirty;
  }
}

export default FileRetention;
