import Service, { service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { isTesting, macroCondition } from '@embroider/macros';

export default class DataPreloader extends Service {
  @service currentUser;
  @service store;
  @service notifier;
  @service licensing;
  @service session;

  loadCurrentUserAndData = task(waitFor(async () => {
    if (!this.session.isAuthenticated) {
      return;
    }

    const spinner = this.#addBodySpinner();

    const store = this.store;
    try {
      await store.findAll('systemConfiguration');
      await this.licensing.loadLicense.perform();

      await all([
        store.findAll('workInstructionFolder'),
        store.findAll('area'),
        store.findAll('kineticPart'),
        store.findAll('user'),
        store.findAll('hardware'),
        store.findAll('hardwareIo'),
        store.findAll('hostedFile'),
        store.findAll('kineticShift'),
      ]);
      await all([
        store.findAll('model'),
        store.findAll('bomSource'),
        store.findAll('oeeButton'),
        this.currentUser.setupUser.perform(),
      ]);

      if (!this.session.isOidc) {
        this.session.isActuallyAuthenticated = true;
      }
    } catch (error) {
      console.error(error);
      this.notifier.sendError('errorcomm');
      await this.session.invalidate();
    } finally {
      spinner?.remove();
    }
  }));

  #addBodySpinner() {
    if (macroCondition(isTesting())) {
      return;
    }

    const spinner = document.createElement('div');
    spinner.classList.add(
      'spinner-overlay', 'h2', 'vh-100', 'd-flex', 'flex-column', 'align-items-center', 'justify-content-center',
    );

    spinner.innerHTML = `
      <div class="spinner spinner-border text-secondary" role="status"></div>
    `;

    document.body.prepend(spinner);
    return spinner;
  }
}
