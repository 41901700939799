import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';
import { action } from '@ember/object';

export default class DebugSystemLogsController extends Controller {
  queryParams = ['searchTerm', 'beginDate', 'endDate', 'path', 'host', 'exclude', 'page'];

  @tracked model = new TrackedArray();
  @tracked searchTerm;
  @tracked beginDate;
  @tracked endDate;
  @tracked path;
  @tracked host;
  @tracked exclude;
  @tracked page = 1;

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
