import { template as template_45b20527342f4dcea7f6771c0f250aea } from "@ember/template-compiler";
import BsButton from 'eflex/components/bs-button';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import TaskStatuses from 'eflex/constants/task-statuses';
export default template_45b20527342f4dcea7f6771c0f250aea(`
<BsButton
  class='complete-jem-task ok-button px-4 py-2'
  @type="success"
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete TaskStatuses.GOOD}}
  ...attributes
>
  {{yield}}
  {{t 'ok'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
