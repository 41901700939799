import { template as template_39a81daa4daf48188e41a434f6288a75 } from "@ember/template-compiler";
import { generatesSerialNumber, STATION_LOAD_OPTIONS } from 'eflex/constants/station-options';
import { isBlank } from '@ember/utils';
import onKey from 'ember-keyboard/modifiers/on-key';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import { inputVal } from 'eflex/helpers';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import KeyboardWedgeEvent from 'eflex/components/keyboard-wedge-event';
import { not } from 'ember-truth-helpers';
const getNewPartButtonDisabled = (disabled, usesComponents, bomSourceLookupValue)=>{
    if (disabled) {
        return true;
    }
    if (usesComponents) {
        return isBlank(bomSourceLookupValue);
    }
    return false;
};
const onLoadNewPart = (loadOption, bomSourceLookupValue, kineticJobNumber, modelCode, onSubmit)=>{
    let partValue;
    switch(loadOption){
        case STATION_LOAD_OPTIONS.bomSourceLookupAuto:
            {
                partValue = bomSourceLookupValue;
                break;
            }
        case STATION_LOAD_OPTIONS.operationScan:
        case STATION_LOAD_OPTIONS.operationSelectAuto:
            {
                partValue = kineticJobNumber;
                break;
            }
        default:
            {
                partValue = modelCode;
            }
    }
    return onSubmit(partValue);
};
const onSubmitWithEnter = (serialNumber, onSubmit, event, ekEvent)=>{
    ekEvent.stopImmediatePropagation();
    ekEvent.stopPropagation();
    onSubmit(serialNumber);
};
export default template_39a81daa4daf48188e41a434f6288a75(`
<div class="jem-serial-input w-100">
  {{#if (generatesSerialNumber @station)}}
    {{#let
      (getNewPartButtonDisabled @disabled @station.usesComponents @bomSourceLookupValue)
      (fn onLoadNewPart @station.loadOption @bomSourceLookupValue @kineticJobNumber @modelCode @onSubmit)
    as |disabled onLoadNewPartFn|}}
      <BsButton
        class="load-new-part text-capitalize"
        @disabled={{disabled}}
        @type="primary"
        @size="lg"
        @onClick={{onLoadNewPartFn}}
        {{onKey
          "ctrl+KeyL"
          onLoadNewPartFn
          activated=(not disabled)
        }}
      >
        {{t "newPart"}}
      </BsButton>
    {{/let}}
  {{else}}
    <div class="row">
      <div class="col">
        <label class="form-label">
          {{t "serialNumberAbbr"}}
        </label>
        <input
          class="jem-part-input-value form-control form-control-lg"
          type="text"
          disabled={{@disabled}}
          placeholder={{@serialNumber}}
          value={{@tempSerialNumber}}
          {{on "input" (inputVal @onInput)}}
          {{onKey
            "Enter"
            (fn onSubmitWithEnter @tempSerialNumber @onSubmit)
            activated=(not @disabled)
          }}
          ...attributes
        />
      </div>
      <div class="col-auto d-flex align-items-end">
        <BsButton
          class="submit-button"
          @disabled={{@disabled}}
          @type="primary"
          @size="lg"
          @onClick={{fn @onSubmit @tempSerialNumber}}
        >
          {{t "start"}}
        </BsButton>
      </div>
    </div>
    <KeyboardWedgeEvent @onScan={{@onScan}} />
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
