import { template as template_5e189679d1ad4931893f5662ab6f3399 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import NoRouteTabs from 'eflex/components/no-route-tabs';
import { concat, fn } from '@ember/helper';
import { append, findBy } from '@eflexsystems/ember-composable-helpers';
import QuantitySelect from 'eflex/components/quantity-select';
import TrashCan from 'eflex/components/trash-can';
import { selectVal, serviceHelper as service } from 'eflex/helpers';
import track from 'ember-simple-track-helper/helpers/track';
import { eq } from 'ember-truth-helpers';
import { getControlType, VARIABLE_TYPES } from 'eflex/constants/variable-types';
const getDisplayVariables = (variableDefs, isFromDevice)=>{
    return variableDefs.filter((item)=>!item.isDeleted).filter((item)=>item.isFromDevice === isFromDevice) ?? [];
};
const getPreviousVariableDefs = (task, taskRepo)=>{
    return taskRepo.getAllPreviousTasks(task)?.flatMap((_task)=>_task.variableDefs).filter((def)=>!def.isDeleted && def.isFromDevice) ?? [];
};
const addMultipleVariables = (task, isFromDevice, variableDefRepo, variableQuantity)=>{
    for(let i = 0; i < variableQuantity; i++){
        variableDefRepo.createVariableDef({
            task,
            isFromDevice
        });
    }
};
const setVariableDefType = (variableDef, variableDefConfig, variableDefRepo, type)=>{
    variableDefRepo.setVariableValue(variableDefConfig, null);
    variableDef.type = type;
};
export const VariableType = template_5e189679d1ad4931893f5662ab6f3399(`
{{#let (service 'variableDefRepo') as |variableDefRepo|}}
  <@form.element
    class="variable-type"
    @controlType="power-select"
    @options={{VARIABLE_TYPES}}
    @value={{findBy "value" @variableDef.type VARIABLE_TYPES}}
    @onChange={{selectVal (fn setVariableDefType @variableDef @variableDefConfig variableDefRepo)}}
  as |el|>
    <el.control
      @triggerClass={{if (eq @size 'sm') 'form-control-sm'}}
    as |type|>
      {{t type.label}}
    </el.control>
  </@form.element>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export const VariableValue = template_5e189679d1ad4931893f5662ab6f3399(`
{{#if (eq @variableDef.type 'variable')}}
  {{#let (service 'variableDefRepo') as |variableDefRepo|}}
    <@form.element
      class="variable-value"
      @controlType='power-select'
      @value={{@variableDefConfig.selectedValue}}
      @size={{@size}}
      @options={{
        append
        @previousVariableDefs
        @variableDefConfig.formattedValue
      }}
      @onChange={{fn variableDefRepo.setVariableValue @variableDefConfig}}
    as |el|>
      <el.control
        @triggerClass={{if (eq @size 'sm') 'form-control-sm'}}
        @searchEnabled={{true}}
        @searchField="name"
      as |value|>
        <span title={{value.task.parent.name}} class="w-100">
          {{value.name}}
        </span>
      </el.control>
    </@form.element>
  {{/let}}
{{else}}
  <@form.element
    class="variable-value"
    @controlType={{getControlType @variableDef.type}}
    @size={{@size}}
    @model={{@variableDefConfig}}
    @property='value'
  />
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export const TaskToVariablesConfig = template_5e189679d1ad4931893f5662ab6f3399(`
<div class="bootstrap-table">
  <div class="row form-row header-row">
    <div class="col-icon border-0" />
    <div class="col">
      {{t "variableName"}}
    </div>
    {{yield to='toVariableCols'}}
    <div class="col-auto">
      <div class="width-rem-6">
        {{t "dataType"}}
      </div>
    </div>
    <div class="col model-specific">
      {{t "variableValue"}}
    </div>
    <div class="col-icon border-0" />
  </div>
  {{#let
    (service 'variableDefRepo')
    (getDisplayVariables @task.variableDefs false)
    (getPreviousVariableDefs @task (service 'taskRepo'))
  as |variableDefRepo displayVariables previousVariableDefs|}}
    {{#each displayVariables as |variableDef|}}
      {{#let (findBy "variableDef" variableDef @taskConfig.variableDefConfigs) as |variableDefConfig|}}
        <div class="variable-row row form-row">
          <div class="col-icon model-specific">
            <@form.element
              @controlType="checkbox"
              @model={{variableDefConfig}}
              @property="enabled"
            />
          </div>
          <div class="col">
            <@form.element
              @controlType="text"
              @model={{variableDef}}
              @property="name"
              class="variable-name"
            />
          </div>

          {{yield variableDef to='toVariableRows'}}

          <div class="col-auto">
            <div class="width-rem-6">
              <VariableType
                @form={{@form}}
                @variableDef={{variableDef}}
                @variableDefConfig={{variableDefConfig}}
              />
            </div>
          </div>

          <div class="col model-specific">
            <VariableValue
              @variableDef={{variableDef}}
              @variableDefConfig={{variableDefConfig}}
              @previousVariableDefs={{previousVariableDefs}}
              @form={{@form}}
            />
          </div>
          <div class="col-icon">
            <TrashCan
              disabled={{@disabled}}
              @onDelete={{fn variableDefRepo.deleteVariable variableDef}}
            />
          </div>
        </div>
      {{/let}}
    {{/each}}
    <div class="row form-row no-hover-row">
      <div class="col-auto">
        <QuantitySelect
          class="px-2 text-uppercase"
          @disabled={{@disabled}}
          @onSubmit={{fn addMultipleVariables @task false variableDefRepo}}
        >
          {{t "components.addVariable"}}
        </QuantitySelect>
      </div>
    </div>
  {{/let}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export const TaskFromVariablesConfig = template_5e189679d1ad4931893f5662ab6f3399(`
<div class="bootstrap-table">
  <div class="row form-row header-row">
    <div class="col">
      {{t "variableName"}}
    </div>
    {{yield to='fromVariableCols'}}
    <div class="col-icon border-0" />
  </div>
  {{#let (service 'variableDefRepo') as |variableDefRepo|}}
    {{#each (getDisplayVariables @task.variableDefs true) as |variableDef|}}
      <div class="variable-row row form-row">
        <div class="col">
          <@form.element
            @controlType="text"
            @model={{variableDef}}
            @property="name"
            class="variable-name"
          />
        </div>

        {{yield variableDef to='fromVariableRows'}}

        <div class="col-icon">
          <TrashCan
            disabled={{@disabled}}
            @onDelete={{fn variableDefRepo.deleteVariable variableDef}}
          />
        </div>
      </div>
    {{/each}}
    <div class="row form-row no-hover-row">
      <div class="col-auto">
        <QuantitySelect
          class="px-2 text-uppercase"
          @disabled={{@disabled}}
          @onSubmit={{fn addMultipleVariables @task true variableDefRepo}}
        >
          {{t "components.addVariable"}}
        </QuantitySelect>
      </div>
    </div>
  {{/let}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template_5e189679d1ad4931893f5662ab6f3399(`
{{#let
  (track (concat 'deviceToVariables-' @taskConfig.id))
  (concat 'deviceFromVariables-' @taskConfig.id)
  (concat 'deviceToVariables-' @taskConfig.id)
as |currentVariableTypeTab deviceFromId deviceToId|}}
  <NoRouteTabs
    @activeTab={{currentVariableTypeTab.value}}
    @tabBecameActive={{currentVariableTypeTab.update}}
  as |tabs|>
    <tabs.navigation class="mb-3" as |nav|>
      <nav.tab @href={{deviceToId}} class="device-to-variables">
        {{t "deviceToVariables"}}
      </nav.tab>
      <nav.tab @href={{deviceFromId}} class="device-from-variables">
        {{t "deviceFromVariables"}}
      </nav.tab>
    </tabs.navigation>
    <tabs.panes as |panes|>
      <panes.pane @id={{deviceToId}}>
        <TaskToVariablesConfig
          @form={{@form}}
          @disabled={{@disabled}}
          @task={{@task}}
          @taskConfig={{@taskConfig}}
        >
          <:toVariableCols>
            {{yield to='toVariableCols'}}
          </:toVariableCols>
          <:toVariableRows as |variableDef|>
            {{yield variableDef to='toVariableRows'}}
          </:toVariableRows>
        </TaskToVariablesConfig>
      </panes.pane>
      <panes.pane @id={{deviceFromId}}>
        <TaskFromVariablesConfig
          @form={{@form}}
          @disabled={{@disabled}}
          @task={{@task}}
          @taskConfig={{@taskConfig}}
        >
          <:fromVariableCols>
            {{yield to='fromVariableCols'}}
          </:fromVariableCols>
          <:fromVariableRows as |variableDef|>
            {{yield variableDef to='fromVariableRows'}}
          </:fromVariableRows>
        </TaskFromVariablesConfig>
      </panes.pane>
    </tabs.panes>
  </NoRouteTabs>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
