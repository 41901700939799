import { assert } from '@ember/debug';

export default function set(target, path, maybeValue) {
  assert(
    // eslint-disable-next-line max-len
    'you must pass a path to {{set}}. You can pass a path statically, as in `{{set this "foo"}}`, or with the path dynamically, as in `{{set this this.greetingPath "Hello"}}`',
    (typeof path === 'string' && path.length > 0) ||
      typeof path === 'symbol' ||
      typeof path === 'number',
  );

  if (arguments.length === 3) {
    return () => { target[path] = maybeValue; };
  }

  return (value) => { target[path] = value; };
}
