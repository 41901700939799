import ApplicationSerializer from 'eflex/serializers/application';

export default class VariableDefSerializer extends ApplicationSerializer {
  attrs = {
    task: {
      serialize: false,
      deserialize: false,
    },
    variableDefConfigs: {
      serialize: false,
      deserialize: false,
    },
    edhrMappings: {
      serialize: false,
      deserialize: false,
    },
  };
}
