import { service } from '@ember/service';
import Helper from '@ember/component/helper';
import { getCaptionText } from 'eflex/util/translation-helper';

export default class CaptionHelper extends Helper {
  @service currentUser;

  compute([captions]) {
    return getCaptionText(captions, this.currentUser);
  }
}
