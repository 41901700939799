import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';
import { PAGE_SIZE } from 'eflex/constants/pagination';

export default class PartsBuildHistoryRoute extends AuthenticatedRoute {
  @service store;
  @service notifier;
  @service eventBus;
  @service router;

  model(params) {
    return this._getData.perform(params);
  }

  setupController(controller, buildHistories) {
    Object.assign(controller, {
      buildHistories: new TrackedArray(buildHistories),
      buildStatuses: new TrackedArray(),
      images: [],
    });

    if (buildHistories[0]) {
      controller.fetchBuildHistoryDetails.perform(buildHistories[0]);
    }
  }

  resetController(controller) {
    super.resetController(...arguments);
    controller.searchTerm = null;
  }

  fetch = task(waitFor(async (params, pagination) => {
    const buildHistories = await this._getData.perform({ ...params, ...pagination });
    if (this.controller.selectAll) {
      buildHistories.forEach(buildHistory => { buildHistory.isSelected = true; });
    }
    this.controller.buildHistories.push(...buildHistories);
  }));

  search = task(waitFor(async (params = {}) => {
    try {
      const buildHistories = await this._getData.perform({
        ...params,
        skip: 0,
      });

      if (this.controller.selectAll) {
        buildHistories.forEach(buildHistory => { buildHistory.isSelected = true; });
      }

      Object.assign(this.controller, {
        buildHistories: new TrackedArray(buildHistories),
        selectAll: false,
      });

      this.eventBus.trigger('resetPagination');
      await this.controller.fetchBuildHistoryDetails.perform(this.controller.buildHistories[0]);
    } catch (e) {
      console.error(e);
      this.notifier.sendError('logs.searchFailed');
    }
  }));

  _getData = task(waitFor(async (params = {}) => {
    if (params.skip === 0) {
      Object.assign(this.controller, {
        selectedBuildHistory: null,
        selectedBuildStatus: null,
      });
    }

    return new TrackedArray(await this.store.query('buildHistory', {
      skip: 0,
      take: PAGE_SIZE,
      ...params,
    }));
  }));

  searchMarriageSerialNumber = task(waitFor(async searchTerm => {
    this.controller.searchTerm = searchTerm;
    await this.search.perform({ searchTerm });
    this.router.transitionTo('parts.buildHistory.index');
  }));
}
