import { modifier } from 'ember-modifier';
import { isTesting, macroCondition } from '@embroider/macros';

export default modifier(function scrollIntoView(element, positional, named = {}) {
  if (macroCondition(isTesting())) {
    return;
  }

  if (!named.shouldScroll) {
    return;
  }

  element.scrollIntoView(named.options);
});
