import { validator } from '@eflexsystems/ember-tracked-validations';
import rangesOverlap from 'eflex/util/ranges-overlap';
import { getOwner } from '@ember/application';
import { checkUniqueField } from 'eflex/util/validators';

const NUMBER_VALIDATOR_OPTIONS = {
  allowString: true,
  allowNone: false,
  integer: true,
  gt: 0,
};

const overlapping = function () {
  return validator('inline', {
    validate(value, options, bomConfig) {
      const intl = getOwner(bomConfig).lookup('service:intl');
      if (
        rangesOverlap(
          bomConfig.start,
          bomConfig.length,
          bomConfig.bomSource.serialNumberStart,
          bomConfig.bomSource.serialNumberLength,
        )
      ) {
        return intl.t('plant.area.serialOverlap');
      }

      for (const sibling of bomConfig.bomSource.nonDeletedConfigs) {
        if (
          sibling !== bomConfig &&
          rangesOverlap(
            bomConfig.start,
            bomConfig.length,
            sibling.start,
            sibling.length,
          )
        ) {
          return intl.t('plant.area.configFieldOverlap', {
            configType: intl.t(sibling.isComponent ? 'component' : 'variable').toLowerCase(),
            configName: sibling.name,
          });
        }
      }

      return true;
    },
  });
};

const lengthValidator = {
  get disabled() {
    if (this.model.isAlwaysRun || this.model.isComplex) {
      return true;
    }

    return !this.model.bomSource.isBarcode && !this.model.bomSource.isGepics;
  },

  validators: [
    validator('presence', true),
    validator('number', NUMBER_VALIDATOR_OPTIONS),
    overlapping(),
  ],
};

export default {
  name: {
    get disabled() {
      return this.model.isAlwaysRun;
    },

    validators: [validator('presence', true)],
  },

  value: {
    get disabled() {
      if (this.model.isAlwaysRun || this.model.isComplex) {
        return true;
      }

      return !(
        this.model.bomSource?.isRest ||
        this.model.bomSource?.isRestPull
      );
    },

    validators: [
      validator('presence', true),

      validator('inline', {
        validate(value, options, bomVariable, attribute) {
          return checkUniqueField(bomVariable, bomVariable.peers, attribute).result;
        },
      }),
    ],
  },

  start: lengthValidator,

  length: lengthValidator,
};
