import { template as template_83864e4a7ecd45638e3c0fded07873ae } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import TaskAndFastenerMatcher from 'eflex/util/bi-task-fastener-matcher';
import EchartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import EChart from 'eflex/components/e-chart';
import { get } from '@ember/helper';
export default class AverageTorqueRejects extends Component {
    @service
    queryRunner;
    @service
    intl;
    get params() {
        return {
            stationIds: this.args.stationIds,
            fasteners: this.args.fasteners,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getTorqueRejectData = task({
        drop: true
    }, waitFor(async (params)=>{
        const torqueRejectDataChartQuery = await this.queryRunner.queryWithParams.perform(params, [
            {
                $limit: 100_000
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $match: {
                    'children.boltProcessData.0': {
                        $exists: true
                    }
                }
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $unwind: {
                    path: '$children.boltProcessData'
                }
            },
            {
                $group: {
                    _id: {
                        taskId: '$children.location._id',
                        taskName: {
                            $first: '$children.location.captions.text'
                        },
                        boltNumber: '$children.boltProcessData.boltNumber'
                    },
                    totalBolts: {
                        $sum: 1
                    },
                    avgTorque: {
                        $avg: '$children.boltProcessData.torque'
                    },
                    avgAngle: {
                        $avg: '$children.boltProcessData.angle'
                    },
                    totalBoltRejects: {
                        $sum: {
                            $cond: {
                                if: {
                                    $or: [
                                        {
                                            $ne: [
                                                '$children.boltProcessData.torqueStatus',
                                                1
                                            ]
                                        },
                                        {
                                            $ne: [
                                                '$children.boltProcessData.angleStatus',
                                                1
                                            ]
                                        },
                                        {
                                            $ne: [
                                                '$children.boltProcessData.errorCode',
                                                null
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    }
                }
            },
            {
                $project: {
                    taskName: '$_id.taskName',
                    boltNumber: '$_id.boltNumber',
                    avgTorque: 1,
                    avgAngle: 1,
                    totalCycles: '$totalBolts',
                    taskAndBolt: {
                        $concat: [
                            '$_id.taskName',
                            ' ',
                            this.intl.t('fastener'),
                            ' ',
                            {
                                $toString: '$_id.boltNumber'
                            }
                        ]
                    },
                    idAndFastener: {
                        $concat: [
                            {
                                $toString: '$_id.taskId'
                            },
                            {
                                $toString: '$_id.boltNumber'
                            }
                        ]
                    },
                    boltRejectRate: {
                        $cond: {
                            if: {
                                $eq: [
                                    '$totalBoltRejects',
                                    0
                                ]
                            },
                            then: 0,
                            else: {
                                $round: [
                                    {
                                        $multiply: [
                                            {
                                                $divide: [
                                                    '$totalBoltRejects',
                                                    '$totalBolts'
                                                ]
                                            },
                                            100
                                        ]
                                    },
                                    1
                                ]
                            }
                        }
                    }
                }
            },
            TaskAndFastenerMatcher(params.fasteners)
        ]);
        let torqueRejectData = await this.queryRunner.runQuery.perform('BuildStatuses', torqueRejectDataChartQuery);
        torqueRejectData = torqueRejectData.toSorted((a, b)=>a.boltRejectRate - b.boltRejectRate);
        return {
            chartData: this.getEchartOptions(torqueRejectData),
            exportData: ()=>clone(torqueRejectData).map(({ _id, idAndFastener, ...relevantData })=>relevantData)
        };
    }));
    torqueRejectData = trackedTask(this, this.getTorqueRejectData, ()=>[
            this.params,
            this.args.refresher
        ]);
    getEchartOptions(torqueRejectData) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.boltRejectRate')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis',
                valueFormatter: (value)=>{
                    return typeof value === 'string' ? `${value}%` : value;
                }
            },
            yAxis: {
                type: 'category',
                data: torqueRejectData.map((m)=>{
                    return `${m.taskAndBolt}`;
                })
            },
            xAxis: [
                {
                    type: 'value',
                    max: 100,
                    interval: 10,
                    position: 'bottom',
                    axisLabel: {
                        formatter: '{value}%'
                    },
                    data: torqueRejectData.map((item)=>item.boltRejectRate),
                    name: this.intl.t('bi.chartLegend.rejectRate'),
                    nameTextStyle: {
                        padding: 10
                    },
                    nameLocation: 'center'
                },
                {
                    type: 'value',
                    data: torqueRejectData.map((item)=>item.totalCycles),
                    name: this.intl.t('bi.label.partCycles'),
                    nameTextStyle: {
                        padding: 10
                    },
                    nameLocation: 'center'
                }
            ],
            series: [
                {
                    name: this.intl.t('bi.chartLegend.rejectRate'),
                    type: 'bar',
                    xAxisIndex: 0,
                    data: torqueRejectData.map((m)=>{
                        return m.boltRejectRate?.toFixed(2).replace(/\.00/, '') ?? 0;
                    }),
                    color: EchartsTheme.colorPalette.danger
                },
                {
                    name: this.intl.t('bi.label.partCycles'),
                    type: 'line',
                    xAxisIndex: 1,
                    data: torqueRejectData?.map((item)=>item.totalCycles),
                    color: EchartsTheme.colorPalette.biBlue
                }
            ]
        };
    }
    static{
        template_83864e4a7ecd45638e3c0fded07873ae(`
    <div class="h-100 w-100">
      {{#if this.torqueRejectData.isRunning}}
        <Spinner />
      {{else if (get this.torqueRejectData.value.chartData.series '0.data.length')}}
        <EChart
          class="h-100 w-100 avg-torque-rejects-chart"
          @option={{this.torqueRejectData.value.chartData}}
          @exportData={{this.torqueRejectData.value.exportData}}
        />
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
