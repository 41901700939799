import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { anyInvalid, anyTreeItemDirty } from 'eflex/util/getter-helpers';
import { arrayAttr, caption } from 'eflex/decorators';

const ON_THE_FLY_REVISION = 'OTF';

export default class KineticPartRev extends Model {
  @attr('string', { defaultValue: 'kineticPartRev' }) type;
  @attr('string') path;
  @attr('string') sourceJobNumber;
  @attr('boolean', { defaultValue: false }) kineticDeleted;

  @arrayAttr captions;
  @caption name;

  @hasMany('kineticOperation', { inverse: 'parent', async: false, cascadeDelete: true }) operations;
  @belongsTo('kineticPart', { inverse: 'partRevs', async: false }) parent;
  @belongsTo('kineticPartRev', { inverse: null, async: false }) sourcePartRev;

  get isFromBom() {
    return isEmpty(this.sourceJobNumber);
  }

  get childType() {
    return 'kineticOperation';
  }

  get treeIconClass() {
    return 'icon-group';
  }

  @cached
  get children() {
    return this.operations.filter(item => !item.isDeleted);
  }

  get allChildren() {
    return this.operations;
  }

  get bomOnlyOperations() {
    return this.children.filter(item => item.isFromBom);
  }

  get part() {
    return this.parent;
  }

  set part(val) {
    this.parent = val;
  }

  get tasks() {
    return this.children.flatMap(operation => operation.children);
  }

  get isOtf() {
    return !isEmpty(this.sourceJobNumber) && this.name === ON_THE_FLY_REVISION;
  }

  @cached
  get isSelfOrChildInvalid() {
    return anyInvalid(this.children);
  }

  @cached
  get isSelfOrChildDirty() {
    return this.isDirty || anyTreeItemDirty(this.operations);
  }

  get validatedRelationships() {
    return ['operations'];
  }

  rollbackAttributes() {
    this.operations.forEach(operation => { operation.rollbackAttributes(); });
    super.rollbackAttributes(...arguments);
  }
}
