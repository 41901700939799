import Modifier from 'ember-modifier';
import { service } from '@ember/service';
import { task, waitForEvent, waitForQueue } from 'ember-concurrency';
import { isTesting, macroCondition } from '@embroider/macros';

export default class OnResizeModifier extends Modifier {
  @service resizeListener;

  #didSetup = false;

  modify(element, positional) {
    if (macroCondition(isTesting())) {
      return;
    }

    if (this.#didSetup) {
      return;
    }

    this.#didSetup = true;
    this._resizeListener.perform(element, positional[0]);
  }

  _resizeListener = task({ onState: null }, async (element, callback) => {
    while (true) {
      await waitForEvent(this.resizeListener, 'resized');
      await waitForQueue('afterRender');
      callback(element);
    }
  });
}
