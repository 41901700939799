import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class KineticEmployeeRepo extends Service {
  @service store;
  @service eflexAjax;
  @service notifier;

  getEmployee = task(waitFor(async (employeeId) => {
    return await this.store.queryRecord('kineticEmployee', { employeeId });
  }));

  clockIn = task(waitFor(async (employee, shift) => {
    await this.eflexAjax.post.perform('kinetic/clockIn', {
      employeeID: employee.employeeId,
      shift: shift.shift,
    });

    employee.currentShift = shift;
    employee.clockInTime = new Date();
    await employee.save();
  }));

  clockOut = task(waitFor(async (employee, shift) => {
    if (employee.currentShift === shift) {
      return;
    }

    await this.eflexAjax.post.perform('kinetic/clockOut', {
      employeeID: employee.employeeId,
    });

    employee.currentShift = null;
    employee.clockInTime = null;
    await employee.save();
  }));
}
