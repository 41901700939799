import Model, { attr, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { isHasManyRefDirty } from 'eflex/util/relationship-helpers';
import { arrayAttr, caption } from 'eflex/decorators';

@buildValidations({
  name: [validator('presence', true)],
})
class BomSource extends Model {
  @attr('string') address;
  @caption name;
  @arrayAttr captions;

  @hasMany('area', { async: false, inverse: 'bomSource', as: 'bom-source' }) areas;
  @hasMany('bomConfig', {
    async: false,
    inverse: 'bomSource',
    as: 'bom-source',
    embedded: true,
    polymorphic: true,
  }) configs;

  get polymorphic() {
    return true;
  }

  get isDirty() {
    return super.isDirty ||
      isHasManyRefDirty(this, 'areas') ||
      this.configs.some(item => item.isDirty);
  }

  get usesVariableValidation() {
    return false;
  }

  get validatedRelationships() {
    return ['configs'];
  }

  get nonDeletedConfigs() {
    return this.configs.filter(item =>
      !item.isDeleted && !item.isAlwaysRun,
    );
  }

  get components() {
    return this.configs.filter(config => config.constructor.modelName === 'component');
  }

  get simpleComponents() {
    return this.components.filter(component =>
      !component.isComplex && !component.isAlwaysRun,
    );
  }

  get complexComponents() {
    return this.components.filter(component => component.isComplex);
  }

  get variables() {
    return this.configs.filter(config => config.constructor.modelName === 'bom-variable');
  }

  get isSelfOrChildInvalid() {
    return this.isInvalid || anyInvalid(this.nonDeletedConfigs);
  }

  get alwaysRunComponent() {
    return this.components.find(component => component.isAlwaysRun);
  }

  get stations() {
    return this.areas.flatMap(area => area.stations);
  }
}

export default BomSource;
