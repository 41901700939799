/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, waitForQueue } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { EditorModes } from 'eflex/constants/work-instructions/tool-props';
import Crop from 'eflex/components/work-instruction-editor/tool-properties/crop';
import Icons from 'eflex/components/work-instruction-editor/tool-properties/icons';
import Image from 'eflex/components/work-instruction-editor/tool-properties/image';
import Lines from 'eflex/components/work-instruction-editor/tool-properties/lines';
import Normal from 'eflex/components/work-instruction-editor/tool-properties/normal';
import Selection from 'eflex/components/work-instruction-editor/tool-properties/selection';
import Shape from 'eflex/components/work-instruction-editor/tool-properties/shape';
import Text from 'eflex/components/work-instruction-editor/tool-properties/text';

export default class WorkInstructionEditorToolProperties extends Component {
  @tracked showConfirmModal = false;
  @tracked isTool = false;
  @tracked locked = false;

  @service eventBus;
  @service workInstructionRepo;

  get toolPropertiesComponent() {
    switch (this.args.visibleMode) {
      case EditorModes.CROP: {
        return Crop;
      }
      case EditorModes.LINES: {
        return Lines;
      }
      case EditorModes.NORMAL: {
        return Normal;
      }
      case EditorModes.SHAPE: {
        return Shape;
      }
      case EditorModes.TEXT: {
        return Text;
      }
      case EditorModes.IMAGE: {
        return Image;
      }
      case EditorModes.SELECTION: {
        return Selection;
      }
      case EditorModes.ICONS: {
        return Icons;
      }
      default: {
        return null;
      }
    }
  }

  onUpdateSelectedTool = task(waitFor(async () => {
    await waitForQueue('afterRender');
    this.isTool = this.args.visibleMode !== EditorModes.NORMAL;
    this.eventBus.trigger('updateSelectedProperties');
  }));

  @action
  onDelete() {
    if (this.isTool) {
      this.args.removeAction?.();
    } else {
      this.showConfirmModal = true;
    }
  }

  deleteConfirm = task(waitFor(async () => {
    await this.workInstructionRepo.deleteSelectedWorkInstructions.perform(
      this.args.currentWorkInstruction,
      [this.args.currentWorkInstruction],
    );

    await this.args.onDelete?.(true);
    this.showConfirmModal = false;
  }));

  @action
  onLockChange(val) {
    this.locked = val;
    this.args.onLockChange(val);
  }
}
