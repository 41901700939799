import { template as template_9f8f9fd2ecb54ad58c53b12bb91ed635 } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import Component from '@glimmer/component';
class JemTaskPlcDetails extends Component {
    get vars() {
        return this.args.task.variableDefs.filter((item)=>item.isFromDevice).map((def)=>({
                name: def.name,
                value: this.args.childStatus?.processData?.find((item)=>item.name === def.name)?.value
            }));
    }
    static{
        template_9f8f9fd2ecb54ad58c53b12bb91ed635(`
    <div class="jem-process-data" ...attributes>
      <div class="mt-2">
        {{#each this.vars as |variable|}}
          <div class={{@childStatus.taskStatusClass}}>
            <div class="process-data-name fs-4 fw-semibold text-gray-800">
              {{variable.name}}
            </div>
            <div class="process-data-value detail-text-value fw-bold text-gray-900">
              {{variable.value}}
            </div>
          </div>
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default template_9f8f9fd2ecb54ad58c53b12bb91ed635(`
{{yield
  (hash
    taskDetails=(component
      JemTaskPlcDetails
      task=@task
      childStatus=@childStatus
    )
  )
}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
