import { template as template_4e84c309e9aa4cc0a3a70d03c1f3db7d } from "@ember/template-compiler";
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { get } from '@ember/helper';
import Component from '@glimmer/component';
import { reject, prop, pipe, map } from 'ramda';
import { sortByProps } from 'ramda-adjunct';
import HardwareSelect from 'eflex/components/hardware-select';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
const hardwareTypes = [
    'adam-io',
    'turck-io'
];
export default class TaskDeviceConfigPick extends Component {
    @service
    hardwareRepo;
    @service
    intl;
    get hardwareIos() {
        return this.hardwareRepo.allHardware.flatMap((hardware)=>hardware.hardwareIos);
    }
    get inputOptions() {
        const options = [
            {
                label: this.intl.t('none'),
                input: null
            }
        ];
        const inputs = pipe(reject(prop('outputOnly')), sortByProps([
            'hardwareName',
            'hardwareId',
            'index'
        ]), map((input)=>({
                label: `${input.hardwareName} - ${input.inputName}`,
                input
            })))(this.hardwareIos);
        return options.concat(inputs);
    }
    get outputOptions() {
        return pipe(reject(prop('inputOnly')), sortByProps([
            'hardwareName',
            'hardwareId',
            'index'
        ]), map((output)=>({
                label: `${output.hardwareName} - ${output.outputName}`,
                output
            })))(this.hardwareIos);
    }
    setInput = ({ input })=>{
        this.args.task.hardwareInput = input;
        if (input == null) {
            this.args.task.taskConfigs.forEach((config)=>{
                config.pickSensorEnabled = false;
                if (isEmpty(config.interlockedTime)) {
                    config.interlockedTime = 1;
                }
            });
        }
    };
    setOutput = ({ output })=>{
        this.args.task.hardwareOutputs = [
            output
        ];
    };
    static{
        template_4e84c309e9aa4cc0a3a70d03c1f3db7d(`
    <div class="row form-row">
      {{#if @task.usesOperations}}
        <HardwareSelect
          class='test-pick-input'
          @model={{@task}}
          @types={{hardwareTypes}}
          @form={{@form}}
        as |hardwareSelect|>
          <hardwareSelect.select class='col test-pick-input-hardware' />
          <hardwareSelect.index class='col-1 test-pick-input-hardware-index' />
          <hardwareSelect.ioIndex class='col-1 test-pick-input-io-index' />
        </HardwareSelect>

        <HardwareSelect
          class='test-pick-output'
          @model={{@task}}
          @types={{hardwareTypes}}
          @form={{@form}}
        as |hardwareSelect|>
          <hardwareSelect.select
            class='col test-pick-output-hardware'
            @property='hardwareOutputAssignedType'
          />
          <hardwareSelect.index
            class='col-1 test-pick-output-hardware-index'
            @property='hardwareOutputIndex'
          />
          <hardwareSelect.ioIndex
            class='col-1 test-pick-output-io-index'
            @property='ioOutputIndex'
          />
        </HardwareSelect>
      {{else}}
        <div class='col'>
          <@form.element
            @controlType="power-select"
            @label={{t "plant.task.pick.sensor"}}
            @options={{this.inputOptions}}
            @value={{findBy "input" @task.hardwareInput this.inputOptions}}
            @optionLabelPath="label"
            @onChange={{this.setInput}}
          as |el|>
            <el.control
              class="app-sensor-input-select"
              @placeholder={{t "none"}}
              @triggerClass="form-control"
              @searchField="label"
              @searchEnabled={{true}}
            />
          </@form.element>
        </div>
        <div class="col">
          <@form.element
            @controlType="power-select"
            @label={{t "plant.task.pick.indicator"}}
            @options={{this.outputOptions}}
            @optionLabelPath="label"
            @value={{
              findBy
              "output"
              (get @task.hardwareOutputs 0)
              this.outputOptions
            }}
            @onChange={{this.setOutput}}
          as |el|>
            <el.control
              class="app-indicator-output-select"
              @placeholder={{t "none"}}
              @triggerClass="form-control"
              @searchField="label"
              @searchEnabled={{true}}
            />
          </@form.element>
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
