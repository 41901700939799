import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class KineticJobsController extends Controller {
  @service router;

  @action
  onOperationSelected(assemblyOperation) {
    this.router.transitionTo('kineticOperations.operation', assemblyOperation.operation);
  }
}
