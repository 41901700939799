/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { service } from '@ember/service';

export default class WorkInstructionEditorToolButtons extends Component {
  supportedFileTypes = WieSettings.supportedFileTypes;

  @service imageEditor;

  @action
  imagesAction(files, resetInput) {
    this.args.imagesAction?.(files);
    resetInput();
  }
}
