import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { tracked } from '@glimmer/tracking';
import { copyable, caption, arrayAttr } from 'eflex/decorators';

@copyable
@buildValidations({
  compareLocation: [
    validator('number', {
      gt: 0,
      allowString: true,
      allowNone: false,
    }),
  ],

})
class BarcodeString extends Model {
  @attr('string') barcodeString;
  @attr('number', { defaultValue: 1 }) compareLocation;
  @attr('boolean', { defaultValue: false }) enabled;
  @arrayAttr captions;
  @caption name;

  @belongsTo('task', { inverse: 'strings', async: false }) task;
  @belongsTo('taskConfig', { inverse: 'strings', async: false }) taskConfig; //task or taskConfig

  @service intl;

  @tracked isBarcodeReader = false;

  get parent() {
    return this.task ?? this.taskConfig;
  }

  get errorIdentifier() {
    return this.parent?.errorIdentifier ?? this.parent?.name;
  }

  get stringValue() {
    if (this.parent.task?.passThrough) {
      return this.intl.t('componentPassThrough');
    } else {
      return this.barcodeString;
    }
  }

  get parentString() {
    const index = this.parent.strings.indexOf(this);
    return this.parent.task.strings[index];
  }

  get isRegex() {
    if (!this.stringValue || !this.stringValue.startsWith('/') || !this.stringValue.endsWith('/')) {
      return false;
    }

    return true;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['task', 'taskConfig']),
    };
  }
}

export default BarcodeString;
