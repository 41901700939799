import Model, { attr } from '@ember-data/model';
import { objectAttr } from 'eflex/decorators';

export default class ConfigurationHistory extends Model {
  @attr('string') version;
  @attr('string') versionDateString;
  @attr('string') versionSequence;
  @attr('string') note;
  @attr('date') timestamp;

  @objectAttr location;
  @objectAttr user;

  get stationId() {
    return this.location?.id ?? this.location?._id;
  }

  get station() {
    return this.store.peekRecord('station', this.stationId);
  }
}
