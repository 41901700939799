import { template as template_b544fbe2f1834fc0a2968b8f2043decc } from "@ember/template-compiler";
import { get, hash } from '@ember/helper';
import { t } from 'ember-intl';
import { isPickWithNoConfiguredInput, getTaskHardwareInput, getTaskHardwareOutput } from 'eflex/helpers';
import { JemTaskTimerDetails } from 'eflex/components/jem/task/timer';
const JemTaskPickDetails = template_b544fbe2f1834fc0a2968b8f2043decc(`
<div ...attributes>
  <div class="mt-2">
    {{#unless (isPickWithNoConfiguredInput @station @task)}}
      <div class="fs-4 fw-semibold text-gray-800 sensor">
        {{t "plant.task.sensor"}}
      </div>
      <div class="detail-text-value fw-bold text-gray-900 text-truncate hardware-input-name">
        {{get (getTaskHardwareInput @station @task) 'inputName'}}
      </div>
    {{/unless}}
  </div>
  <div>
    <div class="fs-4 fw-semibold text-gray-800">
      {{t "plant.task.pick.indicator"}}
    </div>
    <div class="hardware-output {{@childStatus.taskStatusClass}}">
      {{#let (getTaskHardwareOutput @station @task) as |output|}}
        <div class="detail-text-value fw-bold text-gray-900 text-truncate hardware-output-name">
          {{output.outputName}}
        </div>
      {{/let}}
    </div>
  </div>
  {{#if @taskConfig.isTimer}}
    <JemTaskTimerDetails
      @childStatus={{@childStatus}}
      @taskConfig={{@taskConfig}}
    />
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template_b544fbe2f1834fc0a2968b8f2043decc(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskPickDetails
        task=@task
        childStatus=@childStatus
        taskConfig=@taskConfig
        station=@station
        hardware=@hardware
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
