import { template as template_434212a2aaec4aadb7d7eb26a5c43f68 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import PowerSelectMultipleWithCreate from 'eflex/components/power-select-multiple-with-create';
import { fn, get } from '@ember/helper';
import { t } from 'ember-intl';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { set } from 'eflex/helpers';
const getOptions = (siblings, fieldName, additionalOptions)=>{
    let options = siblings.filter((item)=>!item.isDeleted).map((item)=>item[fieldName]);
    options.forEach((element, index, array)=>{
        if (element?.content) {
            array[index] = element.content;
        }
    });
    options = options.flat();
    if (additionalOptions) {
        options = options.concat(additionalOptions);
    }
    return [
        ...new Set(options)
    ].toSorted();
};
const showCreateWhen = (tag, options)=>{
    return !options.includes(tag);
};
const onCreateTag = (fieldName, taggedModel, tag)=>{
    taggedModel[fieldName] = [
        ...taggedModel[fieldName],
        tag
    ];
};
export default class TagsSelect extends Component {
    @service
    intl;
    suggestAddTag = (tag)=>{
        return this.intl.t('plant.task.addNewTag', {
            tag
        });
    };
    static{
        template_434212a2aaec4aadb7d7eb26a5c43f68(`
    {{#let (withDefault @fieldName 'tags') as |fieldName|}}
      <PowerSelectMultipleWithCreate
        @triggerClass="h-auto"
        @disabled={{@disabled}}
        @options={{getOptions @siblings fieldName @additionalOptions}}
        @selected={{get @taggedModel fieldName}}
        @searchEnabled={{true}}
        @searchMessage={{t "plant.task.addTagPrompt"}}
        @onCreate={{fn onCreateTag fieldName @taggedModel}}
        @onChange={{set @taggedModel fieldName}}
        @showCreateWhen={{showCreateWhen}}
        @renderInPlace={{true}}
        @placeholder={{t "plant.task.tagsPlaceholder"}}
        @buildSuggestion={{this.suggestAddTag}}
        class="standard-select tags-select w-100"
        ...attributes
      as |tag|>
        {{tag}}
      </PowerSelectMultipleWithCreate>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
