import { template as template_b48aa6d8b0384617bd70d878ed77f059 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { not, or, lt } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
const MAX_STRINGS = 16;
export default class TaskContextConfigVision extends Component {
    @service
    store;
    @service
    intl;
    get allStringsEnabled() {
        return this.args.taskConfig.strings?.every((item)=>item.enabled);
    }
    get remainingStrings() {
        return MAX_STRINGS - this.args.task.strings.length;
    }
    addMultipleStrings = (stringQuantity)=>{
        const task = this.args.task;
        for(let i = 0; i < stringQuantity; i++){
            if (this.remainingStrings) {
                const stringCount = task.strings.length + 1;
                const name = this.intl.t('plant.task.vision.subtaskDefaultName');
                task.strings.push(this.store.createRecord('barcodeString', {
                    name: `${name} ${stringCount}`
                }));
                task.taskConfigs.forEach((config)=>{
                    config.strings.push(this.store.createRecord('barcodeString', {}));
                });
            }
        }
    };
    removeString = (index)=>{
        const task = this.args.task;
        task.taskConfigs.forEach((config)=>{
            config.strings.splice(index, 1);
        });
        task.strings.splice(index, 1);
    };
    toggleStrings = ()=>{
        this.args.taskConfig.strings.forEach((string)=>{
            string.enabled = !this.allStringsEnabled;
        });
    };
    onIsBarcodeReaderChanged = (taskString, index, checked)=>{
        taskString.isBarcodeReader = checked;
        if (!checked) {
            this.args.task.taskConfigs.forEach((config)=>{
                config.strings[index].barcodeString = '';
            });
        }
    };
    static{
        template_b48aa6d8b0384617bd70d878ed77f059(`
    <div class="bootstrap-table task-config-vision">
      <div class="row form-row header-row">
        <div class="col-icon model-specific border-0">
          <@form.element
            @controlType="checkbox"
            @value={{this.allStringsEnabled}}
            @onChange={{this.toggleStrings}}
          />
        </div>
        <div class="col">
          {{t "task"}}
        </div>
        <div class="col-1 text-uppercase">
          {{t "barcode"}}
        </div>
        <div class="col model-specific">
          {{t "plant.task.barcode.stringToRead"}}
        </div>
        <div class="col-icon border-0" />
      </div>

      {{#each @taskConfig.strings as |configString index|}}
        <div class="row form-row vision-string-row">
          <div class="col-icon model-specific">
            <@form.element
              @model={{configString}}
              @controlType="checkbox"
              @property="enabled"
            />
          </div>
          <div class="col vision-string-name">
            <@form.element
              @model={{configString.parentString}}
              @property="name"
            />
          </div>
          <div class="col-1 d-flex justify-content-center">
            <@form.element
              @model={{configString}}
              @controlType="checkbox"
              @value={{
                or
                configString.parentString.isBarcodeReader
                configString.barcodeString
              }}
              @onChange={{
                fn
                this.onIsBarcodeReaderChanged
                configString.parentString
                index
              }}
              class="is-barcode-reader"
            />
          </div>
          <div class="col model-specific">
            <@form.element
              @model={{configString}}
              @property="barcodeString"
            as |el|>
              <el.control
                class="barcode-string-to-read"
                disabled={{
                  or
                  @disabled
                  (not
                    (or
                      configString.parentString.isBarcodeReader
                      configString.barcodeString
                    )
                  )
                }}
              />
            </@form.element>
          </div>
          <div class="col-icon">
            <TrashCan
              disabled={{@disabled}}
              @onDelete={{fn this.removeString index}}
            />
          </div>
        </div>
      {{/each}}

      <div class="row form-row no-hover-row">
        <div class="col-auto">
          <QuantitySelect
            class="px-2 text-uppercase vision-add-string"
            @disabled={{or @disabled this.addStringDisabled (lt this.remainingStrings 1)}}
            @max={{this.remainingStrings}}
            @onSubmit={{this.addMultipleStrings}}
          >
            {{t "addSubtask"}}
          </QuantitySelect>
        </div>
      </div>
      <div class="row form-row model-specific">
        <div class="col-3">
          <@form.element
            @controlType="number"
            @label={{t "programNumber"}}
            @property="programNumber"
          />
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
