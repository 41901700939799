import { template as template_d4e06e3ae02849c6b18ea349faf4e009 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { toTree } from 'eflex/util/tree-helpers';
import { t } from 'ember-intl';
import AddButton from 'eflex/components/add-button';
import BsForm from 'eflex/components/bs-form';
import { or } from 'ember-truth-helpers';
import TreeSelect from 'eflex/components/tree-select';
import { fn } from '@ember/helper';
import TrashCan from 'eflex/components/trash-can';
const getDisplayedCodes = (codes)=>{
    return codes.filter((item)=>!item.isDeleted);
};
export default class CodesConfig extends Component {
    @service
    store;
    @service
    currentUser;
    @service
    areaRepo;
    get treeData() {
        return this.areaRepo.areas.map((area)=>toTree(area, {
                lastModel: 'station'
            }));
    }
    addCode = ()=>{
        this.store.createRecord(this.args.codeModel, {});
    };
    removeCode = (code)=>{
        code.deleteRecord();
    };
    updateCode = (code, stations)=>{
        const records = stations.map((station)=>this.store.peekRecord(station.modelName, station.id));
        code.stations = records;
    };
    static{
        template_d4e06e3ae02849c6b18ea349faf4e009(`
    <div class="sticky-top d-flex flex-row align-items-center">
      <div class="ms-auto">
        <AddButton
          class="add-code"
          @disabled={{@disabled}}
          @onClick={{this.addCode}}
        >
          {{t 'reasonCodes.reasonCode'}}
        </AddButton>
      </div>
    </div>

    <div class="col bootstrap-table main-overflow h-100" ...attributes>
      <BsForm
        @model={{this}}
        @showValidations={{@showValidations}}
        class="w-100"
      as |form|>
        <div class="row form-row header-row sticky-top bg-white">
          <div class="col">
            {{t "code"}}
          </div>
          <div class="col">
            {{t "description"}}
          </div>
          <div class="col">
            {{t "stations"}}
          </div>
          <div class="col-icon border-0" />
        </div>

        {{#let this.treeData as |treeData|}}
          {{#each (getDisplayedCodes @codes) as |code|}}
            <div class="row form-row code-config-row align-items-start">
              <div class="col">
                <form.element
                  @controlType="text"
                  @property="code"
                  @model={{code}}
                as |el|>
                  <el.control
                    class="code"
                    disabled={{or @disabled code.isFromSync}}
                  />
                </form.element>
              </div>

              <div class="col">
                <form.element
                  @controlType="text"
                  @property="description"
                  @model={{code}}
                as |el|>
                  <el.control
                    class="description"
                    disabled={{or @disabled code.isFromSync}}
                  />
                </form.element>
              </div>

              <div class="col">
                <form.element class="editable-tree-select">
                  <TreeSelect
                    class="code-station-select"
                    @fullWidth={{true}}
                    @contentClass="code-station-tree-content"
                    @disabled={{@disabled}}
                    @sourceTree={{treeData}}
                    @checkable={{true}}
                    @checkedIds={{code.stationIds}}
                    @placeholder={{t "codes.selectedStations"}}
                    @checkAllText={{t "codes.allStations"}}
                    @onCheckedChanged={{fn this.updateCode code}}
                    @minDepth={{2}}
                  />
                </form.element>
              </div>
              <div class="col-icon align-self-center">
                <TrashCan
                  disabled={{or @disabled code.isFromSync}}
                  @onDelete={{fn this.removeCode code}}
                />
              </div>
            </div>
          {{/each}}
        {{/let}}
      </BsForm>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
