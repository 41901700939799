import { isPresent } from '@ember/utils';

export default function inputNum(func) {
  return function({ target }) {
    let val = target.value;
    if (isPresent(val)) {
      val = Number(val);
    }

    func(val);
  };
}
