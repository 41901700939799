import Controller, { inject as controller } from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class BomManagerController extends Controller {
  @service bomSourceRepo;
  @service router;

  @controller('bomManager/bomSource') bomManagerBomSourceController;

  @action
  bomSourceSelected(bomSource) {
    this.router.transitionTo('bomManager.bomSource', bomSource.id);
  }
}
