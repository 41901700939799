import { template as template_c9f3538c4e3e4659b31e5fccd0d44f78 } from "@ember/template-compiler";
import TaskStatuses from 'eflex/constants/task-statuses';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import echartsTheme from 'eflex/echarts-theme';
import moment from 'moment-timezone';
import { waitFor } from '@ember/test-waiters';
import { t } from 'ember-intl';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import BsButton from 'eflex/components/bs-button';
import BsForm from 'eflex/components/bs-form';
import onKey from 'ember-keyboard/modifiers/on-key';
import BiTotalCyclesLabel from 'eflex/components/bi/total-cycles-label';
import BiAverageQualityLabel from 'eflex/components/bi/average-quality-label';
export default class BiQualityPChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    @tracked
    selectedSubgroupSize;
    @tracked
    subgroupSize;
    @tracked
    totalCycles;
    @tracked
    manualRefresh = false;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params)=>{
        const cycleDataQuery = await this.queryRunner.queryWithParams.perform(params, [
            {
                $count: 'total'
            }
        ]);
        const docs = await this.queryRunner.runQuery.perform('BuildStatuses', cycleDataQuery);
        const total = docs[0]?.total ?? 0;
        this.totalCycles = total;
        let subgroupSize;
        if (this.manualRefresh) {
            subgroupSize = this.subgroupSize;
        } else {
            subgroupSize = total <= 100 ? 2 : Math.floor(total / 50);
            this.selectedSubgroupSize = subgroupSize.toString();
        }
        this.manualRefresh = false;
        const pChartDataQuery = await this.queryRunner.queryWithParams.perform(params, [
            {
                $sort: {
                    timestamp: 1
                }
            },
            {
                $group: {
                    _id: 0,
                    docs: {
                        $push: {
                            timestamp: '$timestamp',
                            reject: {
                                $sum: {
                                    $cond: {
                                        if: {
                                            $and: [
                                                {
                                                    $gte: [
                                                        '$status',
                                                        TaskStatuses.REJECT_RANGE_START
                                                    ]
                                                },
                                                {
                                                    $lte: [
                                                        '$status',
                                                        TaskStatuses.REJECT_RANGE_END
                                                    ]
                                                }
                                            ]
                                        },
                                        then: 1,
                                        else: 0
                                    }
                                }
                            },
                            status: '$status'
                        }
                    }
                }
            },
            {
                $unwind: {
                    path: '$docs',
                    includeArrayIndex: 'rownum'
                }
            },
            {
                $group: {
                    _id: {
                        subgroup: {
                            $floor: {
                                $divide: [
                                    '$rownum',
                                    subgroupSize
                                ]
                            }
                        }
                    },
                    avgRejectRate: {
                        $avg: '$docs.reject'
                    },
                    totalCycles: {
                        $first: '$totalCycles'
                    },
                    count: {
                        $sum: 1
                    },
                    rejectCount: {
                        $sum: '$docs.reject'
                    },
                    timestamp: {
                        $first: '$docs.timestamp'
                    }
                }
            },
            {
                $sort: {
                    timestamp: 1
                }
            },
            {
                $group: {
                    _id: null,
                    mean: {
                        $avg: '$avgRejectRate'
                    },
                    data: {
                        $push: {
                            timestamp: '$timestamp',
                            avgRejectRate: '$avgRejectRate',
                            subgroupSize: '$count',
                            subgroupRejects: '$rejectCount'
                        }
                    }
                }
            },
            {
                $addFields: {
                    cl: {
                        $multiply: [
                            {
                                $sqrt: {
                                    $divide: [
                                        {
                                            $multiply: [
                                                '$mean',
                                                {
                                                    $subtract: [
                                                        1,
                                                        '$mean'
                                                    ]
                                                }
                                            ]
                                        },
                                        subgroupSize
                                    ]
                                }
                            },
                            3
                        ]
                    },
                    subgroupSize
                }
            },
            {
                $addFields: {
                    ucl: {
                        $min: [
                            1,
                            {
                                $add: [
                                    '$mean',
                                    '$cl'
                                ]
                            }
                        ]
                    },
                    lcl: {
                        $max: [
                            0,
                            {
                                $subtract: [
                                    '$mean',
                                    '$cl'
                                ]
                            }
                        ]
                    }
                }
            },
            {
                $project: {
                    ucl: 1,
                    lcl: 1,
                    mean: 1,
                    data: 1,
                    subgroupSize: 1,
                    outliers: {
                        $reduce: {
                            input: '$data',
                            initialValue: {
                                count: 0
                            },
                            in: {
                                count: {
                                    $cond: {
                                        if: {
                                            $or: [
                                                {
                                                    $gt: [
                                                        '$$this',
                                                        '$ucl'
                                                    ]
                                                },
                                                {
                                                    $lt: [
                                                        '$$this',
                                                        '$lcl'
                                                    ]
                                                }
                                            ]
                                        },
                                        then: {
                                            $add: [
                                                '$$value.count',
                                                1
                                            ]
                                        },
                                        else: '$$value.count'
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                $unwind: {
                    path: '$data'
                }
            },
            {
                $group: {
                    _id: null,
                    data: {
                        $push: {
                            $cond: {
                                if: {
                                    $eq: [
                                        '$data.subgroupSize',
                                        '$subgroupSize'
                                    ]
                                },
                                then: {
                                    timestamp: '$data.timestamp',
                                    totalRejectRate: '$mean',
                                    subgroupRejectRate: '$data.avgRejectRate',
                                    subgroupSize: '$data.subgroupSize',
                                    subgroupRejects: '$data.subgroupRejects',
                                    ucl: '$ucl',
                                    lcl: '$lcl'
                                },
                                else: '$$REMOVE'
                            }
                        }
                    }
                }
            },
            {
                $project: {
                    _id: 0,
                    data: 1,
                    totalCycles: {
                        $multiply: [
                            {
                                $first: '$data.subgroupSize'
                            },
                            {
                                $size: '$data'
                            }
                        ]
                    },
                    totalRejects: {
                        $sum: {
                            $map: {
                                input: '$data',
                                in: '$$this.subgroupRejects'
                            }
                        }
                    }
                }
            }
        ]);
        const chartData = await this.queryRunner.runQuery.perform('BuildStatuses', pChartDataQuery);
        return {
            chartData: this.getEchartOptions(chartData[0]),
            exportData: ()=>chartData[0]?.data,
            totalCycles: chartData[0]?.totalCycles,
            totalRejects: chartData[0]?.totalRejects
        };
    }));
    results = trackedTask(this, this.getData, ()=>[
            this.params,
            this.subgroupSize,
            this.args.refresher
        ]);
    getEchartOptions(rejectLineChartData) {
        return {
            title: {
                show: false,
                text: 'station quality p-chart'
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis',
                formatter (params) {
                    const tooltipItems = params.map(({ marker, seriesName, value })=>`${marker} ${seriesName} <span style="float:right;font-weight:bold;">${(value * 100).toFixed(2).replace(/\.00/, '')}%</span>`).join('<br>');
                    return `<div style="width:200px;">${tooltipItems}</div>`;
                }
            },
            xAxis: {
                type: 'category',
                data: rejectLineChartData?.data?.map(({ timestamp })=>moment(timestamp).format('MM/DD/YYYY hh:mm A')),
                axisLabel: {
                    show: true,
                    rotate: 75,
                    interval: 'auto'
                }
            },
            yAxis: {
                name: this.intl.t('bi.chartLabel.taskRejectRate'),
                nameGap: 75,
                nameLocation: 'middle',
                type: 'value',
                scale: true,
                axisLabel: {
                    formatter (value) {
                        return `${Math.round(value * 100)}%`;
                    }
                }
            },
            series: [
                {
                    name: this.intl.t('bi.chartLegend.rejectRate'),
                    data: rejectLineChartData?.data?.map((item)=>item.subgroupRejectRate),
                    type: 'line',
                    color: echartsTheme.colorPalette.danger,
                    symbol: 'emptyCircle',
                    symbolSize: 7,
                    markLine: {
                        data: [
                            {
                                type: 'average',
                                name: 'Avg'
                            }
                        ],
                        precision: 4,
                        label: {
                            show: true,
                            position: 'insideEndTop',
                            formatter: (params)=>`${this.intl.t('bi.chartLegend.averageRejectRate')}: ${(params.value * 100).toFixed(2).replace(/\.00/, '')}%`
                        }
                    }
                },
                {
                    name: this.intl.t('bi.chartLegend.ucl'),
                    data: rejectLineChartData?.data?.map((item)=>item.ucl),
                    type: 'line',
                    color: echartsTheme.colorPalette.taskRowRed
                },
                {
                    name: this.intl.t('bi.chartLegend.lcl'),
                    data: rejectLineChartData?.data?.map((item)=>item.lcl),
                    type: 'line',
                    color: echartsTheme.colorPalette.taskRowRed
                }
            ]
        };
    }
    refreshChart = ()=>{
        this.subgroupSize = Number(this.selectedSubgroupSize);
        this.manualRefresh = true;
    };
    static{
        template_c9f3538c4e3e4659b31e5fccd0d44f78(`
    {{#if this.results.isRunning}}
      <Spinner />
    {{else if this.results.value.totalCycles}}
      <div class="row justify-content-center component-bi-quality-p-chart">
        <div class="col-auto">
          <BiAverageQualityLabel
            @displayQuality={{false}}
            @total={{this.results.value.totalCycles}}
            @rejectCount={{this.results.value.totalRejects}}
          />
        </div>
        <div class="col-auto">
          <BiTotalCyclesLabel
            @total={{this.results.value.totalCycles}}
            @rejectCount={{this.results.value.totalRejects}}
          />
        </div>
      </div>
      <EChart
        @option={{this.results.value.chartData}}
        @exportData={{this.results.value.exportData}}
        class="station-quality-p-chart"
      />
      <div class="justify-content-center subgroup-size-wrapper d-flex">
        <label class="text-light rg-from fw-light">
          {{t "bi.pchart.subgroupSize"}}
        </label>
        <BsForm @model={{this}} as |form|>
          <form.element
            class="subgroup-size ps-3 pe-3"
            @controlType="number"
            @property="selectedSubgroupSize"
            {{onKey "Enter" this.refreshChart}}
            as |el|
          >
            <el.control min="2" />
          </form.element>
        </BsForm>
        <div>
          <BsButton
            class="refresh-pchart"
            @type="light"
            @onClick={{this.refreshChart}}
            @outline={{true}}
          >
            {{t "bi.pchart.refreshbutton"}}
          </BsButton>
        </div>
      </div>
    {{else}}
      <BiNoDataPlaceholder />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
